import { makeStyles } from "@material-ui/core"

const HEADER_HEIGHT = 250

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    // "& .main-carousel": {
    //   display: "flex",
    //   "&.single": {
    //     justifyContent: "center",
    //   },
    // },
  },
  header: {
    position: "relative",
    zIndex: -1,
    background: "#fff",
    [theme.breakpoints.up("md")]: {
      height: HEADER_HEIGHT,
      "&::before": {
        content: '""',
        position: "absolute",
        top: -200,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#f8f8f8",
        zIndex: -1,
      },
    },
  },
  headerContentContainer: {
    maxWidth: 1400,
    width: "auto",
    padding: "30px 60px 60px",
  },
  title: {
    fontSize: 24,
    lineHeight: 1.47,
  },
  main: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
      top: -HEADER_HEIGHT + 50,
    },
  },
  sidebarLeft: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      zIndex: 1,
      flex: "0 0 300px",
      "&::before": {
        content: '""',
        background:
          "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
        width: 40,
        height: "98%",
        position: "absolute",
        left: "100%",
        top: HEADER_HEIGHT,
      },
    },
  },
  sidebarRight: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      zIndex: 1,
      flex: "0 0 200px",
      paddingLeft: 40,
      "&::before": {
        content: '""',
        background:
          "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
        width: 40,
        height: "98%",
        position: "absolute",
        right: "100%",
        top: HEADER_HEIGHT,
      },
    },
  },
  positionStatic: {
    position: "relative",
    top: HEADER_HEIGHT,
  },
  positionSticky: {
    position: "sticky",
    top: 10,
  },
  mainCenter: {
    flexGrow: 1,
    overflow: "hidden",
  },
  mainCenterSingleItem: {
    display: "flex",
    justifyContent: "center",
  },
  customerFormContainer: {
    display: "flex",
    justifyContent: "center",
  },
  customerInformationForm: {
    paddingTop: `3em`,
    flex: `1 1 auto`,
    margin: `0 20px`,
    [theme.breakpoints.up("sm")]: {
      flex: `0 1 550px`,
    },
  },
  formActions: {
    "& button": {
      marginBottom: 12,
    },
  },
  buttonProgress: {
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: "50%",
    marginLeft: "-12px",
    marginTop: "-12px",
  },
}))

export default useStyles
