import React from "react"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import { makeStyles } from "@material-ui/core"
import { useLocation } from "react-router-dom"
import qs from "qs"

import { useTranslation } from "react-i18next"
import useDataprovider from "../../../hooks/useDataprovider"

import {
  FormField,
  SerieField,
  ModelField,
  TotalPriceField,
  TypeOfPaymentField,
  BsiIncField,
  DownPaymentField,
  BalloonField,
  GFVField,
  CarPriceField,
  InsuranceField,
  DiscountField,
  FinanceAmountField,
  TermField,
  BSIOptionField,
  BsiPriceField,
  MonthlyField,
  ResidualField,
  PaymentMethodField,
  CampaignField,
  AccessoriesSelectionField,
  CashAmountField,
  AccessoriesSummaryItemField,
  AccessoriesSummaryPriceField,
  AccessoriesTotalPriceField,
  AccessoriesInstallationField,
  AccessoriesDiscountField,
  AddOnAmountField,
} from "./Fields"

import { paymentMethodOptions, adornmentBaht } from "../../../constants"
import { getBSIName } from "../utils/utils"

const HEAD_HEIGHT = 250

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 20px",
    position: "relative",
    minHeight: 800,
    flexShrink: 0,
    width: "calc(100vw - 15px)",
    maxWidth: 350,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
  },
  removeCarCircleBtn: {
    color: "#666666",
    position: "absolute",
    top: 0,
    right: 0,
  },
  carImageContainer: {
    height: HEAD_HEIGHT,
  },
  discountContainer: {
    marginTop: theme.spacing(1.7),
  },
}))

/**
 * Calculation interface for a single car
 * @param {Object} props - Car properties
 * @param {string} props.carMedia - path to model image
 * @param {number} props.carsLength - to tell how many cars in calculator,
 *  if > 1 system will show close icon (for remove car from the list)
 * @param {number} props.index - index of this car
 * @param {function} props.remove - to remove this car from the list
 * @return {React.ReactElement} main calculation component
 */

const CalculateCarForm = React.memo((props) => {
  const { carsLength, carMedia, index, remove } = props

  const { brand } = useDataprovider()
  const bsiName = getBSIName(brand)

  const { search } = useLocation()
  const selectedWheelWithId = qs.parse(search.split("?")[1])
  const { seriesId: rawSeriesId, wheels } = selectedWheelWithId
  const seriesId = parseInt(rawSeriesId)

  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const serieLabel = `${t(`fieldset.serie_model.title`)}${
    brand !== "motorrad" && i18n.language === "th" ? t("common.car.formal") : ""
  }`
  return (
    <div className={classes.root} key={index}>
      {carsLength > 1 && (
        <CancelRoundedIcon
          fontSize="large"
          className={classes.removeCarCircleBtn}
          onClick={() => remove(index)}
        />
      )}
      <div className={classes.carImageContainer}>
        <img
          src={carMedia || `/images/placeholder-${brand}.png`}
          alt="Placeholder"
          style={{ width: "100%", marginBottom: "1em" }}
        />
      </div>
      <div className={classes.carFormContainer}>
        <SerieField
          name={`cars.${index}.serie`}
          label={serieLabel}
          seriesId={seriesId}
        />
        <ModelField
          name={`cars.${index}.model`}
          type="select"
          label={false}
          seriesId={seriesId}
        />
        <FormField
          name={`cars.${index}.retail_price`}
          type="price_readonly"
          adornment={adornmentBaht}
          label={t(`field.retail_price.label`)}
        />
        <PaymentMethodField
          name={`cars.${index}.payment_method`}
          type="button_group"
          row
          label={t("field.payment_method.label")}
          options={paymentMethodOptions}
        />
        <TypeOfPaymentField
          name={`cars.${index}.type_of_payment`}
          type="select"
          label={t("field.payment_type.label")}
        />
        <BSIOptionField
          name={`cars.${index}.bsi`}
          type="select"
          label={t(`field.${brand}.label`)}
          brand={brand}
        />
        <BsiPriceField
          name={`cars.${index}.bsi_price`}
          type="number"
          adornment={adornmentBaht}
          brand={brand}
        />
        <BsiIncField
          name={`cars.${index}.bsi_inc`}
          type="radio"
          label={false}
          brand={brand}
        />
        <DiscountField
          name={`cars.${index}.discount`}
          type="number"
          label={`${t("field.car.discount.label")} (${t("common.optional")})`}
          adornment={adornmentBaht}
        />
        <CarPriceField
          name={`cars.${index}.car_price`}
          type="price_readonly"
          label={t("field.car_price.label")}
          adornment={adornmentBaht}
        />
        <DownPaymentField
          name={`cars.${index}.down_payment`}
          label={t("field.down_payment.label")}
        />
        <InsuranceField
          name={`cars.${index}.insurance`}
          type="number"
          label={`${t("field.insurance.label", { bsi: bsiName })} (${t(
            "common.optional"
          )})`}
          adornment={adornmentBaht}
        />
        <AccessoriesSelectionField
          name={`cars.${index}.accessories_selection`}
          label={`${t("nav.accessories")} (${t("common.optional")})`}
          brand={brand}
        />
        <AccessoriesSummaryItemField
          name={`cars.${index}.accessories`}
          label={t("field.accessory.quotation")}
          brand={brand}
          wheels={wheels}
          seriesId={seriesId}
        />
        <AccessoriesSummaryPriceField
          name={`cars.${index}.accessory_price_summary`}
          type="total_price"
        />
        <AccessoriesDiscountField
          index={index}
          label={`${t("field.accessory.discount.label")} (${t(
            "common.optional"
          )})`}
        />
        <AccessoriesTotalPriceField
          name={`cars.${index}.accessory_total`}
          label={t("field.total_acc.label")}
          type="total_price"
        />
        <AddOnAmountField
          index={index}
          label={`${t("field.add_on_finance.label")} (${t("common.optional")})`}
          brand={brand}
        />
        <FinanceAmountField
          fast
          name={`cars.${index}.finance_amount`}
          label={t("field.finance_amount.label")}
          type="price_readonly"
          adornment={adornmentBaht}
        />
        <AccessoriesInstallationField
          name={`cars.${index}.accessory_installation`}
          label={`${t("nav.accessories")} ${t("field.install_fee.label")}`}
          type="number"
          adornment={adornmentBaht}
        />
        <CashAmountField
          fast
          name={`cars.${index}.cash_amount`}
          label={t("field.cash_amount.label")}
          type="price_readonly"
          adornment={adornmentBaht}
        />
        <TotalPriceField
          name={`cars.${index}.total_price`}
          type="price_readonly"
          label={t("field.total_price.label")}
          adornment={adornmentBaht}
        />
        <TermField
          fast
          name={`cars.${index}.term`}
          type="select"
          label={t("field.term.label")}
        />
        <BalloonField
          name={`cars.${index}.balloon`}
          label={t("field.balloon.label")}
        />
        <GFVField name={`cars.${index}.gfv`} />
        <ResidualField
          name={`cars.${index}.residual`}
          label={t("field.residual.label")}
        />
        <MonthlyField
          name={`cars.${index}.monthly_installment`}
          label={t("field.monthly_installment.label")}
          type="number"
          adornment={adornmentBaht}
        />
        {/* <CampaignField
          name={`cars.${index}.campaigns`}
          term={`cars.${index}.term`}
          label={t(`field.special_campaign.title`)}
        /> */}
      </div>
    </div>
  )
})

export default CalculateCarForm
