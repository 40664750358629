import React from "react"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import useDataprovider from "../../../hooks/useDataprovider"

import {
  FormField,
  SerieField,
  ModelField,
  TotalPriceField,
  TypeOfPaymentField,
  BsiIncField,
  DownPaymentField,
  BalloonField,
  GFVField,
  CarPriceField,
  InsuranceField,
  FinanceAmountField,
  TermField,
  BSIOptionField,
  MonthlyField,
  ResidualField,
  PaymentMethodField,
  CampaignField,
} from "../../calculate/components/Fields"

import {
  QuantityWithTotalField,
  DiscountWithPercentField,
} from "./corporateFields"

import { paymentMethodOptions, adornmentBaht } from "../../../constants"

const HEAD_HEIGHT = 250

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 20px",
    position: "relative",
    minHeight: 800,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
  },
  removeCarCircleBtn: {
    color: "#666666",
    position: "absolute",
    top: 0,
    right: 0,
  },
  carImageContainer: {
    height: HEAD_HEIGHT,
  },
}))

const CorporateCarForm = React.memo((props) => {
  const {
    carsLength,
    carMedia,
    isValid,
    /*values, */ index,
    /*car,*/ remove,
  } = props

  const { brand } = useDataprovider()

  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.root} key={index}>
      {carsLength > 1 && (
        <CancelRoundedIcon
          fontSize="large"
          className={classes.removeCarCircleBtn}
          onClick={() => remove(index)}
        />
      )}
      <div className={classes.carImageContainer}>
        <img
          src={carMedia || `/images/placeholder-${brand}.png`}
          alt="Placeholder"
          style={{ width: "100%", marginBottom: "1em" }}
        />
      </div>
      <div className={classes.carFormContainer}>
        <SerieField
          name={`cars.${index}.serie`}
          label={t(`fieldset.serie_model.title`)}
        />
        <ModelField
          name={`cars.${index}.model`}
          type="select"
          label={false}
          corporateSale={true}
        />
        <FormField
          name={`cars.${index}.retail_price`}
          type="price_readonly"
          adornment={adornmentBaht}
          label={t(`field.retail_price.label`)}
        />
        <QuantityWithTotalField
          name={`cars.${index}.quantity`}
          type="quantity_total"
          label={t("field.quantity.label")}
        />
        <PaymentMethodField
          name={`cars.${index}.payment_method`}
          type="button_group"
          row
          label={t("field.payment_method.label")}
          options={paymentMethodOptions}
        />
        <TypeOfPaymentField
          name={`cars.${index}.type_of_payment`}
          type="select"
          label={t("field.payment_type.label")}
        />
        <BSIOptionField
          name={`cars.${index}.bsi`}
          type="select"
          label={t(`field.${brand}.label`)}
          brand={brand}
        />
        <BsiIncField
          name={`cars.${index}.bsi_inc`}
          type="radio"
          // options={bsiIncOptions}
          label={false}
          brand={brand}
        />
        <InsuranceField
          name={`cars.${index}.insurance`}
          type="number"
          label={t("field.insurance.label")}
          adornment={adornmentBaht}
        />
        <DiscountWithPercentField
          name={`cars.${index}.discount`}
          type="percent_with_preview"
          label={`${t("field.discount.label")} (${t("common.optional")})`}
          adornment={adornmentBaht}
        />
        <CarPriceField
          name={`cars.${index}.car_price`}
          type="price_readonly"
          label={t("field.car_price.label")}
          adornment={adornmentBaht}
        />
        <TotalPriceField
          name={`cars.${index}.total_price`}
          type="price_readonly"
          label={t("field.total_price.label")}
          adornment={adornmentBaht}
        />
        <DownPaymentField
          name={`cars.${index}.down_payment`}
          label={t("field.down_payment.label")}
        />
        <FinanceAmountField
          fast
          name={`cars.${index}.finance_amount`}
          label={t("field.finance_amount.label")}
          type="price_readonly"
          adornment={adornmentBaht}
        />
        <TermField
          fast
          name={`cars.${index}.term`}
          type="select"
          label={t("field.term.label")}
        />
        <BalloonField
          name={`cars.${index}.balloon`}
          label={t("field.balloon.label")}
        />
        <GFVField name={`cars.${index}.gfv`} />
        <ResidualField
          name={`cars.${index}.residual`}
          label={t("field.residual.label")}
        />
        <MonthlyField
          name={`cars.${index}.monthly_installment`}
          label={t("field.monthly_installment.label")}
          type="number"
          isValid={isValid}
          adornment={adornmentBaht}
        />
        {/* <CampaignField
          name={`cars.${index}.campaigns`}
          term={`cars.${index}.term`}
          label={t(`field.special_campaign.title`)}
        /> */}
      </div>
    </div>
  )
})

export default CorporateCarForm
