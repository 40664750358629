/* eslint-disable  react/prop-types */
import React, { useState, useEffect } from "react"
import _ from "lodash"
import { useFormikContext } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Grid from "@material-ui/core/Grid"
import ListSubheader from "@material-ui/core/ListSubheader"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ButtonBase from "@material-ui/core/ButtonBase"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import useDataprovider from "../../../../hooks/useDataprovider"
import { findIndex } from "../../utils/utils"
import {
  getAccessoryPrice,
  getNetAccessoryPrice,
  calculateCashAmount,
  calculateAddOnAmount,
  addVat,
  getAddonPriceInFinance,
} from "../../utils/calculation"
import { thousandSeparator } from "../../utils/utils"
import { FormField } from "./CalculateFields"
import InputField from "../../../../components/InputField"
import ButtonDialog from "../../../../components/ButtonDialog"
import { useTranslation } from "react-i18next"
import {
  discountOptions,
  adornmentBaht,
  adornmentPercent,
  TYPE_OF_DISCOUNT,
  PAYMENT_METHOD,
  BSI_INCLUDE_TYPE,
} from "../../../../constants"

import useStyles from "./style"

const AccessoryDetail = ({ activeAccessory }) => {
  const {
    name,
    description,
    image,
    unit_price,
    discounted_price,
  } = activeAccessory
  const formattedPrice = thousandSeparator(addVat(unit_price))
  const formattedDiscountPrice = discounted_price
    ? thousandSeparator(addVat(discounted_price))
    : null
  const classes = useStyles()
  return (
    <div className={classes.modalRoot}>
      <div className={classes.headerContainer}>
        <div className={classes.modalImageContainer}>
          <img src={image} className={classes.squareImageStyle} />
        </div>
      </div>
      <Typography variant="body1" className={classes.itemNameStyle}>
        {name}
      </Typography>
      {description && (
        <Typography
          variant="body1"
          className={`${classes.thinPriceStyle} ${classes.marginBtm}`}
        >
          {description}
        </Typography>
      )}
      <div className={`${classes.priceContainer} ${classes.marginBtm}`}>
        <Typography
          variant="body1"
          component="span"
          className={classes.boldPriceStyle}
        >
          {formattedDiscountPrice ? formattedDiscountPrice : formattedPrice} THB
        </Typography>
        {formattedDiscountPrice && (
          <Typography
            variant="body2"
            component="span"
            className={`${classes.thinPriceStyle} ${classes.strikeStyle}`}
          >
            {formattedPrice} THB
          </Typography>
        )}
      </div>
    </div>
  )
}

export const AccessoriesSelectionField = (props) => {
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  const classes = useStyles()
  const { t } = useTranslation()
  const index = findIndex(props.name)
  const { accessories } = cars[index]
  const [isOpen, setIsOpen] = useState(false)
  const [activeAccessory, setActiveAccessory] = useState({})
  const serieValue = cars[index].serie
  const { getAccessoriesBySerieId } = useDataprovider()
  const serieAccessories = getAccessoriesBySerieId(serieValue)

  const selectedAccessories = accessories.map((acc) => acc.id)
  const filteredAccessories = serieAccessories.filter(
    (accessory) => !selectedAccessories.includes(accessory.id)
  )

  const categories = serieAccessories
    .map((item) => item.category)
    .filter((value, index, self) => self.indexOf(value) === index)

  const addAccessory = () => {
    setFieldValue(`cars.${index}.accessories`, [
      ...accessories,
      { ...activeAccessory, quantity: 1 },
    ])
    setIsOpen(false)
  }

  const handleSelectAccessory = (id) => {
    setIsOpen(true)
    const accessory = filteredAccessories.find((acc) => acc.id === id)
    setActiveAccessory(accessory)
  }

  const getAccessoriesByCategory = (category) => {
    return filteredAccessories.filter(
      (accessory) => accessory.category === category
    )
  }

  return (
    props.brand === "bmw" && (
      <div className={classes.fieldRoot}>
        <FormControl component="fieldset" variant="outlined" fullWidth>
          <FormLabel component="legend">{props.label}</FormLabel>
          <ButtonDialog
            isOpen={isOpen}
            payload={{
              content: <AccessoryDetail activeAccessory={activeAccessory} />,
              submitBtnLabel: t("common.add"),
            }}
            controller={{
              delay: 0, //second
              lifetime: 2, //min
            }}
            events={{
              onSubmit: addAccessory,
              onDismiss: () => setIsOpen(false),
            }}
            isWide
          />
          <Select
            name={props.name}
            displayEmpty
            variant="outlined"
            margin="dense"
            disabled={serieAccessories.length === 0}
            renderValue={() => {
              return (
                <span className={classes.thinSelectPlaceHolder}>
                  {serieAccessories.length
                    ? t("field.accessories.placeholder")
                    : t("field.accessories.empty")}
                </span>
              )
            }}
          >
            <div className={classes.accessoryItem}>
              {categories.map((category) => {
                const categorisedAccessories = getAccessoriesByCategory(
                  category
                )
                return (
                  categorisedAccessories.length > 0 && (
                    <React.Fragment>
                      <ListSubheader
                        className={classes.subHeaderStyle}
                        disableGutters
                      >
                        {category}
                      </ListSubheader>
                      {categorisedAccessories.map((accessory) => (
                        <MenuItem
                          key={accessory.id}
                          value={accessory.name}
                          onClick={() => handleSelectAccessory(accessory.id)}
                        >
                          {t(`${accessory.name}`)}
                        </MenuItem>
                      ))}
                      <div className={classes.dropdownDivider} />
                    </React.Fragment>
                  )
                )
              })}
            </div>
          </Select>
        </FormControl>
      </div>
    )
  )
}

export const CashAmountField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  const index = findIndex(props.name)
  const { t } = useTranslation()
  const {
    retail_price,
    bsi_inc,
    bsi_price,
    payment_method,
    accessories,
    discount_type,
    insurance,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  } = cars[index]

  const discount_baht = _.get(cars, `${index}.accessory_discount_baht`, 0)
  const discount_percent = _.get(cars, `${index}.accessory_discount_percent`, 0)
  const install_fee = _.get(cars, `${index}.accessory_installation`, 0)
  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht,
    discount_percent,
  })
  const totalAddOnAmount = calculateAddOnAmount({
    insurance,
    accessoryNetPrice,
    bsi_inc,
    bsi_price,
  })
  const value = calculateCashAmount({
    totalAddOnAmount,
    retail_price,
    install_fee,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  })

  const shouldHidden =
    payment_method === PAYMENT_METHOD.CASH ||
    (cars[index].accessories.length === 0 && value === 0)

  return (
    <InputField
      name={props.name}
      type={props.type}
      label={props.label}
      value={value}
      adornment={props.adornment}
      hidden={shouldHidden}
      guideline={t("field.cash_amount.guideline")}
    />
  )
}

export const AccessoriesSummaryItemField = (props) => {
  const { name, wheels = [], seriesId = null } = props
  const {
    values: { cars },
    handleChange,
    setFieldValue,
  } = useFormikContext()
  const { t } = useTranslation()
  const index = findIndex(props.name)
  const { accessories, serie, retail_price, bsi_price } = cars[index]
  const { getAccessoriesBySerieId } = useDataprovider()
  const [isOpen, setIsOpen] = useState(false)
  const [activeAccessory, setActiveAccessory] = useState({})
  const classes = useStyles()

  const handleAdd = ({ accessoryIndex, quantity }) => {
    handleChange({
      target: {
        name: `${name}.${accessoryIndex}.quantity`,
        value: quantity + 1,
      },
    })
  }

  useEffect(() => {
    if (serie !== "" && seriesId) {
      if (wheels.length > 0 && seriesId && seriesId === serie) {
        const seriesAccessories = getAccessoriesBySerieId(seriesId)
        if (seriesAccessories.length > 0) {
          const preSelectedWheels = seriesAccessories.filter((accessory) => {
            return wheels.some((wheel) => {
              return wheel.id == accessory.id
            })
          })
          const selectedWheelsWithQuantity = preSelectedWheels.map(
            (selectedWheel) => {
              return {
                ...selectedWheel,
                quantity: parseInt(
                  wheels.find((wheel) => wheel.id == selectedWheel.id).quantity
                ),
              }
            }
          )
          const totalWheelsPrice = selectedWheelsWithQuantity.reduce(
            (acc, accessory) => {
              const wheelPrice = addVat(
                accessory.discounted_price || accessory.unit_price
              )
              return wheelPrice * accessory.quantity + acc
            },
            0
          )
          const addOnAmountInFinance = getAddonPriceInFinance({
            retail_price,
            totalAddOnAmount: totalWheelsPrice + bsi_price,
          })
          setFieldValue(props.name, selectedWheelsWithQuantity)
          setFieldValue(
            `cars.${index}.add_on_finance_baht`,
            addOnAmountInFinance
          )
        }
      } else if (seriesId !== serie) {
        setFieldValue(props.name, [])
      }
    }
  }, [wheels, seriesId])

  useEffect(() => {
    // clear accessory discount and installation when there are no selected accessory
    if (accessories.length === 0) {
      setFieldValue(`cars.${index}.accessory_discount_baht`, 0)
      setFieldValue(`cars.${index}.accessory_discount_percent`, 0)
      setFieldValue(`cars.${index}.accessory_installation`, 0)
    }
  }, [accessories, setFieldValue])

  const handleMinus = ({ accessoryIndex, quantity, id }) => {
    if (quantity === 1) {
      const filteredAccessories = accessories.filter(
        (accessory) => accessory.id !== id
      )
      setFieldValue(`cars.${index}.accessories`, filteredAccessories)
    } else {
      handleChange({
        target: {
          name: `${name}.${accessoryIndex}.quantity`,
          value: quantity - 1,
        },
      })
    }
  }

  const handleClickImage = (accessory) => {
    setActiveAccessory(accessory)
    setIsOpen(true)
  }

  return (
    props.brand === "bmw" && (
      <div className={classes.fieldRoot}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">{props.label}</FormLabel>
          <ButtonDialog
            isOpen={isOpen}
            payload={{
              content: <AccessoryDetail activeAccessory={activeAccessory} />,
            }}
            controller={{
              delay: 0, //second
              lifetime: 2, //min
            }}
            events={{
              onDismiss: () => setIsOpen(false),
            }}
            isWide
          />
          <div className={classes.solidHeader}>{t("common.items.label")}</div>
          {accessories.map((accessory, index) => {
            const {
              image,
              name: itemName,
              unit_price,
              discounted_price,
              quantity,
              id,
            } = accessory
            const formattedPrice = thousandSeparator(addVat(unit_price))
            const formattedDiscountPrice = discounted_price
              ? thousandSeparator(addVat(discounted_price))
              : null
            return (
              <div className={classes.itemWithImageRoot}>
                <ButtonBase onClick={() => handleClickImage(accessory)}>
                  <div className={classes.squareImageContainer}>
                    <img
                      src={image}
                      alt={`${itemName}`}
                      className={classes.squareImageStyle}
                    />
                  </div>
                </ButtonBase>
                <div className={classes.detailContainer}>
                  <Typography
                    variant="body1"
                    component="span"
                    className={classes.itemNameStyle}
                  >
                    {itemName}
                  </Typography>
                  <div className={classes.detailFooterContainer}>
                    <div className={classes.priceContainer}>
                      <Typography
                        variant="body1"
                        component="span"
                        className={classes.boldPriceStyle}
                      >
                        {discounted_price
                          ? formattedDiscountPrice
                          : formattedPrice}{" "}
                        THB
                      </Typography>
                      {discounted_price && (
                        <Typography
                          variant="body2"
                          component="span"
                          className={`${classes.thinPriceStyle} ${classes.strikeStyle}`}
                        >
                          {formattedPrice} THB
                        </Typography>
                      )}
                    </div>
                    <div className={classes.quantityContainer}>
                      <IconButton
                        onClick={() =>
                          handleMinus({ accessoryIndex: index, quantity, id })
                        }
                        color="primary"
                        className={classes.buttonPadding}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography className={classes.itemNameStyle}>
                        {quantity}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          handleAdd({ accessoryIndex: index, quantity })
                        }
                        color="primary"
                        className={classes.buttonPadding}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </FormControl>
      </div>
    )
  )
}

export const AccessoriesSummaryPriceField = (props) => {
  const { name, type, label, ...rest } = props
  const {
    values: { cars },
  } = useFormikContext()
  const index = findIndex(props.name)
  const { accessories } = cars[index]
  const total = getAccessoryPrice(accessories)

  return (
    <InputField
      name={name}
      type={type}
      label={label}
      value={total}
      hidden={cars[index].accessories.length === 0}
      {...rest}
    />
  )
}

export const AccessoriesTotalPriceField = (props) => {
  const { name, type, label, ...rest } = props
  const {
    values: { cars },
  } = useFormikContext()
  const index = findIndex(props.name)
  const { accessories, discount_type } = cars[index]
  const discount_baht = _.get(cars, `${index}.accessory_discount_baht`, 0)
  const discount_percent = _.get(cars, `${index}.accessory_discount_percent`, 0)
  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht,
    discount_percent,
  })

  return (
    <InputField
      name={name}
      type={type}
      label={label}
      value={accessoryNetPrice}
      hidden={cars[index].accessories.length === 0}
      {...rest}
    />
  )
}

export const AccessoriesInstallationField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  const index = findIndex(props.name)
  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      adornment={props.adornment}
      hidden={cars[index].accessories.length === 0}
      showBlankOnZero={true}
    />
  )
}

export const AccessoriesDiscountField = (props) => {
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  const { label, index, ...rest } = props
  const { discount_type } = cars[index]

  React.useEffect(() => {
    setFieldValue(`cars.${index}.accessory_discount_percent`, 0)
    setFieldValue(`cars.${index}.accessory_discount_baht`, 0)
  }, [discount_type])
  return (
    cars[index].accessories.length !== 0 && (
      <React.Fragment>
        <FormLabel>{label}</FormLabel>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={4}>
            <FormField
              name={`cars.${index}.discount_type`}
              options={discountOptions}
              value={discount_type}
              type="select"
            />
          </Grid>
          <Grid item xs={8}>
            {discount_type === TYPE_OF_DISCOUNT.PERCENT ? (
              <FormField
                name={`cars.${index}.accessory_discount_percent`}
                type="number"
                adornment={adornmentPercent}
                {...rest}
              />
            ) : (
              <FormField
                name={`cars.${index}.accessory_discount_baht`}
                type="number"
                adornment={adornmentBaht}
                {...rest}
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  )
}

export const AddOnAmountField = (props) => {
  const {
    values: { cars },
    errors,
    setFieldValue,
  } = useFormikContext()
  const { label, index, ...rest } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    add_on_finance_type,
    add_on_finance_percent = 0,
    retail_price,
    bsi_inc,
    bsi_price,
    payment_method,
    insurance,
    accessories,
    discount_type,
  } = cars[index]

  const discount_baht = _.get(cars, `${index}.accessory_discount_baht`, 0)
  const discount_percent = _.get(cars, `${index}.accessory_discount_percent`, 0)
  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht,
    discount_percent,
  })
  const totalAddOnAmount = calculateAddOnAmount({
    insurance,
    accessoryNetPrice,
    bsi_inc,
    bsi_price,
  })

  const shouldHide =
    (insurance === 0 || insurance === undefined) &&
    accessories.length === 0 &&
    bsi_inc === BSI_INCLUDE_TYPE.CAR

  React.useEffect(() => {
    const maxBahtValue = retail_price * 0.1
    const bahtValue =
      totalAddOnAmount > maxBahtValue ? maxBahtValue : totalAddOnAmount
    setFieldValue(`cars.${index}.add_on_finance_percent`, 10, false)
    setFieldValue(`cars.${index}.add_on_finance_baht`, bahtValue, false)
  }, [add_on_finance_type, retail_price, totalAddOnAmount, bsi_price])

  const isError = !!(
    _.get(errors, `cars.${index}.add_on_finance_percent`) ||
    _.get(errors, `cars.${index}.add_on_finance_baht`)
  )

  return (
    props.brand === "bmw" &&
    !shouldHide &&
    payment_method !== PAYMENT_METHOD.CASH && (
      <div className={classes.fieldRoot}>
        <FormLabel>{label}</FormLabel>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={4}>
            <FormField
              name={`cars.${index}.add_on_finance_type`}
              options={discountOptions}
              value={add_on_finance_type}
              noBottomGap
              type="select"
            />
          </Grid>
          <Grid item xs={8}>
            {add_on_finance_type === TYPE_OF_DISCOUNT.PERCENT ? (
              <FormField
                name={`cars.${index}.add_on_finance_percent`}
                type="number"
                value={add_on_finance_percent}
                adornment={adornmentPercent}
                noBottomGap
                {...rest}
              />
            ) : (
              <FormField
                name={`cars.${index}.add_on_finance_baht`}
                type="number"
                adornment={adornmentBaht}
                noBottomGap
                {...rest}
              />
            )}
          </Grid>
        </Grid>
        {!isError && (
          <div className={classes.gapTop}>
            <Typography variant="caption">
              {t("field.finance_amount.guideline")}
            </Typography>
          </div>
        )}
      </div>
    )
  )
}
