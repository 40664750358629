import React from "react"
import { useLocalStorage } from "react-use"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

export default function CookiePolicyDesk() {
  const [value, setValue, remove] = useLocalStorage(
    "bmw-dsk:allow-cookie",
    false
  )
  const [open, setOpen] = React.useState(false)

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: `20px 40px`,
        color: "white",
        background: "#222",
        display: value === true ? "none" : "block",
      }}
    >
      <Typography variant="h4" gutterBottom style={{ marginBottom: 30 }}>
        คุกกี้บนเว็บไซต์บีเอ็มดับเบิลยูกรุ๊ป
      </Typography>
      <Typography gutterBottom style={{ marginBottom: 20 }}>
        ด้วยการรวบรวมคุกกี้
        เว็บไซต์จะสามารถมอบประสบการณ์การใช้งานที่ดีขึ้นและตรงกับความต้องการของลูกค้า&nbsp;ข้อมูลนี้จะถูกใช้โดยบีเอ็มดับเบิลยูประเทศไทยและบริษัทในเครือเท่านั้น
        ดังนั้นการโอนถ่ายข้อมูลคุกกี้จึงไม่สามารถเกิดขึ้นได้
        ลูกค้าสามารถปิดการตั้งค่าการทำงานของคุกกี้ได้โดยเข้าไปที่หน้าการตั้งค่าบราวเซอร์และตั้งค่าความเป็นส่วนตัวเพื่อหยุดการรวบรวมข้อมูลทุกอย่างของคุกกี้ในอนาคต
      </Typography>
      <div style={{ display: "flex" }}>
        <Button
          variant="outlined"
          style={{ marginRight: 8 }}
          onClick={(e) => setOpen(true)}
        >
          อ่านข้อมูลเพิ่มเติม
        </Button>
        <Button variant="contained" onClick={(e) => setValue(true)}>
          ยอมรับ Cookie
        </Button>
      </div>

      <Dialog open={open}>
        <DialogContent>
          <Typography>COOKIES POLICY</Typography>
          <p>
            คุกกี้คือไฟล์ข้อความขนาดเล็กซึ่งถูกคัดลอกลงในฮาร์ดดิสก์ของคุณโดยเว็บไซต์
            คุกกี้ไม่ทำให้คอมพิวเตอร์ของคุณเสียหายและไม่มีไวรัสใดๆ ทั้งสิ้น
            คุกกี้จากเว็บไซต์ของเราจะไม่เก็บรวบรวมข้อมูลส่วนตัวของคุณ
            คุณสามารถปิดการใช้งานคุกกี้ได้ตลอดเวลาผ่านการตั้งค่าในบราวเซอร์ของคุณ
            ตามกฎแล้ว
            คุกกี้จะถูกใช้บนเว็บไซต์ของเราตามระยะเวลาที่คุณใช้งานเท่านั้น
            ทั้งนี้เพื่อใช้สำหรับวัตถุประสงค์ที่ไม่เป็นที่เปิดเผย
            การประเมินผลทางสถิติ
            และเพื่อการปรับปรุงความง่ายและสะดวกต่อการใช้งานสำหรับผู้ใช้ต่อไป
            ในบางกรณี คุกกี้อาจถูกใช้งานเพื่อวัตถุประสงค์อื่นๆ
            ในบางส่วนของเว็บไซต์
            คุณจะได้รับการแจ้งเตือนในกรณีนี้หากคุณเข้าใช้งานในส่วนที่เกี่ยวข้องดังกล่าว
          </p>
          <p>1. คุกกี้คืออะไร</p>
          <p>
            คุกกี้คือไฟล์ข้อความขนาดเล็กซึ่งถูกดาวน์โหลดไปยังคอมพิวเตอร์ของลูกค้า
            ไฟล์นี้อนุญาตให้เรา (บริษัท บีเอ็มดับเบิลยู (ประเทศไทย) จำกัด)
            สามารถรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าชมเว็บไซต์ของผู้เยี่ยมชมได้
          </p>
          <p>2. ประโยชน์ของคุกกี้</p>
          <p>
            ด้วยการรวบรวมคุกกี้
            เว็บไซต์จะสามารถมอบประสบการณ์การใช้งานที่ดีขึ้นและตรงกับความต้องการของลูกค้าในอนาคตได้
            ข้อมูลนี้จะถูกใช้โดยบีเอ็มดับเบิลยูประเทศไทยและบริษัทในเครือเท่านั้น
            ดังนั้นการโอนถ่ายข้อมูลคุกกี้จึงไม่สามารถเกิดขึ้นได้
          </p>
          <p>
            คุกกี้จะบันทึกการตั้งค่าแรกของเว็บไซต์ไว้ด้วยเช่นกัน
            ซึ่งจะช่วยให้ลูกค้าได้รับค่าที่ตั้งไว้เดิมแต่แรกทุกครั้งที่ใช้งาน
            หากคุกกี้ถูกลบ การตั้งค่าทุกอย่างจะกลับไปที่ค่าเริ่มต้น
          </p>
          <p>3. คุกกี้ไม่ทำให้เกิดอันตรายต่อคอมพิวเตอร์ของคุณ</p>
          <p>
            วัตถุประสงค์หลักของคุกกี้คือการรวบรวมข้อมูลในรูปแบบไฟล์
            ซึ่งไม่มีหน้าที่อื่นใดนอกเหนือจากนี้
          </p>
          <p>4. สิ่งที่คุกกี้ไม่ได้รวบรวมมีอะไรบ้าง</p>
          <p>
            คุกกี้ไม่เก็บรวบรวมข้อมูลเกี่ยวกับข้อมูลรายละเอียดส่วนบุคคลของคุณเช่น
            หมายเลขโทรศัพท์ หรือวันเดือนปีเกิดของคุณ
            หากมีการเก็บข้อมูลเพิ่มเติมนอกเหนือจากนี้จะต้องได้รับความยินยอมจากลูกค้าก่อนการเก็บข้อมูล
          </p>
          <p>5. วิธีการปิดการทำงานของคุกกี้ทำอย่างไร</p>
          <p>
            ลูกค้าสามารถปิดการตั้งค่าการทำงานของคุกกี้ได้โดยเข้าไปที่หน้าการตั้งค่าบราวเซอร์และตั้งค่าความเป็นส่วนตัวเพื่อหยุดการรวบรวมข้อมูลทุกอย่างของคุกกี้ในอนาคต
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
