import _ from "lodash"
import { Decimal } from "decimal.js"
import {
  calculateFinanceAmount,
  calculateResidual,
  calculateCarPrice,
  findRate,
  tvmCalculatePayment,
  calculateFutureValue,
  addVat,
  getAccessoryPrice,
  getAccDiscountValue,
  getNetAccessoryPrice,
  getAddonPriceInFinance,
  calculateAddOnAmount,
  calculateCashAmount,
} from "./calculation"

import {
  PAYMENT_METHOD,
  PAYMENT_TYPE,
  BSI_INCLUDE_TYPE,
  TYPE_OF_DISCOUNT,
} from "../../../constants"

const prepareSubmitQuotation = (values, { lang, user, findSFRate }) => {
  const submitValues = {
    company_id: user.company_id || 1,
    branch_id: user.branch_id || 1,
    user_id: user.legacy_id || 1,
    first_name: _.get(values, "customer_information.customer_firstname"),
    last_name: _.get(values, "customer_information.customer_surname"),
    action: 1,
    email: _.get(values, "customer_information.email"),
    phone: _.get(values, "customer_information.telephone").replaceAll("-", ""),
    nickname: _.get(values, "customer_information.customer_nickname", ""),
    line_id: _.get(values, "customer_information.customer_line_id", ""),
    sale_name: _.get(values, "customer_information.sale_name", ""),
    sale_contact: user.email,
    sale_phone: _.get(values, "customer_information.sale_phone", "").replaceAll(
      "-",
      ""
    ),
    remark: _.get(values, "remark", ""),
    pick_up_remark: `${_.get(values, "pick_up_remark", "")}\n\n${_.get(values, "pick_up_amount", "")}`,
    print_status: 1,
    export_file: 1,
    data: _.map(values.cars, (car) => {
      const {
        serie,
        model,
        retail_price,
        discount = 0,
        bsi_inc,
        bsi,
        bsi_price,
        insurance = 0,
        type_of_payment,
        payment_method,
        term,
        down_payment,
        balloon,
        boundary,
        margin_rate,
        campaigns,
        accessory_installation,
        accessories,
        discount_type,
        accessory_discount_baht,
        accessory_discount_percent,
        add_on_finance_type = TYPE_OF_DISCOUNT.PERCENT,
        add_on_finance_baht = 0,
        add_on_finance_percent = 10,
      } = car
      const sf_rate = findSFRate(
        model,
        type_of_payment,
        payment_method,
        term,
        down_payment,
        balloon
      )
      const car_price = calculateCarPrice(
        retail_price,
        discount,
        bsi_inc,
        bsi_price
      )

      const totalAccPrice = getAccessoryPrice(accessories)
      const accessoryDiscountValue = getAccDiscountValue({
        totalPrice: totalAccPrice,
        discount_type,
        discount_baht: accessory_discount_baht,
        discount_percent: accessory_discount_percent,
      })
      const netAccPrice = getNetAccessoryPrice({
        accessories,
        discount_type,
        discount_baht: accessory_discount_baht,
        discount_percent: accessory_discount_percent,
      })
      const totalAddOnAmount = calculateAddOnAmount({
        insurance,
        accessoryNetPrice: netAccPrice,
        bsi_inc,
        bsi_price,
      })

      const addOnAmountInFinance = getAddonPriceInFinance({
        retail_price,
        totalAddOnAmount,
        add_on_finance_type,
        add_on_finance_baht,
        add_on_finance_percent,
      })

      let suggested_finance = calculateFinanceAmount({
        retail_price,
        down_payment: 25,
        discount,
        bsi_inc,
        bsi_price,
        addOnAmountInFinance,
      })

      const balloonLimit = _.get(boundary, `balloon.1`, 45)
      const hpbl_sf_rate = findSFRate(model, "2", "1", 60, 25, balloonLimit)

      const suggestedFinanceOption =
        payment_method === PAYMENT_METHOD.CASH
          ? {
              down: 25,
              down_amount: Decimal(0.01).times(25).times(car_price).toNumber(), // 0.01 * 25 * car_price
              balloon: balloonLimit,
              balloon_amount: Decimal(0.01)
                .times(balloonLimit)
                .times(car_price)
                .toNumber(), // 0.01 * balloonLimit * car_price
              finance: suggested_finance,
              term: 60,
              monthly: tvmCalculatePayment(
                findRate(hpbl_sf_rate, margin_rate),
                60,
                suggested_finance,
                calculateFutureValue(
                  retail_price,
                  car_price,
                  boundary.balloon[1],
                  type_of_payment
                ),
                0
              ),
            }
          : {}

      return {
        model: serie,
        tier: model,
        payment_type:
          payment_method === PAYMENT_METHOD.CASH ? `5` : type_of_payment,
        price: Decimal(retail_price)
          .plus(bsi_inc === BSI_INCLUDE_TYPE.CAR ? bsi_price : 0)
          .toNumber(), // retail_price + BSI (If inc. in Car)
        discount: discount,
        option: 0,
        term: term,
        down: down_payment,
        down_amount: 0.01 * down_payment * car_price,
        balloon: balloon,
        balloon_amount: Decimal(0.01)
          .times(balloon)
          .times(
            type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE
              ? retail_price
              : car_price
          )
          .toNumber(),
        // 0.01 * balloon * (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE ? retail_price : car_price)
        deposit: balloon,
        deposit_amount: Decimal(0.01)
          .times(balloon)
          .times(car_price)
          .toNumber(), // 0.01 * balloon * car_price
        finance: calculateFinanceAmount({
          retail_price,
          down_payment,
          discount,
          bsi_inc,
          bsi_price,
          addOnAmountInFinance,
        }),
        monthly: car.monthly_installment,
        residual: calculateResidual(car_price, down_payment, balloon),
        margin: car.margin_rate,
        customer_rate: findRate(sf_rate, car.margin_rate),
        flate_rate: sf_rate,
        dealer_rebate: car.rebate || 0,
        rebate_sharing: 0,
        insurance: insurance,
        remark: car.remark,
        raw: "raw",
        bsi: bsi_price,
        include: bsi_inc,
        lang: lang,
        bsi_year: bsi,
        status: 1,
        campaign_list: _.map(campaigns, _.toNumber),
        installation_fee: accessory_installation,
        accessory_discount_amount: accessoryDiscountValue,
        accessory_total_amount: totalAccPrice,
        accessory_net_amount: netAccPrice + accessory_installation,
        accessory_cash_amount: calculateCashAmount({
          totalAddOnAmount,
          retail_price,
          install_fee: accessory_installation,
          add_on_finance_type,
          add_on_finance_baht,
          add_on_finance_percent,
        }),
        accessories: accessories.map((accessory) => ({
          id: accessory.id,
          unit_price: addVat(accessory.unit_price),
          discounted_price: accessory.discounted_price
            ? addVat(accessory.discounted_price)
            : null,
          quantity: accessory.quantity,
          total_price:
            addVat(accessory.discounted_price || accessory.unit_price) *
            accessory.quantity,
        })),
        ...suggestedFinanceOption,
      }
    }),
  }

  return submitValues
}

export default prepareSubmitQuotation
