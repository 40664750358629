import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  fieldRoot: {
    marginBottom: theme.spacing(2.5),
    padding: "4px 0px",
    maxWidth: "calc(100vw - 40px)",
  },
  removeCampaignBtn: {
    color: "#666",
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  },
  disabledCampaignBtn: {
    display: "none",
  },
  campaignImageContainer: {
    position: "relative",
    margin: "10px 0",
  },
  campaignImg: {
    borderRadius: 10,
    width: "100%",
  },
  tooltipText: {
    whiteSpace: "pre-line",
  },
  checkicon: {
    borderRadius: 3,
    width: 25,
    height: 25,
    border: "1px solid black",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  accessoryItem: {
    width: "350px",
    "& li": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
    },
  },
  thinSelectPlaceHolder: {
    fontWeight: "normal",
  },
  subHeaderStyle: {
    fontWeight: 300,
    lineHeight: "20px",
    fontSize: "12px",
    paddingLeft: "10px",
  },
  dropdownDivider: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    "&:last-child": {
      borderTop: "none",
    },
  },
  solidHeader: {
    backgroundColor: "#E0E0E0",
    padding: theme.spacing(1.25),
    marginBottom: theme.spacing(1.75),
  },
  itemWithImageRoot: {
    padding: theme.spacing(1.25),
    paddingLeft: 0,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  squareImageContainer: {
    width: "70px",
    height: "70px",
    marginRight: "12px",
    flexShrink: 0,
  },
  squareImageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  },
  itemNameStyle: {
    lineHeight: "20px",
    fontWeight: 300,
    fontSize: "14px",
  },
  detailContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  detailFooterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
  },
  boldPriceStyle: {
    fontWeight: 700,
    fontSize: "14px",
    marginRight: "4px",
  },
  quantityContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  thinPriceStyle: {
    fontWeight: 300,
    fontSize: "12px",
  },
  strikeStyle: {
    textDecorationLine: "line-through",
  },
  buttonPadding: {
    padding: theme.spacing(1),
  },
  modalRoot: {
    width: "100%",
  },
  modalImageContainer: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(6),
    width: "180px",
    height: "180px",
  },
  marginBtm: {
    marginBottom: theme.spacing(1),
  },
  gapTop: {
    marginTop: theme.spacing(0.5),
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
  },
}))

export default useStyles
