/* eslint-disable  react/prop-types */
import React, { useCallback, useEffect } from "react"
import { Field, FastField, useFormikContext } from "formik"
import Alert from "@material-ui/lab/Alert"

import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Decimal } from "decimal.js"

import { ReactComponent as ArteriskIcon } from "../../../components/Icon/Arterisk.svg"

import InputField, {
  LockableButton,
  FieldLabel,
} from "../../../components/InputField"

import useDataprovider from "../../../hooks/useDataprovider"

import {
  findRate,
  calculateFinanceAmount as FA,
  calculateFutureValue,
  calculateResidual,
  tvmCalculatePayment as calcPMT,
  calculateRebate,
} from "../utils/calculation"

import useAdvanceCalculation from "../hooks/useAdvanceCalculation"

import {
  PAYMENT_METHOD,
  PAYMENT_TYPE,
  typeOfPaymentOptions,
  adornmentPercent,
} from "../../../constants"

import campaignOptions from "../../../data/campaignOptions"

import { makeStyles, withStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import Checkbox from "@material-ui/core/Checkbox"
import FormLabel from "@material-ui/core/FormLabel"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import CheckIcon from "@material-ui/icons/Check"
import Tooltip from "@material-ui/core/Tooltip"

const Arterisk = () => (
  <ArteriskIcon style={{ width: 14, height: 14, marginRight: 8 }} />
)

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: "#f0f0f0",
  },
  tooltip: {
    minWidth: 400,
    backgroundColor: "#f0f0f0",
    padding: theme.spacing(4),
    color: theme.palette.common.black,
    fontSize: 14,
    lineHeight: 1.8,
  },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  fieldRoot: {
    marginBottom: theme.spacing(2.5),
    padding: 4,
    maxWidth: "calc(100vw - 40px)",
  },
  removeCampaignBtn: {
    color: "#666",
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  },
  disabledCampaignBtn: {
    display: "none",
  },
  campaignImageContainer: {
    position: "relative",
    margin: "10px 0",
  },
  campaignImg: {
    borderRadius: 10,
    width: "100%",
  },
  tooltipText: {
    whiteSpace: "pre-line",
  },
  checkicon: {
    borderRadius: 3,
    width: 25,
    height: 25,
    border: "1px solid black",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
}))

const findIndex = (name) => {
  return name.split(".")[1]
}

/*
 * Default FormField, for normal Input
 **/

class FormField extends React.PureComponent {
  static defaultProps = {
    inputRowStyle: { position: "relative" },
  }

  render() {
    const {
      fast = false,
      name,
      type,
      label,
      locked = false,
      lockable,
      inputRowStyle,
      inputFieldProps,
      hidden,
      ...rest
    } = this.props
    const FieldComponent = fast ? FastField : Field
    return (
      <FieldComponent name={name}>
        {({ field, form, meta }) => {
          const { values, touched, errors, setFieldValue } = form
          const { advance_calculation } = values
          const [prefix, index, fieldName] = field.name.split(".")
          const lockname = `${prefix}.${index}.locked.${fieldName}`

          const allLocked = _.get(values, `${prefix}.${index}.locked`)
          const countUnlock = _.filter(allLocked, (v) => v === false).length
          const lockValue = locked || _.get(values, lockname)
          const disableLockClick = lockValue === true && countUnlock >= 2

          const handleToggleLock = () => {
            const targetLockValue = !_.get(values, lockname)
            if (targetLockValue === false && countUnlock >= 2) {
              console.log(`Can't unlock more than 2 fields`)
              return
            }
            setFieldValue(lockname, targetLockValue)
          }

          return (
            <div>
              <FieldLabel label={label} lockable={lockable} hidden={hidden} />
              <div style={inputRowStyle}>
                <LockableButton
                  lockable={lockable}
                  locked={lockValue}
                  label={label}
                  disabled={disableLockClick}
                  onToggleLock={handleToggleLock}
                  hidden={hidden}
                />
                <InputField
                  type={type}
                  label={label}
                  locked={advance_calculation && lockValue}
                  lockable={lockable}
                  hidden={hidden}
                  error={
                    _.get(touched, field.name) && !!_.get(errors, field.name)
                  }
                  helperText={_.get(errors, field.name)}
                  {...field}
                  {...inputFieldProps}
                  {...rest}
                />
              </div>
            </div>
          )
        }}
      </FieldComponent>
    )
  }
}

export const MatrixRowField = (props) => {
  const { values, setFieldValue } = useFormikContext()
  const { usedCarProvider } = useDataprovider()
  const { models, findSFRate } = usedCarProvider

  const { cars } = values
  const index = findIndex(props.name)
  const {
    production_year,
    type_of_payment,
    term,
    down_payment,
    balloon,
    goalseek,
  } = cars[index]

  // On Production Year chages, reset payment type, balloon, down_payment
  useEffect(() => {
    setFieldValue(
      `cars.${index}.type_of_payment`,
      PAYMENT_TYPE.HIRE_PURCHASE,
      false
    )
    setFieldValue(`cars.${index}.term`, 48, false)
    setFieldValue(`cars.${index}.insurance_or_accessories`, 0, false)
    setFieldValue(`cars.${index}.down_payment`, 35, false)
    setFieldValue(`cars.${index}.balloon`, 0)
  }, [production_year, index, setFieldValue])

  // Parameter (production_year, type_of_payment, term, down_payment, balloon) changes, update matrix
  useEffect(() => {
    const model = models.find((model) => model.year === production_year)

    if (model) {
      const matrixRow = findSFRate(
        production_year,
        type_of_payment,
        term,
        down_payment,
        balloon,
        "row"
      )

      if (matrixRow) {
        // Set Matrix Row
        setFieldValue(`cars.${index}.matrix_row`, matrixRow)

        setFieldValue(
          `cars.${index}.boundary.balloon`,
          _.get(matrixRow, "balloon", [0, 45])
        )

        // Set TypeOfPaymentsOptions
        const newOptions = _.map(typeOfPaymentOptions, (option) => {
          if (_.isNaN(matrixRow[`rate_${option.value}`])) {
            return { ...option, disabled: true }
          }
          return option
        })
        setFieldValue(`cars.${index}.type_of_payment_options`, newOptions)

        // Set SF Rate
        setFieldValue(
          `cars.${index}.sf_rate`,
          matrixRow[`rate_${type_of_payment}`]
        )

        setFieldValue(`cars.${index}.sf_rate_valid`, true)
      } else {
        console.log(`cannot find matrix row`)
        setFieldValue(`cars.${index}.sf_rate_valid`, false)
      }
    }
  }, [production_year, type_of_payment, term, down_payment, balloon])

  // Type of Payment change, update Margin
  useEffect(() => {
    if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE)
      setFieldValue(`cars.${index}.margin_rate`, 0.5)
    else setFieldValue(`cars.${index}.margin_rate`, 2.0)
  }, [type_of_payment])

  return <div />
}

/**
 * YearField
 * -------------
 */
export const ProductionYearField = (props) => {
  return (
    <FormField
      name={props.name}
      type="select"
      options={props.options}
      {...props}
    />
  )
}

export const EstimateCarPriceField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  let index = findIndex(props.name)

  return (
    <FormField
      name={props.name}
      type={props.type}
      options={props.options}
      {...props}
    />
  )
}

export const InsuranceField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  const index = findIndex(props.name)
  const car = cars[index]

  return (
    <FormField
      name={props.name}
      type={props.type}
      options={props.options}
      {...props}
    />
  )
}

export const FinanceAmountField = (props) => {
  const {
    values: { cars },
    errors,
  } = useFormikContext()
  let index = findIndex(props.name)

  const {
    estimate_car_price = 0,
    vat_include_type,
    down_payment,
    discount,
    insurance_or_accessories,
  } = cars[index]

  const financeAmount = FA(
    estimate_car_price,
    vat_include_type,
    down_payment,
    discount,
    insurance_or_accessories
  )

  return (
    <FormField
      name={props.name}
      type="price_readonly"
      label={props.label}
      adornment={props.adornment}
      error={!!_.get(errors, props.name)}
      helperText={_.get(errors, props.name)}
      value={financeAmount}
    />
  )
}

/*
 * TypeOfPaymentField
 * -----------------
 * 1. Show when payment_method = "finance"
 **/

export const TypeOfPaymentField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()

  const [, index] = props.name.split(".")

  const { type_of_payment_options } = cars[index]

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      options={type_of_payment_options}
    />
  )
}

/*
 * ===================================================================================
 * Calculation Setting
 * ===================================================================================
 */

/* Rebate Net Rate Field
 * -----------------
 * 1. provides a Rebate Net for user
 * 2. this field will have a reverse calculation in the future
 */

export const RebateField = React.memo((props) => {
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)
  const {
    estimate_car_price = 0,
    vat_include_type,
    type_of_payment,
    term,
    margin_rate,
    down_payment,
    discount,
    insurance_or_accessories,
    balloon,
    monthly_installment,
    sf_rate,
  } = cars[index]

  const finance_amount = FA(
    estimate_car_price,
    vat_include_type,
    down_payment,
    discount,
    insurance_or_accessories
  )

  const future_value = calculateFutureValue(finance_amount, balloon)

  React.useEffect(() => {
    const rebateValue = calculateRebate(
      margin_rate,
      sf_rate,
      term,
      finance_amount,
      future_value,
      type_of_payment
    )
    setFieldValue(`cars.${index}.rebate`, rebateValue)
  }, [
    margin_rate,
    sf_rate,
    term,
    finance_amount,
    monthly_installment,
    type_of_payment,
    future_value,
    setFieldValue,
  ])

  const handleBlur = useCallback(
    (e) => {
      const rebateValue = new Decimal(
        (e.target.value || "0").replace(/,/g, "")
      ).toNumber()

      let _rebate = 0
      let targetDiff = 10000
      let _margin_rate = new Decimal(margin_rate)
      let i = 0
      while (Math.abs(targetDiff) > 1 && i < 10000) {
        _rebate = calculateRebate(
          _margin_rate.toNumber(),
          sf_rate,
          term,
          finance_amount,
          future_value,
          type_of_payment
        )

        targetDiff = rebateValue - _rebate

        const step = targetDiff / 30000
        _margin_rate = _margin_rate.add(step)
        i++

        // console.log(
        //   `seek rebate [${i}]: ${_rebate}, diff: ${targetDiff}, step: ${step}, margin: ${_margin_rate.toNumber()}`
        // )
      }
      const _monthlyInstallment = calcPMT(
        findRate(sf_rate, _margin_rate.toNumber()),
        term,
        finance_amount,
        future_value,
        0
      )
      // console.log(
      //   `seek monthly: ${_monthlyInstallment}, margin: ${_margin_rate
      //     .toDP(6)
      //     .toNumber()}`
      // )
      // console.log(`setFieldValue: ${_margin_rate.toDP(6).toNumber()}`)
      setFieldValue(
        `cars.${index}.margin_rate`,
        _margin_rate.toDP(6).toNumber()
      )
    },
    [margin_rate, sf_rate, term, finance_amount, future_value]
  )

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      beforeInput={props.beforeInput}
      onBlur={handleBlur}
      adornment={props.adornment}
    />
  )
})

/* SF Rate Field (Readonly)
 * -----------------
 * 1. provides a SF Net Rate for all types of payment
 * 2. SF Rate will change when (term, type of payment, type_of_payment) are change
 * 3. the value are following dsk logic
 */

export const MatrixField = (props) => {
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  const { usedCarProvider } = useDataprovider()
  const { models, findSFRate } = usedCarProvider

  let index = findIndex(props.name)

  const {
    production_year,
    type_of_payment,
    term,
    down_payment,
    balloon,
  } = cars[index]

  useEffect(() => {
    if (!_.isEmpty(production_year)) {
      const matrixRow = findSFRate(
        production_year,
        type_of_payment,
        term,
        down_payment,
        balloon,
        "row"
      )
      // console.log(`[useEffect] setMatrixRow ${props.name} = `, matrixRow)
      setFieldValue(props.name, matrixRow)
    }
  }, [models, production_year, term, down_payment, balloon])

  return <div />
}

export const SfRateField = (props) => {
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()

  let index = findIndex(props.name)

  const { matrix_row, type_of_payment, sf_rate } = cars[index]
  useEffect(() => {
    const matrixSFRate = _.get(matrix_row, `rate_${type_of_payment}`, 0)
    setFieldValue(props.name, matrixSFRate)
  }, [JSON.stringify(matrix_row), type_of_payment])

  return (
    <InputField
      name={props.name}
      type="number"
      value={sf_rate}
      label={props.label}
      beforeInput={props.beforeInput}
      adornment={adornmentPercent}
      disabled
    />
  )
}

/* Term Fields
 * -----------------
 * 1. will show an option of term depend on type_of_payment
 * 2. this field will hidden when payment_method === cash
 */

export const TermField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  const index = findIndex(props.name)
  const options = [24, 36, 48, 60].map((v) => ({
    value: v,
    label: `${v} Months`,
  }))
  return (
    <FormField
      name={props.name}
      type={props.type}
      options={options}
      label={props.label}
      hidden={cars[index].payment_method === PAYMENT_METHOD.CASH}
    />
  )
}

export const BalloonField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  const { usedCarProvider } = useDataprovider()
  const { findSFRate } = usedCarProvider

  const [prefix, index, fieldName] = props.name.split(".")

  const {
    seekMarginRate,
    seekDownPayment,
    seekMonthlyInstallment,
  } = useAdvanceCalculation(index)

  const {
    type_of_payment,
    estimate_car_price = 0,
    model,
    payment_method,
    term,
    down_payment,
    balloon,
    production_year,
    goalseek,
    boundary,
    locked,
    vat_include_type,
    discount,
    insurance_or_accessories,
  } = cars[index]

  const financeAmount = FA(
    estimate_car_price,
    vat_include_type,
    down_payment,
    discount,
    insurance_or_accessories
  )

  const inputFieldProps = { fullValue: financeAmount }

  React.useEffect(() => {
    if (
      type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE ||
      payment_method === PAYMENT_METHOD.CASH
    ) {
      setFieldValue(`${prefix}.${index}.balloon`, 0)
    } else {
      const matrixRow = findSFRate(
        production_year,
        type_of_payment,
        term,
        down_payment,
        balloon,
        "row"
      )

      if (matrixRow) {
        // Set Boundary
        console.log(`set Boundary Balloon to ${_.get(matrixRow, "balloon")}`)
        setFieldValue(
          `${prefix}.${index}.boundary.balloon`,
          _.get(matrixRow, "balloon", [0, 40]),
          false
        )
        console.log(`set Balloon to ${_.get(matrixRow, "balloon.1", 40)}`)
        setFieldValue(
          `${prefix}.${index}.balloon`,
          _.get(matrixRow, "balloon.1", 40)
        )

        // Blur element to trigger recalculation
        setTimeout(() => {
          if (document && document.activeElement) document.activeElement.blur()
        }, 50)
      }
    }
  }, [model, type_of_payment, term, payment_method])

  const handleBlur = useCallback(
    (e) => {
      if (!advance_calculation) return

      const futureValue = calculateFutureValue(financeAmount, balloon)

      switch (goalseek) {
        case "down_payment":
          seekDownPayment({ futureValue })
          break
        case "margin_rate":
          seekMarginRate({ futureValue })
          break
        case "monthly_installment":
          seekMonthlyInstallment({ futureValue }, "BalloonField")
          break
        default:
          break
      }
    },
    [advance_calculation, goalseek, estimate_car_price, boundary.balloon]
  )

  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        const unlockField = _.findKey(locked, (v, k) => {
          return v === false && k !== "balloon"
        })
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [advance_calculation, JSON.stringify(locked), index, setFieldValue]
  )

  const hidden =
    [
      PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON,
      PAYMENT_TYPE.FINANCIAL_LEASE,
    ].indexOf(type_of_payment) === -1

  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type="percent_with_preview"
        inputFieldProps={inputFieldProps}
        locked={props.lock}
        lockable={advance_calculation}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label={props.label}
        hidden={hidden}
      />
    </React.Fragment>
  )
}

export const ResidualField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  let index = findIndex(props.name)

  const {
    down_payment,
    estimate_car_price = 0,
    balloon,
    type_of_payment,
  } = cars[index]

  const show = type_of_payment === PAYMENT_TYPE.FINANCIAL_LEASE

  const value = calculateResidual(estimate_car_price, down_payment, balloon)
  return (
    <InputField
      type="price_readonly"
      value={value}
      label={props.label}
      hidden={!show}
    />
  )
}

/* Monthly Installment Field
 * -----------------
 * 1. provides a Monthly Installment for user by using calculatePayment function
 * 2. this field will hidden when payment_method === cash
 */

export const MonthlyField = (props) => {
  const {
    values: { cars, advance_calculation },
    errors,
    setFieldValue,
  } = useFormikContext()
  const index = findIndex(props.name)

  const {
    seekMarginRate,
    seekMonthlyInstallment,
    seekDownPayment,
    seekBalloon,
  } = useAdvanceCalculation(index)

  const {
    locked,
    goalseek,
    estimate_car_price = 0,
    vat_include_type,
    down_payment,
    discount,
    insurance_or_accessories,
    term,
    balloon,
    margin_rate,
    type_of_payment,
    sf_rate,
  } = cars[index]

  const error = _.get(errors, `cars.${index}.monthly_installment`)

  const rate = findRate(sf_rate, margin_rate)
  const financeAmount = FA(
    estimate_car_price,
    vat_include_type,
    down_payment,
    discount,
    insurance_or_accessories
  )
  const futureValue = calculateFutureValue(financeAmount, balloon)

  React.useEffect(() => {
    if (goalseek === "monthly_installment") {
      seekMonthlyInstallment({}, "MonthlyField::useEffect")
    }
  }, [
    goalseek,
    type_of_payment,
    financeAmount,
    rate,
    term,
    futureValue,
    balloon,
  ])

  const handleBlur = useCallback(
    (e) => {
      const value = new Decimal(
        (e.target.value || "0").replace(/,/g, "")
      ).toNumber()
      if (!advance_calculation) return

      switch (goalseek) {
        case "margin_rate":
          seekMarginRate({ payment: value })
          break
        case "balloon":
          seekBalloon({ payment: value })
          break
        case "down_payment":
          seekDownPayment({ payment: value })
          break
        default:
          break
      }
    },
    [advance_calculation, goalseek]
  )

  const unlockField = _.findKey(
    locked,
    (v, k) => v === false && k !== "monthly_installment"
  )
  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [advance_calculation, unlockField, index, setFieldValue]
  )

  return !error ? (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      adornment={props.adornment}
      onBlur={handleBlur}
      onFocus={handleFocus}
      lockable={advance_calculation}
      disabled={!advance_calculation}
    />
  ) : (
    <>
      <InputField label={props.label} value="N/A" />
      <Alert severity="warning">{error}</Alert>
    </>
  )
}

/*
 * CampaignField
 * -----------------
 **/
export const CampaignField = (props) => {
  const { values, setFieldValue, handleChange } = useFormikContext()
  const index = findIndex(props.name)
  const campaignValue = _.get(values, props.name)
  const classes = useStyles()
  const { payment_method, type_of_payment, serie, model } = values.cars[index]
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const filteredCampaignOptions = React.useMemo(() => {
    return campaignOptions.filter(
      (option) =>
        (option.series.includes(serie) || option.models.includes(model)) &&
        option.paymentType.includes(type_of_payment) &&
        payment_method === PAYMENT_METHOD.FINANCE
    )
  }, [payment_method, type_of_payment, serie, model])

  const selectedCampaign = filteredCampaignOptions.filter((option) =>
    campaignValue.includes(option.id)
  )

  //Remove selected campaign by clicking (x) on artwork
  const removeCampaignOption = (id) => {
    _.remove(campaignValue, function (e) {
      return e === id
    })
    setFieldValue(props.name, campaignValue)
  }

  // On serieValue change, load new options
  React.useEffect(() => {
    if (serie) {
      if (type_of_payment) {
        setFieldValue(
          props.name,
          filteredCampaignOptions
            .filter((option) => option.lock === true)
            .map((option) => option.id)
        )
      }
    }
  }, [payment_method, type_of_payment, serie, model])

  return (
    <div className={classes.fieldRoot}>
      <FormControl component="fieldset" variant="outlined" fullWidth>
        <FormLabel component="legend">{props.label}</FormLabel>
        <Select
          name={props.name}
          multiple
          displayEmpty
          variant="outlined"
          value={campaignValue}
          onChange={handleChange}
          margin="dense"
          renderValue={(selected) => {
            if (selected.length === 0) {
              if (filteredCampaignOptions.length === 0) {
                return (
                  <span className={classes.alignCenter}>
                    <Arterisk /> {t("field.special_campaign.empty.label")}
                  </span>
                )
              } else
                return (
                  <span className={classes.alignCenter}>
                    <Arterisk /> {t("field.special_campaign.select.label")}
                  </span>
                )
            } else {
              const labels = selected.map((id) => {
                const campaign = filteredCampaignOptions.find(
                  (opt) => opt.id === id
                )
                if (campaign !== undefined)
                  return _.get(campaign, `title.${lang}`)
              })
              return (
                <span>
                  <Arterisk /> {labels.join(", ")}
                </span>
              )
            }
          }}
        >
          {filteredCampaignOptions.map((option) => (
            <MenuItem key={option.id} value={option.id} disabled={option.lock}>
              <ListItemText primary={_.get(option, `title.${lang}`)} />
              <Checkbox
                checked={campaignValue.indexOf(option.id) > -1}
                icon={<span className={classes.checkicon} />}
                checkedIcon={<CheckIcon className={classes.checkicon} />}
              />
            </MenuItem>
          ))}
        </Select>

        {selectedCampaign.map((campaign) => (
          <div key={campaign.id} className={classes.campaignImageContainer}>
            <CancelRoundedIcon
              fontSize="large"
              className={
                campaign.lock === true
                  ? classes.disabledCampaignBtn
                  : classes.removeCampaignBtn
              }
              onClick={() => removeCampaignOption(campaign.id)}
            />
            <LightTooltip
              title={
                <span className={classes.tooltipText}>
                  {_.get(campaign, `description.${lang}`)}
                </span>
              }
              placement="right"
              arrow
            >
              <img
                className={classes.campaignImg}
                src={campaign.image}
                alt="CampaignImage"
              />
            </LightTooltip>
          </div>
        ))}
      </FormControl>
    </div>
  )
}

/* DownPaymentInputField
 * --------------------
 * 1. when payment_method = "finance" ? visible : down_payment set to 0
 * 2. when down_payment < 15 will show error msg and set value to 15
 * 3. when down_payment > 50 will set value to 50
 * 4. when type_of_payment = "Financial Lease" will change label to deposit
 */

export const DownPaymentField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)
  const {
    seekMarginRate,
    seekBalloon,
    seekMonthlyInstallment,
  } = useAdvanceCalculation(index)
  const { t } = useTranslation()

  const {
    estimate_car_price = 0,
    down_payment,
    payment_method,
    goalseek,
    locked,
    vat_include_type,
    type_of_payment,
  } = cars[index]

  const handleBlur = useCallback(
    (e) => {
      if (advance_calculation) {
        const presentValue = FA(
          estimate_car_price,
          vat_include_type,
          down_payment
        )

        switch (goalseek) {
          case "margin_rate":
            seekMarginRate({ presentValue })
            break
          case "balloon":
            seekBalloon({ presentValue })
            break
          case "monthly_installment":
            seekMonthlyInstallment({ presentValue }, "DownPaymentField")
            break
          default:
            break
        }
      }
    },
    [
      setFieldValue,
      payment_method,
      down_payment,
      props.name,
      advance_calculation,
    ]
  )

  const inputFieldProps = { fullValue: estimate_car_price }

  const unlockField = _.findKey(
    locked,
    (v, k) => v === false && k !== "down_payment"
  )
  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [
      advance_calculation,
      unlockField,
      JSON.stringify(unlockField),
      index,
      setFieldValue,
    ]
  )

  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type="percent_with_preview"
        inputFieldProps={inputFieldProps}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label={
          type_of_payment === PAYMENT_TYPE.FINANCIAL_LEASE
            ? t("field.down_payment.deposit")
            : props.label
        }
        locked={props.locked}
        lockable={advance_calculation}
        disabled={props.disabled}
      />
    </React.Fragment>
  )
}

/* Margin Rate Field
 * -----------------
 * 1. a field to receive an input to calculate a Rebate rate by using the logic from Quotation
 * 2. will provide a Rebate Net Rate correctly when payment_method === finance
 * 3. have to have more logic for cash payment
 */

export const MarginField = React.memo((props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)

  const {
    seekMonthlyInstallment,
    seekDownPayment,
    seekBalloon,
  } = useAdvanceCalculation(index)

  const { sf_rate, locked, goalseek } = cars[index]

  // Advance Calculation
  const handleBlur = useCallback(
    (e) => {
      const marginRateValue = new Decimal(
        (e.target.value || "0").replace(/,/g, "")
      ).toNumber()
      if (!advance_calculation) return

      const rate = findRate(sf_rate, marginRateValue)
      switch (goalseek) {
        case "monthly_installment":
          seekMonthlyInstallment({ rate }, "MarginField")
          break
        case "down_payment":
          seekDownPayment({ rate })
          break
        case "balloon":
          seekBalloon({ rate })
          break
        default:
          break
      }
    },
    [advance_calculation, goalseek, sf_rate]
  )

  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        const unlockField = _.findKey(locked, (v, k) => {
          return v === false && k !== "margin_rate"
        })
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [advance_calculation, JSON.stringify(locked), index, setFieldValue]
  )

  return (
    <FormField
      name={props.name}
      type="percent"
      label={props.label}
      beforeInput={props.beforeInput}
      onBlur={handleBlur}
      onFocus={handleFocus}
      adornment={props.adornment}
      decimalScale={4}
      lockable={advance_calculation}
    />
  )
})

export const AdvanceCalculationToggleField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()

  React.useEffect(() => {
    if (!advance_calculation) {
      _.forEach(_.range(cars.length), (index) =>
        setFieldValue(`cars.${index}.goalseek`, "monthly_installment")
      )
    }
  }, [advance_calculation, cars.length, setFieldValue])

  return <FormField name={props.name} type="switch" label={props.label} />
}

export const ResetField = (props) => {
  const { resetForm } = useFormikContext()
  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      reset={resetForm}
    />
  )
}

export default FormField
