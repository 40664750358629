import React from "react"
import {
  TableRow,
  TableCell,
  Button,
  IconButton,
  Collapse,
  makeStyles,
  TableBody,
  Table,
} from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import { Link } from "react-router-dom"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  gapLeft: {
    marginLeft: 8,
  },
  basicTableCell: {
    width: "100%",
    display: "flex",
  },
  buttonQuote: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "6px 4px",
      "& .MuiButton-label": {
        fontSize: 11,
      },
    },
  },
  tbody: {
    "&::before": {
      content: '""',
      display: "block",
      marginTop: 10,
    },
    "& tr": {
      boxShadow: "none !important",
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "flex-start",
    },
    "& td": {
      padding: "14px 16px",
      fontSize: 16,
      fontWeight: 300,
    },
    "& b": {
      marginRight: theme.spacing(1),
    },
  },
}))

const CollapsibleRow = (props) => {
  const { quotation, brand, isSmallScreen } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <React.Fragment>
      <TableRow
        key={`quotation-${quotation.quoteId}-${quotation.quoteInfoId}`}
        hover
      >
        {isSmallScreen && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        <TableCell>ID #{quotation.quoteId}</TableCell>
        <TableCell>{quotation.vehicle || "-"}</TableCell>
        {!isSmallScreen && (
          <React.Fragment>
            <TableCell>{quotation.name || "-"}</TableCell>
            <TableCell>{dayjs(quotation.updated_at).format("DD/MM")}</TableCell>
            <TableCell>{dayjs(quotation.updated_at).format("HH:mm")}</TableCell>
            <TableCell align="right">
              <div className={classes.basicTableCell}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={classes.buttonQuote}
                  onClick={() => {
                    const windowReference = window.open()
                    windowReference.location = `/quotation/pdf/${quotation.quoteId}`
                  }}
                >
                  {t("field.booking_view_quotation.label")}
                </Button>
                {brand === "mini" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    component={Link}
                    className={`${classes.buttonQuote} ${classes.gapLeft}`}
                    to={`/view-quotations/${brand}/create?quotation_id=${quotation.quoteId}&quotation_info_id=${quotation.quoteInfoId}&brand=${brand}`}
                  >
                    {t("field.booking_continue.label")}
                  </Button>
                )}
              </div>
            </TableCell>
          </React.Fragment>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen}>
            <Table>
              <TableBody classes={{ root: classes.tbody }}>
                <TableRow>
                  <b>{t("field.booking_customer_name.label")}</b>{" "}
                  {quotation.name || "-"}
                </TableRow>
                <TableRow>
                  <b>{t("field.booking_date.label")}</b>{" "}
                  {dayjs(quotation.updated_at).format("DD/MM HH:mm")}
                </TableRow>
                <TableRow>
                  <div className={classes.basicTableCell}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      className={classes.buttonQuote}
                      onClick={() => {
                        const windowReference = window.open()
                        windowReference.location = `/quotation/pdf/${quotation.quoteId}`
                      }}
                    >
                      {t("field.booking_view_quotation.label")}
                    </Button>
                    {brand === "mini" && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        component={Link}
                        className={`${classes.buttonQuote} ${classes.gapLeft}`}
                        to={`/view-quotations/${brand}/create?quotation_id=${quotation.quoteId}&quotation_info_id=${quotation.quoteInfoId}&brand=${brand}`}
                      >
                        {t("field.booking_continue.label")}
                      </Button>
                    )}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default CollapsibleRow
