export const PAYMENT_METHOD = {
  CASH: `0`,
  FINANCE: `1`,
}

export const PAYMENT_TYPE = {
  HIRE_PURCHASE: `1`,
  HIRE_PURCHASE_WITH_BALLOON: `2`,
  FINANCIAL_LEASE: `3`,
  FREEDOM_CHOICE: `4`,
}

export const BSI_INCLUDE_TYPE = {
  CAR: `0`,
  FINANCE: `1`,
}

export const paymentMethodOptions = [
  { value: PAYMENT_METHOD.FINANCE, label: "Finance" },
  { value: PAYMENT_METHOD.CASH, label: "Cash" },
]

export const typeOfPaymentOptions = [
  { value: PAYMENT_TYPE.HIRE_PURCHASE, label: "Hire Purchase" },
  {
    value: PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON,
    label: "Hire Purchase with Balloon",
  },
  { value: PAYMENT_TYPE.FINANCIAL_LEASE, label: "Finance Lease" },
]

export const typeOfPaymentWithFreedomOptions = [
  ...typeOfPaymentOptions,
  { value: PAYMENT_TYPE.FREEDOM_CHOICE, label: "Freedom Choice" },
]

export const adornmentBaht = { end: "THB" }
export const adornmentKm = { end: "KM" }
export const adornmentPercent = { end: "%" }

export const TYPE_OF_DISCOUNT = {
  THB: `0`,
  PERCENT: `1`,
}

export const discountOptions = [
  { value: TYPE_OF_DISCOUNT.THB, label: "THB" },
  { value: TYPE_OF_DISCOUNT.PERCENT, label: "%" },
]

export const SALES_TYPE = {
  COUNTER: `0`,
  EVENT: `1`,
}

export const salesOptions = [
  { value: SALES_TYPE.COUNTER, label: "Sales Counter" },
  { value: SALES_TYPE.EVENT, label: "Sales Event" },
]

export const VAT_INCLUDE_TYPE = {
  FINANCE: `0`,
  CAR: `1`,
}

export const corporateOptions = [
  { value: "SME", label: "SME" },
  { value: "BPP", label: "BPP" },
  { value: "Special occupation", label: "Special occupation" },
  { value: "Listed Company", label: "Listed Company" },
  { value: "International Key Account", label: "International Key Account" },
]
