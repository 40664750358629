import React from "react"
import { Menu } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  menu: {
    overflow: "visible",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    marginTop: "2.8rem",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5),
    gap: theme.spacing(2),
  },
}))

const DropdownMenu = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Menu
      elevation={0}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      classes={{
        paper: classes.menu,
        list: classes.list,
      }}
      {...props}
    >
      {children}
    </Menu>
  )
}

export default DropdownMenu
