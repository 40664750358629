import React from "react"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"

import useAuthprovider from "../hooks/useAuthprovider"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `#000 url(/images/login-background.png) center center no-repeat`,
  },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1024px",
  },
  loginBox: {
    padding: theme.spacing(6),
    flex: `0 1 360px`,
    background: "rgba(255, 255, 255, 0.2)",
    color: "white",
    textAlign: "center",
  },
  title: { fontSize: 24, color: "white", marginBottom: "0.5em" },
  description: { fontSize: 16, color: "white", marginBottom: "0.5em" },
  loginButton: {
    marginTop: theme.spacing(6),
  },
}))

const LoginPage = () => {
  const classes = useStyles()
  const { signInWithPopup } = useAuthprovider()
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Login | DSK | BMW Leasing Thailand</title>
      </Helmet>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Paper className={classes.loginBox}>
              <Typography variant="h4" className={classes.title}>
                WELCOME
              </Typography>
              <Typography
                variant="body1"
                component="div"
                className={classes.description}
              >
                Login to Digital Salesman Kit
              </Typography>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                className={classes.loginButton}
                onClick={signInWithPopup}
              >
                Login
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default LoginPage
