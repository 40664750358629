import { Decimal } from "decimal.js"
import Finance from "tvm-financejs"
import _ from "lodash"

import {
  PAYMENT_TYPE,
  BSI_INCLUDE_TYPE,
  TYPE_OF_DISCOUNT,
} from "../../../constants"

export const findRate = (sf_rate, margin_rate = 0) => {
  return new Decimal(sf_rate + margin_rate).toNumber()
}
/**
 * Calculate Car Price function
 * @param {number} retail_price - suggest retail price
 * @param {number} discount - in THB
 * @param {string} bsi_inc - bsi price include in car price or in finance //see constant
 * @param {number} bsi_price - price of bsi that provided by product planning team
 * @param {number} quantity - quantity of the selected car
 * @returns {number} total car price
  Term Mapping
  ========
  presentValue = Finance Amount
  finalValue = Balloon
*/

export const calculateCarPrice = (
  retail_price = 0,
  discount = 0,
  bsi_inc,
  bsi_price = 0,
  quantity = 1
) => {
  let result = retail_price * quantity - discount
  if (bsi_inc === BSI_INCLUDE_TYPE.CAR) {
    result += bsi_price * quantity
  }
  return result
}

/**
 * Calculate Finance Amount function (car price - down payment)
 * @param {Object} props
 * @param {number} props.retail_price - suggest retail price
 * @param {number} props.down_payment - percent of down payment / deposit
 * @param {number} props.discount - in THB
 * @param {string} props.bsi_inc - bsi price include in car price or in finance //see constant
 * @param {number} props.bsi_price - price of bsi that provided by product planning team
 * @param {number} props.quantity - quantity of the selected car
 * @param {number} props.addOnAmountInFinance - total amount that over the retail price (accessory, bsi, insurance, etc)
 * @returns {number} finance amount based on bsi_inc type
 */

export const calculateFinanceAmount = ({
  retail_price = 0,
  down_payment = 0,
  discount = 0,
  bsi_inc = BSI_INCLUDE_TYPE.FINANCE,
  bsi_price = 0,
  quantity = 1,
  addOnAmountInFinance = 0,
}) => {
  if (!retail_price) return 0
  let totalPrice = new Decimal(retail_price)
  let totalBSIPrice = new Decimal(bsi_price).times(quantity)
  if (bsi_inc === BSI_INCLUDE_TYPE.CAR) {
    return totalPrice
      .times(quantity)
      .minus(discount)
      .add(totalBSIPrice)
      .times(1 - down_payment * 0.01)
      .add(addOnAmountInFinance)
      .toDP(2)
      .toNumber()
  } else {
    return totalPrice
      .times(quantity)
      .minus(discount)
      .times(1 - down_payment * 0.01)
      .add(addOnAmountInFinance)
      .toDP(2)
      .toNumber()
  }
}

export const calculateFutureValue = (
  retail_price = 0,
  car_price = 0,
  balloon = 0,
  payment_type = PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON,
  quantity = 1
) => {
  let price =
    payment_type === PAYMENT_TYPE.FREEDOM_CHOICE
      ? retail_price * quantity
      : car_price
  return new Decimal(price)
    .times(0.01)
    .times(_.toNumber(balloon))
    .toDP(2)
    .toNumber()
}

export const calculateBalloon = (future_value, retail_price, discount = 0) => {
  return new Decimal(future_value)
    .div(retail_price - discount)
    .times(100)
    .toDP(2)
    .toNumber()
}

export const calculateResidual = (car_price, down_payment = 0, balloon = 0) => {
  return new Decimal(_.toNumber(down_payment) + _.toNumber(balloon))
    .times(0.01)
    .times(car_price)
    .toDP(2)
    .toNumber()
}

export const calculateDownPaymentPercent = (
  financeAmount,
  carPrice,
  bsi_inc,
  bsi_price = 0
) => {
  const bsiAmount = bsi_inc === BSI_INCLUDE_TYPE.FINANCE ? bsi_price : 0
  return new Decimal((carPrice - financeAmount + bsiAmount) / carPrice)
    .times(100)
    .toNumber()
}
/*
 * ==========================================================================================
 * Time-Value-Money Calculation
 * ==========================================================================================
 
  * get Monthly Installment function
  * -----------------
  * 1. generate a Monthly Installment by using a variant of type_of_payment
  * 2. if type_of_payment === Hire Purchase will using
  *    Customer Flat Rate (sf_rate + margin_rate)  *  finance_amount * term / 12 then add finance_amount after that will divide by term
  * 3. if type_of_payment !== Hire Purchase will using PMT (payment finance function base on excel)
  *    which is fill with ( Customer Eff Rate (sf_rate + margin_rate)/12, Term, Finance_amount, Balloon )
  */

export const tvmCalculatePayment = (
  rate,
  term,
  presentValue,
  finalValue,
  type_of_payment
) => {
  // console.log(`tvmCalculatePayment`, {
  //   rate,
  //   term,
  //   presentValue,
  //   finalValue,
  //   type_of_payment
  // })
  const finance = new Finance()
  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    // Flat rate
    return new Decimal(0.01)
      .times(rate)
      .times(presentValue)
      .times(term)
      .div(12)
      .add(presentValue)
      .div(term)
      .toDP(2)
      .toNumber()
    // return ((0.01 * rate * presentValue * term) / 12 + presentValue) / term
  } else {
    // non-flat, PMT rate
    const pmt = finance.PMT((0.01 * rate) / 12, term, -presentValue, finalValue)
    return pmt
  }
}

export const tvmCalculatePresentValue = (
  rate,
  term,
  payment,
  futureValue = 0,
  type_of_payment = PAYMENT_TYPE.HIRE_PURCHASE
) => {
  const type = 0 // when payments are due (0 for end of period/arrears, and 1 for beginning of period/advance)
  const finance = new Finance()

  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    return new Decimal(payment)
      .times(term)
      .div((0.01 * rate * term) / 12 + 1)
      .toDP(2)
      .toNumber()
  } else {
    const pv = -finance.PV((0.01 * rate) / 12, term, payment, futureValue, type)
    // console.log(`tvmCalculatePresentValue`, {
    //   pv,
    //   rate: (0.01 * rate) / 12,
    //   term,
    //   payment,
    //   futureValue,
    //   type,
    // })
    return pv
  }
}

export const tvmCalculateRate = (
  term,
  payment,
  presentValue,
  futureValue = 0,
  type_of_payment = PAYMENT_TYPE.HIRE_PURCHASE
) => {
  console.log(`tvmCalculateRate`, {
    term,
    payment,
    presentValue,
    futureValue,
    type_of_payment,
  })
  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    const rate =
      ((12 * (payment * term - presentValue)) / (presentValue * term)) * 100
    return rate
  } else {
    const type = 0 // when payments are due (0 for end of period/arrears, and 1 for beginning of period/advance)
    const finance = new Finance()
    const rate =
      finance.RATE(term, payment, -presentValue, futureValue, type) * 12
    return new Decimal(rate).toDP(4).times(100).toNumber()
  }
}

export const tvmCalculateFutureValue = (rate, term, payment, presentValue) => {
  const finance = new Finance()
  return finance.FV((0.01 * rate) / 12, term, payment, presentValue, 0)
}

/*
 * ==========================================================================================
 * End of Time-Value-Money Calculation
 * ==========================================================================================
 */

export const calculateDiscountRate = (margin_rate = 0, sf_rate = 0) => {
  if (margin_rate <= 0.5) {
    return sf_rate
  } else {
    return (margin_rate - 0.5) / 2 + sf_rate
  }
}

export const calculateRebate = (
  marginRate,
  sfRate,
  term,
  presentValue,
  futureValue = 0,
  type_of_payment
) => {
  const finance = new Finance()
  const discountRate = calculateDiscountRate(marginRate, sfRate)
  const customerRate = findRate(marginRate, sfRate)
  const monthlyInstallment = tvmCalculatePayment(
    customerRate,
    term,
    presentValue,
    futureValue,
    type_of_payment
  )
  const discountInstallment = tvmCalculatePayment(
    discountRate,
    term,
    presentValue,
    type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE ? 0 : futureValue,
    type_of_payment
  )

  let discountEffectiveRate
  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    discountEffectiveRate =
      finance.RATE(term, discountInstallment, -presentValue, 0, 0) * 12 * 100
  } else discountEffectiveRate = discountRate
  const pv = -finance.PV(
    (0.01 * discountEffectiveRate) / 12,
    term,
    monthlyInstallment - discountInstallment,
    0
  )
  return new Decimal(pv).toDP(2).toNumber()
}

/**
 * Add VAT (7%)
 * @param {number} num - price excluded VAT
 * @returns {number} price included VAT with no decimal
 */

export const addVat = (num) => {
  return new Decimal(num).times(1.07).toDP(0).toNumber()
}

/**
 * Get total Accessories price
 * it multiples the quantity with unit price or discounted price if any.
 * @param {array} accessories - array of selected accessories
 * @returns {number} - total amount of accessory
 */
export const getAccessoryPrice = (accessories = []) => {
  return accessories.reduce((acc, accessory) => {
    const excludedVatPrice = accessory.discounted_price || accessory.unit_price
    const price = addVat(excludedVatPrice)
    const { quantity } = accessory
    return new Decimal(price).times(quantity).plus(acc).toNumber()
  }, 0)
}

/**
 * Function to get the accessory discount value in THB no matter user choose THB or percent
 * @param {Object} props
 * @param {number} props.totalPrice - total accessory price
 * @param {string} props.discount_type - THB or percent based on constant
 * @param {number} props.discount_baht - discount price in THB
 * @param {number} props.discount_percent - discount price in percent
 * @returns discount value in THB
 */

export const getAccDiscountValue = (props) => {
  const {
    totalPrice,
    discount_type = TYPE_OF_DISCOUNT.THB,
    discount_baht = 0,
    discount_percent = 0,
  } = props
  return discount_type === TYPE_OF_DISCOUNT.THB
    ? discount_baht
    : new Decimal(totalPrice).times(discount_percent).times(0.01).toNumber()
}

/**
 * Get Net accessory price (discounted already)
 * @param {Object} props
 * @param {array} props.accessories - array of selected accessories
 * @param {string} props.discount_type - THB or percent based on constant
 * @param {number} props.discount_baht - discount price in THB
 * @param {number} props.discount_percent - discount price in percent
 * @returns Net amount of accessory with no decimal
 */

export const getNetAccessoryPrice = (props) => {
  const {
    accessories,
    discount_type = TYPE_OF_DISCOUNT.THB,
    discount_baht = 0,
    discount_percent = 0,
  } = props
  const totalPrice = getAccessoryPrice(accessories)
  const discountValue = getAccDiscountValue({
    totalPrice,
    discount_type,
    discount_baht,
    discount_percent,
  })
  return new Decimal(totalPrice).sub(discountValue).toDP(0).toNumber()
}

/**
 * calculate price that over retail price which can be in finance amount (10% max)
 * @param {Object} props
 * @param {number} props.retail_price - suggest retail price
 * @param {number} props.totalAddOnAmount - total price that are over the suggest retail price
 * @param {string} props.add_on_finance_type - THB or percent based on constant
 * @param {number} props.add_on_finance_baht - desired price in THB
 * @param {number} props.add_on_finance_percent - desired price in percent
 * @returns number that can inject into the finance amount
 */

export const getAddonPriceInFinance = (props) => {
  const {
    retail_price,
    totalAddOnAmount,
    add_on_finance_type = TYPE_OF_DISCOUNT.PERCENT,
    add_on_finance_baht = 0,
    add_on_finance_percent = 10,
  } = props
  const limitValue =
    add_on_finance_type === TYPE_OF_DISCOUNT.THB
      ? add_on_finance_baht
      : new Decimal(retail_price)
          .times(add_on_finance_percent)
          .div(100)
          .toDP(2)
          .toNumber()
  if (limitValue < totalAddOnAmount) return limitValue
  return totalAddOnAmount
}

/**
 * calculate amount that customer has to pay in cash in case the add on amount is over limitation
 * @param {Object} props
 * @param {number} props.totalAddOnAmount - total price that are over the suggest retail price
 * @param {number} props.retail_price - suggest retail price
 * @param {number} props.install_fee - price for install the accessory
 * @param {string} props.add_on_finance_type - THB or percent based on constant
 * @param {number} props.add_on_finance_baht - desired price in THB
 * @param {number} props.add_on_finance_percent - desired price in percent
 * @returns Amount that customer has to pay
 */

export const calculateCashAmount = (props) => {
  const {
    totalAddOnAmount,
    retail_price,
    install_fee = 0,
    add_on_finance_type = TYPE_OF_DISCOUNT.THB,
    add_on_finance_baht = 0,
    add_on_finance_percent = 10,
  } = props
  const priceInFinance = getAddonPriceInFinance({
    retail_price,
    totalAddOnAmount,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  })
  return totalAddOnAmount - priceInFinance + install_fee
}

/**
 * Summarise every amount over retail price
 * @param {Object} props - Values to calculate
 * @param {number} props.insurance - insurance amount
 * @param {number} props.accessoryNetPrice - accessory net price
 * @param {string} props.bsi_inc - constant of bsi included type (finance or car)
 * @param {number} props.bsi_price - bsi price
 * @param {number} props.quantity - quantity of car (to also handle corporate sales calculation)
 * @returns {number} every amount that over suggest retail price
 */

export const calculateAddOnAmount = (props) => {
  const {
    insurance = 0,
    accessoryNetPrice = 0,
    bsi_inc = BSI_INCLUDE_TYPE.FINANCE,
    bsi_price = 0,
    quantity = 1,
  } = props
  const totalBSIPrice = bsi_price * quantity
  return (
    insurance +
    accessoryNetPrice +
    (bsi_inc === BSI_INCLUDE_TYPE.FINANCE ? totalBSIPrice : 0)
  )
}
