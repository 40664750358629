import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import DescriptionIcon from "@material-ui/icons/Description"
import PrintIcon from "@material-ui/icons/Print"

import InputField from "../../../components/InputField"

import { adornmentBaht } from "../../../constants"
import {
  TotalCarPriceField,
  AdditionalDiscountField,
  TotalDiscountField,
  VatField,
  TotalMonthlyField,
  TotalFinanceField,
} from "./corporateFields"

import useStyles from "../style"

const CorporateFooter = ({ handleChange, values, isValid }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container justify="center">
      <Grid item md={1} xs={false} />
      <Grid item md={10}>
        <div className={classes.footerContainer}>
          <Grid container>
            <Grid item md={8} xs={12}>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <div className={classes.gridMarginRight}>
                    <InputField
                      name="sale_consult_name"
                      value={values.sale_consult_name}
                      onChange={handleChange}
                      label={t("field.sale_consult_name.label")}
                      type="text"
                      maxLength={40}
                    />
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={classes.gridMarginRight}>
                    <InputField
                      name="sale_consult_no"
                      value={values.sale_consult_no}
                      onChange={handleChange}
                      label={t("field.sale_consult_no.label")}
                      placeholder="000 000 0000"
                      type="telephone"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className={classes.gridMarginLeft}>
                <AdditionalDiscountField
                  name="additional_discount"
                  label={t("field.total_discount.label")}
                  type="number"
                  adornment={adornmentBaht}
                />
              </div>
            </Grid>
            <Grid item md={8} xs={12}>
              <div className={classes.gridMarginRight}>
                <InputField
                  name="remark"
                  multiline
                  rows={6}
                  value={values.remark}
                  label={t("field.remark_tc.label")}
                  type="text"
                  onChange={handleChange}
                />
                {/* <InputField
                  name="term"
                  multiline
                  rows={10}
                  value={values.term}
                  onChange={handleChange}
                  label={t("field.condition.label")}
                  type="text"
                /> */}
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div
                className={`${classes.gridMarginLeft} ${classes.summaryContainer}`}
              >
                {values.cars.map((car, index) => (
                  <TotalCarPriceField
                    name={`total.${index}.car_price`}
                    disabled
                    type="price_summary"
                  />
                ))}
                {values.cars.length < 2 && (
                  <Fragment>
                    <TotalDiscountField
                      name="total_discount"
                      label={t("field.discount_value.label")}
                      disabled
                      type="price_summary"
                    />
                    <VatField
                      name="vat"
                      label="VAT 7%"
                      disabled
                      type="price_summary"
                    />
                    <div className={classes.dividerThin} />
                    <TotalFinanceField
                      name="total_finance"
                      label={t("field.total_finance_amount.label")}
                      disabled
                      type="price_summary"
                    />
                    <TotalMonthlyField
                      name="total_monthly"
                      label={t("field.total_monthly_installment.label")}
                      disabled
                      type="price_summary"
                    />
                  </Fragment>
                )}
                <div className={classes.divider} />
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  type="submit"
                  endIcon={<DescriptionIcon />}
                  disable={!isValid}
                  className={classes.marginTop}
                >
                  {t("form_action.create_quotation")}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  fullWidth
                  type="submit"
                  endIcon={<PrintIcon />}
                  disable={!isValid}
                  className={classes.marginTop}
                >
                  {t("form_action.print_request")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item md={1} xs={false} />
    </Grid>
  )
}

export default CorporateFooter
