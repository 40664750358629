import { omitBy, every } from "lodash"
import { PAYMENT_TYPE, typeOfPaymentWithFreedomOptions, typeOfPaymentOptions } from "../../../constants"

export const thousandSeparator = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const validateSymbol = (text) => {
  return /[!@#$%^&*(),?"':{}|<>]/.test(String(text))
}

export const validateEmail = (email) => {
  const isEmail = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  const hasSymbol = validateSymbol(email.substring(0, email.lastIndexOf("@")))

  return !hasSymbol && isEmail
}

export const validateSpace = (text) => {
  return /\s/.test(String(text))
}

export const countSpace = (text) => {
  let count = 0
  text.split("").forEach((char) => {
    if (validateSpace(char)) count++
  })
  return count
}

export const validateName = (text) => {
  return /[^a-zA-Z0-9ก-๛\s]/.test(String(text))
}

export const validateTelephone = (text) => {
  const digits = text.split("")
  const isStartZero = digits[0] === "0"
  const isFollowZero = digits[1] === "0"

  return isStartZero && !isFollowZero
}

export const findIndex = (name) => {
  return name.split(".")[1]
}

export const isElectricModel = (model) => {
  if (!model) return false

  if (model?.is_electric) return true
  return false
}

export const getBSIName = (brand) => {
  switch (brand) {
    case "mini":
      return "MSI"
    case "motorrad":
      return "BMSI"
    case "bmw":
    default:
      return "BSI"
  }
}

export const filterPaymentType = ({ model_matrix, model_has_freedomchoice }) => {
  const hasHP = !every(model_matrix, {'rate_1': 0})
  const hasHPBL = !every(model_matrix, {'rate_2': 0})
  const hasFL = !every(model_matrix, {'rate_3': 0})
  let options = model_has_freedomchoice
  ? typeOfPaymentWithFreedomOptions
  : typeOfPaymentOptions
  if (!hasHP) {
    options = omitBy(options, { value: PAYMENT_TYPE.HIRE_PURCHASE})
  }
  if (!hasHPBL) {
    options = omitBy(options, { value: PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON})
  }
  if (!hasFL) {
    options = omitBy(options, { value: PAYMENT_TYPE.FINANCIAL_LEASE})
  }
  return Object.values(options)
}
