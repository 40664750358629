import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  headerBackground: {
    background: "#f8f8f8",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: 330,
      "&::before": {
        content: "''",
        position: "absolute",
        top: -200,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#f8f8f8",
        zIndex: -1,
      },
    },
  },
  headlineContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  headerContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  textFieldContainer: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(3),
    },
  },
  detailFieldContainer: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  accessoryContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
    },
  },
  sliderContainer: {
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.5),
    },
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderBottom: "3px solid black",
    width: "100%",
  },
  footer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  addItemContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    borderBottom: "2px solid rgba(0,0,0,0.38)",
    borderTop: "2px solid rgba(0,0,0,0.38)",
    [theme.breakpoints.up("md")]: {
      borderBottom: "2px solid rgba(0,0,0,0.38)",
      borderTop: "none",
    },
  },
  addBtnPadding: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(0),
    },
  },
  noAccessoryContainer: {
    display: "block",
    justifyContent: "center",
    textAlign: "center",
    opacity: 0.5,
    padding: `1em 0`,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(2),
      borderBottom: "2px dotted rgba(0,0,0,0.18)",
    },
  },
  marginTop: {
    marginTop: theme.spacing(3.3),
  },
  gridSpacingRight: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: 0,
    },
  },
  gridSpacingLeft: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0),
    },
  },
  discountContainer: {
    marginTop: theme.spacing(1.65),
  },
  gridMobileOrder: {
    display: "flex",
    flexDirection: "column-reverse",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  summaryContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    backgroundColor: "#fff",
    position: "relative",
    "&::before": {
      content: "''",
      backgroundColor: "#fff",
      borderTopRightRadius: "20px",
      borderTopLeftRadius: "20px",
      filter: "drop-shadow(0 -10px 8px rgba(0,0,0,0.08))",
      display: "block",
      width: "100vw",
      height: "1rem",
      marginLeft: theme.spacing(-2.5),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(-2),
      marginRight: 0,
      "&::before": {
        display: "none",
      },
    },
  },
  gridMarginLeft: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
  gridMarginRight: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  suggestionAccessory: {
    borderBottom: "2px solid gray",
    marginTop: theme.spacing(2),
  },
  termTextAlign: {
    "& .MuiInputBase-input": {
      textAlign: "left",
    },
  },
}))

export default useStyles
