/* eslint-disable  react/prop-types */
import React, { useState, useCallback } from "react"
import { Field, FastField, useFormikContext } from "formik"

import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Decimal } from "decimal.js"

import { ReactComponent as ArteriskIcon } from "../../../../components/Icon/Arterisk.svg"

import InputField, {
  LockableButton,
  FieldLabel,
} from "../../../../components/InputField"

import useDataprovider from "../../../../hooks/useDataprovider"
import { findIndex, isElectricModel, getBSIName, filterPaymentType } from "../../utils/utils"
import {
  findRate,
  calculateCarPrice,
  calculateFinanceAmount as FA,
  calculateFutureValue,
  calculateResidual,
  calculateDownPaymentPercent,
  tvmCalculatePresentValue as calcPV,
  tvmCalculatePayment as calcPMT,
  tvmCalculateRate as calcRATE,
  tvmCalculateFutureValue as calcFV,
  calculateRebate,
  getAddonPriceInFinance,
  calculateAddOnAmount,
  getNetAccessoryPrice,
} from "../../utils/calculation"

import {
  PAYMENT_METHOD,
  PAYMENT_TYPE,
  BSI_INCLUDE_TYPE,
  typeOfPaymentOptions,
  adornmentPercent,
  TYPE_OF_DISCOUNT,
} from "../../../../constants"

import campaignOptions from "../../../../data/campaignOptions"

import { withStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import Checkbox from "@material-ui/core/Checkbox"
import FormLabel from "@material-ui/core/FormLabel"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import CheckIcon from "@material-ui/icons/Check"
import Tooltip from "@material-ui/core/Tooltip"

import useStyles from "./style"

const Arterisk = () => (
  <ArteriskIcon style={{ width: 14, height: 14, marginRight: 8 }} />
)

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: "#f0f0f0",
  },
  tooltip: {
    minWidth: 400,
    backgroundColor: "#f0f0f0",
    padding: theme.spacing(4),
    color: theme.palette.common.black,
    fontSize: 14,
    lineHeight: 1.8,
  },
}))(Tooltip)

/*
 * Default FormField, for normal Input
 **/

export class FormField extends React.PureComponent {
  static defaultProps = {
    inputRowStyle: { position: "relative" },
  }

  render() {
    const {
      fast = false,
      name,
      type,
      label,
      lockable,
      inputRowStyle,
      inputFieldProps,
      hidden,
      ...rest
    } = this.props
    const FieldComponent = fast ? FastField : Field
    return (
      <FieldComponent name={name}>
        {({ field, form, meta }) => {
          const { values, touched, errors, setFieldValue } = form
          const { advance_calculation } = values
          const [prefix, index, fieldName] = field.name.split(".")
          const lockname = `${prefix}.${index}.locked.${fieldName}`

          const allLocked = _.get(values, `${prefix}.${index}.locked`)
          const countUnlock = _.filter(allLocked, (v) => v === false).length
          const lockValue = _.get(values, lockname)
          const disableLockClick = lockValue === true && countUnlock >= 2

          const handleToggleLock = () => {
            const targetLockValue = !_.get(values, lockname)
            if (targetLockValue === false && countUnlock >= 2) {
              console.log(`Can't unlock more than 2 fields`)
              return
            }
            setFieldValue(lockname, targetLockValue)
          }

          return (
            <div>
              <FieldLabel label={label} lockable={lockable} hidden={hidden} />
              <div style={inputRowStyle}>
                <LockableButton
                  lockable={lockable}
                  locked={lockValue}
                  label={label}
                  disabled={disableLockClick}
                  onToggleLock={handleToggleLock}
                  hidden={hidden}
                />
                <InputField
                  type={type}
                  label={label}
                  locked={advance_calculation && lockValue}
                  lockable={lockable}
                  hidden={hidden}
                  error={
                    _.get(touched, field.name) && !!_.get(errors, field.name)
                  }
                  helperText={_.get(errors, field.name)}
                  {...field}
                  {...inputFieldProps}
                  {...rest}
                />
              </div>
            </div>
          )
        }}
      </FieldComponent>
    )
  }
}

/*
 * SerieField
 * -----------------
 * 1. on `serie` change
 *   - set car `imgURL`
 **/
export const SerieField = (props) => {
  const { brandId, seriesId } = props
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()

  const index = findIndex(props.name)
  const serieValue = cars[index].serie

  const { series } = useDataprovider(brandId)

  const selectedSeries = seriesId || serieValue

  const serie = selectedSeries
    ? series.find((serie) => serie.id === selectedSeries)
    : {}

  React.useEffect(() => {
    if (seriesId) {
      setFieldValue(props.name, seriesId)
    }
  }, [seriesId, setFieldValue])

  const media_url = _.get(serie, "media_url", "")
  React.useEffect(() => {
    if (media_url) {
      setFieldValue(`cars.${index}.media_url`, media_url)
    } else {
      setFieldValue(`cars.${index}.media_url`, ``)
    }
  }, [media_url, setFieldValue, index])

  return (
    <FormField
      name={props.name}
      type="select"
      label={props.label}
      options={series}
      optionProps={(option) => ({
        "data-item": "series",
        "data-name": option.label,
        "data-id": option.value,
      })}
    />
  )
}

/*
 * ModelField
 * -----------------
 * 1. on `serie` change
 *   - Clear value from Model field
 *   - fetch models from serie and populate modelOptions
 * 2. on `model` value change
 *   - set model's `retail_price`
 *   - set model's `name`
 **/

export const ModelField = (props) => {
  const { corporateSale, seriesId } = props
  const { values, setFieldValue } = useFormikContext()
  const {
    models,
    getModelsBySerieId,
    findDownPaymentBoundaryByTerm,
  } = useDataprovider()
  const { t } = useTranslation()

  const { cars } = values
  const index = findIndex(props.name)
  const serieValue = cars[index].serie
  const modelValue = cars[index].model

  const [options, setOptions] = useState([])

  // On serieValue change, load new options for models
  React.useEffect(() => {
    if (serieValue) {
      const empty = { value: "", label: t("field.model.placeholder") }
      const models = getModelsBySerieId(serieValue)
      if (models.length > 0) {
        // Populate Options
        setOptions([empty, ...models])

        // Auto set to first car in model
        setFieldValue(props.name, models[0].value)
        // clear accessories when serie change
        if (!seriesId) setFieldValue(`cars.${index}.accessories`, [])
      }
    }
  }, [serieValue, setFieldValue, props.name, t, models])

  // On ModelValue change, set other fields (price, media_path, margins)
  React.useEffect(() => {
    const model = models.find((model) => model.id === modelValue)
    if (model) {
      const {
        name,
        price,
        hp_margin,
        hpb_margin,
        fl_margin,
        fc_margin,
        has_freedomchoice,

        is_electric,
        is_gfv_matrix,
        matrix,
        matrix_gfv,

        corporatesale_matrix,
        corporatesale_matrix_gfv,
      } = model

      setFieldValue(
        `cars.${index}.model_margin`,
        {
          [PAYMENT_TYPE.HIRE_PURCHASE]: Number(hp_margin),
          [PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON]: Number(hpb_margin),
          [PAYMENT_TYPE.FINANCIAL_LEASE]: Number(fl_margin),
          [PAYMENT_TYPE.FREEDOM_CHOICE]: Number(fc_margin),
        },
        false
      )
      setFieldValue(`cars.${index}.retail_price`, price, false)
      setFieldValue(
        `cars.${index}.model_has_freedomchoice`,
        has_freedomchoice,
        false
      )
      setFieldValue(`cars.${index}.name`, name, false)

      // Matrix
      if (corporateSale) {
        setFieldValue(`cars.${index}.model_matrix`, corporatesale_matrix, false)
        setFieldValue(
          `cars.${index}.model_matrix_gfv`,
          corporatesale_matrix_gfv
        )
      } else {
        setFieldValue(`cars.${index}.model_matrix`, matrix, false)
        setFieldValue(`cars.${index}.model_matrix_gfv`, matrix_gfv)
      }

      setFieldValue(`cars.${index}.is_gfv_matrix`, is_gfv_matrix, false)
      setFieldValue(`cars.${index}.is_electric`, is_electric, false)
    } else {
      // Clear values
      setFieldValue(
        `cars.${index}.model_margin`,
        {
          [PAYMENT_TYPE.HIRE_PURCHASE]: 0,
          [PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON]: 0,
          [PAYMENT_TYPE.FINANCIAL_LEASE]: 0,
          [PAYMENT_TYPE.FREEDOM_CHOICE]: 0,
        },
        false
      )
      setFieldValue(`cars.${index}.retail_price`, 0, false)
      setFieldValue(`cars.${index}.model_has_freedomchoice`, false)
      setFieldValue(`cars.${index}.name`, ``, false)

      setFieldValue(`cars.${index}.model_matrix`, [], false)
      setFieldValue(`cars.${index}.is_electric`, false)
      setFieldValue(`cars.${index}.is_gfv_matrix`, false)
      setFieldValue(`cars.${index}.model_matrix_gfv`, [])
    }

    //reset to initial value when choose new model
    setFieldValue(`cars.${index}.payment_method`, PAYMENT_METHOD.FINANCE, false)
    setFieldValue(
      `cars.${index}.type_of_payment`,
      PAYMENT_TYPE.HIRE_PURCHASE,
      false
    )
    setFieldValue(`cars.${index}.term`, 60, false)
    // set down payment range default
    const downPaymentRange = findDownPaymentBoundaryByTerm(
      modelValue,
      PAYMENT_TYPE.HIRE_PURCHASE,
      60
    )
    setFieldValue(
      `cars.${index}.boundary.down_payment`,
      downPaymentRange[60],
      false
    )

    setFieldValue(`cars.${index}.bsi`, 3, false)
    if (model !== undefined && isElectricModel(model)) {
      console.log(`model is electric`, model)
      setFieldValue(`cars.${index}.bsi`, 4, false)
    }
    setFieldValue(`cars.${index}.bsi_inc`, BSI_INCLUDE_TYPE.CAR, false)
    setFieldValue(`cars.${index}.insurance`, 0, false)
    setFieldValue(`cars.${index}.discount`, 0, false)
    setFieldValue(`cars.${index}.down_payment`, 35, false)
    setFieldValue(`cars.${index}.balloon`, 0)
  }, [modelValue, index, setFieldValue])

  return (
    <FormField
      name={props.name}
      type="select"
      label={false}
      options={options}
      {...props}
    />
  )
}

export const PaymentMethodField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)

  const handleAdvanceToggleChange = React.useEffect(() => {
    if (cars[index].payment_method === `0` && advance_calculation)
      setFieldValue("advance_calculation", false)
  }, [cars[index].payment_method, advance_calculation])

  React.useEffect(() => {
    if (cars[index].payment_method === `0`)
      setFieldValue(`cars.${index}.down_payment`, 0)
    else setFieldValue(`cars.${index}.down_payment`, 35)
  }, [cars[index].payment_method])

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      row={props.row}
      options={props.options}
      onClick={handleAdvanceToggleChange}
    />
  )
}

/*
 * TypeOfPaymentField
 * -----------------
 * 1. Show when payment_method = "finance"
 **/

export const TypeOfPaymentField = (props) => {
  const {
    values: { cars }, setFieldValue
  } = useFormikContext()

  /* eslint-disable no-unused-vars */
  const [prefix, index, fieldName] = props.name.split(".")
  /* eslint-enable no-unused-vars */
  const { model_has_freedomchoice, model_matrix } = cars[index]

  const parsedOptions = filterPaymentType({ model_matrix, model_has_freedomchoice })
  const options = parsedOptions.length ? parsedOptions : typeOfPaymentOptions

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      options={options}
      hidden={cars[index].payment_method === PAYMENT_METHOD.CASH}
    />
  )
}

/* Total Car Price Field
 * -----------------
 * 1. Show when payment_method = "cash"
 */

export const TotalPriceField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()

  let index = findIndex(props.name)
  const {
    payment_method,
    retail_price,
    insurance = 0,
    discount = 0,
    bsi_price = 0,
    accessory_installation = 0,
    accessories,
    discount_type,
    accessory_discount_baht,
    accessory_discount_percent,
  } = cars[index]

  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht: accessory_discount_baht,
    discount_percent: accessory_discount_percent,
  })
  let totalPrice =
    retail_price +
    insurance -
    discount +
    bsi_price +
    accessory_installation +
    accessoryNetPrice

  return (
    <InputField
      name={props.name}
      type={props.type}
      label={props.label}
      value={totalPrice}
      adornment={props.adornment}
      hidden={payment_method === PAYMENT_METHOD.FINANCE}
    />
  )
}

/* BSI Option Fields
 * -----------------
 * 1. If payment type = "Freedom Choice", then choice offer 3 - 5 years.
 */

export const BSIOptionField = (props) => {
  const { brand } = props
  const { t } = useTranslation()
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  const { findBsiPrice } = useDataprovider()
  const [prefix, index, fieldName] = props.name.split(".")
  const { model, bsi, type_of_payment, term, is_electric } = cars[index]

  const bsiName = getBSIName(brand)
  const defaultOptions = [
    {
      value: 3,
      label: t("field.bsi_option", {
        year: 3,
        packageName: `${bsiName} Standard Package`,
      }),
    },
    {
      value: 5,
      label: t("field.bsi_option", {
        year: 5,
        packageName: `${bsiName} Ultimate Package`,
      }),
    },
  ]

  const termYear = Math.round(term / 12)
  const freedomChoiceOptions = _.compact([
    termYear === 3 || termYear === 2
      ? {
          value: 3,
          label: t("field.bsi_option", {
            year: 3,
            packageName: `${bsiName} Standard Package`,
          }),
        }
      : null,
    termYear === 4 || termYear === 2
      ? {
          value: 4,
          label: t("field.bsi_option", {
            year: 4,
            packageName: `${bsiName} SF Package`,
          }),
        }
      : null,
    {
      value: 5,
      label: t("field.bsi_option", {
        year: 5,
        packageName: `${bsiName} Ultimate Package`,
      }),
    },
  ])

  const electricModelsOptions = [
    {
      value: 4,
      label: t("field.bsi_option", {
        year: 4,
        packageName: `${bsiName} Standard Package`,
      }),
    },
    {
      value: 6,
      label: t("field.bsi_option", {
        year: 6,
        packageName: `${bsiName} Ultimate Package`,
      }),
    },
  ]

  React.useEffect(() => {
    const bsi_price = findBsiPrice(model, bsi)
    setFieldValue(`${prefix}.${index}.bsi_price`, bsi_price)
  }, [model, bsi])

  React.useEffect(() => {
    //set year of bsi equal to term only when type_of_payment is freedom choice
    if (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE) {
      const availableBSIOptions = is_electric
        ? electricModelsOptions
        : freedomChoiceOptions
      const minBSI = _.minBy(availableBSIOptions, "value")
      setFieldValue(props.name, minBSI.value)
    }
  }, [term, type_of_payment])

  const options = React.useMemo(() => {
    if (is_electric) return electricModelsOptions
    else if (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE)
      return freedomChoiceOptions
    else return defaultOptions
  }, [type_of_payment, isElectricModel, defaultOptions])

  return (
    <FormField
      name={props.name}
      type={props.type}
      options={options}
      label={props.label}
      hidden={brand === `motorrad`}
    />
  )
}

export const BsiPriceField = (props) => {
  const { brand } = props
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)
  const { t } = useTranslation()
  const { findMaxBsi } = useDataprovider()
  const { model } = cars[index]
  const bsiName = getBSIName(brand)

  React.useEffect(() => {
    // set maximum bsi price at 5 or 6 years (if any) every times model has been changed
    const maxBsi = findMaxBsi(model)
    setFieldValue(`cars.${index}.boundary.bsi_price`, maxBsi, false)
  }, [model])

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={t("field.bsi_price.label", { bsi: bsiName })}
      adornment={props.adornment}
      hidden={brand === `motorrad`}
    />
  )
}

// Temporary remove translation, until we can find better way:
// t("field.bsi.option", { year: num }), //${num} Years BSI Standard Package`,
// BSIOptionField.defaultProps = {
//   defaultOptions: [
//     { value: 3, label: `3 Years BSI Standard Package` },
//     { value: 5, label: `5 Years BSI Standard Package` },
//   ],
//   freedomChoiceOptions: [
//     { value: 3, label: `3 Years BSI Standard Package` },
//     { value: 4, label: `4 Years BSI Standard Package` },
//   ],
// }

/* BSI INC Field
 * -----------------
 * 1. when payment_type = "cash" show only one option
 * 2. when payment_type = "finance" show both options
 */

export const BsiIncField = (props) => {
  const { brand } = props
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)
  const { payment_method } = cars[index]

  // Set Initial value if payment_method changed
  React.useEffect(() => {
    if (payment_method === PAYMENT_METHOD.CASH) {
      setFieldValue(props.name, BSI_INCLUDE_TYPE.CAR)
    }
  }, [payment_method])

  const { t } = useTranslation()
  const bsiName = getBSIName(brand)
  const includeTranslate = t("field.bsi_inc.include")
  const inCarTranslate = t("field.bsi_inc.car")
  const inFinanceTranslate = t("field.bsi_inc.finance")

  const cashOption = [
    {
      value: BSI_INCLUDE_TYPE.CAR,
      label: `${includeTranslate} ${bsiName} ${inCarTranslate}`,
    },
  ]
  const financeOptions = [
    {
      value: BSI_INCLUDE_TYPE.CAR,
      label: `${includeTranslate} ${bsiName} ${inCarTranslate}`,
    },
    {
      value: BSI_INCLUDE_TYPE.FINANCE,
      label: `${includeTranslate} ${bsiName} ${inFinanceTranslate}`,
    },
  ]

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      options={
        payment_method === PAYMENT_METHOD.CASH ? cashOption : financeOptions
      }
      hidden={brand === `motorrad`}
    />
  )
}

// Temporary remove Translation, until we find a performant way to do so
// t("field.bsi_inc.option.car") / t("field.bsi_inc.option.finance"),
BsiIncField.defaultProps = {
  cashOption: [
    { value: BSI_INCLUDE_TYPE.CAR, label: "Include BSI in Car Price" },
  ],
  financeOptions: [
    { value: BSI_INCLUDE_TYPE.CAR, label: "Include BSI in Car Price" },
    { value: BSI_INCLUDE_TYPE.FINANCE, label: "Include BSI in Finance Amount" },
  ],
}

/* Car Price Field
 * -----------------
 * 1. including a BSI price if (bsi_inc = "cash")
 */

export const CarPriceField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  let index = findIndex(props.name)
  const { retail_price, discount, bsi_price, bsi_inc, quantity = 1 } = cars[
    index
  ]
  const value = calculateCarPrice(
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    quantity
  )

  return (
    <InputField
      name={props.name}
      type={props.type}
      value={value}
      label={props.label}
      adornment={props.adornment}
    />
  )
}

export const FinanceAmountField = (props) => {
  const {
    values: { cars },
    errors,
  } = useFormikContext()
  const { t } = useTranslation()
  let index = findIndex(props.name)
  const {
    model,
    retail_price,
    discount,
    insurance,
    down_payment,
    bsi_inc,
    bsi_price,
    payment_method,
    quantity = 1,
    accessories,
    discount_type,
    add_on_finance_type = TYPE_OF_DISCOUNT.PERCENT,
    add_on_finance_baht = 0,
    add_on_finance_percent = 10,
  } = cars[index]

  const isError = !!model && !!_.get(errors, props.name)

  let accessoryNetPrice = 0

  if (accessories?.length > 0 && model !== ``) {
    const discount_baht = _.get(cars, `${index}.accessory_discount_baht`, 0)
    const discount_percent = _.get(
      cars,
      `${index}.accessory_discount_percent`,
      0
    )
    accessoryNetPrice = getNetAccessoryPrice({
      accessories,
      discount_type,
      discount_baht,
      discount_percent,
    })
  }

  const totalAddOnAmount = calculateAddOnAmount({
    insurance,
    accessoryNetPrice,
    bsi_inc,
    bsi_price,
    quantity,
  })
  const addOnAmountInFinance = getAddonPriceInFinance({
    retail_price,
    totalAddOnAmount,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  })

  const value =
    model === ``
      ? ``
      : FA({
          retail_price,
          down_payment,
          discount,
          bsi_inc,
          bsi_price,
          quantity,
          addOnAmountInFinance,
        })

  return (
    <InputField
      name={props.name}
      type={props.type}
      value={value}
      label={props.label}
      adornment={props.adornment}
      hidden={payment_method === PAYMENT_METHOD.CASH}
      error={isError}
      helperText={!!model && _.get(errors, props.name)}
    />
  )
}

/* Insurance Field
 * -----------------
 * 1. limited insurance value above 0
 * 2. when (payment_method = "finance") and insurance price over 10% of suggest price will warning and set insurance to 10%
 */

export const InsuranceField = (props) => {
  return (
    <React.Fragment>
      <FormField
        fast={props.fast}
        name={props.name}
        type={props.type}
        label={props.label}
        adornment={props.adornment}
        showBlankOnZero={true}
      />
    </React.Fragment>
  )
}

/* Discount Field
 * -----------------
 * 1. limited discount value above 0
 * 2. when discount price over suggest price will set discount to 0
 */

export const DiscountField = (props) => {
  return (
    <FormField
      fast={props.fast}
      name={props.name}
      type={props.type}
      label={props.label}
      adornment={props.adornment}
      showBlankOnZero={true}
    />
  )
}

/*
 * ===================================================================================
 * Calculation Setting
 * ===================================================================================
 */

/* Rebate Net Rate Field
 * -----------------
 * 1. provides a Rebate Net for user
 * 2. this field will have a reverse calculation in the future
 */

export const RebateField = React.memo((props) => {
  const { findSFRate } = useDataprovider()
  const {
    values: { cars },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)
  const {
    model,
    type_of_payment,
    payment_method,
    term,
    margin_rate,
    retail_price,
    down_payment,
    discount,
    insurance,
    bsi_price,
    bsi_inc,
    balloon,
    monthly_installment,
    quantity = 1,
    accessories,
    discount_type,
    accessory_discount_baht,
    accessory_discount_percent,
    add_on_finance_type = TYPE_OF_DISCOUNT.PERCENT,
    add_on_finance_baht = 0,
    add_on_finance_percent = 10,
  } = cars[index]
  const sf_rate = findSFRate(
    model,
    type_of_payment,
    payment_method,
    term,
    down_payment,
    balloon
  )

  const car_price = calculateCarPrice(
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    quantity
  )
  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht: accessory_discount_baht,
    discount_percent: accessory_discount_percent,
  })
  const totalAddOnAmount = calculateAddOnAmount({
    insurance,
    accessoryNetPrice,
    bsi_inc,
    bsi_price,
    quantity,
  })

  const addOnAmountInFinance = getAddonPriceInFinance({
    retail_price,
    totalAddOnAmount,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  })

  const finance_amount = FA({
    retail_price,
    down_payment,
    discount,
    bsi_inc,
    bsi_price,
    quantity,
    addOnAmountInFinance,
  })
  const future_value = calculateFutureValue(
    retail_price,
    car_price,
    balloon,
    type_of_payment
  )

  React.useEffect(() => {
    if (model) {
      const rebateValue = calculateRebate(
        margin_rate,
        sf_rate,
        term,
        finance_amount,
        future_value,
        type_of_payment
      )
      setFieldValue(`cars.${index}.rebate`, rebateValue)
    }
  }, [
    model,
    margin_rate,
    sf_rate,
    term,
    finance_amount,
    monthly_installment,
    type_of_payment,
    future_value,
    setFieldValue,
  ])

  const handleBlur = useCallback(
    (e) => {
      const rebateValue = new Decimal(
        (e.target.value || "0").replace(/,/g, "")
      ).toNumber()

      let _rebate = 0
      let targetDiff = 10000
      let _margin_rate = new Decimal(margin_rate)
      let i = 0
      while (Math.abs(targetDiff) > 1 && i < 10000) {
        _rebate = calculateRebate(
          _margin_rate.toNumber(),
          sf_rate,
          term,
          finance_amount,
          future_value,
          type_of_payment
        )

        targetDiff = rebateValue - _rebate

        const step = targetDiff / 30000
        _margin_rate = _margin_rate.add(step)
        i++

        console.log(
          `seek rebate [${i}]: ${_rebate}, diff: ${targetDiff}, step: ${step}, margin: ${_margin_rate.toNumber()}`
        )
      }
      const _monthlyInstallment = calcPMT(
        findRate(sf_rate, _margin_rate.toNumber()),
        term,
        finance_amount,
        future_value,
        0
      )
      console.log(
        `seek monthly: ${_monthlyInstallment}, margin: ${_margin_rate
          .toDP(6)
          .toNumber()}`
      )
      console.log(`setFieldValue: ${_margin_rate.toDP(6).toNumber()}`)
      setFieldValue(
        `cars.${index}.margin_rate`,
        _margin_rate.toDP(6).toNumber()
      )
    },
    [margin_rate, sf_rate, term, finance_amount, future_value]
  )

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      beforeInput={props.beforeInput}
      onBlur={handleBlur}
      adornment={props.adornment}
    />
  )
})

/* SF Rate Field (Readonly)
 * -----------------
 * 1. provides a SF Net Rate for all types of payment
 * 2. SF Rate will change when (term, type of payment, type_of_payment) are change
 * 3. the value are following dsk logic
 */

export const SfRateField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  const { findSFRate } = useDataprovider()
  let index = findIndex(props.name)

  const {
    model,
    type_of_payment,
    payment_method,
    term,
    down_payment,
    balloon,
  } = cars[index]
  const sfValue =
    model === ""
      ? ``
      : findSFRate(
          model,
          type_of_payment,
          payment_method,
          term,
          down_payment,
          balloon
        )

  return (
    <InputField
      name={props.name}
      type="number"
      label={props.label}
      beforeInput={props.beforeInput}
      value={sfValue}
      adornment={adornmentPercent}
      disabled
    />
  )
}

/* Term Fields
 * -----------------
 * 1. will show an option of term depend on type_of_payment
 * 2. this field will hidden when payment_method === cash
 */

export const TermField = (props) => {
  // const { defaultOptions, freedomChoiceOptions } = props
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  const {
    findAvailableTerms,
    findDownPaymentBoundaryByTerm,
  } = useDataprovider()
  const [options, setOptions] = useState([])
  let index = findIndex(props.name)

  const { model, type_of_payment, down_payment, payment_method, term } = cars[
    index
  ]
  const { t } = useTranslation()

  React.useEffect(() => {
    if (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE) {
      // If user pick Freedom choice, set term to 36
      setFieldValue(props.name, 36)
    }
  }, [type_of_payment])

  React.useEffect(() => {
    if (model) {
      const downPaymentRange = findDownPaymentBoundaryByTerm(
        model,
        type_of_payment,
        term
      )
      setFieldValue(`cars.${index}.boundary.down_payment`, downPaymentRange)
    }
  }, [type_of_payment, term, model])

  React.useEffect(() => {
    // Find available Terms
    const availableTerms = findAvailableTerms(
      model,
      type_of_payment,
      down_payment
    )
    const termOptions = availableTerms.map((value) => {
      return { value, label: `${value} ${t("field.term.months")}` }
    })
    setOptions(termOptions)

    // Check if current value is in available Terms. if not, set last item as value
    if (availableTerms.length && availableTerms.indexOf(term) === -1) {
      setFieldValue(props.name, _.last(availableTerms))
    }
  }, [model, type_of_payment, down_payment, payment_method, term, t])

  // On Focus this field, set goalseek to monthly_installment
  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        setFieldValue(`cars.${index}.goalseek`, `monthly_installment`)
      }
    },
    [advance_calculation, index, setFieldValue]
  )

  return (
    <FormField
      name={props.name}
      type={props.type}
      onFocus={handleFocus}
      options={options}
      label={props.label}
      hidden={cars[index].payment_method === PAYMENT_METHOD.CASH}
    />
  )
}

export const ResidualField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  let index = findIndex(props.name)

  const {
    down_payment,
    retail_price,
    balloon,
    discount,
    bsi_inc,
    bsi_price,
    type_of_payment,
    payment_method,
    quantity = 1,
  } = cars[index]
  const car_price = calculateCarPrice(
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    quantity
  )

  const hidden =
    type_of_payment !== PAYMENT_TYPE.FINANCIAL_LEASE ||
    payment_method === PAYMENT_METHOD.CASH

  const value = calculateResidual(car_price, down_payment, balloon)
  return (
    <InputField
      type="price_readonly"
      value={value}
      label={props.label}
      hidden={hidden}
    />
  )
}

export const AdvanceCalculationToggleField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()

  let disabledToggle = true
  let financePaymentChecker = 0
  _.forEach(cars, (value) => {
    if (value.payment_method === PAYMENT_METHOD.FINANCE) financePaymentChecker++
  })
  if (financePaymentChecker > 0) disabledToggle = false

  React.useEffect(() => {
    if (!advance_calculation) {
      _.forEach(_.range(cars.length), (index) =>
        setFieldValue(`cars.${index}.goalseek`, "monthly_installment")
      )
    }
  }, [advance_calculation, cars.length, setFieldValue])
  return (
    <FormField
      name={props.name}
      type="switch"
      label={props.label}
      disabled={disabledToggle}
    />
  )
}
export const ResetField = (props) => {
  const { resetForm } = useFormikContext()
  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      reset={resetForm}
    />
  )
}

/*
 * ===================================================================================
 * Advance Fields
 * ===================================================================================
 */

const useAdvanceCalculation = (index) => {
  const { values, setFieldValue } = useFormikContext()
  const { findSFRate } = useDataprovider()

  const car = values.cars[index]

  const {
    model,
    margin_rate,
    term,
    type_of_payment,
    payment_method,
    retail_price,
    discount,
    insurance,
    balloon,
    down_payment,
    bsi_inc,
    bsi_price,
    monthly_installment: defaultPMT,
    quantity = 1,
    accessories,
    discount_type,
    accessory_discount_baht,
    accessory_discount_percent,
    add_on_finance_type = TYPE_OF_DISCOUNT.PERCENT,
    add_on_finance_baht = 0,
    add_on_finance_percent = 10,
  } = car

  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht: accessory_discount_baht,
    discount_percent: accessory_discount_percent,
  })
  const totalAddOnAmount = calculateAddOnAmount({
    insurance,
    accessoryNetPrice,
    bsi_inc,
    bsi_price,
    quantity,
  })

  const addOnAmountInFinance = getAddonPriceInFinance({
    retail_price,
    totalAddOnAmount,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  })

  const defaultPV = FA({
    retail_price,
    down_payment,
    discount,
    bsi_inc,
    bsi_price,
    quantity,
    addOnAmountInFinance,
  })

  const defaultSfRate = findSFRate(
    model,
    type_of_payment,
    payment_method,
    term,
    down_payment,
    balloon
  )
  const defaultRATE = findRate(defaultSfRate, margin_rate)
  const carPrice = calculateCarPrice(
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    quantity
  )
  const defaultFV = calculateFutureValue(
    retail_price,
    carPrice,
    balloon,
    type_of_payment,
    quantity
  )

  const seekMarginRate = ({
    presentValue = defaultPV,
    payment = defaultPMT,
    futureValue = defaultFV,
  }) => {
    const rate = calcRATE(
      term,
      payment,
      presentValue,
      futureValue,
      type_of_payment
    )
    const sfRate = findSFRate(
      model,
      type_of_payment,
      payment_method,
      term,
      down_payment,
      balloon
    )
    const marginValue = rate - sfRate
    // if (process.env.NODE_ENV !== "production")
    //   console.log(`seekMargin: ${marginValue}`)
    setFieldValue(`cars.${index}.margin_rate`, marginValue)
  }

  const seekDownPayment = ({
    rate = defaultRATE,
    payment = defaultPMT,
    futureValue = defaultFV,
  }) => {
    const presentValue = calcPV(
      rate,
      term,
      payment,
      futureValue,
      type_of_payment
    )

    const carPrice = calculateCarPrice(
      retail_price,
      discount,
      bsi_inc,
      bsi_price,
      quantity
    )

    const downPaymentValue = calculateDownPaymentPercent(
      presentValue,
      carPrice,
      bsi_inc,
      bsi_price
    )

    if (process.env.NODE_ENV !== "production")
      console.log(`seekDownPayment: ${downPaymentValue}`, {
        presentValue,
        carPrice,
        futureValue,
        rate,
        term,
        payment,
      })
    setFieldValue(`cars.${index}.down_payment`, downPaymentValue)
  }

  const seekBalloon = ({
    presentValue = defaultPV,
    rate = defaultRATE,
    payment = defaultPMT,
  }) => {
    const futureValue = calcFV(rate, term, payment, -presentValue)
    const balloonValue = new Decimal(futureValue)
      .div(carPrice)
      .times(100)
      .div(quantity)
      .toNumber()

    setFieldValue(`cars.${index}.balloon`, balloonValue)
  }

  const seekMonthlyInstallment = (
    { presentValue = defaultPV, rate = defaultRATE, futureValue = defaultFV },
    source = ``
  ) => {
    if (model) {
      const monthlyInstallmentValue = calcPMT(
        rate,
        term,
        presentValue,
        futureValue,
        type_of_payment
      )
      // console.log(
      //   `seekMonthlyInstallment [${source}]: ${monthlyInstallmentValue}`,
      //   {
      //     presentValue,
      //     rate,
      //     term,
      //     futureValue,
      //   }
      // )

      setFieldValue(
        `cars.${index}.monthly_installment`,
        monthlyInstallmentValue
      )
    }
  }

  return {
    seekMarginRate,
    seekDownPayment,
    seekBalloon,
    seekMonthlyInstallment,
  }
}

/* Margin Rate Field
 * -----------------
 * 1. a field to receive an input to calculate a Rebate rate by using the logic from Quotation
 * 2. will provide a Rebate Net Rate correctly when payment_method === finance
 * 3. have to have more logic for cash payment
 */

export const MarginField = React.memo((props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)
  const {
    seekDownPayment,
    seekBalloon,
    seekMonthlyInstallment,
  } = useAdvanceCalculation(index)

  const { findSFRate } = useDataprovider()

  const {
    model,
    model_margin,
    payment_method,
    type_of_payment,
    term,
    down_payment,
    balloon,
    locked,
    goalseek,
  } = cars[index]

  // useWhyDidYouUpdate(`MarginField`, props)

  // Set Margin by value receive from model database.
  const margin =
    payment_method === PAYMENT_METHOD.CASH ? 2 : model_margin[type_of_payment]
  React.useEffect(() => {
    setFieldValue(`cars.${index}.margin_rate`, margin || 0)
  }, [margin, index, setFieldValue])

  // Advance Calculation
  const handleBlur = useCallback(
    (e) => {
      const marginRateValue = new Decimal(
        (e.target.value || "0").replace(/,/g, "")
      ).toNumber()
      if (!advance_calculation) return
      const sfRate = findSFRate(
        model,
        type_of_payment,
        payment_method,
        term,
        down_payment,
        balloon
      )
      const rate = findRate(sfRate, marginRateValue)
      switch (goalseek) {
        case "down_payment":
          seekDownPayment({ rate })
          break
        case "balloon":
          seekBalloon({ rate })
          break
        case "monthly_installment":
          seekMonthlyInstallment({ rate }, "MarginField")
          break
        default:
          break
      }
    },
    [advance_calculation, goalseek, type_of_payment, payment_method, term]
  )

  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        const unlockField = _.findKey(locked, (v, k) => {
          return v === false && k !== "margin_rate"
        })
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [advance_calculation, JSON.stringify(locked), index, setFieldValue]
  )

  return (
    <FormField
      name={props.name}
      type="percent"
      label={props.label}
      beforeInput={props.beforeInput}
      onBlur={handleBlur}
      onFocus={handleFocus}
      adornment={props.adornment}
      decimalScale={4}
      lockable={advance_calculation && payment_method !== PAYMENT_METHOD.CASH}
    />
  )
})

/* DownPaymentInputField
 * --------------------
 * 1. when payment_method = "finance" ? visible : down_payment set to 0
 * 2. when down_payment < 15 will show error msg and set value to 15
 * 3. when down_payment > 50 will set value to 50
 * 4. when type_of_payment = "Financial Lease" will change label to deposit
 */

export const DownPaymentField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  let index = findIndex(props.name)
  const {
    seekMarginRate,
    seekBalloon,
    seekMonthlyInstallment,
  } = useAdvanceCalculation(index)
  const { t } = useTranslation()

  const {
    type_of_payment,
    payment_method,
    down_payment,
    retail_price,
    discount,
    insurance,
    bsi_inc,
    bsi_price,
    locked,
    goalseek,
    quantity = 1,
    accessories,
    discount_type,
    accessory_discount_baht,
    accessory_discount_percent,
    add_on_finance_type = TYPE_OF_DISCOUNT.PERCENT,
    add_on_finance_baht = 0,
    add_on_finance_percent = 10,
  } = cars[index]

  const car_price = calculateCarPrice(
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    quantity
  )
  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht: accessory_discount_baht,
    discount_percent: accessory_discount_percent,
  })
  const totalAddOnAmount = calculateAddOnAmount({
    insurance,
    accessoryNetPrice,
    bsi_inc,
    bsi_price,
    quantity,
  })

  const addOnAmountInFinance = getAddonPriceInFinance({
    retail_price,
    totalAddOnAmount,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  })

  const handleBlur = useCallback(
    (e) => {
      if (advance_calculation) {
        const presentValue = FA({
          retail_price,
          down_payment,
          discount,
          bsi_inc,
          bsi_price,
          quantity,
          addOnAmountInFinance,
        })

        switch (goalseek) {
          case "margin_rate":
            seekMarginRate({ presentValue })
            break
          case "balloon":
            seekBalloon({ presentValue })
            break
          case "monthly_installment":
            seekMonthlyInstallment({ presentValue }, "DownPaymentField")
            break
          default:
            break
        }
      }
    },
    [
      setFieldValue,
      payment_method,
      down_payment,
      props.name,
      advance_calculation,
    ]
  )

  const inputFieldProps = { fullValue: car_price }

  const unlockField = _.findKey(
    locked,
    (v, k) => v === false && k !== "down_payment"
  )
  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        // console.log(`focus DownPaymentField: set goalseek to ${unlockField}`)
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [
      advance_calculation,
      unlockField,
      JSON.stringify(unlockField),
      index,
      setFieldValue,
    ]
  )

  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type="percent_with_preview"
        inputFieldProps={inputFieldProps}
        onBlur={handleBlur}
        onFocus={handleFocus}
        lockable={advance_calculation}
        label={
          type_of_payment === PAYMENT_TYPE.FINANCIAL_LEASE
            ? t("field.down_payment.deposit")
            : props.label
        }
        hidden={payment_method === `0`}
      />
    </React.Fragment>
  )
}

export const BalloonField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  const { findSFRate } = useDataprovider()

  // let index = findIndex(props.name)
  const [prefix, index, fieldName] = props.name.split(".")

  const {
    seekMarginRate,
    seekDownPayment,
    seekMonthlyInstallment,
  } = useAdvanceCalculation(index)

  const {
    model,
    type_of_payment,
    payment_method,
    term,
    down_payment,
    balloon,
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    locked,
    goalseek,
    boundary,
    quantity = 1,
  } = cars[index]

  const car_price = calculateCarPrice(
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    quantity
  )

  const inputFieldProps = { fullValue: car_price }

  /*
   * Set Balloon value to max value possible, when user select model+payment+term+down
   * on 2 cases
   * 1. on Basic Calculation
   * 2. on Advance Calculation when Balloon Field is locked
   */
  React.useEffect(() => {
    // if (!advance_calculation || (advance_calculation && locked.balloon)) {
    if (
      type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE ||
      payment_method === PAYMENT_METHOD.CASH
    ) {
      setFieldValue(`${prefix}.${index}.balloon`, 0)
    } else {
      const matrixRow = findSFRate(
        model,
        type_of_payment,
        payment_method,
        term,
        down_payment,
        balloon,
        "row"
      )

      if (matrixRow) {
        console.log({ matrixRow })
        // Set Boundary
        console.log(`set Boundary Balloon to ${_.get(matrixRow, "balloon")}`)
        setFieldValue(
          `${prefix}.${index}.boundary.balloon`,
          _.get(matrixRow, "balloon", [0, 45]),
          false
        )
        console.log(`set Balloon to ${_.get(matrixRow, "balloon.1", 40)}`)
        setFieldValue(
          `${prefix}.${index}.balloon`,
          _.get(matrixRow, "balloon.1", 40)
        )

        // Blur element to trigger recalculation
        setTimeout(() => {
          if (document && document.activeElement) document.activeElement.blur()
        }, 50)
      }
    }
    // }
  }, [
    model,
    type_of_payment,
    term,
    payment_method,
    down_payment
    // locked.balloon,
  ])

  const handleBlur = useCallback(
    (e) => {
      let balloonValue = new Decimal(
        (e.target.value || "0").replace(/,/g, "")
      ).toNumber()

      // Check if in bound
      const balloonBound = boundary.balloon
      // console.log({
      //   balloonBound,
      //   balloonValue,
      //   inRange: _.inRange(
      //     balloonValue,
      //     Math.round(balloonBound[0]),
      //     balloonBound[1]
      //   ),
      // })

      // if (
      //   !_.inRange(balloonValue, Math.round(balloonBound[0]), balloonBound[1])
      // ) {
      //   const forceValue = _.clamp(
      //     balloonValue,
      //     Math.round(balloonBound[0]),
      //     balloonBound[1]
      //   )
      //   setFieldValue(`${prefix}.${index}.balloon`, forceValue)
      //   balloonValue = forceValue
      // }

      if (!advance_calculation) return

      const futureValue = calculateFutureValue(
        retail_price,
        car_price,
        balloonValue,
        type_of_payment
      )

      switch (goalseek) {
        case "down_payment":
          seekDownPayment({ futureValue })
          break
        case "margin_rate":
          seekMarginRate({ futureValue })
          break
        case "monthly_installment":
          seekMonthlyInstallment({ futureValue }, "BalloonField")
          break
        default:
          break
      }
    },
    [advance_calculation, goalseek, car_price, discount, boundary.balloon]
  )

  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        const unlockField = _.findKey(locked, (v, k) => {
          return v === false && k !== "balloon"
        })
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [advance_calculation, JSON.stringify(locked), index, setFieldValue]
  )

  const hidden =
    [
      PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON,
      PAYMENT_TYPE.FINANCIAL_LEASE,
    ].indexOf(type_of_payment) === -1 || payment_method === PAYMENT_METHOD.CASH

  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type="percent_with_preview"
        inputFieldProps={inputFieldProps}
        lockable={advance_calculation}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label={props.label}
        hidden={hidden}
      />
    </React.Fragment>
  )
}

export const GFVField = (props) => {
  const {
    values: { cars },
  } = useFormikContext()
  /* eslint-disable no-unused-vars */
  const [prefix, index, fieldName] = props.name.split(".")
  const { t } = useTranslation()
  /* eslint-enable no-unused-vars */

  const {
    type_of_payment,
    payment_method,
    balloon,
    retail_price,
    quantity = 1,
  } = cars[index]

  const hidden =
    type_of_payment !== PAYMENT_TYPE.FREEDOM_CHOICE ||
    payment_method === PAYMENT_METHOD.CASH

  return (
    <InputField
      type="price_readonly"
      label={t("field.gfv.label", { percent: balloon })}
      value={0.01 * balloon * retail_price * quantity}
      hidden={hidden}
    />
  )
}

/* Monthly Installment Field
 * -----------------
 * 1. provides a Monthly Installment for user by using calculatePayment function
 * 2. this field will hidden when payment_method === cash
 */

export const MonthlyField = (props) => {
  const {
    values: { cars, advance_calculation },
    setFieldValue,
  } = useFormikContext()
  const { findSFRate } = useDataprovider()
  const { isValid } = props
  const index = findIndex(props.name)
  const {
    seekMarginRate,
    seekDownPayment,
    seekBalloon,
    seekMonthlyInstallment,
  } = useAdvanceCalculation(index)

  const {
    model,
    locked,
    goalseek,
    retail_price,
    discount,
    insurance,
    down_payment,
    bsi_inc,
    bsi_price,
    type_of_payment,
    payment_method,
    term,
    margin_rate,
    balloon,
    quantity = 1,
    accessories,
    discount_type,
    accessory_discount_baht,
    accessory_discount_percent,
    add_on_finance_type = TYPE_OF_DISCOUNT.PERCENT,
    add_on_finance_baht = 0,
    add_on_finance_percent = 10,
  } = cars[index]

  const car_price = calculateCarPrice(
    retail_price,
    discount,
    bsi_inc,
    bsi_price,
    quantity
  )
  const accessoryNetPrice = getNetAccessoryPrice({
    accessories,
    discount_type,
    discount_baht: accessory_discount_baht,
    discount_percent: accessory_discount_percent,
  })
  const totalAddOnAmount = calculateAddOnAmount({
    insurance,
    accessoryNetPrice,
    bsi_inc,
    bsi_price,
    quantity,
  })

  const addOnAmountInFinance = getAddonPriceInFinance({
    retail_price,
    totalAddOnAmount,
    add_on_finance_type,
    add_on_finance_baht,
    add_on_finance_percent,
  })

  // Basic Calculation
  const finanace_amount = FA({
    retail_price,
    down_payment,
    discount,
    bsi_inc,
    bsi_price,
    quantity,
    addOnAmountInFinance,
  })

  const sf_rate = findSFRate(
    model,
    type_of_payment,
    payment_method,
    term,
    down_payment,
    balloon
  )
  const rate = findRate(sf_rate, margin_rate)
  const _margin_rate = margin_rate !== undefined ? margin_rate : 0
  new Decimal(sf_rate).add(_margin_rate).toDP(2).toNumber()

  const futureValue = calculateFutureValue(
    retail_price,
    car_price,
    balloon,
    type_of_payment
  )

  React.useEffect(() => {
    if (
      goalseek === "monthly_installment" ||
      (quantity && advance_calculation)
    ) {
      seekMonthlyInstallment({}, "MonthlyField::useEffect")
    }
  }, [
    goalseek,
    type_of_payment,
    finanace_amount,
    rate,
    term,
    futureValue,
    quantity,
  ])

  const handleBlur = useCallback(
    (e) => {
      const value = new Decimal(
        (e.target.value || "0").replace(/,/g, "")
      ).toNumber()
      if (!advance_calculation) return

      switch (goalseek) {
        case "down_payment":
          seekDownPayment({ payment: value })
          break
        case "margin_rate":
          seekMarginRate({ payment: value })
          break
        case "balloon":
          seekBalloon({ payment: value })
          break
        default:
          break
      }
    },
    [advance_calculation, goalseek]
  )

  const unlockField = _.findKey(
    locked,
    (v, k) => v === false && k !== "monthly_installment"
  )
  const handleFocus = useCallback(
    (e) => {
      if (advance_calculation) {
        setFieldValue(`cars.${index}.goalseek`, unlockField)
      }
    },
    [advance_calculation, unlockField, index, setFieldValue]
  )

  return (
    <FormField
      name={props.name}
      type={props.type}
      label={props.label}
      adornment={props.adornment}
      onBlur={handleBlur}
      onFocus={handleFocus}
      hidden={cars[index].payment_method === PAYMENT_METHOD.CASH}
      lockable={advance_calculation}
      disabled={!advance_calculation}
    />
  )
}

/*
 * CampaignField
 * -----------------
 **/
export const CampaignField = (props) => {
  const { values, setFieldValue, handleChange } = useFormikContext()
  const index = findIndex(props.name)
  const campaignValue = _.get(values, props.name)
  const termValue = _.get(values, props.term, 0)

  const classes = useStyles()
  const {
    payment_method,
    type_of_payment,
    serie,
    model,
    down_payment,
  } = values.cars[index]
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const filteredCampaignOptions = React.useMemo(() => {
    return campaignOptions.filter((option) => {
      return (
        (option.series.includes(serie) || option.models.includes(model)) &&
        option.paymentType.includes(type_of_payment) &&
        option.requiredTerm.includes(termValue) &&
        down_payment >= option.downPaymentRange[0] &&
        down_payment <= option.downPaymentRange[1]
      )
    })
  }, [payment_method, type_of_payment, serie, model, termValue, down_payment])

  const selectedCampaign = filteredCampaignOptions.filter((option) =>
    campaignValue.includes(option.id)
  )

  //Remove selected campaign by clicking (x) on artwork
  const removeCampaignOption = (id) => {
    _.remove(campaignValue, function (e) {
      return e === id
    })
    setFieldValue(props.name, campaignValue)
  }

  // On serieValue change, load new options
  React.useEffect(() => {
    if (serie) {
      if (type_of_payment) {
        setFieldValue(
          props.name,
          filteredCampaignOptions
            .filter((option) => option.lock === true)
            .map((option) => option.id)
        )
      }
    }
  }, [payment_method, type_of_payment, serie, model])

  // This hook is for filter out selected campaigns that's not available when campaignOptions changed
  React.useEffect(() => {
    const newCampaignValue = _.filter(campaignValue, (campaignId) =>
      filteredCampaignOptions.find(
        (availableCampaign) => availableCampaign.id === campaignId
      )
    )

    if (newCampaignValue.length !== campaignValue.length) {
      setFieldValue(props.name, newCampaignValue)
    }
  }, [filteredCampaignOptions, props.name, setFieldValue, campaignValue])

  return (
    <div className={classes.fieldRoot}>
      <FormControl component="fieldset" variant="outlined" fullWidth>
        <FormLabel component="legend">{props.label}</FormLabel>
        <Select
          name={props.name}
          multiple
          displayEmpty
          variant="outlined"
          value={campaignValue}
          onChange={handleChange}
          margin="dense"
          renderValue={(selected) => {
            if (selected.length === 0) {
              if (filteredCampaignOptions.length === 0) {
                return (
                  <span
                    className={`${classes.alignCenter} ${classes.thinSelectPlaceHolder}`}
                  >
                    <Arterisk /> {t("field.special_campaign.empty.label")}
                  </span>
                )
              } else
                return (
                  <span
                    className={`${classes.alignCenter} ${classes.thinSelectPlaceHolder}`}
                  >
                    <Arterisk /> {t("field.special_campaign.select.label")}
                  </span>
                )
            } else {
              const labels = selected.map((id) => {
                const campaign = filteredCampaignOptions.find(
                  (opt) => opt.id === id
                )
                if (campaign !== undefined)
                  return _.get(campaign, `title.${lang}`)
              })
              return (
                <span>
                  <Arterisk /> {labels.join(", ")}
                </span>
              )
            }
          }}
        >
          {filteredCampaignOptions.map((option) => (
            <MenuItem key={option.id} value={option.id} disabled={option.lock}>
              <ListItemText primary={_.get(option, `title.${lang}`)} />
              <Checkbox
                checked={campaignValue.indexOf(option.id) > -1}
                icon={<span className={classes.checkicon} />}
                checkedIcon={<CheckIcon className={classes.checkicon} />}
              />
            </MenuItem>
          ))}
        </Select>

        {selectedCampaign.map((campaign) => (
          <div key={campaign.id} className={classes.campaignImageContainer}>
            <CancelRoundedIcon
              fontSize="large"
              className={
                campaign.lock === true
                  ? classes.disabledCampaignBtn
                  : classes.removeCampaignBtn
              }
              onClick={() => removeCampaignOption(campaign.id)}
            />
            <LightTooltip
              title={
                <span className={classes.tooltipText}>
                  {_.get(campaign, `description.${lang}`)}
                </span>
              }
              placement="right"
              arrow
            >
              <img
                className={classes.campaignImg}
                src={campaign.image}
                alt="CampaignImage"
              />
            </LightTooltip>
          </div>
        ))}
      </FormControl>
    </div>
  )
}
