import React from "react"
import { useFormikContext } from "formik"
import { Decimal } from "decimal.js"

import {
  getTotalDiscount,
  getTotalCarPrice,
  getVat,
  getTotalMonthly,
  getTotalFinance,
} from "../utils/calculation"

import { FormField } from "../../calculate/components/Fields"
import InputField from "../../../components/InputField"

const findIndex = (name) => {
  return name.split(".")[1]
}

export const QuantityWithTotalField = (props) => {
  const {
    values: { cars },
    handleChange,
  } = useFormikContext()

  let index = findIndex(props.name)
  const { retail_price, quantity } = cars[index]
  let totalPrice = new Decimal(retail_price).times(quantity).toNumber()

  return (
    <InputField
      name={props.name}
      label={props.label}
      type={props.type}
      value={quantity}
      totalPrice={totalPrice}
      onChange={handleChange}
    />
  )
}

export const DiscountWithPercentField = (props) => {
  const {
    values: { cars },
    handleChange,
  } = useFormikContext()
  let index = findIndex(props.name)
  const { retail_price, quantity, discount } = cars[index]
  let totalPrice = new Decimal(retail_price).times(quantity).toNumber()
  const percent = new Decimal(discount).div(totalPrice).times(100).toNumber()
  const inputFieldProps = { fullValue: totalPrice }
  const handleDiscount = (e) => {
    const discountValue = new Decimal(e.target.value)
      .times(totalPrice)
      .div(100)
      .toNumber()
    handleChange({
      target: {
        name: e.target.name,
        value: discountValue,
      },
    })
  }

  return (
    <FormField
      name={props.name}
      type={props.type}
      value={percent}
      onChange={handleDiscount}
      inputFieldProps={inputFieldProps}
      label={props.label}
    />
  )
}

export const TotalCarPriceField = (props) => {
  const { name, type, ...rest } = props
  const {
    values: { cars },
  } = useFormikContext()
  let index = findIndex(props.name)

  const { name: carName, quantity } = cars[index]
  const label = `${carName} (${quantity})`
  const value = getTotalCarPrice([cars[index]])

  return carName ? (
    <InputField name={name} type={type} value={value} label={label} {...rest} />
  ) : (
    <></>
  )
}

export const AdditionalDiscountField = (props) => {
  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type={props.type}
        label={props.label}
        adornment={props.adornment}
      />
    </React.Fragment>
  )
}

export const TotalDiscountField = (props) => {
  const { name, type, ...rest } = props
  const { values } = useFormikContext()
  const totalDiscount = getTotalDiscount(values)

  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type={props.type}
        label={props.label}
        value={totalDiscount}
        {...rest}
      />
    </React.Fragment>
  )
}

export const VatField = (props) => {
  const { name, type, ...rest } = props
  const { values } = useFormikContext()
  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type={props.type}
        label={props.label}
        value={getVat(values)}
        {...rest}
      />
    </React.Fragment>
  )
}

export const TotalMonthlyField = (props) => {
  const { name, type, ...rest } = props
  const {
    values: { cars },
  } = useFormikContext()
  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type={props.type}
        label={props.label}
        value={getTotalMonthly(cars)}
        {...rest}
      />
    </React.Fragment>
  )
}

export const TotalFinanceField = (props) => {
  const { name, type, ...rest } = props
  const {
    values: { cars },
  } = useFormikContext()
  return (
    <React.Fragment>
      <FormField
        name={props.name}
        type={props.type}
        label={props.label}
        value={getTotalFinance(cars)}
        {...rest}
      />
    </React.Fragment>
  )
}
