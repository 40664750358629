import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 0 /* Remove scrollbar space */,
      background: "transparent" /* Optional: just make scrollbar invisible */,
    },
  },
  single: {
    justifyContent: "center",
  },
}))

const Slider = ({ items, renderItem }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.sliderContainer, {
          [classes.single]: items.length === 1,
        })}
      >
        {items.map((item, index) => (
          <div className="carousel-cell" key={`car-${index}`}>
            {renderItem(item, index)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Slider
