import { makeStyles } from "@material-ui/core"

const HEADER_HEIGHT = 10

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  headerBackground: {
    background: "#f8f8f8",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: 330,
      "&::before": {
        content: "''",
        position: "absolute",
        top: -200,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#f8f8f8",
        zIndex: -1,
      },
    },
  },
  headlineContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "start",
      marginLeft: 0,
      maxWidth: 225,
    },
  },
  headerContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  textFieldContainer: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(3),
    },
  },
  gridMarginRight: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  gridMarginLeft: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
  footer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderBottom: "3px solid black",
  },
  dividerThin: {
    marginTop: theme.spacing(1),
    borderBottom: "1px solid black",
  },
  footerContainer: {
    borderTop: "1px solid black",
    paddingTop: theme.spacing(2.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  summaryContainer: {
    position: "relative",
    "&::before": {
      content: "''",
      backgroundColor: "#fff",
      borderTopRightRadius: "20px",
      borderTopLeftRadius: "20px",
      filter: "drop-shadow(0 -10px 8px rgba(0,0,0,0.08))",
      display: "block",
      height: "1rem",
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.5),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(-2),
      marginRight: 0,
      "&::before": {
        display: "none",
      },
    },
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  //calculator area
  main: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
      top: -HEADER_HEIGHT + 20,
    },
  },
  sidebarLeft: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      zIndex: 1,
      flex: "0 0 300px",
    },
  },
  positionStatic: {
    position: "relative",
    top: HEADER_HEIGHT,
  },
  positionSticky: {
    position: "sticky",
    top: 10,
  },
  mainCenter: {
    flexGrow: 1,
    overflow: "hidden",
  },
  errorContainer: {
    margin: "auto",
    maxWidth: "88%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40%",
    },
  },
}))

export default useStyles
