import React, { Suspense } from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom"
import { Helmet } from "react-helmet"

import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DayJSUtils from "@date-io/dayjs"

import CssBaseline from "@material-ui/core/CssBaseline"
import ReactGA from "react-ga"
import RouteChangeTracker from "./components/RouteChangeTracker"

// import useAuthProvider from "./hooks/useAuthprovider"
import useAuthProvider, { AuthProvider } from "./hooks/useAuthprovider"
import useDataprovider, { DataProvider } from "./hooks/useDataprovider"
import usePublicDataProvider, {
  PublicDataProvider,
} from "./hooks/usePublicDataprovider"
import NavBar from "./components/NavBar"
import CookiePolicyDesk from "./components/CookiePolicyDesk"
import Footer from "./components/Footer"
import theme from "./styles/theme"

import Homepage from "./pages/index"
import LoginPage from "./pages/login"
import Calculate from "./pages/calculate/[brand]"
import News from "./pages/news/news"
import PdfViewer from "./pages/PdfViewer"
import Article from "./pages/news/components/Article"
import Accessory from "./pages/accessory/accessory"
import Booking from "./pages/booking"
import BookingForm from "./pages/booking/bookingForm"
import UsedCar from "./pages/used-car"
import CorporateSales from "./pages/corporateSales"
import TermsAndConditions from "./pages/termsAndConditions"
import ConsentConfirmation from "./pages/consentConfirmation"
import WheelSelector from "./pages/wheelSelector"

import "./styles/globals.css"

const trackingId1 = process.env.REACT_APP_GA_TRACKING_ID1
const trackingId2 = process.env.REACT_APP_GA_TRACKING_ID2
ReactGA.initialize(
  [
    { trackingId: trackingId1, gaOptions: { name: "tracker1" } },
    { trackingId: trackingId2, gaOptions: { name: "tracker2" } },
  ],
  {
    alwaysSendToDefaultTracker: false,
  }
)

ReactGA.pageview(window.location.pathname + window.location.search, [
  "tracker1",
])
ReactGA.pageview(window.location.pathname + window.location.search, [
  "tracker2",
])

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
)

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { code, isLogin } = useAuthProvider()

  // Show Empty screen, if have ?code= in the url.
  if (code) return <div />
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const AppNavBar = () => {
  const { logout } = useAuthProvider()
  const { brand } = useDataprovider()
  return (
    <ThemeProvider theme={theme[brand]}>
      <NavBar onLogout={logout} brand={brand} />
    </ThemeProvider>
  )
}

const QuotationPdfViewer = (props) => <PdfViewer type="car" {...props} />
const AccessoryQuotationPdfViewer = (props) => (
  <PdfViewer type="accessory" {...props} />
)
const UsedCarQuotationPdfViewer = (props) => (
  <PdfViewer type="usedcar-quotation" {...props} />
)
const BookingPdfViewer = (props) => <PdfViewer type="booking" {...props} />

const CorporateSaleQuotationPdfViewer = (props) => (
  <PdfViewer type="corporate-sale-quotation" {...props} />
)

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme.default}>
        <CssBaseline />
        <AuthProvider>
          <DataProvider>
            <PublicDataProvider>
              <MuiPickersUtilsProvider utils={DayJSUtils}>
                <Router>
                  <Route
                    exact
                    path="/consent/:consentId"
                    component={ConsentConfirmation}
                  />
                  <RouteChangeTracker />
                  <div className="App">
                    <Helmet>
                      <title>Home | DSK | BMW Leasing Thailand</title>
                    </Helmet>
                    <Route
                      path={[
                        "/",
                        "/login",
                        "/quotation/pdf/:quotationId",
                        "/booking/pdf/:bookingId",
                        "/consent/:consentId",
                      ]}
                      exact
                    >
                      {(props) => {
                        const { match } = props
                        if (!match) return <AppNavBar />
                        return null
                      }}
                    </Route>
                    <div className="main">
                      <Route path="/callback" component={Loader} />
                      <Route path="/login" component={LoginPage} />
                      <PrivateRoute
                        path="/accessory/:brand?"
                        component={Accessory}
                      />
                      <Switch>
                        <PrivateRoute
                          exact
                          path="/view-quotations/:brand?"
                          component={Booking}
                        />
                        <PrivateRoute
                          path="/view-quotations/:brand/create"
                          component={BookingForm}
                        />
                        <Route exact={true} path="/news" component={News} />
                        <Route
                          exact={true}
                          path="/news/:newsId"
                          component={Article}
                        />
                      </Switch>
                      <PrivateRoute path="/" exact component={Homepage} />
                      <PrivateRoute
                        path="/calculate/:brand?"
                        component={Calculate}
                      />
                      <PrivateRoute
                        path="/corporate-sales/:brand?"
                        component={CorporateSales}
                      />
                      <PrivateRoute
                        path="/terms-and-conditions"
                        component={TermsAndConditions}
                      />
                      <PrivateRoute path="/used-car" component={UsedCar} />
                      <Switch>
                        <PrivateRoute
                          exact
                          path="/quotation/pdf/accs-:quotationId"
                          component={AccessoryQuotationPdfViewer}
                        />
                        <PrivateRoute
                          exact
                          path="/quotation/pdf/usedcar-:quotationId"
                          component={UsedCarQuotationPdfViewer}
                        />
                        <PrivateRoute
                          exact
                          path="/quotation/pdf/corporatesales-:quotationId"
                          component={CorporateSaleQuotationPdfViewer}
                        />
                        <PrivateRoute
                          exact
                          path="/quotation/pdf/:quotationId"
                          component={QuotationPdfViewer}
                        />
                        <PrivateRoute
                          exact
                          path="/booking/pdf/:bookingId"
                          component={BookingPdfViewer}
                        />
                        <PrivateRoute
                          exact
                          path="/wheel/:brand?"
                          component={WheelSelector}
                        />
                      </Switch>
                      <Route path={["/consent/:consentId"]} exact>
                        {(props) => {
                          const { match } = props
                          if (!match) return <Footer />
                          return null
                        }}
                      </Route>
                    </div>
                  </div>
                </Router>
                <CookiePolicyDesk />
              </MuiPickersUtilsProvider>
            </PublicDataProvider>
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
