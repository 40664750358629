import { createMuiTheme } from "@material-ui/core/styles"

const defaultOverrides = {
  MuiCssBaseline: {
    "@global": {
      html: {
        WebkitFontSmoothing: "auto",
        fontFamily: ["BMWTypeNext", "Arial", "Helvetica", "sans-serif"].join(
          ","
        ),
      },
      body: {
        fontFamily: ["BMWTypeNext", "Arial", "Helvetica", "sans-serif"].join(
          ","
        ),
      },
    },
  },
  MuiButton: {
    root: {
      borderRadius: `0`,
    },
    label: {
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    contained: {
      boxShadow: "none",
    },
    outlined: {
      padding: `4px 14px`, // Remove by -1px to ensure outlined button still same size as other button variants
      borderWidth: `2px !important`, // Force borderWidth = 2, on all Outlined button states
    },
    outlinedSizeSmall: {
      padding: `2px 8px`, // Remove by -1px to ensure outlined button still same size as other button variants
    },
    outlinedSizeLarge: {
      padding: `6px 20px`, // Remove by -1px to ensure outlined button still same size as other button variants
    },
  },
  MuiFormHelperText: {
    root: {
      "&$disabled": {
        color: "#f44336",
      },
    },
  },
  MuiInputBase: {
    input: {
      color: "#333",
      "&$disabled": {},
    },
    root: {
      backgroundColor: "#fff",
      "&$disabled": {
        backgroundColor: "#f8f8f8",
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      "& fieldset": {
        borderColor: "secondary",
      },
    },
  },
  MuiButtonGroup: {
    grouped: {
      borderRadius: 4,
    },
    groupedOutlinedHorizontal: {
      "&:not(:last-child)": {
        borderRightColor: "1px solid #9f9f9f",
        marginRight: "20px",
      },
    },
    groupedHorizontal: {
      "&:not(:last-child)": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
      },
      "&:not(:first-child)": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      },
    },
  },
  MuiInput: {
    // for disabled style
    underline: {
      "&$disabled": {
        "&:before": {
          borderBottomStyle: "solid",
          borderBottomColor: "#666",
        },
      },
      "&:before": {
        borderBottomStyle: "none",
      },
    },
  },
  MuiSelect: {
    root: {
      fontWeight: "bold",
      textAlign: "left",
      "&$disabled": {
        opacity: "0.7",
      },
    },
    select: {
      "&:focus": {},
    },
  },
  MuiFormLabel: {
    root: {
      marginBottom: "6px",
      color: "#000000",
      maxWidth: "100%",
      fontWeight: 100,
      fontSize: `1em`,
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: "-6px",
    },
  },
  MuiListItemText: {
    primary: {
      fontSize: "0.875rem",
      fontWeight: 200,
    },
  },
}

const miniOverrides = {
  ...defaultOverrides,
  MuiListItem: {
    //font to apply Navbar
    root: {
      fontFamily: [
        "MINISerif",
        "DBOnuma",
        "Arial",
        "Helvetica",
        "sans-serif",
      ].join(","),
      fontSize: "1.1rem",
    },
  },
  MuiListItemText: {
    primary: {
      fontSize: "1.1rem",
    },
  },
  // for adjust font size
  // MuiFormLabel: {
  //   root: {
  //     fontSize: '1.4em',
  //     color: "#f00",
  //     marginBottom: "6px",
  //   },
  // },
  // MuiFormHelperText: {
  //   root: {
  //     fontSize: '1.1rem',
  //   }
  // },
  // MuiTypography: {
  //   body1: {
  //     fontSize: '1.2rem',
  //   },
  //   subtitle2: {
  //     fontSize: '1rem',
  //   }
  // },
  // MuiAlert: {
  //   root: {
  //     fontSize: '1rem',
  //   }
  // },
  // MuiButton: {
  //   containedSizeLarge: {
  //     fontSize: '1.1rem',
  //   },
  //   outlinedSizeLarge: {
  //     fontSize: '1.1rem',
  //   },
  // }
}

const motorradOverrides = {
  ...defaultOverrides,
  MuiListItem: {
    //font to apply Navbar
    root: {
      fontFamily: [
        "Motorrad",
        "BMWThailand",
        "Arial",
        "Helvetica",
        "sans-serif",
      ].join(","),
    },
  },
}

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1c69d4",
    },
    secondary: {
      main: "#4C4C4C",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#666",
    },
  },
  typography: {
    fontFamily: ["BMWTypeNext", "Arial", "Helvetica", "sans-serif"].join(","),
  },
  shape: {
    borderRadius: "3px",
  },
  overrides: {
    ...defaultOverrides,
  },
})

export const miniTheme = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: "#c34029",
    },
    secondary: {
      main: "#9f9f9f",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "MINISerif",
      "DBOnuma",
      "Arial",
      "Helvetica",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    ...miniOverrides,
  },
})

export const motorradTheme = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#9f9f9f",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Motorrad",
      "BMWThailand",
      "Arial",
      "Helvetica",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    ...motorradOverrides,
  },
})

const themes = {
  default: theme,
  bmw: theme,
  mini: miniTheme,
  motorrad: motorradTheme,
}

export default themes
