import { createContext, useContext } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"

import config from "../config"

const PublicDataProviderContext = createContext()

export const PublicDataProvider = (props) => {
  const { i18n } = useTranslation()

  const getCustomerData = async (consentId) => {
    return await axios
      .get(`${config.apiEndpoint}/public/customer-consent/${consentId}`)
      .then((res) => {
        return res.data
      })
  }

  const captureConsent = async ({ consentId, data }) => {
    console.log(`captureCustomerConsentId ${consentId} with`, data)
    return await axios
      .post(`${config.apiEndpoint}/public/customer-consent/${consentId}`, data)
      .then((response) => {
        return response.data.data
      })
      .then((result) => {
        const { url } = result
        return { url }
      })
  }

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  return (
    <PublicDataProviderContext.Provider
      value={{
        getCustomerData,
        captureConsent,
        switchLanguage,
      }}
    >
      {props.children}
    </PublicDataProviderContext.Provider>
  )
}

const usePublicDataProvider = () => {
  return useContext(PublicDataProviderContext)
}

export default usePublicDataProvider
