import React from "react"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"

import { makeStyles, Typography, Button, Paper } from "@material-ui/core"
import CheckRoundedIcon from "@material-ui/icons/CheckRounded"
import AddRoundedIcon from "@material-ui/icons/AddRounded"

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: "none",
    cursor: "pointer",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: "15px",
    width: "320px",
    display: "flex",
    flexDirection: "row",
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    marginRight: "12px",
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  },
  detailContainer: {
    flex: 1,
    position: "relative",
    paddingBottom: "20px",
  },
  itemName: {
    fontSize: "0.8rem",
    whiteSpace: "pre-line",
  },
  btnContainer: {
    padding: "0",
    position: "absolute",
    right: 0,
    bottom: -3,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  vatWarningContainer: {},
  iconContainer: {
    marginBottom: "-3px",
    marginRight: "-8px",
  },
  animated: {
    transition: `all .4s`,
  },
  animatedOut: {
    transform: `scale(0)`,
  },
}))

const AccessoryItem = (props) => {
  const { item_key, img_url, name, price, index, push, added = false } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    // <div className={clsx(classes.animated, added && classes.animatedOut)}>
    <Paper elevation={5} className={classes.root}>
      <div className={classes.imageContainer}>
        <img
          src={img_url}
          alt={`${name}_${index}`}
          className={classes.imageStyle}
        />
      </div>
      <div className={classes.detailContainer}>
        <Typography
          variant="body1"
          component="span"
          className={classes.itemName}
        >
          {t(name)}
        </Typography>
        <br />
        <Typography variant="subtitle1" component="span">
          <NumericFormat
            value={price}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" THB"}
          />
        </Typography>
        <br />
        <div className={classes.btnContainer}>
          <Typography
            className={classes.vatWarningContainer}
            variant="caption"
            component="span"
          >
            {t("field.acc_item.waring")}
          </Typography>
          <Button
            size="small"
            color={added ? "default" : "primary"}
            disabled={added}
            onClick={() => {
              push({
                key: item_key,
                name: name,
                quantity: 1,
                price: price,
                img_url: img_url,
              })
            }}
            startIcon={
              added ? (
                <CheckRoundedIcon className={classes.iconContainer} />
              ) : (
                <AddRoundedIcon className={classes.iconContainer} />
              )
            }
          >
            {added ? t("common.added") : t("common.add")}
          </Button>
        </div>
      </div>
    </Paper>
    // </div>
  )
}

export default AccessoryItem
