import React from "react"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles, withStyles } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"

import { useTranslation } from "react-i18next"
import _ from "lodash"

import FormField, {
  MatrixRowField,
  ProductionYearField,
  EstimateCarPriceField,
  InsuranceField,
  TypeOfPaymentField,
  DownPaymentField,
  FinanceAmountField,
  TermField,
  BalloonField,
  MonthlyField,
  ResidualField,
  CampaignField,
} from "./Fields"

import {
  adornmentBaht,
  adornmentKm,
  VAT_INCLUDE_TYPE,
} from "../../../constants"

import useDataprovider from "../../../hooks/useDataprovider"
import { useFormikContext } from "formik"

const HEAD_HEIGHT = 250

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: "#f0f0f0",
  },
  tooltip: {
    minWidth: 400,
    backgroundColor: "#f0f0f0",
    padding: theme.spacing(4),
    color: theme.palette.common.black,
    fontSize: 14,
    lineHeight: 1.8,
    whiteSpace: "pre-line",
  },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 20px",
    position: "relative",
    minHeight: 800,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
  },
  removeCarCircleBtn: {
    color: "#666666",
    position: "absolute",
    top: 0,
    right: 0,
  },
  carImageContainer: {
    height: HEAD_HEIGHT,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  fieldInfoIcon: {
    marginLeft: 8,
    color: "#888",
  },
}))

const UsedCarForm = React.memo((props) => {
  const { carsLength, isValid, /*values, */ index, remove } = props
  const { values } = useFormikContext()
  const car = values.cars[index]

  const { usedCarProvider } = useDataprovider()
  const { models } = usedCarProvider

  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root} key={index}>
      {carsLength > 1 && (
        <CancelRoundedIcon
          fontSize="large"
          className={classes.removeCarCircleBtn}
          onClick={() => remove(index)}
        />
      )}
      <div className={classes.carImageContainer}>
        <img
          src={`/images/bmw-certified.png`}
          alt="BMW Certified"
          style={{ width: "100%", marginBottom: "1em" }}
        />
      </div>
      <div className={classes.carFormContainer}>
        <FormField
          name={`cars.${index}.model_name`}
          label={`${t(`field.model_name.label`)} *`}
        />
        <FormField
          name={`cars.${index}.is_bmw_premium_selection`}
          type="radio"
          label={
            <div className={classes.label}>
              <span>{t(`field.is_bmw_premium_selection.label`)}</span>
              <LightTooltip
                placement="right"
                title={t("field.is_bmw_premium_selection.helpText")}
              >
                <InfoIcon className={classes.fieldInfoIcon} />
              </LightTooltip>
            </div>
          }
          options={[
            { value: "0", label: "None" },
            { value: "1", label: "BPS (BMW Premium Selection)" },
          ]}
        />
        <ProductionYearField
          name={`cars.${index}.production_year`}
          label={`${t(`field.production_year.label`)} *`}
          type="select"
          options={_.map(models, (model) => ({
            value: `${model.year}`,
            label: `${model.year}`,
          }))}
        />
        <FormField
          name={`cars.${index}.mileage`}
          type="number"
          adornment={adornmentKm}
          label={`${t(`field.mileage.label`)} *`}
        />
        <FormField
          name={`cars.${index}.warranty_expire`}
          label={`${t(`field.warranty_expire.label`)} (Optional)`}
          type="date"
          inputFieldProps={{ min: "2020-01-24" }}
        />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormField
              name={`cars.${index}.bsi_expire`}
              label={t(`field.bsi_expire.label`)}
              type="date"
              inputFieldProps={{ min: "2020-01-24" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              name={`cars.${index}.maximum_miles`}
              label={t(`field.maximum_miles.label`)}
              type="number"
              adornment={adornmentKm}
              placeholder="0"
            />
          </Grid>
        </Grid>
        <EstimateCarPriceField
          name={`cars.${index}.estimate_car_price`}
          type="number"
          adornment={adornmentBaht}
          label={t(`field.car_price.label`)}
        />
        <FormField
          name={`cars.${index}.vat_include_type`}
          type="radio"
          label={
            <div className={classes.label}>
              <span>{t(`field.vat_include_type.label`)}</span>
              <LightTooltip
                placement="right"
                title={t("field.vat_include_type.helpText")}
              >
                <InfoIcon className={classes.fieldInfoIcon} />
              </LightTooltip>
            </div>
          }
          options={[
            {
              value: VAT_INCLUDE_TYPE.FINANCE,
              label: `${t("common.answer.yes")}`,
            },
            {
              value: VAT_INCLUDE_TYPE.CAR,
              label: `${t("common.answer.no")}`,
            },
          ]}
        />
        <InsuranceField
          name={`cars.${index}.insurance_or_accessories`}
          type="number"
          label={`${t("field.insurance_or_accessories.label")} (Optional)`}
        />
        {/* <FormField
          name={`cars.${index}.discount`}
          type="number"
          label={`${t("field.discount.label")} (Optional)`}
        /> */}
        <DownPaymentField
          name={`cars.${index}.down_payment`}
          label={t("field.down_payment.label")}
        />
        <FinanceAmountField
          fast
          name={`cars.${index}.finance_amount`}
          label={t("field.finance_amount.label")}
          adornment={adornmentBaht}
        />
        <TypeOfPaymentField
          name={`cars.${index}.type_of_payment`}
          type="select"
          label={t("field.payment_type.label")}
        />
        <BalloonField
          name={`cars.${index}.balloon`}
          label={t("field.balloon.label")}
        />
        <TermField
          fast
          name={`cars.${index}.term`}
          type="select"
          label={t("field.term.label")}
        />
        <ResidualField
          name={`cars.${index}.residual`}
          label={t("field.residual.label")}
        />
        <MatrixRowField name={`cars.${index}.matrix_row`} />
        <MonthlyField
          name={`cars.${index}.monthly_installment`}
          label={t("field.monthly_installment.label")}
          type="number"
          isValid={isValid}
          adornment={adornmentBaht}
        />
        {/* <CampaignField
          name={`cars.${index}.campaigns`}
          label={t(`field.special_campaign.title`)}
        /> */}
      </div>
    </div>
  )
})

export default UsedCarForm
