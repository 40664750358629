import React, { useState } from "react"
import _ from "lodash"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { Formik, Form, FieldArray } from "formik"
import qs from "qs"
import { TYPE_OF_DISCOUNT } from "../../constants"
import { Helmet } from "react-helmet"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/Add"
import Hidden from "@material-ui/core/Hidden"
import useAuthprovider from "../../hooks/useAuthprovider"
import useDataprovider from "../../hooks/useDataprovider"
import { useTranslation } from "react-i18next"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"

import HorizontalScrollMenu from "../../components/HorizontalScrollMenu"

import InputField from "../../components/InputField"
import AccessoryList from "./components/AccessoryList"
import AccessoryItem from "./components/AccessoryItem"
import hardCodedAccessories from "./accessory.json"
import useLastVisitBrand from "../../hooks/useLastVisitBrand"

import {
  getNetValue,
  getDiscountValue,
  getVat,
  getTotal,
} from "./utils/calculation"

import { discountOptions } from "../../constants"
import {
  NetField,
  SummaryDiscountValueField,
  SummaryInstallField,
  VatField,
  TotalField,
  DiscountBahtField,
  DiscountPercentField,
} from "./components/Field"
import { capitalizeBrand } from "../utils"

import useStyles from "./style"

const Accessory = () => {
  const classes = useStyles()
  const {
    /*setBrand, */ submitAccessoryQuotation,
    getAccessoriesBySerieId,
    getSeries,
  } = useDataprovider()
  const { user } = useAuthprovider()
  const params = useParams()
  const { brand } = params
  const cappedBrand = capitalizeBrand(brand)
  const history = useHistory()
  const { t } = useTranslation()
  const [
    lastVisitBrand,
    setLastVisitBrand,
    { getBrandId },
  ] = useLastVisitBrand()
  const { setBrand } = useDataprovider()
  const [seriesOptions, setSeriesOptions] = useState([
    { label: t("field.accessory.select.label"), value: "all" },
  ])
  const [selectedSeries, setSelectedSeries] = useState("all")
  const [accessoriesList, setAccessoriesList] = useState([])
  const [selectedWheels, setSelectedWheels] = useState([])

  const { search } = useLocation()
  const selectedWheelWithId = qs.parse(search.split("?")[1])
  const { wheels = [], seriesId: rawSeriesId } = selectedWheelWithId
  const seriesId = parseInt(rawSeriesId)

  React.useEffect(() => {
    if (
      wheels.length > 0 &&
      accessoriesList.length > 0 &&
      selectedWheels.length === 0
    ) {
      const preSelectedWheels = accessoriesList.filter((accessory) => {
        return wheels.some((wheel) => {
          return wheel.id == accessory.key
        })
      })
      const selectedWheelsWithQuantity = preSelectedWheels.map(
        (selectedWheel) => {
          return {
            ...selectedWheel,
            quantity: parseInt(
              wheels.find((wheel) => wheel.id == selectedWheel.key).quantity
            ),
          }
        }
      )
      setSelectedWheels(selectedWheelsWithQuantity)
    }
  }, [accessoriesList])

  React.useEffect(() => {
    if (seriesId) setSelectedSeries(seriesId)
  }, [seriesId, setSelectedSeries])

  React.useEffect(() => {
    if (brand) {
      setBrand(brand)
      setLastVisitBrand(brand) // save to localStorage
    } else {
      history.push(`/accessory/${lastVisitBrand}`)
    }
  }, [brand, setBrand])

  React.useEffect(() => {
    const getAccessoriesList = async () => {
      if (brand === "bmw") {
        const accessoriesList = await getAccessoriesBySerieId(selectedSeries)
        setAccessoriesList(
          accessoriesList.map((accessory) => {
            return {
              key: accessory.id,
              name: accessory.name,
              img_url: accessory.image,
              price: accessory.discounted_price
                ? accessory.discounted_price
                : accessory.unit_price,
              productTags: accessory.modelIds,
            }
          })
        )
      } else {
        setAccessoriesList(
          hardCodedAccessories.filter(
            (accessories) =>
              (selectedSeries === 0 || selectedSeries === "all"
                ? true
                : accessories.tags.includes(selectedSeries)) &&
              accessories.key.includes("mini")
          )
        )
      }
    }
    getAccessoriesList()
  }, [brand, selectedSeries, getAccessoriesBySerieId, setAccessoriesList])

  React.useEffect(() => {
    const seriesOptionMINI = [
      { label: t("field.accessory.select.label"), value: "all" },
      { label: "MINI HATCH 3-DOOR", value: "f56" },
      { label: "MINI HATCH 5-DOOR", value: "f54" },
      { label: "MINI CLUBMAN", value: "f55" },
      { label: "MINI COUNTRYMAN", value: "f60" },
    ]
    const getSeriesOptions = async () => {
      if (brand === "bmw") {
        const seriesOption = await getSeries({ brandId: 1 })
        seriesOption.unshift({
          name: t("field.accessory.select.label"),
          id: "all",
        })
        setSeriesOptions(
          seriesOption.map((series) => {
            return { label: series.name, value: series.id }
          })
        )
      } else {
        setSeriesOptions(seriesOptionMINI)
      }
    }
    getSeriesOptions()
  }, [brand, getSeries])

  // accessory data will fetch from database
  const accessory = {
    name: "",
    quantity: 0,
    price: 0,
  }

  const checkedAdd = (values, item) => {
    return values.purchase_accessories.some((accs) => accs.key === item.key)
  }

  const initialValues = {
    customer_name: "",
    customer_address: "",
    customer_no: "",
    account_no: "",
    wip_no: "",
    sales_type: "Event",
    install_fee: 0,
    discount_type: TYPE_OF_DISCOUNT.THB,
    discount_baht: 0,
    discount_percent: 0,
    remark: "",
    purchase_accessories: selectedWheels,
    term:
      "- ใบเสนอราคามีอายุ 7 วัน\n- ราคาอุปกรณ์ตกแต่งและค่าแรงสามารถเปลี่ยนแปลงได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า \n- อุปกรณ์ตกแต่งและอะไหล่แท้จาก บริษัท บีเอ็มดับเบิลยู (ประเทศไทย) จำกัด รับประกันตามเงื่อนไขของทางบริษัทฯ เป็นระยะเวลา 2 ปี ไม่จำกัดระยะทาง",
  }

  const onSubmit = async (values) => {
    console.log("onSubmit", values)
    const submitValues = {
      company_id: user.company_id || 1,
      branch_id: user.branch_id || 1,
      user_id: user.legacy_id || 1,
      brand_id: getBrandId(lastVisitBrand),
      customer_name: values.customer_name,
      customer_no: values.customer_no,
      customer_address: values.customer_address,
      account_no: values.account_no,
      wip: values.wip_no,
      sale_type: values.sales_type,
      specialist_name: values.specialist_name,
      specialist_contact_no: values.specialist_contact_no,
      sales_name: values.sales_name,
      sales_contact_no: values.sales_contact_no,
      installation_fees: values.install_fee ? values.install_fee : 0,
      net_value: getNetValue(values.purchase_accessories),
      discount_value: getDiscountValue(values),
      vat: getVat(values),
      total: getTotal(values),
      remark: values.remark,
      term: values.term,
      data: values.purchase_accessories.map((accs) => {
        return {
          name: t(accs.name),
          image_url: accs.img_url,
          accessory_id: lastVisitBrand === "mini" ? 0 : accs.key || 0,
          unit_price: accs.price,
          quantity: accs.quantity,
        }
      }),
    }

    const windowReference = window.open()
    submitAccessoryQuotation(submitValues).then((response) => {
      windowReference.location = `/quotation/pdf/accs-${response.id}`
    })
  }

  const validate = (values) => {
    const errors = {}
    if (
      _.isEmpty(values.purchase_accessories) ||
      _.size(values.purchase_accessories) === 0
    )
      _.set(
        errors,
        `purchase_accessories`,
        t("accessory.error.select_atleast_one")
      )
    if (getTotal(values) <= 0)
      _.set(errors, `total`, t("accessory.error.total_under_zero"))
    return errors
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {cappedBrand} Accessories Quote | DSK | BMW Leasing Thailand
        </title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, isValid, isSubmitting, values, handleChange }) => (
          <Form>
            <div className={classes.headerBackground}>
              <Grid container justify="center">
                <Grid item md={1} xs={false} />
                <Grid item md={10}>
                  <Typography
                    variant="h5"
                    className={classes.headlineContainer}
                  >
                    Create Accessory Quotation
                  </Typography>
                  <div className={classes.headerContainer}>
                    <Grid container>
                      <Grid item md={8} xs={12}>
                        <div className={classes.textFieldContainer}>
                          <Grid container>
                            <Grid item md={6} xs={12}>
                              <div className={classes.gridMarginRight}>
                                <InputField
                                  name="customer_name"
                                  value={values.customer_name}
                                  onChange={handleChange}
                                  label={t("field.customer_name.label")}
                                  type="text"
                                />
                              </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <div className={classes.gridMarginLeft}>
                                <InputField
                                  name="customer_no"
                                  value={values.customer_no}
                                  onChange={handleChange}
                                  label={t("field.customer_no.label")}
                                  type="text"
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <InputField
                            name="customer_address"
                            value={values.customer_address}
                            onChange={handleChange}
                            label={t("field.customer_address.label")}
                            multiline
                            rows={5}
                            type="text"
                          />
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div className={classes.detailFieldContainer}>
                          <InputField
                            name="account_no"
                            value={values.account_no}
                            onChange={handleChange}
                            label={t("field.account_no.label")}
                            type="text"
                          />
                          <InputField
                            name="wip_no"
                            value={values.wip_no}
                            onChange={handleChange}
                            label={t("field.wip_no.label")}
                            type="text"
                          />
                          <InputField
                            name="sales_type"
                            value={values.sales_type}
                            onChange={handleChange}
                            label={t("field.sales_type.label")}
                            type="text"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item md={1} xs={false} />
              </Grid>
            </div>
            <div className={classes.body}>
              <Grid container>
                <Grid item md={1} xs={false} />
                <Grid item md={10} xs={12}>
                  <FieldArray name="purchase_accessories">
                    {({ push, remove }) => (
                      <div className={classes.accessoryContainer}>
                        <Grid item xs={12}>
                          <div className={classes.suggestionAccessory}>
                            <Grid
                              container
                              direction="row"
                              justifycontent="space-between"
                              alignItems="center"
                            >
                              <Typography component="span" style={{ flex: 1 }}>
                                {t("field.suggest.label")}
                              </Typography>
                              <InputField
                                name="accessory"
                                type="select"
                                defaultValue="all"
                                options={seriesOptions}
                                value={selectedSeries}
                                onChange={(e) => {
                                  setSelectedSeries(e.target.value)
                                }}
                                optionProps={(option) => ({
                                  "data-item": "series",
                                  "data-name": option.label,
                                  "data-id": option.value,
                                })}
                              />
                            </Grid>
                            <div className={classes.sliderContainer}>
                              <HorizontalScrollMenu
                                items={accessoriesList}
                                renderItem={(accessory, index) => {
                                  const added = checkedAdd(values, accessory)
                                  return !added ? (
                                    <AccessoryItem
                                      item_key={accessory.key}
                                      img_url={accessory.img_url}
                                      name={accessory.name}
                                      price={accessory.price}
                                      added={added}
                                      index={index}
                                      push={push}
                                      onChange={handleChange}
                                    />
                                  ) : null
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid container>
                          <Hidden smDown>
                            <Grid item md={1}>
                              <Typography component="span">
                                {t("field.acc_img.label")}
                              </Typography>
                            </Grid>
                            <Grid item md={1} />
                          </Hidden>
                          <Grid item md={3}>
                            <Typography component="span">
                              {t("field.acc_item.label")}
                            </Typography>
                            <Hidden smUp>
                              {" "}
                              ({values.purchase_accessories.length})
                            </Hidden>
                          </Grid>
                          <Hidden smDown>
                            <Grid item md={2}>
                              <Typography component="span">
                                {t("field.acc_qty.label")}
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Typography component="span">
                                {t("field.unit_price.label")}
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Typography component="span">
                                {t("field.net_price.label")}
                              </Typography>
                            </Grid>
                            <Grid item md={1}>
                              <Typography component="span">
                                {t("field.delete.label")}
                              </Typography>
                            </Grid>
                          </Hidden>
                        </Grid>
                        <div className={classes.divider} />
                        <Grid container>
                          <Grid item md={12}>
                            {values.purchase_accessories.length > 0 ? (
                              values.purchase_accessories.map(
                                (accessory, index) => (
                                  <AccessoryList
                                    values={accessory}
                                    key={`accs-${index}`}
                                    index={index}
                                    remove={remove}
                                    onChange={handleChange}
                                  />
                                )
                              )
                            ) : (
                              <Typography
                                color="secondary"
                                className={classes.noAccessoryContainer}
                              >
                                {t("common.empty.item")}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <div className={classes.addItemContainer}>
                              <Button
                                onClick={() => push(accessory)}
                                color="primary"
                                className={classes.addBtnPadding}
                                startIcon={<AddIcon />}
                              >
                                <Typography color="primary">
                                  {t("common.add.item")}
                                </Typography>
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </FieldArray>
                </Grid>
                <Grid item md={1} xs={false} />
              </Grid>
            </div>
            <div className={classes.footer}>
              <Grid container>
                <Grid item md={1} xs={false} />
                <Grid item md={10} xs={12}>
                  <Grid container>
                    <div className={classes.gridMobileOrder}>
                      <Grid item md={8} xs={12}>
                        <div className={classes.gridSpacingRight}>
                          <Grid container>
                            <Grid item md={6} xs={12}>
                              <div className={classes.gridMarginRight}>
                                <InputField
                                  name="specialist_name"
                                  label={t("field.acc_specialist_name.label")}
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <div className={classes.gridMarginLeft}>
                                <InputField
                                  name="specialist_contact_no"
                                  label={t("field.acc_specialist_no.label")}
                                  type="telephone"
                                  onChange={handleChange}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item md={6} xs={12}>
                              <div className={classes.gridMarginRight}>
                                <InputField
                                  name="sales_name"
                                  label={t("field.sale_consult_name.label")}
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <div className={classes.gridMarginLeft}>
                                <InputField
                                  name="sales_contact_no"
                                  label={t("field.sale_consult_no.label")}
                                  type="telephone"
                                  onChange={handleChange}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div className={classes.gridSpacingLeft}>
                          <InputField
                            name="install_fee"
                            value={values.install_fee}
                            onChange={handleChange}
                            label={t("field.install_fee.label")}
                            type="number"
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <InputField
                                name="discount_type"
                                options={discountOptions}
                                value={values.discount_type}
                                onChange={handleChange}
                                label={t("field.discount.label")}
                                type="select"
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <div className={classes.discountContainer}>
                                <DiscountPercentField
                                  name="discount_percent"
                                  value={values.discount_percent}
                                  onChange={handleChange}
                                  type="number"
                                />
                                <DiscountBahtField
                                  name="discount_baht"
                                  value={values.discount_baht}
                                  onChange={handleChange}
                                  type="number"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid container>
                    <Grid item md={8} xs={12}>
                      <div className={classes.gridSpacingRight}>
                        <InputField
                          name="remark"
                          multiline
                          rows={6}
                          value={values.remark}
                          label={t("field.remark.label")}
                          type="text"
                          onChange={handleChange}
                        />
                        <InputField
                          name="term"
                          multiline
                          rows={10}
                          className={classes.termTextAlign}
                          value={values.term}
                          onChange={handleChange}
                          label={t("field.condition.label")}
                          type="text"
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <div className={classes.summaryContainer}>
                        <NetField
                          name="net_value"
                          label={t("field.net_value.label")}
                          type="price_summary"
                        />
                        <SummaryInstallField
                          label={t("field.install_fee.label")}
                          disabled
                          type="price_summary"
                        />
                        <SummaryDiscountValueField
                          label={t("field.discount_value.label")}
                          disabled
                          type="price_summary"
                        />
                        <VatField
                          name="vat"
                          label="VAT 7%"
                          disabled
                          type="price_summary"
                        />
                        <TotalField
                          name="total"
                          label={t("field.total_value.label")}
                          disabled
                          type="price_summary"
                        />
                        <div className={classes.divider} />
                        {_.size(errors) > 0 && (
                          <Alert severity="error" style={{ marginBottom: 8 }}>
                            <AlertTitle>Error</AlertTitle>
                            <ul style={{ paddingLeft: 0 }}>
                              {_.map(errors, (error, i) => (
                                <li key={`error-${i}`}>{error}</li>
                              ))}
                            </ul>
                          </Alert>
                        )}
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          fullWidth
                          type="submit"
                          className={classes.marginTop}
                          disabled={!isValid}
                        >
                          {t("form_action.create_quotation")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1} xs={false} />
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default Accessory
