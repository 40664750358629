import _ from "lodash"
import { Decimal } from "decimal.js"
import { TYPE_OF_DISCOUNT } from "../../../constants"

export const getNetValue = (accessories) => {
  let sum = accessories.reduce((acc, accessory) => {
    const quantity = _.get(accessory, "quantity", 0)
    const price = _.get(accessory, "price", 0)
    return new Decimal(quantity).times(price).plus(acc).toNumber()
  }, 0)
  return sum
}

export const getDiscountValue = (values) => {
  if (values.discount_type === TYPE_OF_DISCOUNT.THB)
    return values.discount_baht || 0

  const netValue = getNetValue(values.purchase_accessories)
  const percent = values.discount_percent || 0
  const beforeDiscountValue = new Decimal(netValue)
    .plus(values.install_fee || 0)
    .toNumber()
  return new Decimal(percent).times(0.01).times(beforeDiscountValue).toNumber()
}

export const getVat = (values) => {
  const sum = getNetValue(values.purchase_accessories)
  const vat =
    ((values.install_fee || 0) - (getDiscountValue(values) || 0) + sum) * 0.07
  return vat
}

export const getTotal = (values) => {
  const sum = getNetValue(values.purchase_accessories)
  const total =
    ((values.install_fee || 0) - (getDiscountValue(values) || 0) + sum) * 1.07
  return total
}
