import React, { useState, useRef, useEffect } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10),
    maxWidth: 320,
    position: "relative",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  contentStyle: {
    color: "#666666",
    padding: 0,
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  buttonProgress: {
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: "50%",
    marginLeft: "-12px",
    marginTop: "-12px",
  },
  closeIcon: {
    position: "absolute",
    zIndex: 1,
    right: 0,
    top: 0,
  },
  widePaddingHorizontal: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(5),
    maxWidth: 600,
  },
}))

const ButtonDialog = (props) => {
  const classes = useStyles()
  const { title, isOpen, events, payload, controller, isWide = false } = props
  const { content, submitBtnLabel, dismissBtnLabel } = payload
  const { delay = 10, limit, lifetime = 10, isSubmitting } = controller
  const { onSubmit, onDismiss } = events
  const { t } = useTranslation()

  const delayRef = useRef(null)
  const countdownRef = useRef(null)
  const [timerLabel, setTimerLabel] = useState(0)
  const [count, setCount] = useState(0)

  //countdown 10 min auto close
  useEffect(() => {
    if (isOpen) {
      const id = setTimeout(() => {
        handleClose()
        clearTimeout(countdownRef.current)
      }, lifetime * 60000)
      countdownRef.current = id
    }
  }, [isOpen, delayRef.current])

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date())
    const seconds = Math.floor(total / 1000)
    return {
      total,
      seconds,
    }
  }

  const startTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e)
    if (total >= 0) {
      setTimerLabel(seconds)
    }
  }

  const clearTimer = (e) => {
    setTimerLabel(delay)
    if (delayRef.current) clearInterval(delayRef.current)
    const id = setInterval(() => {
      startTimer(e)
    }, 1000)
    delayRef.current = id
  }

  const getDeadTime = () => {
    let deadline = new Date()
    deadline.setSeconds(deadline.getSeconds() + delay)
    return deadline
  }

  const handleResend = () => {
    clearTimeout(countdownRef.current)
    clearTimer(getDeadTime())
    setCount(count + 1)
    onSubmit()
  }

  const handleClose = () => {
    clearTimeout(countdownRef.current)
    clearInterval(delayRef.current)
    setTimerLabel(0)
    setCount(0)
    onDismiss()
  }

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={handleClose}>
      <div
        className={`${classes.root} ${isWide && classes.widePaddingHorizontal}`}
      >
        <IconButton
          aria-label="close"
          className={classes.closeIcon}
          onClick={onDismiss}
        >
          <CloseRoundedIcon />
        </IconButton>
        <Typography variant="h5" className={classes.marginBtm}>
          <b>{title}</b>
        </Typography>
        <DialogContent
          className={`${classes.contentStyle} ${classes.marginBtm}`}
        >
          {content}
        </DialogContent>
        <div className={classes.buttonContainer}>
          {onSubmit && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              disabled={timerLabel > 0 || count === limit || isSubmitting}
              className={classes.marginBtm}
              onClick={handleResend}
            >
              {submitBtnLabel}{" "}
              {timerLabel > 0 && count !== limit && `(${timerLabel})`}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          )}
          {limit && limit - count <= 2 && (
            <Typography variant="caption1" className={classes.marginBtm}>
              {t("field.attempt.limit", { time: limit - count })}
            </Typography>
          )}
          {onDismiss && dismissBtnLabel && (
            <Button
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              onClick={handleClose}
            >
              {dismissBtnLabel}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default ButtonDialog
