import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 50px",
    color: "#4C4C4C",
    background: "#d9d9d9",
    zIndex: 1,
    display: "flex",
    justifyContent: "flex-start",
    overflow: "auto",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  const [showFooter, setShowFooter] = useState(false)
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === "/") {
      setShowFooter(false)
    } else {
      setShowFooter(true)
    }
  }, [location, setShowFooter])

  return (
    <footer
      className={classes.root}
      style={{ display: showFooter ? "flex" : "none" }}
    >
      <Typography variant="span" style={{ marginRight: 30, flexShrink: 0 }}>
        &copy;BMWFinancialServices
      </Typography>
      <Typography variant="span" style={{ marginRight: 30, flexShrink: 0 }}>
        <a href="https://www.bmw.co.th/th/footer/legal-information/Business-Partner-Privacy.html">
          Business Partner Privacy Policy
        </a>
      </Typography>
      <Typography variant="span" style={{ flexShrink: 0 }}>
        <a href="/terms-and-conditions">Terms And Conditions</a>
      </Typography>
    </footer>
  )
}
