import React from "react"
import { useTranslation } from "react-i18next"

import { Typography, List, ListItem } from "@material-ui/core"

import LogoSVG from "../../../components/logoSvg"

import useStyles from "../style"

const ConfirmedConsent = (props) => {
  const { t, i18n } = useTranslation()
  const { isExpired } = props
  const classes = useStyles()
  const locales = ["en", "th"]

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  return (
    <div style={{ height: "90vh" }}>
      <div className={classes.langContainer}>
        <List className={classes.menuList}>
          {locales.map((lang, index) => (
            <ListItem
              className={`
                  ${classes.menuButtonLanguage}
                  ${i18n.language === lang ? "selected" : ""}
                `}
              button
              key={`menu-lang-${index}`}
              onClick={() => changeLanguage(lang)}
            >
              {lang}
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.centerContainer}>
        <LogoSVG />
        <Typography
          variant="h5"
          style={{ margin: "0.5em 0", textTransform: "uppercase" }}
        >
          {isExpired
            ? t("field.consent.expired")
            : t("field.consent.confirmed")}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "1rem" }}>
          {t("field.consent.24hr_detail")}
        </Typography>
        <Typography variant="body1">{t("field.please_close.tab")}</Typography>
      </div>
    </div>
  )
}

export default ConfirmedConsent
