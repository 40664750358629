import React from "react"
import _ from "lodash"
import Typography from "@material-ui/core/Typography"
import useBookingPageStyle from "./style"
import { useTranslation } from "react-i18next"
import { Formik, Form, useFormikContext } from "formik"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

import { SerieField, ModelField } from "../calculate/components/Fields"

import useLastVisitBrand from "../../hooks/useLastVisitBrand"
import useDataprovider from "../../hooks/useDataprovider"
import useAuthprovider from "../../hooks/useAuthprovider"
import InputField from "../../components/InputField"

import { capitalizeBrand } from "../utils"

const SetPrePopulatedFields = (props) => {
  const { values } = props
  const { setFieldValue } = useFormikContext()
  const { series } = useDataprovider()
  const { t } = useTranslation()

  const params = useParams()
  const { brand: brandFromUrl } = params
  const si = brandFromUrl === "mini" ? "msi" : "bsi"

  const typeOfPaymentOptions = [
    { value: "1", label: "Hire Purchase" },
    { value: "2", label: "Hire Purchase with Balloon" },
    { value: "3", label: "Finance Lease" },
    { value: "4", label: "Freedom Choice" },
    { value: "5", label: "Cash" },
  ]

  const siOptions = [
    { value: "3", label: `${t(`field.${si}_standard.label`)}` },
    { value: "4", label: `${t(`field.${si}_standard.label`)}` },
    { value: "5", label: `${t(`field.${si}_ultimate.label`)}` },
    { value: "6", label: `${t(`field.${si}_ultimate.label`)}` },
  ]

  React.useEffect(() => {
    if (values) {
      if (Object.keys(values).length !== 0) {
        const selectedBSI = siOptions.find(
          (item) => item.value === values.bsi_year
        )
        const paymentType = typeOfPaymentOptions.find(
          (item) => item.value === values.type
        )

        const [fName, lName] =
          values?.customer_name?.replace(/\s+/g, " ").split(" ") || ""
        setFieldValue("buyer_firstname", fName || "")
        setFieldValue("buyer_lastname", lName || "")
        setFieldValue("buyer_email", values.customer_email || "")
        setFieldValue("buyer_mobile", values.customer_phone || "")
        setFieldValue("vehicle_make", values.model_name)
        setFieldValue("model_type", values.tier_name)
        setFieldValue("total_price", values.price)
        setFieldValue("deposit_placed", values.down)
        setFieldValue("deposit_amount", values.down_amount)
        setFieldValue("payment_type", values.type)
        setFieldValue("model_id", values.tier_id)
        setFieldValue("remarks", values.quotation_remark || "")
        setFieldValue(
          "to_be_settled_by",
          paymentType !== undefined ? paymentType.label : ""
        )
        setFieldValue("bsi", values.bsi_year)
        //For FFREEDOM Choice type and 4years > bsi name = BSI SF
        if (values.type === "4" && values.bsi_year === "4")
          setFieldValue("bsi_label", t(`field.${si}_sf.label`))
        else
          setFieldValue(
            "bsi_label",
            selectedBSI !== undefined ? selectedBSI.label : ""
          )

        const serie = values.model_name
          ? series.find((serie) => serie.name === values.model_name)
          : {}

        if (serie !== undefined)
          setFieldValue("cars.0.media_url", serie.media_url)
      }
    }
  }, [values, setFieldValue])

  return null
}

const BookingForm = () => {
  const classes = useBookingPageStyle()
  const { t, i18n } = useTranslation()
  const { user } = useAuthprovider()
  const currentYear = new Date().getFullYear()
  const {
    setBrand,
    getBookingFormInitialValueFromQuotation,
    submitBooking,
    series,
  } = useDataprovider()
  const [lastVisitBrand, setLastVisitBrand] = useLastVisitBrand()

  const urlParams = new URLSearchParams(window.location.search)
  const quotationId = urlParams.get("quotation_id")
  const quotationInfoId = urlParams.get("quotation_info_id")

  const params = useParams()
  const { brand: brandFromUrl } = params
  const si = brandFromUrl === "mini" ? "msi" : "bsi"
  const cappedBrand = capitalizeBrand(brandFromUrl)

  React.useEffect(() => {
    if (brandFromUrl) {
      setBrand(brandFromUrl)
      setLastVisitBrand(brandFromUrl) // save to localStorage
    }
  }, [brandFromUrl, setBrand])

  const [quotation, setQuotation] = React.useState({})
  const isBlankForm = quotationId === null

  const yearOptions = [
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
  ]

  const paymentOptions = [
    { value: "Cash", label: `${t("field.payment_cash.label")}` },
    { value: "Certified Cheques", label: `${t("field.payment_cheque.label")}` },
    {
      value: "Bank Transfer",
      label: `${t("field.payment_bank_transfer.label")}`,
    },
    { value: "Credit Card", label: `${t("field.payment_creditcard.label")}` },
  ]

  const buyerTypeOptions = [
    { value: "Retail", label: `${t("field.buyer_retail.label")} *` },
    { value: "Corporate", label: `${t("field.buyer_corporate.label")} *` },
  ]

  const carInitialValues = {
    media_url: "/images/placeholder-bmw.png",
    serie: "",
    model: "",
  }

  const initialValues = {
    lang: i18n.language || "th",
    salesperson: "",
    buyer_type: "",
    buyer_company_name: "",
    buyer_firstname: "",
    buyer_lastname: "",
    buyer_profession: "",
    buyer_address: "",
    buyer_postal: "",
    buyer_province: "",
    buyer_mobile: "",
    buyer_fax: "",
    buyer_workphone: "",
    buyer_home_phone: "",
    buyer_email: "",
    vehicle_make: "",
    model_type: "",
    production_year: `${currentYear}`,
    body_colour: "",
    engine_displacement: "",
    chassis_no: "",
    engine_no: "",
    bsi: "",
    bsi_label: "",
    total_price: 0,
    deposit_placed: 0,
    source_of_payment: "",
    deposit_amount: 0,
    balance_due_within: "",
    to_be_settled_by: "",
    approximate_delivery_on: "",
    remarks: "",
    payment_type: "",
    model_id: "",
    cars: [carInitialValues],
  }

  React.useEffect(() => {
    if (quotationId) {
      getBookingFormInitialValueFromQuotation(
        quotationId,
        quotationInfoId
      ).then((response) => {
        setQuotation(response)
      })
    }
  }, [quotationId, quotationInfoId, getBookingFormInitialValueFromQuotation])

  // React.useEffect(() => {
  //   setBrand(brand)
  // }, [brand])

  const validate = (values) => {
    const errors = {}

    // Salesperson
    if (!values.salesperson)
      _.set(
        errors,
        "salesperson",
        `${t("field.mandatory.warning")} ${t("field.salesperson.label")}`
      )

    // Buyer Type
    if (!values.buyer_type)
      _.set(
        errors,
        "buyer_type",
        `${t("field.mandatory.warning")} ${t("field.buyer_type.label")}`
      )

    // Buyer Type
    if (values.buyer_type.indexOf("Corporate") > -1) {
      if (!values.buyer_company_name)
        _.set(
          errors,
          "buyer_company_name",
          `${t("field.mandatory.warning")} ${t(
            "field.buyer_company_name.label"
          )}`
        )
    }

    //Buyer Name
    if (!values.buyer_firstname)
      _.set(
        errors,
        "buyer_firstname",
        `${t("field.mandatory.warning")} ${t("field.buyer_firstname.label")}`
      )

    //Buyer  Last Name
    if (!values.buyer_lastname)
      _.set(
        errors,
        "buyer_lastname",
        `${t("field.mandatory.warning")} ${t("field.buyer_lastname.label")}`
      )

    //Buyer Address
    if (!values.buyer_address)
      _.set(
        errors,
        "buyer_address",
        `${t("field.mandatory.warning")} ${t("field.buyer_address.label")}`
      )

    //Buyer Mobile
    if (!values.buyer_mobile)
      _.set(
        errors,
        "buyer_mobile",
        `${t("field.mandatory.warning")} ${t("field.buyer_mobile.label")}`
      )

    //Buyer Email
    if (!values.buyer_email)
      _.set(
        errors,
        "buyer_email",
        `${t("field.mandatory.warning")} ${t("field.buyer_email.label")}`
      )

    //Vehicle make
    if (
      (!isBlankForm && !values.vehicle_make) ||
      (isBlankForm && !values.cars[0].model)
    )
      _.set(
        errors,
        "vehicle_make",
        `${t("field.mandatory.warning")} ${t("field.vehicle_make.label")}`
      )

    //Model/Type
    if (
      (!isBlankForm && !values.model_type) ||
      (isBlankForm && !values.cars[0].model)
    )
      _.set(
        errors,
        "model_type",
        `${t("field.mandatory.warning")} ${t("field.model_type.label")}`
      )

    //Production Year
    if (!values.production_year)
      _.set(
        errors,
        "production_year",
        `${t("field.mandatory.warning")} ${t("field.production_year.label")}`
      )

    // BSI
    if (!values.bsi_label)
      _.set(
        errors,
        "bsi",
        `${t("field.mandatory.warning")} ${t("field.bsi.label")}`
      )

    //Total price
    if (!values.total_price)
      _.set(
        errors,
        "total_price",
        `${t("field.mandatory.warning")} ${t("field.total_price.label")}`
      )

    //Deposit placed %
    if (!values.deposit_placed)
      _.set(
        errors,
        "deposit_placed",
        `${t("field.mandatory.warning")} ${t("field.deposit_placed.label")}`
      )

    //Source of Payment
    if (!values.source_of_payment)
      _.set(
        errors,
        "source_of_payment",
        `${t("field.mandatory.warning")} ${t("field.source_of_payment.label")}`
      )

    //Deposit Amount
    if (!values.deposit_amount)
      _.set(
        errors,
        "deposit_amount",
        `${t("field.mandatory.warning")} ${t("field.deposit_amount.label")}`
      )

    //Balance Due within (Date)
    if (!values.balance_due_within)
      _.set(
        errors,
        "balance_due_within",
        `${t("field.mandatory.warning")} ${t("field.balance_due_within.label")}`
      )

    //To be settle by
    if (!values.to_be_settled_by)
      _.set(
        errors,
        "to_be_settled_by",
        `${t("field.mandatory.warning")} ${t("field.to_be_settled_by.label")}`
      )

    //Approximate Delivery on (Date)
    if (!values.approximate_delivery_on)
      _.set(
        errors,
        "approximate_delivery_on",
        `${t("field.mandatory.warning")} ${t(
          "field.approximate_delivery_on.label"
        )}`
      )

    return errors
  }

  const onSubmit = async (values) => {
    const serie = values.cars[0].serie
      ? series.find((serie) => serie.id === values.cars[0].serie)
      : {}

    console.log({ user })

    const submitValues = {
      lang: i18n.language || "th",
      salesperson_id: user.legacy_id || 2,
      company_id: user.company_id,
      branch_id: user.branch_id,
      buyer_type: values.buyer_type,
      buyer_company_name: values.buyer_company_name,
      buyer_name: `${values.buyer_firstname} ${values.buyer_lastname}`,
      buyer_profession: values.buyer_profession,
      buyer_address: `${values.buyer_address} ${values.buyer_postal} ${values.buyer_province}`,
      buyer_addressphone: values.buyer_home_phone,
      buyer_mobile: values.buyer_mobile,
      buyer_fax: values.buyer_fax,
      buyer_workphone: values.buyer_workphone,
      buyer_email: values.buyer_email,
      vehicle_make: isBlankForm ? serie.label : values.vehicle_make,
      vehicle_model_id: isBlankForm
        ? `${values.cars[0].model}`
        : `${values.model_id}`,
      vehicle_production_year: values.production_year,
      vehicle_color: values.body_colour,
      vehicle_engine_displacement: values.engine_displacement,
      vehicle_chassis_no: values.chassis_no,
      vehicle_engine_no: values.engine_no,
      //vehicle_warranty_start_date: null,
      bsi_year: values.bsi_label,
      total_price: Number(values.total_price),
      deposit_percent: Number(values.deposit_placed),
      deposit_amount: Number(values.deposit_amount),
      deposit_source: values.source_of_payment,
      //deposit_status: "",
      payment_due_date: dayjs(values.balance_due_within).format(
        "YYYY-MM-DD HH:MM:ss"
      ),
      payment_type: values.payment_type,
      delivery_date: dayjs(values.approximate_delivery_on).format(
        "YYYY-MM-DD HH:MM:ss"
      ),
      remark: values.remarks,
      salesperson_name: values.salesperson,
    }

    const windowReference = window.open()
    submitBooking(submitValues)
      .then((response) => {
        console.log(response)
        windowReference.location = `/booking/pdf/${response.id}`
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{cappedBrand} Create Booking | DSK | BMW Leasing Thailand</title>
      </Helmet>
      <div className={classes.header}>
        <div className={classes.headerContentContainer}>
          <Typography variant="h2" className={classes.title}>
            ONLINE BOOKING FORM
          </Typography>
        </div>
      </div>
      <div className={classes.main}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ errors, isValid, isSubmitting, values, handleChange }) => (
            <Form>
              <SetPrePopulatedFields values={quotation} />
              <div className={classes.formContainter}>
                <div className={classes.carImageContainer}>
                  <img
                    src={
                      values.cars[0].media_url === ""
                        ? "/images/placeholder-bmw.png"
                        : values.cars[0].media_url
                    }
                    alt="Placeholder"
                    style={{ width: "100%", marginBottom: "1em" }}
                  />
                </div>
                <div className={classes.fieldRoot}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.headlineContainer}
                      >
                        {t("field.buyer_info.label")}
                      </Typography>
                      <InputField
                        name="buyer_type"
                        value={values.buyer_type}
                        onChange={handleChange}
                        options={buyerTypeOptions}
                        type="radio"
                      />

                      {values.buyer_type.indexOf("Corporate") > -1 && (
                        <InputField
                          name="buyer_company_name"
                          value={values.buyer_company_name}
                          onChange={handleChange}
                          label={`${t("field.buyer_company_name.label")} *`}
                          type="text"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="buyer_firstname"
                        value={values.buyer_firstname}
                        onChange={handleChange}
                        label={`${t("field.buyer_firstname.label")} *`}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="buyer_lastname"
                        value={values.buyer_lastname}
                        onChange={handleChange}
                        label={`${t("field.buyer_lastname.label")} *`}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        name="buyer_profession"
                        value={values.buyer_profession}
                        onChange={handleChange}
                        label={t("field.buyer_profession.label")}
                        type="text"
                      />
                      <InputField
                        name="buyer_address"
                        value={values.buyer_address}
                        onChange={handleChange}
                        label={`${t("field.buyer_address.label")} *`}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="buyer_postal"
                        value={values.buyer_postal}
                        onChange={handleChange}
                        label={`${t("field.buyer_postal.label")} *`}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="buyer_province"
                        value={values.buyer_province}
                        onChange={handleChange}
                        label={`${t("field.buyer_province.label")} *`}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        name="buyer_home_phone"
                        value={values.buyer_home_phone}
                        onChange={handleChange}
                        label={t("field.buyer_home_phone.label")}
                        type="telephone"
                      />
                      <InputField
                        name="buyer_mobile"
                        value={values.buyer_mobile}
                        onChange={handleChange}
                        label={`${t("field.buyer_mobile.label")} *`}
                        type="telephone"
                      />
                      <InputField
                        name="buyer_fax"
                        value={values.buyer_fax}
                        onChange={handleChange}
                        label={t("field.buyer_fax.label")}
                        type="telephone"
                      />
                      <InputField
                        name="buyer_workphone"
                        value={values.buyer_workphone}
                        onChange={handleChange}
                        label={t("field.buyer_workphone.label")}
                        type="telephone"
                      />

                      <InputField
                        name="buyer_email"
                        value={values.buyer_email}
                        onChange={handleChange}
                        label={`${t("field.buyer_email.label")} *`}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.headlineContainer}
                      >
                        {t("field.vehicle_info.label")}
                      </Typography>

                      {isBlankForm ? (
                        <>
                          <SerieField
                            name="cars.0.serie"
                            label={`${t("field.vehicle_make.label")} *`}
                          />
                          <ModelField
                            name="cars.0.model"
                            type="select"
                            label={`${t("field.model_type.label")} *`}
                          />
                        </>
                      ) : (
                        <>
                          <InputField
                            name="vehicle_make"
                            value={values.vehicle_make}
                            onChange={handleChange}
                            label={`${t("field.vehicle_make.label")} *`}
                            type="text"
                          />
                          <InputField
                            name="model_type"
                            value={values.model_type}
                            onChange={handleChange}
                            label={`${t("field.model_type.label")} *`}
                            type="text"
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="production_year"
                        value={values.production_year}
                        onChange={handleChange}
                        options={yearOptions}
                        label={`${t("field.production_year.label")} *`}
                        type="select"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="body_colour"
                        value={values.body_colour}
                        onChange={handleChange}
                        label={t("field.body_colour.label")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="engine_displacement"
                        value={values.engine_displacement}
                        onChange={handleChange}
                        label={t("field.engine_displacement.label")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="chassis_no"
                        value={values.chassis_no}
                        onChange={handleChange}
                        label={t("field.chassis_no.label")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="engine_no"
                        value={values.engine_no}
                        onChange={handleChange}
                        label={t("field.engine_no.label")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.headlineContainer}
                      >
                        {t(`field.${si}.label`)}
                      </Typography>
                      <InputField
                        name="bsi_label"
                        value={values.bsi_label}
                        onChange={handleChange}
                        type="text"
                        disabled={!isBlankForm}
                        variant={isBlankForm ? "outlined" : "standard"}
                        className={`${classes.fieldAlignment}`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Typography
                        variant="h6"
                        className={classes.headlineContainer}
                      >
                        {`${t("field.totalprice.label")} *`}
                      </Typography>
                      <InputField
                        name="total_price"
                        value={values.total_price}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.headlineContainer}
                      >
                        {t("field.payment_info.label")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="deposit_placed"
                        value={values.deposit_placed}
                        onChange={handleChange}
                        label={`${t("field.deposit_placed.label")} *`}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="source_of_payment"
                        value={values.source_of_payment}
                        onChange={handleChange}
                        options={paymentOptions}
                        label={`${t("field.source_of_payment.label")} *`}
                        type="select"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="deposit_amount"
                        value={values.deposit_amount}
                        onChange={handleChange}
                        label={`${t("field.deposit_amount.label")} *`}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.headlineContainer}
                      >
                        {t("field.balance_info.label")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid item xs={12} sm={6}>
                        <InputField
                          name="balance_due_within"
                          value={values.balance_due_within}
                          onChange={handleChange}
                          label={`${t("field.balance_due_within.label")} *`}
                          type="date"
                        />
                      </Grid>
                      <Grid item xs={12} sm={isBlankForm ? 6 : 12}>
                        <InputField
                          name="to_be_settled_by"
                          value={values.to_be_settled_by}
                          onChange={handleChange}
                          label={`${t("field.to_be_settled_by.label")} *`}
                          type="text"
                          variant={isBlankForm ? "outlined" : "standard"}
                          className={`${classes.fieldAlignment}`}
                          disabled={!isBlankForm}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputField
                          name="approximate_delivery_on"
                          value={values.approximate_delivery_on}
                          onChange={handleChange}
                          label={`${t(
                            "field.approximate_delivery_on.label"
                          )} *`}
                          type="date"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        name="salesperson"
                        value={values.salesperson}
                        onChange={handleChange}
                        label={`${t("field.salesperson.label")} *`}
                        type="text"
                      />
                      <Typography
                        variant="h6"
                        className={classes.headlineContainer}
                      >
                        {t("field.remarks.label")}
                      </Typography>
                      <InputField
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                        type="text"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <div>
                    {_.size(errors) > 0 && (
                      <Alert severity="error" style={{ marginBottom: 8 }}>
                        <AlertTitle>Error</AlertTitle>
                        <ul style={{ paddingLeft: 0 }}>
                          {_.map(errors, (error, i) => (
                            <li key={`error-${i}`}>{error}</li>
                          ))}
                        </ul>
                      </Alert>
                    )}
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                      >
                        Print Request
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default BookingForm
