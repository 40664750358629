import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import { Grid } from "@material-ui/core"

import ConsentForm from "./components/ConsentForm"
import ConfirmedConsent from "./components/ConfirmedConsent"

import usePublicDataProvider from "../../hooks/usePublicDataprovider"

import useStyles from "./style"

const ConsentConfirmation = () => {
  const classes = useStyles()
  const params = useParams()
  const { getCustomerData, switchLanguage } = usePublicDataProvider()
  const [isExpired, setIsExpired] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)

  const [customerData, setCustomerData] = useState({
    phonenumber: "",
    firstName: "",
    lastName: "",
    email: "",
    nickname: "",
    lineId: "",
  })

  useEffect(() => {
    getCustomerData(params.consentId)
      .then((response) => {
        return response.data
      })
      .then((result) => {
        const {
          phonenumber = "",
          first_name = "",
          last_name = "",
          email = "",
          nickname = "",
          line_id = "",
          is_received = 0,
          expire_time = "",
          lang = "th",
        } = result

        switchLanguage(lang)

        setIsExpired(new Date().valueOf() > new Date(expire_time).valueOf())
        setIsConfirmed(is_received === 1)
        setCustomerData({
          phonenumber,
          firstName: first_name,
          lastName: last_name,
          email,
          nickname,
          lineId: line_id,
        })
      })
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={1}></Grid>
        <Grid item md={4} xs={10}>
          {!isExpired && !isConfirmed ? (
            <ConsentForm
              customerData={customerData}
              consentId={params.consentId}
            />
          ) : (
            <ConfirmedConsent isExpired={isExpired} />
          )}
        </Grid>
        <Grid item md={4} xs={1}></Grid>
      </Grid>
    </div>
  )
}

export default ConsentConfirmation
