import React, { useState } from "react"
import clsx from "clsx"
import _ from "lodash"
import LogoSVG from "../logoSvg"

import { NavLink, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import MuiAppBar from "@material-ui/core/AppBar"
import ListSubheader from "@material-ui/core/ListSubheader"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import { makeStyles } from "@material-ui/core/styles"
import { Menu, ExpandLess, ExpandMore } from "@material-ui/icons"
import {
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
  Collapse,
} from "@material-ui/core"
import DropdownMenu from "../DropdownMenu"

const BREAK_POINT = "sm"

const useStyles = makeStyles((theme) => ({
  appBar: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px 60px 20px",
    fontWeight: "200",
    zIndex: 1201,
    [theme.breakpoints.down(BREAK_POINT)]: {
      padding: "1px 0px 1px",
      borderBottom: "1px solid #707070",
    },
  },
  toolBar: {
    width: "inherit",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: "1px solid #707070",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down(BREAK_POINT)]: {
      alignItems: "center",
      borderBottom: "none",
    },
  },
  menuButton: {
    width: "max-content",
    [theme.breakpoints.down(BREAK_POINT)]: {
      width: "100%",
    },
  },
  menuButtonLanguage: {
    textTransform: "uppercase",
    width: "max-content",
    color: theme.palette.primary,
    [theme.breakpoints.down(BREAK_POINT)]: {
      width: "100%",
    },
  },
  menuButtonLanguageActive: {
    textTransform: "uppercase",
    width: "max-content",
    color: "#1C69D4",
    fontWeight: 700,
    [theme.breakpoints.down(BREAK_POINT)]: {
      width: "100%",
    },
  },
  spacer: {
    flex: 1,
  },
  menuList: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 0,
    alignItems: "flex-end",
    [theme.breakpoints.down(BREAK_POINT)]: {
      display: "none",
    },
  },
  dropdownMenuItem: {
    display: "inline",
    padding: theme.spacing(1),
    "&:hover": {
      borderBottom: "2px solid #4D4D4D",
    },
  },
  orLang: {
    width: "1px",
    height: "26px",
    background: "black",
    marginBottom: "5px",
    marginTop: "5px",
  },
  containerLogoBMW: {
    width: "66px",
    marginLeft: "26px",
    [theme.breakpoints.down(BREAK_POINT)]: {
      width: 40,
      height: 40,
      marginRight: "10px",
    },
  },
  containerLogoMini: {
    width: "66px",
    marginLeft: "26px",
    [theme.breakpoints.down(BREAK_POINT)]: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(8),
    },
  },
  containerLogoMotorrad: {
    width: 155,
    mixBlendMode: "multiply",
    right: 0,
    top: 0,
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down(BREAK_POINT)]: {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  menuBurger: {
    display: "none",
    [theme.breakpoints.down(BREAK_POINT)]: {
      display: "block",
    },
  },
  drawerPaper: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    top: theme.spacing(7.5),
  },
  drawerHeader: {
    width: "inherit",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "3px",
    borderBottom: "1px solid #ececec",
  },
  drawerList: {
    paddingBottom: "0px",
    borderBottom: "1px solid #ececec",
  },
  drawerLang: {
    fontSize: "12px",
    color: "#9B9B9B",
    fontWeight: 300,
  },
  drawerMenuContainer: {
    justifyContent: "space-between",
  },
  drawerMenu: {
    fontWeight: 200,
  },
  drawerMenuItem: {
    fontWeight: 200,
    paddingLeft: theme.spacing(4),
  },
  btnLogout: {
    fontSize: "0.875rem",
    fontWeight: 300,
  },
  navActive: {
    fontWeight: 700,
  },
}))

const Drawer = ({
  listMenus,
  toggleDrawer,
  stateDrawer,
  getLanguageNavItemClass,
  locales,
  changeLanguage,
  onLogout,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <SwipeableDrawer
        anchor="top"
        elevation={0}
        open={stateDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{
          height: "auto",
          width: "100%",
          zIndex: 4,
        }}
      >
        <List className={classes.drawerList}>
          {listMenus.map((menu, index) => {
            if (menu.items) {
              return (
                <React.Fragment key={`menu-mobile-${index}`}>
                  <ListItem
                    button
                    onClick={handleClick}
                    className={classes.drawerMenuContainer}
                  >
                    <ListItemText
                      component="p"
                      primary={menu.name}
                      classes={{
                        primary: clsx({
                          [classes.navActive]: menu.items.some((item) =>
                            location.pathname.includes(item.to)
                          ),
                        }),
                      }}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {menu.items.map(({ name, to }, index) => (
                        <ListItem
                          button
                          component={NavLink}
                          key={`menu-mobile-item-${index}`}
                          onClick={toggleDrawer(false)}
                          className={classes.drawerMenuItem}
                          to={to}
                          activeClassName={classes.navActive}
                        >
                          {name}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              )
            } else {
              return (
                <ListItem
                  button
                  component={menu.to ? NavLink : "a"}
                  target={menu.href && "_blank"}
                  className={classes.drawerMenu}
                  key={`menu-mobile-${index}`}
                  exact={menu.exact}
                  to={menu.to}
                  href={menu.href}
                  onClick={toggleDrawer(false)}
                  activeClassName={classes.navActive}
                >
                  {menu.name}
                </ListItem>
              )
            }
          })}
        </List>
        <List
          className={classes.drawerList}
          subheader={
            <ListSubheader className={classes.drawerLang}>
              Language
            </ListSubheader>
          }
        >
          {locales.map((lang, index) => (
            <ListItem
              button
              onClick={() => changeLanguage(lang)}
              key={`menu-lang-mobile-${index}`}
            >
              <ListItemText
                classes={{
                  primary: getLanguageNavItemClass(lang),
                }}
                primary={lang}
              />
            </ListItem>
          ))}
        </List>
        <List className={classes.drawerList}>
          <ListItem button onClick={onLogout}>
            <Typography className={classes.btnLogout} component="span">
              Logout
            </Typography>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </>
  )
}

const flexibleLogo = (brand) => {
  switch (brand) {
    case "mini": {
      return <img src="/images/Mini/Mini_logo_black.svg" alt="MINI Logo" />
    }
    case "motorrad": {
      return (
        <img
          src="/images/BMW_Motorrad/BMW_Motorrad_logo.png"
          alt="Motorrad Logo"
        />
      )
    }
    case "bmw":
    default:
      return <LogoSVG size={"100%"} />
  }
}

const AppBar = ({
  listMenus,
  toggleDrawer,
  getLanguageNavItemClass,
  locales,
  changeLanguage,
  onLogout,
  brand,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const brandLogo = flexibleLogo(brand)
  let logoContainer = null
  if (brand === "motorrad") logoContainer = classes.containerLogoMotorrad
  else if (brand === "mini") logoContainer = classes.containerLogoMini
  else logoContainer = classes.containerLogoBMW

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MuiAppBar
        className={classes.appBar}
        color="transparent"
        elevation={0}
        position="static"
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            className={classes.menuBurger}
          >
            <Menu />
          </IconButton>
          <List className={classes.menuList}>
            {listMenus.map((menu, index) => {
              if (menu.items) {
                return (
                  <React.Fragment key={`menu-${index}`}>
                    <ListItem
                      button
                      className={clsx({
                        [classes.menuButton]: true,
                        [classes.navActive]: menu.items.some((item) =>
                          location.pathname.includes(item.to)
                        ),
                      })}
                      onClick={handleClick}
                    >
                      {menu.name}
                    </ListItem>
                    <DropdownMenu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                    >
                      {menu.items.map(({ name, to }, index) => {
                        return (
                          <NavLink
                            to={to}
                            key={`menu-item-${index}`}
                            activeClassName={classes.navActive}
                          >
                            <ListItemText
                              primary={name}
                              classes={{
                                primary: classes.dropdownMenuItem,
                              }}
                            />
                          </NavLink>
                        )
                      })}
                    </DropdownMenu>
                  </React.Fragment>
                )
              } else {
                return (
                  <ListItem
                    button
                    component={menu.to ? NavLink : "a"}
                    target={menu.href && "_blank"}
                    className={classes.menuButton}
                    key={`menu-${index}`}
                    exact={menu.exact}
                    to={menu.to}
                    href={menu.href}
                    activeClassName={classes.navActive}
                  >
                    {menu.name}
                  </ListItem>
                )
              }
            })}
          </List>
          <div className={classes.spacer} />
          <List className={classes.menuList}>
            {locales.map((lang, index) => (
              <ListItem
                className={getLanguageNavItemClass(lang)}
                button
                key={`menu-lang-${index}`}
                onClick={() => changeLanguage(lang)}
              >
                {lang}
              </ListItem>
            ))}
            <div className={classes.orLang} />
            <ListItem button onClick={onLogout}>
              Logout
            </ListItem>
          </List>
        </Toolbar>
        <div className={logoContainer}>{brandLogo}</div>
      </MuiAppBar>
    </>
  )
}

export default function NavBar({ onLogout, brand }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const locales = ["en", "th"]
  const [stateDrawer, setStateDrawer] = useState(false)

  const getLanguageNavItemClass = (lang) => {
    return lang === i18n.language
      ? classes.menuButtonLanguageActive
      : classes.menuButtonLanguage
  }

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown") {
      return
    }
    setStateDrawer(open)
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  const generateListMenus = (brand) => {
    switch (brand) {
      case "mini": {
        return _.compact([
          { name: t("nav.home"), to: "/", exact: true },
          {
            name: t("nav.quotation"),
            to: "#",
            items: [
              { name: t("nav.new-car"), to: `/calculate` },
              { name: t("nav.accessories"), to: "/accessory" },
            ],
          },
          { name: t("nav.booking"), to: "/view-quotations" },
          { name: t("nav.help"), href: "https://help.bmwleasingthailand.com/" },
        ])
      }
      case "motorrad": {
        return _.compact([
          { name: t("nav.home"), to: "/", exact: true },
          {
            name: t("nav.quotation"),
            to: "#",
            items: [{ name: t("nav.new-car"), to: `/calculate` }],
          },
          { name: t("nav.help"), href: "https://help.bmwleasingthailand.com/" },
        ])
      }
      case "bmw":
      default:
        return _.compact([
          { name: t("nav.home"), to: "/", exact: true },
          {
            name: t("nav.quotation"),
            to: "#",
            items: [
              { name: t("nav.new-car"), to: `/calculate` },
              { name: t("nav.used-car"), to: `/used-car` },
              { name: t("nav.corporate-sales"), to: "/corporate-sales" },
              { name: t("nav.accessories"), to: "/accessory" },
            ],
          },
          { name: t("nav.booking"), to: "/view-quotations" },
          { name: t("nav.wheel"), to: "/wheel" },
          { name: t("nav.help"), href: "https://help.bmwleasingthailand.com/" },
        ])
    }
  }

  const listMenus = generateListMenus(brand)

  const childProps = {
    locales,
    stateDrawer,
    getLanguageNavItemClass,
    listMenus,
    changeLanguage,
    toggleDrawer,
    onLogout,
    brand,
  }

  return (
    <>
      <AppBar {...childProps} />
      <Drawer {...childProps} />
    </>
  )
}
