import { Decimal } from "decimal.js"
import { useFormikContext } from "formik"

import {
  findRate,
  // calculateCarPrice,
  calculateFinanceAmount as FA,
  calculateEstimateCarpriceFromFinanceAmount as FAtoECP,
  calculateFutureValue,
  tvmCalculatePresentValue as calcPV,
  tvmCalculatePayment as calcPMT,
  tvmCalculateRate as calcRATE,
  tvmCalculateFutureValue as calcFV,
  calculateDownPaymentPercent,
} from "../utils/calculation"

const useAdvanceCalculation = (index) => {
  const { values, setFieldValue } = useFormikContext()

  const car = values.cars[index]

  const {
    sf_rate: defaultSfRate,
    margin_rate,
    term,
    type_of_payment,
    estimate_car_price = 0,
    production_year,
    vat_include_type,
    insurance_or_accessories,
    discount,
    balloon,
    down_payment,
    monthly_installment: defaultPMT,
  } = car

  const defaultPV = FA(
    estimate_car_price,
    vat_include_type,
    down_payment,
    discount,
    insurance_or_accessories
  )

  const defaultRATE = findRate(defaultSfRate, margin_rate)
  const defaultFV = calculateFutureValue(defaultPV, balloon)

  const seekMarginRate = ({
    presentValue = defaultPV,
    payment = defaultPMT,
    futureValue = defaultFV,
  }) => {
    const rate = calcRATE(
      term,
      payment,
      presentValue,
      futureValue,
      type_of_payment
    )

    const marginValue = rate - defaultSfRate

    // console.log(`seekMarginRate [${marginValue}]`, {
    //   presentValue,
    //   payment,
    //   futureValue,
    // })

    setFieldValue(`cars.${index}.margin_rate`, marginValue)
  }

  const seekMonthlyInstallment = (
    { presentValue = defaultPV, rate = defaultRATE, futureValue = defaultFV },
    source = ``
  ) => {
    if (production_year) {
      const monthlyInstallmentValue = calcPMT(
        rate,
        term,
        presentValue,
        futureValue,
        type_of_payment
      )
      // console.log(`seekMonthlyInstallment [${monthlyInstallmentValue}]`, {
      //   presentValue,
      //   rate,
      //   futureValue,
      // })

      setFieldValue(
        `cars.${index}.monthly_installment`,
        monthlyInstallmentValue
      )
    }
  }

  const seekDownPayment = ({
    rate = defaultRATE,
    payment = defaultPMT,
    futureValue = defaultFV,
  }) => {
    const presentValue = calcPV(
      rate,
      term,
      payment,
      futureValue,
      type_of_payment
    )

    const downPaymentValue = calculateDownPaymentPercent(
      presentValue,
      estimate_car_price
    )

    if (process.env.NODE_ENV !== "production")
      console.log(`seekDownPayment: ${downPaymentValue}`, {
        presentValue,
        estimate_car_price,
        futureValue,
        rate,
        term,
        payment,
      })
    setFieldValue(`cars.${index}.down_payment`, downPaymentValue)
  }

  const seekBalloon = ({
    presentValue = defaultPV,
    rate = defaultRATE,
    payment = defaultPMT,
  }) => {
    const futureValue = calcFV(rate, term, payment, -presentValue)
    const balloonValue = new Decimal(futureValue)
      .div(estimate_car_price)
      .times(100)
      .toNumber()

    setFieldValue(`cars.${index}.balloon`, balloonValue)
  }

  return {
    seekMarginRate,
    seekMonthlyInstallment,
    seekDownPayment,
    seekBalloon,
  }
}

export default useAdvanceCalculation
