import React from "react"
import _ from "lodash"
import { Field, useFormikContext } from "formik"
import { useTranslation, Trans } from "react-i18next"

import InputField, { FieldLabel } from "../../../components/InputField"

const ConsentFormField = (props) => {
  const { name, type, label, inputRowStyle, inputFieldProps, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { touched, errors } = form
        return (
          <div>
            <FieldLabel label={label} />
            <div style={inputRowStyle}>
              <InputField
                type={type}
                label={label}
                error={
                  _.get(touched, field.name) && !!_.get(errors, field.name)
                }
                helperText={_.get(errors, field.name)}
                {...field}
                {...inputFieldProps}
                {...rest}
              />
            </div>
          </div>
        )
      }}
    </Field>
  )
}

/** Checkboxes Field */
export const CheckboxesField = (props) => {
  const { t } = useTranslation()
  const {
    values: { checkbox_group },
  } = useFormikContext()
  const { basic_consent, marketing_consent } = checkbox_group

  const options = [
    {
      value: basic_consent,
      label: (
        <Trans
          i18nKey="field.basic.consent"
          components={{
            TCLink: (
              <a
                style={{ color: "#1C69D4" }}
                href="https://www.bmw.co.th/th/footer/legal-information/terms-and-conditions.html"
                target="_blank"
              />
            ),
            PPLink1: (
              <a
                style={{ color: "#1C69D4" }}
                href="https://www.bmw.co.th/th/footer/legal-information/privacy-policy.html"
                target="_blank"
              />
            ),
            PPLink2: (
              <a
                style={{ color: "#1C69D4" }}
                href="https://www.bmw.co.th/th/footer/legal-information/privacy-policy.html"
                target="_blank"
              />
            ),
          }}
        />
      ),
      name: "checkbox_group.basic_consent",
      required: true,
    },
    {
      value: marketing_consent,
      label: `${t("field.marketing.consent")}`,
      name: "checkbox_group.marketing_consent",
      required: false,
    },
  ]

  return (
    <ConsentFormField
      name={props.name}
      type="checkboxes"
      label={props.label}
      options={options}
    />
  )
}

export default ConsentFormField
