import React from "react"
import { makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  addRemoveCarBtn: {
    display: "flex",
    flexDirection: "column",
    "& button": {
      marginTop: 8,
    },
    "& button:nth-of-type(1)": {
      marginTop: 0,
    },
  },
}))

const CalculationActions = ({ values, push, remove, carInitialValues }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.addRemoveCarBtn}>
      <Button
        variant="contained"
        size="large"
        color="secondary"
        onClick={() => push(carInitialValues)}
      >
        {t("form_action.add_car")}
      </Button>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={() => remove(values.cars.length - 1)}
        disabled={!(values.cars.length > 1)}
      >
        {t("form_action.remove_car")}
      </Button>
    </div>
  )
}

export default CalculationActions
