import React from "react"
import { useParams } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useDataprovider from "../../../hooks/useDataprovider"

const useStyles = makeStyles((theme) => ({
  articleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    paddingTop: theme.spacing(5),
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  divider: {
    borderTop: "1px solid #f8f8f8",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      alignItems: "center",
    },
  },
}))

const Article = () => {
  const params = useParams()
  const newsId = params.newsId
  const [article, setArticle] = React.useState({})
  const { getArticleById } = useDataprovider()
  const classes = useStyles()

  React.useEffect(() => {
    getArticleById(newsId).then(setArticle)
  }, [newsId, getArticleById])

  if (!article) return null

  const { title, body } = article
  return (
    <div className={classes.body}>
      <div className={classes.articleWrapper}>
        <Typography variant="h4">{title}</Typography>
      </div>
      <div className={classes.imageWrapper}>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <div className={classes.divider} />
      <div className={classes.footer}>
        <Typography component="span">
          All images are only used for commercial purposes.
        </Typography>
        <br />
        <Typography component="span">
          Some equipment may vary from the model shown.
        </Typography>
      </div>
    </div>
  )
}

export default Article
