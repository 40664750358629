import { useState, useCallback } from "react"
import { useLocalStorage } from "react-use"

const useLastVisitBrand = () => {
  const [lastVisitBrandInStorage, setLastVisitBrandInStorage] = useLocalStorage(
    "bmw-dsk:lastvisit-brand"
  )
  const [lastVisitBrand, setLastVisitBrand] = useState(lastVisitBrandInStorage)

  const set = (brand) => {
    setLastVisitBrand(brand)
    setLastVisitBrandInStorage(brand)
  }

  const getBrandId = useCallback((brand) => {
    switch (brand) {
      case "bmw":
        return 1
      case "mini":
        return 2
      case "motorrad":
        return 3
      default:
        return 1
    }
  }, [])

  return [lastVisitBrand, set, { getBrandId }]
}

export default useLastVisitBrand
