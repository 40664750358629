import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 0 /* Remove scrollbar space */,
      background: "transparent" /* Optional: just make scrollbar invisible */,
    },
  },
  cell: {},
}))

const HorizontalScrollMenu = ({ items, renderItem }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.sliderContainer}>
        {items.map((item, index) => (
          <div className={classes.cell} key={`cell-${index}`}>
            {renderItem(item)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default HorizontalScrollMenu
