import _ from "lodash"
import { Decimal } from "decimal.js"
import {
  calculateFinanceAmount,
  calculateResidual,
  calculateCarPrice,
  findRate,
  tvmCalculatePayment,
  calculateFutureValue,
} from "./calculation"

const prepareSubmitQuotation = (values, { lang, user, findSFRate }) => {
  const submitValues = {
    company_id: user.company_id || 1,
    branch_id: user.branch_id || 1,
    user_id: user.legacy_id || 1,
    name: `${_.get(values, "customer_information.customer_firstname")} ${_.get(
      values,
      "customer_information.customer_surname"
    )}`,
    action: 1,
    email: _.get(values, "customer_information.email"),
    phone: _.get(values, "customer_information.telephone"),
    sale_name: user.name,
    sale_contact: user.email,
    sale_phone: user.phone_number,
    remark: _.get(values, "remark", ""),
    print_status: 1,
    data: _.map(values.cars, (car) => {
      const {
        margin_rate,
        rebate,
        model_name,
        is_bmw_premium_selection,
        production_year,
        mileage,
        warranty_expire,
        bsi_expire,
        maximum_miles = 0,
        estimate_car_price = 0,
        vat_include_type,
        type_of_payment,
        insurance_or_accessories = 0,
        discount = 0,
        down_payment = 0,
        term = 48,
        balloon = 0,
        campaigns,
      } = car

      const sf_rate = findSFRate(
        production_year,
        type_of_payment,
        term,
        down_payment,
        balloon
      )

      const car_price = estimate_car_price

      const finance_amount = calculateFinanceAmount(
        estimate_car_price,
        vat_include_type,
        down_payment,
        discount,
        insurance_or_accessories
      )

      const rate = findRate(sf_rate, margin_rate)

      const balloon_amount = Decimal(0.01)
        .times(balloon)
        .times(estimate_car_price)
        .toNumber() // 0.01 * balloon * estimate_car_price

      const monthly_installment = tvmCalculatePayment(
        rate,
        term,
        finance_amount,
        balloon_amount,
        type_of_payment
      )

      return {
        model_name: model_name,
        is_bmw_premium_selection: is_bmw_premium_selection,
        production_year: `${production_year}`,
        mileage: `${mileage}`,
        warranty_expire: warranty_expire,
        bsi_expire: bsi_expire,
        maximum_miles: `${maximum_miles}`,
        payment_type: type_of_payment,
        price: estimate_car_price,
        discount: discount,
        term: term,
        down: down_payment,
        down_amount: Decimal(0.01)
          .times(down_payment)
          .times(estimate_car_price)
          .toNumber(), // 0.01 * down_payment * estimate_car_price
        balloon: balloon,
        balloon_amount: Decimal(0.01)
          .times(balloon)
          .times(estimate_car_price)
          .toNumber(), // 0.01 * balloon * estimate_car_price
        deposit: balloon,
        deposit_amount: Decimal(0.01)
          .times(balloon)
          .times(estimate_car_price)
          .toNumber(), // 0.01 * balloon * estimate_car_price
        finance: finance_amount,
        monthly: monthly_installment,
        residual: calculateResidual(estimate_car_price, down_payment, balloon),
        margin_rate: car.margin_rate,
        customer_rate: findRate(sf_rate, car.margin_rate),
        flat_rate: sf_rate,
        dealer_rebate: rebate,
        rebate_sharing: 0,
        insurance: insurance_or_accessories,
        remark: JSON.stringify({
          model_name,
          is_bmw_premium_selection,
          production_year,
          mileage,
          warranty_expire,
          bsi_expire,
          maximum_miles,
          vat_include_type,
        }),
        raw: JSON.stringify({}),
        lang: lang,
        status: 1,
        campaign_list: _.map(campaigns, _.toNumber),
      }
    }),
  }

  return submitValues
}

export default prepareSubmitQuotation
