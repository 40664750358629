import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Button from "@material-ui/core/Button"

import { useTranslation } from "react-i18next"

import {
  SfRateField,
  MarginField,
  ResetField,
  RebateField,
  AdvanceCalculationToggleField,
} from "../../calculate/components/Fields"

import { adornmentPercent, adornmentBaht } from "../../../constants"

const useStyles = makeStyles((theme) => ({
  calculationSettingRoot: {
    minHeight: 40,
    padding: 16,
    background: "#ccc",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    flex: 1,
    color: "black",
    paddingBottom: 0,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    [theme.breakpoints.down("sm")]: {
      height: "auto !important",
    },
  },
  divider: {
    borderTop: "1px solid #ffffff",
    marginBottom: theme.spacing(4),
  },
  screenActionsContainer: {},
  calculationOptionsRoot: {
    marginTop: theme.spacing(2),
  },
  addRemoveCarBtn: {
    display: "none",
    "& button": {
      marginTop: 8,
    },
    "& button:nth-of-type(1)": {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
    },
  },
}))

const CorporateCalculationSetting = React.memo(
  ({ values = [], push, remove, carInitialValues }) => {
    const [expanded, setExpanded] = React.useState(true)
    const classes = useStyles()
    const { t } = useTranslation()
    return (
      <div>
        <div className={classes.calculationSettingRoot}>
          <div className={classes.header}>
            <Typography component="h3" className={classes.title}>
              {t("fieldset.calculation_setting.title")}
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              size="small"
              onClick={(e) => setExpanded(!expanded)}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <Collapse in={expanded} timeout="auto" className={classes.collapse}>
            <p>&nbsp;</p>
            {values.cars.length > 0 &&
              values.cars.map((car, index) => (
                <MarginField
                  key={index}
                  label={index === 0 ? t("field.margin.label") : false}
                  beforeInput={car.name || ``}
                  name={`cars.${index}.margin_rate`}
                  adornment={adornmentPercent}
                />
              ))}
            <div className={classes.divider} />
            {values.cars.length > 0 &&
              values.cars.map((car, index) => (
                <RebateField
                  key={index}
                  name={`cars.${index}.rebate`}
                  type="number"
                  label={index === 0 ? t("field.rebate.label") : false}
                  beforeInput={car.name || ``}
                  adornment={adornmentBaht}
                />
              ))}
            <div className={classes.divider} />
            {values.cars.length > 0 &&
              values.cars.map((car, index) => (
                <SfRateField
                  key={index}
                  label={index === 0 ? t("field.sf_rate.label") : false}
                  beforeInput={car.name || ``}
                  name={`cars.${index}.sf_rate`}
                  disabled
                />
              ))}
          </Collapse>
        </div>
        <div className={classes.calculationOptionsRoot}>
          <div className={classes.addRemoveCarBtn}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => push(carInitialValues)}
            >
              {t("form_action.add_car")}
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={() => remove(values.cars.length - 1)}
              disabled={!(values.cars.length > 1)}
            >
              {t("form_action.remove_car")}
            </Button>
          </div>
          <AdvanceCalculationToggleField
            name={`advance_calculation`}
            type="switch"
            label={t("field.advanced_calculation.label")}
          />
          <ResetField
            name={`clear_calculation`}
            type="reset"
            label={t("field.clear_calculation.label")}
          />
        </div>
      </div>
    )
  }
)

export default CorporateCalculationSetting
