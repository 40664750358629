import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
// import Link from "../adapters/Link"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { use100vh } from "react-div-100vh"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#262626",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5vh 8vw",
    color: "#ffffff",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
      flexDirection: "column",
      padding: "5vw 1vw 5vw 0px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "5vw 5vw",
    },
  },
  content: {
    flex: 1,
    overflow: "hidden",
    // [theme.breakpoints.up("md")]: {
    //   height: "100%",
    // },
  },
  salesmanKit: {
    color: "#fff",
    fontSize: "3.5vh",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "3vh",
      margin: "0px 0px 0px 7vw",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "3vh",
      margin: "0px",
    },
  },
  financeService: {
    fontSize: "2.8vh",
    fontWeight: "1000",
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: "1vw",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "2.5vh",
      "& span": {
        marginLeft: "3vw",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "2.5vh",
    },
  },
  bmwLogo: {
    [theme.breakpoints.between("xs", "sm")]: {
      transform: "scale(1.8,1.8)",
      padding: "4vw 0px 0px 0px",
    },
  },
  border: {
    position: "relative",
    paddingTop: "0%",
    height: "100%",
    border: "2px solid #ffffff",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    // height: "85vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vw",
      border: "none",
    },
  },
  resize: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "50%",
    bottom: "0",
    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000 )",
  },
  brandLogo: {
    position: "absolute",
    top: "0",
    right: "0",
    padding: "28px 36px 0px 0px",
  },
  blendMode: {
    mixBlendMode: "multiply",
  },
  typographyRoot: {
    color: "#fff",
  },
  brandDetail: {
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "0px 0px 64px 57px",
    color: "#ffffff",
    letterSpacing: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    "& h1": {
      margin: "0px 0px 15px 0px",
      fontSize: "2em",
      fontWeight: "200",
    },
    "& .btn-get-start": {
      border: "2px solid #ffffff",
      color: "#ffffff",
      fontSize: "1em",
      fontWeight: "800",
      backgroundColor: "transparent",
      outline: "none",
      padding: "15px 86px",
      "&:hover": {
        backgroundColor: "#ffffff",
        color: "#262626",
        transition: "0.5s",
      },
    },
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "0px 0px 40px 40px",
      "& h1": {
        margin: "0px 0px 5px 0px",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "0px 0px 50px 50px",
      "& h1": {
        margin: "0px 0px 15px 0px",
      },
    },
  },
}))

export default function Home() {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))

  const height = use100vh()
  return (
    <div
      className={classes.root}
      style={matches ? { height } : { height: "auto" }}
    >
      <div className={classes.header}>
        <Typography component="span" className={classes.salesmanKit}>
          Digital Salesman Kit
        </Typography>
        <div className={classes.financeService}>
          <img
            src="/images/BMW/logo-BMW-white@2x.png"
            width="30vw"
            height="30vw"
            alt="BMW Company Logo"
          />
          <Typography component="span" className={classes.typographyRoot}>
            BMW Financial Services
          </Typography>
        </div>
      </div>
      <Grid container className={classes.content}>
        <Grid className={classes.border} item xs={12} md={4}>
          <img
            className={classes.resize}
            src="/images/BMW/BMW_main.jpg"
            alt="BMW"
          />
          <div className={classes.overlay} />
          <div className={classes.brandLogo}>
            <img
              src="/images/BMW/logo-BMW-white@2x.png"
              width="52"
              height="52"
              alt="BMW Logo"
            />
          </div>
          <div className={classes.brandDetail}>
            <Typography variant="h1" className={classes.typographyRoot}>
              BMW
            </Typography>
            <Button
              component={Link}
              to="/calculate/bmw"
              className="btn-get-start"
            >
              Get Started
            </Button>
          </div>
        </Grid>
        <Grid className={classes.border} item xs={12} md={4}>
          <img
            className={classes.resize}
            src="/images/Mini/Mini_main.jpg"
            alt="Mini"
          />
          <div className={classes.overlay} />
          <div className={classes.brandLogo}>
            <img
              src="/images/Mini/Mini_logo.svg"
              width="93"
              height="41"
              alt="Mini Logo"
            />
          </div>
          <div className={classes.brandDetail}>
            <Typography variant="h1" className={classes.typographyRoot}>
              MINI
            </Typography>
            <Button
              component={Link}
              to="/calculate/mini"
              className="btn-get-start"
            >
              Get Started
            </Button>
          </div>
        </Grid>
        <Grid className={classes.border} item xs={12} md={4}>
          <img
            className={classes.resize}
            src="/images/BMW_Motorrad/BMW_Motorrad_main.jpg"
            alt="Motorrad"
          />
          <div className={classes.overlay} />
          <div className={`${classes.brandLogo} ${classes.blendMode}`}>
            <img
              src="/images/BMW_Motorrad/BMW_Motorrad_logo@2x.png"
              width="165"
              height="50"
              alt="Motorrad"
            />
          </div>
          <div className={classes.brandDetail}>
            <Typography variant="h1" className={classes.typographyRoot}>
              BMW Motorrad
            </Typography>
            <Button
              component={Link}
              to="/calculate/motorrad"
              className="btn-get-start"
            >
              Get Started
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
