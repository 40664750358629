import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    padding: "30px 60px 60px",
    minHeight: "84vh",
  },
  title: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 700,
  },
  seriesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
  },
  subtitle: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 300,
    marginRight: theme.spacing(1),
  },
  uppercaseTypoStyle: {
    textTransform: "uppercase",
  },
  bold: {
    fontWeight: 500,
  },
  imgSliderContainer: {
    position: "relative",
    paddingTop: "56.25%",
    marginBottom: theme.spacing(2),
  },
  imgRatio: {
    position: "absolute",
    objectFit: "cover",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  imgContainer: {
    position: "relative",
  },
  // textOverlay: { //hide for now, we might need it again in the future
  //   position: "absolute",
  //   top: 0,
  //   paddingTop: theme.spacing(2.5),
  //   color: "white",
  //   display: "flex",
  //   width: "100%",
  // background:
  //   "linear-gradient(180deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.48) 0.01%, rgba(24, 24, 24, 0.213747) 33.33%, rgba(43, 43, 43, 0) 70.95%, rgba(0, 0, 0, 0) 100%)",
  //   marginRight: 0,
  // },
  // left: {
  //   paddingLeft: "20%",
  // },
  // right: {
  //   paddingLeft: "70%",
  // },
  marginVertical10: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
}))

export default useStyles
