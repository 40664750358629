import React from "react"
import { useTranslation } from "react-i18next"

import ThaiTermsAndCons from "./component/thaiTermsAndCons"
import EnglishTermsAndCons from "./component/englishTermsAndCons"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(10),
    wordWrap: "break-word",
    "& a": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(10),
      paddingTop: theme.spacing(1),
      justifyContent: "center",
    },
  },
}))

const TermsAndConditions = () => {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {currentLanguage === "en" ? (
        <EnglishTermsAndCons />
      ) : (
        <ThaiTermsAndCons />
      )}
    </div>
  )
}

export default TermsAndConditions
