const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  authClientId: process.env.REACT_APP_AUTH_CLIENTID,
  authAuthorization: process.env.REACT_APP_AUTH_AUTHORIZATION,
  authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
  authRedirectURI: process.env.REACT_APP_AUTH_REDIRECTURI,
  assetHost: process.env.REACT_APP_ASSET_HOST,
  baseUrl: process.env.REACT_APP_BASE_URL,
}

export default config
