import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import ListAltIcon from "@material-ui/icons/ListAlt"
import Link from "@material-ui/core/Link"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { Link as RouterLink } from "react-router-dom"
import _ from "lodash"
import useDataprovider from "../../hooks/useDataprovider"
import dayjs from "dayjs"

// assume that's an article will be fetched from the backend

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "auto",
    verticalAlign: "top",
    "& button": {
      marginRight: theme.spacing(4),
      height: "2.57rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      flexDirection: "column",
      "& button": {
        marginRight: 0,
        marginTop: theme.spacing(2),
      },
    },
  },
  selectorStyle: {
    "& .MuiSelect-root": {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1.4),
      color: "#fff",
    },
    "& .MuiSelect-icon": {
      color: "#ff",
    },
    width: "16rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  },
  articleStyle: {
    border: "1px solid #f8f8f8",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "50%",
    },
  },
  articleDetailStyle: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  articlePicStyle: {
    position: "relative",
    "& $img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  articleFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    borderTop: "1px solid #f8f8f8",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      alignItems: "center",
    },
  },
}))

const News = ({ match }) => {
  const { articles } = useDataprovider()
  const [filterId, setFilterId] = useState(0)

  const getFilterArticles = () => {
    if (filterId === 0) return articles
    else return _.filter(articles, ["tagId", filterId])
  }
  let filteredArticles = getFilterArticles()

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.bodyStyle}>
        <Grid container justify="center" spacing={2}>
          <Grid md={1} item />
          <Grid md={10} xs={12} item>
            <div className={classes.headerContainer}>
              <Typography variant="h5">NEWS & PROMOTION</Typography>
              <div className={classes.btnWrapper}>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  startIcon={<ListAltIcon />}
                >
                  View Bulletin
                </Button>
                <Select
                  name="filter"
                  className={classes.selectorStyle}
                  defaultValue={0}
                  onChange={(e) => setFilterId(e.target.value)}
                >
                  <MenuItem value={0}>All Promotion</MenuItem>
                  <MenuItem value={1}>BMW Promotion</MenuItem>
                  <MenuItem value={2}>Mini Promotion</MenuItem>
                  <MenuItem value={3}>BMW Motorrad Promotion</MenuItem>
                </Select>
              </div>
            </div>
            <div className={classes.body}>
              {filteredArticles.map((value, index) => (
                <Grid
                  key={index}
                  className={classes.articleStyle}
                  justify="flex-start"
                  container
                >
                  <Grid item className={classes.articlePicStyle} md={4} xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: value.img }} />
                  </Grid>
                  <Grid
                    className={classes.articleDetailStyle}
                    item
                    md={7}
                    xs={12}
                  >
                    <div>
                      <Typography variant="h6">{value.title}</Typography>
                      <Typography component="span">{value.detail}</Typography>
                    </div>
                    <div className={classes.articleFooter}>
                      <RouterLink
                        component={Link}
                        to={`${match.url}/${value.id}`}
                        key={`${value.id}`}
                      >
                        > View More
                      </RouterLink>
                      <Typography component="span">
                        Date: {dayjs(value.date).format("YYYY-MM-DD HH:MM Z")}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Grid>
          <Grid md={1} item />
        </Grid>
      </div>
      <div className={classes.divider} />
      <div className={classes.footer}>
        <Typography component="span">
          All images are only used for commercial purposes.
        </Typography>
        <br />
        <Typography component="span">
          Some equipment may vary from the model shown.
        </Typography>
      </div>
    </div>
  )
}

export default News
