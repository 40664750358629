import React from "react"
import { Formik, Form, FieldArray } from "formik"
import _ from "lodash"
import { useParams, useHistory } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { ThemeProvider } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"
import emojiRegex from "emoji-regex"

import { useTranslation } from "react-i18next"

import ButtonDialog from "../../components/ButtonDialog"
import Slider from "../../components/Slider/index"
import CalculationAppBar from "./components/CalculationAppBar"
import CalculationSetting from "./components/CalculationSetting"
import CalculationActions from "./components/CalculationActions"
import CalculateCarForm from "./components/CalculateCarForm"
import CustomerForm from "./components/CustomerForm"
import {
  calculateFinanceAmount,
  getAccessoryPrice,
  calculateCarPrice,
  calculateAddOnAmount,
  getNetAccessoryPrice,
} from "./utils/calculation"

import {
  PAYMENT_METHOD,
  PAYMENT_TYPE,
  BSI_INCLUDE_TYPE,
  TYPE_OF_DISCOUNT,
} from "../../constants"

import useAuthprovider from "../../hooks/useAuthprovider"
import useDataprovider from "../../hooks/useDataprovider"
import useLastVisitBrand from "../../hooks/useLastVisitBrand"
import theme from "../../styles/theme"
import ReactGA from "react-ga"

import {
  thousandSeparator,
  validateEmail,
  validateSpace,
  validateName,
  validateTelephone,
  getBSIName,
} from "./utils/utils"
import prepareSubmitData from "./utils/prepare-submit-data"
import useCalculatePageStyle from "./style"
import { capitalizeBrand } from "../utils"

import campaignOptions from "../../data/campaignOptions"

const modalInitialState = {
  isSuccessOpen: false,
  isFailOpen: false,
  isSentOpen: false,
}

const modalReducer = (state, action) => {
  switch (action.type) {
    case "success":
      return { isSuccessOpen: !state.isSuccessOpen }
    case "fail":
      return { isFailOpen: !state.isFailOpen }
    case "sent":
      return { isSentOpen: !state.isSentOpen }
  }
}

const Calculate = () => {
  const params = useParams()
  const history = useHistory()
  const { brand } = params
  const type = brand === "motorrad" ? "Bike" : "Car"
  const cappedBrand = capitalizeBrand(brand)

  const classes = useCalculatePageStyle()
  const { t, i18n } = useTranslation()
  const { user } = useAuthprovider()
  const {
    setBrand,
    submitQuotation,
    getQuotationImageZip,
    findSFRate,
    switchDomain,
    createAndSendQuotation,
    resendConsentSMS,
  } = useDataprovider()
  const [modalState, modalDispatch] = React.useReducer(
    modalReducer,
    modalInitialState
  )
  const [consentRefId, setConsentRefId] = React.useState("")
  const [isResend, setIsResend] = React.useState(false)
  const [lastVisitBrand, setLastVisitBrand] = useLastVisitBrand()

  React.useEffect(() => {
    switchDomain("default")
  }, [])

  React.useEffect(() => {
    if (brand) {
      setBrand(brand)
      setLastVisitBrand(brand) // save to localStorage
    } else {
      history.push(`/calculate/${lastVisitBrand}`)
    }
  }, [brand, setBrand])

  // Disable enter key from submit form
  const onKeyDown = (keyEvent) => {
    const target = keyEvent.target
    const tagName = target.tagName
    if (
      tagName !== "TEXTAREA" &&
      (keyEvent.charCode || keyEvent.keyCode) === 13
    ) {
      keyEvent.preventDefault()
      keyEvent.target.blur()
    }
  }

  const handleResend = () => {
    setIsResend(true)
    resendConsentSMS({
      referenceId: consentRefId,
      language: i18n.language.toUpperCase(),
    })
      .then((response) => {
        const { reference_id } = response
        console.log(`resent ${reference_id}`)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsResend(false)
      })
  }

  const handleSuccessClose = () => {
    modalDispatch({ type: "success" })
    setConsentRefId("")
  }

  const onSubmit = (values, actions) => {
    console.log("onSubmit", values)
    const submitValues = prepareSubmitData(values, {
      user,
      lang: i18n.language,
      findSFRate,
    })
    const output = values.output || "pdf"
    if (output === "pdf") {
      ReactGA.event({
        category: "PDF",
        action: "create PDF",
      })
      const anonymousValues = _.omit(submitValues, [
        "first_name",
        "last_name",
        "email",
        "phone",
        "nickname",
        "line_id",
      ])
      const windowReference = window.open()
      submitQuotation(anonymousValues)
        .then((response) => {
          actions.setSubmitting(false)
          // Trick to make iOS open new tab
          windowReference.location = `/quotation/pdf/${response.id}`
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    } else if (output === "sms") {
      ReactGA.event({
        category: "SMS",
        action: "Send SMS",
      })
      const brand_id = brand === "mini" ? 2 : brand === "motorrad" ? 3 : 1
      const language = i18n.language.toUpperCase()
      const createAndSendValues = { brand_id, language, ...submitValues }
      createAndSendQuotation(createAndSendValues)
        .then((response) => {
          const { reference_id, is_verified } = response
          if (!is_verified) {
            setConsentRefId(reference_id)
            modalDispatch({ type: "success" })
          } else {
            modalDispatch({ type: "sent" })
          }
        })
        .catch((error) => {
          console.log(error)
          modalDispatch({ type: "fail" })
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    }
  }

  const carInitialValues = {
    name: ``,
    media_url: undefined,
    model_margin: {},
    model_has_freedomchoice: false,
    margin_rate: 0,
    rebate: 0,
    serie: "",
    model: "",
    retail_price: 0,
    type_of_payment: PAYMENT_TYPE.HIRE_PURCHASE,
    payment_method: PAYMENT_METHOD.FINANCE,
    term: 60,
    bsi: 3,
    bsi_price: 0,
    bsi_inc: BSI_INCLUDE_TYPE.CAR,
    insurance: 0,
    discount: 0,
    down_payment: 35,
    monthly_installment: 0,
    balloon: 0,
    boundary: {
      balloon: [0, 45],
      bsi_price: 0,
      down_payment: [15, 50],
    },
    locked: {
      margin_rate: false,
      monthly_installment: false,
      down_payment: true,
      balloon: true,
    },
    accessory_installation: 0,
    accessories: [],
    discount_type: TYPE_OF_DISCOUNT.THB,
    accessory_discount_baht: 0,
    accessory_discount_percent: 0,
    add_on_finance_type: TYPE_OF_DISCOUNT.PERCENT,
    add_on_finance_baht: 0,
    add_on_finance_percent: 10,
    cash_amount: 0,
    goalseek: "monthly_installment",
    campaigns: campaignOptions
      .filter((option) => option.lock === true)
      .map((option) => option.id),
  }

  const initialCarValues = {
    customer_information: {
      customer_name: "",
      customer_firstname: "",
      customer_surname: "",
      sale_name: "",
      sale_phone: "",
      email: "",
      telephone: "",
      customer_nickname: "",
      customer_line_id: "",
    },
    advance_calculation: false,
    remark: "",
    pick_up_remark: "",
    pick_up_amount: "",
    cars: [carInitialValues],
  }

  const trigger = useScrollTrigger({
    threshold: 430,
    disableHysteresis: true,
  })

  if (!brand) return null

  const validate = (values) => {
    const errors = {}
    const { customer_information, remark } = values
    const {
      sale_name,
      sale_phone,
      email,
      telephone,
      customer_firstname,
      customer_surname,
      customer_nickname,
    } = customer_information

    values.cars.forEach((car, index) => {
      const {
        retail_price,
        discount,
        bsi,
        bsi_inc,
        bsi_price,
        insurance,
        down_payment,
        type_of_payment,
        balloon,
        monthly_installment,
        margin_rate,
        rebate,
        model,
        payment_method,
        term,
        boundary,
        campaigns,
        accessories,
        accessory_discount_baht,
        accessory_discount_percent,
        accessory_installation,
        add_on_finance_baht,
        add_on_finance_percent,
        quantity = 1,
        discount_type,
      } = car

      // const matrixRow = findSFRate(
      //   model,
      //   type_of_payment,
      //   payment_method,
      //   term,
      //   down_payment,
      //   balloon,
      //   "row"
      // )

      const sf_rate = findSFRate(
        model,
        type_of_payment,
        payment_method,
        term,
        down_payment,
        balloon
      )

      const seriesErrorMsg = `${t("field.serie.placeholder")}${
        brand !== "motorrad" && i18n.language === "th" ? t("common.car") : ""
      }`
      const modelErrorMsg = `${t("field.model.placeholder")}${
        brand !== "motorrad" && i18n.language === "th" ? t("common.car") : ""
      }`

      const bsiName = getBSIName(brand)

      const totalAccessoryPrice = getAccessoryPrice(accessories)

      const maxRate = brand === "motorrad" ? 23 : 10

      const carPrice = calculateCarPrice(
        retail_price,
        discount,
        bsi_inc,
        bsi_price,
        quantity,
        payment_method
      )

      const accessoryNetPrice = getNetAccessoryPrice({
        accessories,
        discount_type,
        discount_baht: accessory_discount_baht,
        discount_percent: accessory_discount_percent,
      })
      const totalAddOnAmount = calculateAddOnAmount({
        insurance,
        accessoryNetPrice,
        bsi_inc,
        bsi_price,
        quantity,
      })

      // Series
      if (!car.serie) _.set(errors, `cars.${index}.serie`, seriesErrorMsg)

      // Model
      if (!car.model) _.set(errors, `cars.${index}.model`, modelErrorMsg)

      // Bsi Price
      if (bsi_price > boundary.bsi_price) {
        _.set(
          errors,
          `cars.${index}.bsi_price`,
          t("field.bsi_price.warning.over", {
            bsi: bsiName,
            price: thousandSeparator(boundary.bsi_price),
          })
        )
      } else if (bsi_price < 0) {
        _.set(
          errors,
          `cars.${index}.bsi_price`,
          t("field.bsi_price.warning.under", {
            bsi: bsiName,
          })
        )
      }

      // Check Insurance
      if (insurance < 0) {
        _.set(
          errors,
          `cars.${index}.insurance`,
          t("field.insurance.warning.under")
        )
      }

      // Check Discount
      if (discount >= retail_price) {
        _.set(
          errors,
          `cars.${index}.discount`,
          t("field.discount.warning.over")
        )
      } else if (discount < 0) {
        _.set(
          errors,
          `cars.${index}.discount`,
          t("field.discount.warning.under")
        )
      }

      //check finance amount
      //no need to be accurate just a rough amount to not let finance amount below 0
      const financeAmount = calculateFinanceAmount({
        retail_price,
        down_payment,
        discount,
        bsi_inc,
        bsi_price,
      })
      if (financeAmount <= 0)
        _.set(
          errors,
          `cars.${index}.finance_amount`,
          t("field.finance_amount.warning")
        )

      // check down payment
      const minDownpaymentAllow =
        brand === "motorrad" ? 5 : _.get(boundary, "down_payment.0", 15)
      const maxDownpaymentAllow = _.get(boundary, "down_payment.1", 50)
      if (down_payment > maxDownpaymentAllow) {
        _.set(
          errors,
          `cars.${index}.down_payment`,
          t("field.down_payment.waring_over", { percent: maxDownpaymentAllow })
        )
      } else if (
        down_payment < minDownpaymentAllow &&
        type_of_payment !== PAYMENT_TYPE.FREEDOM_CHOICE &&
        payment_method !== PAYMENT_METHOD.CASH
      ) {
        _.set(
          errors,
          `cars.${index}.down_payment`,
          t("field.down_payment.waring_under", { percent: minDownpaymentAllow })
        )
      }
      //check balloon
      if (balloon > boundary.balloon[1]) {
        _.set(
          errors,
          `cars.${index}.balloon`,
          t("field.balloon.warning", { percent: boundary.balloon[1] })
        )
      }

      if (monthly_installment < 0) {
        _.set(
          errors,
          `cars.${index}.monthly_installment`,
          t("field.monthly_installment.warning")
        )
      }

      if (margin_rate < -sf_rate) {
        _.set(
          errors,
          `cars.${index}.margin_rate`,
          t("field.margin.error_less_than_sfrate")
        )
      } else if (margin_rate > 3) {
        _.set(
          errors,
          `cars.${index}.margin_rate`,
          t("field.margin.error_3percent")
        )
      }

      if (
        type_of_payment !== PAYMENT_TYPE.FINANCIAL_LEASE &&
        margin_rate + sf_rate > maxRate
      ) {
        _.set(
          errors,
          `cars.${index}.margin_rate`,
          t("field.interest.error_percent", { percent: maxRate })
        )
      }

      // if (rebate < 0) {
      //   _.set(errors, `cars.${index}.rebate`, t("field.rebate.warning"))
      // }

      //check bsi only in freedom choice
      if (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE && bsi < term / 12) {
        _.set(
          errors,
          `cars.${index}.bsi`,
          t("field.bsi_option.warning", {
            brand: brand === "mini" ? "MSI" : "BSI",
          })
        )
      }

      //Check Accessory
      if (
        accessories.length > 0 &&
        accessory_discount_baht > totalAccessoryPrice
      ) {
        _.set(
          errors,
          `cars.${index}.accessory_discount_baht`,
          t("field.accessory_discount.warning.over")
        )
      } else if (accessory_discount_baht < 0) {
        _.set(
          errors,
          `cars.${index}.accessory_discount_baht`,
          t("field.discount.warning.under")
        )
      }

      if (accessories.length > 0 && accessory_discount_percent > 100) {
        _.set(
          errors,
          `cars.${index}.accessory_discount_percent`,
          t("field.accessory_discount.warning.over")
        )
      } else if (accessory_discount_percent < 0) {
        _.set(
          errors,
          `cars.${index}.accessory_discount_percent`,
          t("field.discount.warning.under")
        )
      }

      if (accessory_installation < 0) {
        _.set(
          errors,
          `cars.${index}.accessory_installation`,
          t("field.install_accessories.warning.under")
        )
      }

      // Check add on limit
      if (add_on_finance_percent > 10) {
        _.set(
          errors,
          `cars.${index}.add_on_finance_percent`,
          t("field.add_on_finance.over")
        )
      } else if (add_on_finance_percent < 0) {
        _.set(
          errors,
          `cars.${index}.add_on_finance_percent`,
          t("field.add_on_finance.under")
        )
      }

      if (add_on_finance_baht < 0) {
        _.set(
          errors,
          `cars.${index}.add_on_finance_baht`,
          t("field.add_on_finance.under")
        )
      } else if (add_on_finance_baht > totalAddOnAmount) {
        _.set(
          errors,
          `cars.${index}.add_on_finance_baht`,
          t("field.add_on_finance.over_net_price")
        )
      } else if (add_on_finance_baht > retail_price * 0.1) {
        _.set(
          errors,
          `cars.${index}.add_on_finance_baht`,
          t("field.add_on_finance.over")
        )
      }

      //Check special campaign
      if (campaigns.length > 3) {
        _.set(
          errors,
          `cars.${index}.campaigns`,
          t("field.special_campaign.warning.over")
        )
      }

      //check sale name
      if (validateName(sale_name)) {
        _.set(
          errors,
          `customer_information.sale_name`,
          t("field.name.error.special")
        )
      }

      //check emoji on remark field
      const regex = emojiRegex()
      if ([...remark.matchAll(regex)].length) {
        _.set(errors, `remark`, t("field.remark.emoji"))
      }

      //check sale phone
      if (sale_phone.length > 0 && !validateTelephone(sale_phone)) {
        _.set(errors, `customer_information.sale_phone`, t("field.phone.error"))
      } else if (sale_phone.length > 0 && validateSpace(sale_phone)) {
        _.set(
          errors,
          `customer_information.sale_phone`,
          t("field.phone.not_complete")
        )
      }

      //check client first name
      if (validateName(customer_firstname)) {
        _.set(
          errors,
          `customer_information.customer_firstname`,
          t("field.name.error.special")
        )
      }

      //check client surname
      if (validateName(customer_surname)) {
        _.set(
          errors,
          `customer_information.customer_surname`,
          t("field.last_name.error.special")
        )
      }

      //check client nickname
      if (validateName(customer_nickname)) {
        _.set(
          errors,
          `customer_information.customer_nickname`,
          t("field.nickname.error.special")
        )
      }

      //check client email
      if (!validateEmail(email) && email.length > 0) {
        _.set(
          errors,
          `customer_information.email`,
          t("field.customer_email.error")
        )
      }

      //check client phone
      if (telephone.length > 0 && !validateTelephone(telephone)) {
        _.set(errors, `customer_information.telephone`, t("field.phone.error"))
      } else if (telephone.length > 0 && validateSpace(telephone)) {
        _.set(
          errors,
          `customer_information.telephone`,
          t("field.phone.not_complete")
        )
      }
    })
    return errors
  }

  return (
    <ThemeProvider theme={theme[brand]}>
      <div className={classes.root}>
        <Helmet>
          <title>
            {cappedBrand} New {type} Quote | DSK | BMW Leasing Thailand
          </title>
        </Helmet>
        <ButtonDialog
          title={t("common.successful")}
          isOpen={modalState.isSuccessOpen}
          payload={{
            content: t("field.sms_detail"),
            submitBtnLabel: t("button.resend_quotation.label"),
            dismissBtnLabel: t("common.close"),
          }}
          controller={{
            delay: 10, //second
            limit: 5, //times
            lifetime: 10, //min
            isSubmitting: isResend,
          }}
          events={{
            onSubmit: handleResend,
            onDismiss: handleSuccessClose,
          }}
        />
        <ButtonDialog
          title={t("field.sms.failed")}
          isOpen={modalState.isFailOpen}
          payload={{
            content: t("field.sms.failed_detail"),
            dismissBtnLabel: t("common.close"),
          }}
          controller={{
            lifetime: 10, //min
          }}
          events={{
            onDismiss: () => {
              modalDispatch({ type: "fail" })
            },
          }}
        />
        <ButtonDialog
          title={t("common.successful")}
          isOpen={modalState.isSentOpen}
          payload={{
            content: t("field.sms.sent_detail"),
            dismissBtnLabel: t("common.close"),
          }}
          controller={{
            lifetime: 10, //min
          }}
          events={{
            onDismiss: () => {
              modalDispatch({ type: "sent" })
            },
          }}
        />
        <Formik
          initialValues={initialCarValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, setFieldValue, values, errors }) => (
            <Form noValidate onKeyDown={onKeyDown}>
              <FieldArray name="cars">
                {({ push, remove }) => (
                  <div>
                    <CalculationAppBar
                      values={values}
                      carInitialValues={carInitialValues}
                      push={push}
                      LeftPanel={CalculationSetting}
                      brand={brand}
                    />
                    <div className={classes.header}>
                      <div className={classes.headerContentContainer}>
                        <Typography variant="h2" className={classes.title}>
                          {t("common.quotation.select")}
                          <br />
                          {t("common.quotation.create_quote")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.main}>
                      <div className={classes.sidebarLeft}>
                        <div
                          className={
                            trigger
                              ? classes.positionSticky
                              : classes.positionStatic
                          }
                        >
                          <CalculationSetting values={values} />
                        </div>
                      </div>
                      <div className={classes.mainCenter}>
                        <Slider
                          items={values.cars}
                          renderItem={(car, index) => (
                            <CalculateCarForm
                              carMedia={car.media_url}
                              carsLength={values.cars.length}
                              index={index}
                              remove={remove}
                            />
                          )}
                        />
                      </div>
                      <div className={classes.sidebarRight}>
                        <div
                          className={
                            trigger
                              ? classes.positionSticky
                              : classes.positionStatic
                          }
                        >
                          <CalculationActions
                            values={values}
                            push={push}
                            remove={remove}
                            carInitialValues={carInitialValues}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
              <CustomerForm
                isSubmitting={isSubmitting}
                isValid={isValid}
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                brand={brand}
              />
            </Form>
          )}
        </Formik>
      </div>
    </ThemeProvider>
  )
}

export default Calculate
