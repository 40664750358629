import React from "react"
import { Formik, Form } from "formik"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import {
  Typography,
  Grid,
  List,
  ListItem,
  CircularProgress,
  Button,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"

import usePublicDataProvider from "../../../hooks/usePublicDataprovider"

import ConsentFormField, { CheckboxesField } from "./Fields"
import LogoSVG from "../../../components/logoSvg"

import {
  validateEmail,
  validateName,
  countSpace,
} from "../../calculate/utils/utils"

import useStyles from "../style"

const ConsentForm = ({ customerData, consentId }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const locales = ["en", "th"]
  const {
    phonenumber,
    firstName,
    lastName,
    email,
    nickname,
    lineId,
  } = customerData
  const { captureConsent } = usePublicDataProvider()

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  const initialClientValue = {
    phone: phonenumber,
    first_name: firstName,
    last_name: lastName,
    email: email,
    nickname: nickname,
    line_id: lineId,
    checkbox_group: {
      basic_consent: true,
      marketing_consent: false,
    },
  }

  const onSubmit = (values, actions) => {
    //prepare confirmation data, getting ref from above api
    const submitValues = {
      phonenumber: _.get(values, "phone"),
      first_name: _.get(values, "first_name", ""),
      last_name: _.get(values, "last_name", ""),
      email: _.get(values, "email"),
      line_id: _.get(values, "line_id", ""),
      nickname: _.get(values, "nickname", ""),
      sms_marketing_consent_allowed: _.get(
        values,
        "checkbox_group.marketing_consent"
      ),
      email_marketing_consent_allowed: _.get(
        values,
        "checkbox_group.marketing_consent"
      ),
      language: `${i18n.language.toUpperCase()}`,
    }
    captureConsent({ consentId, data: submitValues })
      .then((response) => {
        const { url } = response
        window.open(url, "_self")
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  const validate = (values) => {
    const errors = {}
    const { first_name, last_name, nickname, email, checkbox_group } = values

    //check client first name
    if (!first_name) {
      _.set(errors, "first_name", t("field.client_first_name.empty"))
    } else if (validateName(first_name)) {
      _.set(errors, `first_name`, t("field.name.error.special"))
    } else if (
      countSpace(first_name) === first_name.length &&
      first_name.length > 0
    ) {
      _.set(errors, `first_name`, t("field.name.error.space"))
    }

    //check client surname
    if (!last_name) {
      _.set(errors, "last_name", t("field.client_last_name.empty"))
    } else if (validateName(last_name)) {
      _.set(errors, `last_name`, t("field.last_name.error.special"))
    } else if (
      countSpace(last_name) === last_name.length &&
      last_name.length > 0
    ) {
      _.set(errors, `last_name`, t("field.last_name.error.space"))
    }

    //check client nickname
    if (validateName(nickname)) {
      _.set(errors, `nickname`, t("field.nickname.error.special"))
    }

    if (!validateEmail(email) && email.length > 0) {
      _.set(errors, `email`, t("field.customer_email.error"))
    }

    if (!checkbox_group.basic_consent) {
      _.set(errors, `checkbox_group`, t("field.client_consent.check"))
    }

    return errors
  }

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        <LogoSVG />
        <List className={classes.menuList}>
          {locales.map((lang, index) => (
            <ListItem
              className={`
                  ${classes.menuButtonLanguage}
                  ${i18n.language === lang ? "selected" : ""}
                `}
              button
              key={`menu-lang-${index}`}
              onClick={() => changeLanguage(lang)}
            >
              {lang}
            </ListItem>
          ))}
        </List>
      </div>
      <Typography variant="subtitle1" className={classes.captionStyle}>
        {t("field.fill_check.helper")}
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={initialClientValue}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ errors, isValid, isSubmitting }) => (
          <Form>
            <ConsentFormField
              name={`phone`}
              label={t("field.customer_telephone.label")}
              type="telephone"
              disabled
              placeholder="000 000 0000"
              maxLength={40}
              isBold
            />
            <ConsentFormField
              name="first_name"
              label={`${t("field.customer_name.label")} *`}
              placeholder="John"
              maxLength={40}
              isShort
              isBold
            />
            <ConsentFormField
              name="last_name"
              placeholder="Smith"
              maxLength={40}
            />
            <ConsentFormField
              name="email"
              label={t("field.email.label")}
              placeholder="johnsmith@gmail.com"
              maxLength={40}
              isBold
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ConsentFormField
                  name="nickname"
                  label={t("field.nickname.label")}
                  placeholder="Johny"
                  maxLength={40}
                  isBold
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ConsentFormField
                  name="line_id"
                  label={t("field.line_id.label")}
                  placeholder="bmwdsk"
                  maxLength={40}
                  isBold
                />
              </Grid>
            </Grid>
            <CheckboxesField label={t("field.apply_all")} />
            {_.size(errors) > 0 && (
              <Alert severity="error" style={{ marginBottom: 8 }}>
                <AlertTitle>Error</AlertTitle>
                <ul style={{ paddingLeft: 0 }}>
                  {_.map(errors, (error, i) => (
                    <li key={`error-${i}`}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              fullWidth
              disabled={!isValid || isSubmitting}
            >
              {t("button.confirm.consent")}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default ConsentForm
