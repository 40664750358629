import React from "react"
import _ from "lodash"
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Collapse,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Description, ArrowDropDown, ArrowDropUp } from "@material-ui/icons"

import { FormField } from "./Fields"
import { useTranslation } from "react-i18next"

import useStyles from "../style"

const CustomerForm = (props) => {
  const { isSubmitting, isValid, setFieldValue, errors, values, brand } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const isCustomerPhoneValid = !_.get(errors, "customer_information.telephone")
  //telephone field will return length = 12 even though user fill just 1 char
  const isCustomerPhoneFilled =
    _.get(values, "customer_information.telephone").length === 12
  const [isOpen, setIsOpen] = React.useState(false)

  const isRemarkError = !!(_.size(errors.remark) > 0)

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.sidebarLeft}></div>
        <div
          className={`${classes.mainCenter} ${classes.mainCenterSingleItem} noSpacing`}
        >
          <div className={classes.customerInformationForm}>
            <FormField
              name={`remark`}
              label={t("field.remark.label")}
              placeholder={t("field.remark.placeholder")}
              multiline
              rows={5}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormField
                  name={`pick_up_remark`}
                  label={t("field.pick_up.label")}
                  placeholder={t("field.pickup_remark.placeholder")}
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  name={`pick_up_amount`}
                  label={t("field.pick_up.amount")}
                  placeholder={"5000฿\n10000฿"}
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.sidebarRight}></div>
      </div>
      <div className={classes.darkDivider} />
      <div className={`${classes.main} ${classes.mainCustomer}`}>
        <div className={`${classes.sidebarLeft} ${classes.customerDetail}`}>
          <div className={classes.sidebarLeftLabel}>
            <Typography variant="h5">
              {t("field.sales_detail.label")}
            </Typography>
          </div>
        </div>
        <div
          className={`${classes.mainCenter} ${classes.mainCenterSingleItem} noSpacing`}
        >
          <div className={classes.customerInformationForm}>
            <FormField
              name={`customer_information.sale_name`}
              label={t("field.sale_consult_name.label")}
              placeholder={t("placeholder.sales.name")}
              isBold
              isShort
              maxLength={40}
            />
            <FormField
              name={`customer_information.sale_phone`}
              type="telephone"
              placeholder="000 000 0000"
            />
          </div>
        </div>
        <div className={classes.sidebarRight}></div>
      </div>
      <div className={classes.darkDivider} />
      <div className={`${classes.main} ${classes.mainCustomer}`}>
        <div className={`${classes.sidebarLeft} ${classes.customerDetail}`}>
          <div className={classes.sidebarLeftLabel}>
            <Typography variant="h5" style={{ marginBottom: 4 }}>
              {t("field.customer_detail.label")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginBottom: "2em",
                whiteSpace: "normal",
                fontWeight: 300,
              }}
            >
              {t("field.customer_detail.consent")}
            </Typography>
            <Typography
              variant="body1"
              style={{ margin: "2em 0", fontWeight: 300 }}
            >
              {t("field.anonymous.quote.detail")}
            </Typography>
            <Button
              color="text"
              variant="text"
              size="large"
              type="submit"
              endIcon={<Description />}
              className={classes.underlineButton}
              disabled={isSubmitting || !isValid}
              onClick={(e) => {
                setFieldValue("output", "pdf")
              }}
            >
              {t("form_action.create_quotation")}
            </Button>
          </div>
        </div>
        <div
          className={`${classes.mainCenter} ${classes.mainCenterSingleItem} noSpacing`}
        >
          <div className={classes.customerInformationForm}>
            <FormField
              name={`customer_information.telephone`}
              label={t("field.customer_telephone.label")}
              type="telephone"
              placeholder="000 000 0000"
              isBold
              guideline={
                !isCustomerPhoneFilled &&
                isCustomerPhoneValid &&
                t("field.customer_telephone.required")
              }
            />
            <FormField
              name={`customer_information.customer_firstname`}
              label={t("field.customer_firstname.label")}
              placeholder={t("placeholder.customer.name")}
              maxLength={40}
              isShort
              isBold
            />
            <FormField
              name={`customer_information.customer_surname`}
              maxLength={40}
              placeholder={t("placeholder.customer.lastname")}
            />
            <Button
              color="primary"
              variant="text"
              size="large"
              endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
              className={classes.underlineButton}
              onClick={() => setIsOpen(!isOpen)}
            >
              {t("common.more_info")}
            </Button>
            <Collapse in={isOpen} style={{ marginBottom: 16 }}>
              <FormField
                name={`customer_information.email`}
                label={t("field.customer_email.label")}
                placeholder="johnsmith@gmail.com"
                isBold
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    name={`customer_information.customer_nickname`}
                    label={t("field.nickname.label")}
                    maxLength={40}
                    placeholder={t("placeholder.customer.nickname")}
                    isBold
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    name={`customer_information.customer_line_id`}
                    label={t("field.line_id.label")}
                    maxLength={40}
                    placeholder="bmwdsk"
                    isBold
                  />
                </Grid>
              </Grid>
            </Collapse>
            {_.map(_.get(errors, "cars", []), (car, index) => {
              if (car === undefined) return null
              else
                return (
                  <Alert
                    key={`alert-${index}`}
                    severity="error"
                    style={{ marginBottom: 8 }}
                  >
                    <AlertTitle>
                      {!!_.get(values, `cars.${index}.name`)
                        ? _.get(values, `cars.${index}.name`)
                        : `Error`}
                    </AlertTitle>
                    <ul style={{ paddingLeft: 0 }}>
                      {_.map(car, (error, i) => (
                        <li key={`error-${i}`}>{error}</li>
                      ))}
                    </ul>
                  </Alert>
                )
            })}
            {(_.size(errors.customer_information) > 0 || isRemarkError) && (
              <Alert severity="error" style={{ marginBottom: 8 }}>
                <AlertTitle>Error</AlertTitle>
                <ul style={{ paddingLeft: 0 }}>
                  {_.map(
                    _.get(errors, "customer_information", []),
                    (error, i) => (
                      <li key={`error-${i}`}>{error}</li>
                    )
                  )}
                  {isRemarkError && (
                    <li key={`error-remark`}>{errors.remark}</li>
                  )}
                </ul>
              </Alert>
            )}
            <div className={classes.formActions}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                className={classes.submitBtn}
                disabled={isSubmitting || !isValid || !isCustomerPhoneFilled}
                onClick={(e) => {
                  setFieldValue("output", "sms")
                }}
              >
                {t("form_action.send_quotation")}
                {isSubmitting && values.output === "sms" && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className={`${classes.sidebarRight} relative`} />
      </div>
    </React.Fragment>
  )
}

export default CustomerForm
