import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Helmet } from "react-helmet"

import { Alert } from "@material-ui/lab"
import { Search, ArrowBack, ArrowForward, Add } from "@material-ui/icons"
import {
  Grid,
  Typography,
  Button,
  ButtonGroup,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import InputField from "../../components/InputField"
import useDataprovider from "../../hooks/useDataprovider"
import useAuthProvider from "../../hooks/useAuthprovider"
import useLastVisitBrand from "../../hooks/useLastVisitBrand"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import CollapsibleRow from "./components/CollapsibleRow"
import { capitalizeBrand } from "../utils"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 23,
    fontWeight: 400,
    margin: "15px 0 30px 0",
    wordSpacing: 3,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: 1.6,
      margin: "12px 0",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  filterBox: {
    "& input": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 10,
    },
    "& button.search": {
      marginTop: 6,
    },
    "& button.create": {
      marginTop: -12,
      [theme.breakpoints.down("xs")]: {
        minWidth: "unset",
        padding: "5px 10px",
      },
    },
  },
  tableContainer: {
    margin: "0 -5px",
    padding: "0 5px",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 4px",
  },
  thead: {
    "& th": {
      borderTop: "1px solid #BDBDBD",
      borderBottom: "1px solid #757575",
    },
  },
  tbody: {
    "&::before": {
      content: '""',
      display: "block",
      marginTop: 10,
    },
    "& tr": {
      boxShadow: "0px 2px 6px 0px rgba(99, 99, 99, 0.2)",
    },
    "& td": {
      padding: "14px 16px",
      fontSize: 16,
      fontWeight: 300,
    },
  },
  pagination: {
    "& button:not(:last-child)": {
      marginRight: 8,
    },
  },
}))

const BookingList = () => {
  const { t } = useTranslation()

  const classes = useStyles()
  const searchRef = useRef()
  const [lastVisitBrand, setLastVisitBrand] = useLastVisitBrand()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(655))
  const { quotations, getQuotations, setBrand, brandId } = useDataprovider()
  const { user } = useAuthProvider()

  const params = useParams()
  const history = useHistory()

  const { brand: brandFromUrl } = params
  React.useEffect(() => {
    if (brandFromUrl) {
      setBrand(brandFromUrl)
      setLastVisitBrand(brandFromUrl) // save to localStorage
    } else {
      history.push(`/view-quotations/${lastVisitBrand}`)
    }
  }, [brandFromUrl, setBrand])
  const cappedBrand = capitalizeBrand(brandFromUrl)

  const [queryParams, setQueryParams] = useState({
    id: undefined,
    // brandId,
    skip: 0,
    limit: 11, // Limit:10 | +1 for pagination
    pageNo: 1,
  })

  useEffect(() => {
    getQuotations({
      ...queryParams,
      brandId,
      authorId: user.legacy_id || undefined,
    })
  }, [brandId, queryParams])

  const quotationList = useMemo(() => {
    if (quotations && quotations.data) {
      return quotations.data.length == queryParams.limit
        ? quotations.data?.slice(0, -1)
        : quotations.data
    } else {
      return []
    }
  }, [quotations])

  const searchQuote = useCallback((event) => {
    event.preventDefault()
    setQueryParams({
      ...queryParams,
      id: searchRef.current.value,
      skip: 0,
      pageNo: 1,
    })
  }, [])

  const paginate = useCallback(
    ({ isNext }) => {
      const { pageNo, limit } = queryParams
      const mPage = isNext ? pageNo + 1 : pageNo - 1
      const mSkip = (mPage - 1) * (limit - 1)
      setQueryParams({ ...queryParams, pageNo: mPage, skip: mSkip })
    },
    [queryParams]
  )

  return (
    <Grid container justify="center">
      <Helmet>
        <title>{cappedBrand} View Quotes | DSK | BMW Leasing Thailand</title>
      </Helmet>
      <Grid item xs={10} md={10}>
        <Typography variant="h2" className={classes.title}>
          {t("field.booking_heading.label")}
        </Typography>
        <Grid container justify="space-between" className={classes.filterBox}>
          <Grid container spacing={1} item xs={9} sm={9} md={6}>
            <Grid item xs={8} sm={6} md={6}>
              <InputField
                inputRef={searchRef}
                name="quoteId"
                type="text"
                placeholder={t("field.booking_placeholder.label")}
                adornment={{ start: <Search /> }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                className="search"
                onClick={searchQuote}
              >
                {t("field.booking_search.label")}
              </Button>
            </Grid>
          </Grid>
          {brandFromUrl === "mini" && (
            <Grid
              container
              item
              xs={3}
              sm={3}
              md={6}
              alignItems="center"
              justify="flex-end"
            >
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                className="create"
              >
                <Link to={`/view-quotations/${brandFromUrl}/create`}>
                  {isSmallScreen ? <Add /> : t("field.booking_create.label")}
                </Link>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table}>
              {!isSmallScreen && (
                <TableHead classes={{ root: classes.thead }}>
                  <TableRow>
                    <TableCell>{t("field.booking_quote_id.label")}</TableCell>
                    <TableCell>
                      {t("field.booking_vehicle_model.label")}
                    </TableCell>
                    <TableCell>
                      {t("field.booking_customer_name.label")}
                    </TableCell>
                    <TableCell>{t("field.booking_date.label")}</TableCell>
                    <TableCell>{t("field.booking_time.label")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody classes={{ root: classes.tbody }}>
                {quotationList.map((quotation) => (
                  <CollapsibleRow
                    quotation={quotation}
                    isSmallScreen={isSmallScreen}
                    brand={brandFromUrl}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {quotationList.length > 0 && (
            <Grid container justify="center" style={{ padding: 20 }}>
              <ButtonGroup
                size="large"
                color="secondary"
                className={classes.pagination}
                disabled={quotations?.err != ""}
              >
                <Button
                  variant="contained"
                  startIcon={<ArrowBack />}
                  disabled={queryParams.pageNo == 1}
                  onClick={() => paginate({ isNext: false })}
                >
                  {t("field.booking_previous.label")}
                </Button>
                <Button
                  variant="contained"
                  endIcon={<ArrowForward />}
                  disabled={quotations.data.length < queryParams.limit}
                  onClick={() => paginate({ isNext: true })}
                >
                  {t("field.booking_next.label")}
                </Button>
              </ButtonGroup>
            </Grid>
          )}
          <Snackbar
            open={quotations && quotations.err != ""}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert elevation={6} severity="error" variant="filled">
              Quotations not found
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BookingList
