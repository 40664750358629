/**
 * Reference
 *  104	MINI Clubman	MINI Clubman
    116	F 850	F 850
    135	5 Series	5
    151	1 Series	1
    152	4 Series	4
    153	6 Series	6
    154	7 Series	7
    155	8 Series	8
    156	X1	X1
    158	X3	X3
    159	X4	X4
    160	X5	X5
    161	X6	X6
    162	X7	X7
    163	Z	Z
    164	i8	i8
    165	M2	M2
    166	M4	M4
    167	M5	M5
    168	i3	i3
    169	X3 M	X3 M
    170	X4 M	X4 M
    171	MINI Hatch 3-Door	MINI Hatch 3-Door
    172	MINI Hatch 5-Door	MINI Hatch 5-Door
    173	MINI Convertible	MINI Convertible
    174	MINI Countryman	MINI Countryman
    175	MINI JCW	MINI JCW
    177	G 310	G 310
    178	C 400	C 400
    179	C 650	C 650
    182	F 750	F 750
    183	S 1000	S 1000
    184	R 1200	R 1200
    185	R 1250	R 1250
    186	R nine T	R nine T
    187	K 1600	K 1600
    188	2 Series	2
    189	3 Series	3
    190	M8	M8
    191	F 900 R	F 900 R
    192	F 900 XR	F 900 XR
    193	F 700	F 700
    194	F 800	F 800
    202	F 900	F 900
    203	S 1000	S 1000
    204	R 18	R 18
    205	iX	iX
    206	iX3	iX3
    207	R NINET	R NINET
    209	i4 i4
    242 i5 i5

    ====================================================

  * Payment Type
    1 Hire Purchase
    2 Hire Purchase with Balloon
    3 Finance Lease
    4 Freedom Choice
    5 Cash only
 */
const latestCampaignDateEn = `1 - 31 Dec 2023`
const latestCampaignDateTh = `1 - 31 ธค 2566`

const options = [
  {
    id: "1",
    title: {
      en: "Free BMW Protect 1 Year Insurance",
      th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 1 year for Hire Purchase, Hire Purchase with Balloon, Finance Lease contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี สำหรับ Hire Purchase หรือ Hire Purchase with Balloon หรือ Finance Lease\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-1year-protect.jpg",
    series: [],
    models: [
      1506,
      1699, // 220i
      1527,
      1528, //520d
      1529, //530e Luxury
      1530,
      1531, //530e M Sport
      1535,
      1723, //630i
      1789, //740d
      1536,
      1537,
      1724, //750e
      1538,
      1539, //M760e
      1543,
      1735, //X3 20d
      1545,
      1736, //X3 30e
      1547,
      1737, //X4 20d
      1548,
      1549,
      1550, //X5 30d
      1553,
      1554,
      1555, //X5 45e
      1558,
      1559,
      1560, //X6 40i
    ],
    paymentType: ["1", "2", "3"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "2",
    title: {
      en: "Free BMW Protect 2 Years Insurance",
      th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 2 ปี",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 2 years for Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment 48 months/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 2 ปี สำหรับ BMW Freedom Choice\n• ${latestCampaignDateTh}\n• Term 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-2years-protect.jpg",
    series: [],
    models: [
      1506,
      1699, // 220i
      1535,
      1723, //630i
      1789, //740d
      1536,
      1537,
      1724, //750e
      1538,
      1539, //M760e
      1543,
      1735, //X3 20d
      1545,
      1736, //X3 30e
      1547,
      1737, //X4 20d
      1548,
      1549,
      1550, //X5 30d
      1553,
      1554,
      1555, //X5 45e
      1558,
      1559,
      1560, //X6 40i
    ],
    paymentType: ["4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "3",
    title: {
      en: "Free BMW Protect 3 Years Insurance",
      th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 3 ปี",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 3 years for Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 3 ปี สำหรับ BMW Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-3years-protect.jpg",
    series: [],
    models: [
      1527,
      1528, //520d
      1529, //530e Luxury
      1530,
      1531, //530e M Sport
    ],
    paymentType: ["4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "4",
    title: {
      en: "Free BSI 10 Years",
      th: "ฟรี BSI Upgrade 10 ปี",
    },
    description: {
      en: `• BSI Upgrade 10 Years\n• ${latestCampaignDateEn}\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BSI upgrade 10 ปี หรือ 100,000 กม (อย่างใดอย่างหนึ่งถึงก่อน)\n• ${latestCampaignDateTh}\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-10years-bsi.jpg",
    series: [],
    models: [
      1527,
      1528, //520d
      1529, //530e Luxury
      1530,
      1531, //530e M Sport
    ],
    paymentType: ["2", "3"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "5",
  // title: {
  //   "en": "Free Upgrade 10 Years BSI",
  //   "th": "ฟรี อัพเกรด BSI 10 ปี"
  // },
  // description: {
  //   "en": "• ฟรี  อัพเกรด BSI เป็น 10 ปีหรือ 100,000 กม.\n• 1 - 31 ส.ค. 2564\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //   "th": "• ฟรี  อัพเกรด BSI เป็น 10 ปีหรือ 100,000 กม.\n• 1 - 31 ส.ค. 2564\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า"
  // },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636523473/bmw-dsk/stage/campaign/10_Years_BSI_1_Year_First_Class_Insurance-01_usbm9b.jpg",
  //   series: [
  //     188,
  //     189,
  //     152,
  //     135,
  //     153,
  //     154,
  //     155,
  //     156,
  //     158,
  //     159,
  //     160,
  //     161,
  //     162,
  //     163,
  //     168,
  //     164,
  //     165,
  //     166,
  //     167,
  //     190,
  //     169,
  //     170,
  //     205,
  //     206,
  //   ],
  //   paymentType: ["1", "2", "3", "4"],
  // },
  // {
  //   id: "6",
  //   title: {
  //     en: "Free BMW Advance Car Eye 2.0",
  //     th: "ฟรี BMW Advance Car Eye 2.0",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี รับเพิ่ม BMW Advance Car Eye 2.0 (ไม่รวมค่าติดตั้ง)\n• 1 - 31 ต.ค. 2564\n• Term อย่างน้อย 36 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด / ลูกค้าจะได้รับกล้องในเดือนถัดไป  ที่ผู้จำหน่ายบีเอ็มดับเบิลยูอย่างเป็นทางการ\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //     th:
  //       "• ฟรี รับเพิ่ม BMW Advance Car Eye 2.0 (ไม่รวมค่าติดตั้ง)\n• 1 - 31 ต.ค. 2564\n• Term อย่างน้อย 36 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด / ลูกค้าจะได้รับกล้องในเดือนถัดไป  ที่ผู้จำหน่ายบีเอ็มดับเบิลยูอย่างเป็นทางการ\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636523474/bmw-dsk/stage/campaign/BMW_X1_Advance_car_eye_2.0_for_FL_FC_lir4sn.png",
  //   series: [156],
  //   models: [],
  //   paymentType: ["3", "4"],
  //   lock: false,
  // },
  // {
  //   id: "7",
  //   title: {
  //     en: "BMW Financial Services 20th Anniversary Lucky Draw",
  //     th: "BMW Financial Services ลุ้นโชคครบรอบ 20 ปี",
  //   },
  //   description: {
  //     en:
  //       "• ลุ้นรางวัลใหญ่ BMW 2 Series Gran Coupé และของรางวัลอื่นๆ ในทุกๆเดือน รวมกว่า 7 ล้านบาท\n• วันนี้ถึง 31 มีนาคม 2565\n• ออกรถและทำสัญญา BMW, MINI & BMW Motorrad กับบริษัทฯ รับ 1 สิทธ์ร่วมลุ้น / รายละเอียดเพิ่มเติมที่ www.bmw.co.th\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //     th:
  //       "• ลุ้นรางวัลใหญ่ BMW 2 Series Gran Coupé และของรางวัลอื่นๆ ในทุกๆเดือน รวมกว่า 7 ล้านบาท\n• วันนี้ถึง 31 มีนาคม 2565\n• ออกรถและทำสัญญา BMW, MINI & BMW Motorrad กับบริษัทฯ รับ 1 สิทธ์ร่วมลุ้น / รายละเอียดเพิ่มเติมที่ www.bmw.co.th\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636523473/bmw-dsk/stage/campaign/Prize_Draw_Camp_i7c605.jpg",
  //   series: [
  //     151,
  //     188,
  //     189,
  //     152,
  //     135,
  //     153,
  //     154,
  //     155,
  //     156,
  //     157,
  //     158,
  //     159,
  //     160,
  //     161,
  //     162,
  //     163,
  //     168,
  //     164,
  //     165,
  //     166,
  //     167,
  //     190,
  //     169,
  //     170,
  //     205,
  //     206,
  //     209, // i4
  //   ],
  //   models: [],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: true,
  // },
  // {
  //   id: "8",
  //   title: {
  //     en: "M Performance Special Offers",
  //     th: "ชุดแต่งราคาพิเศษสำหรับรถรุ่น M Sport",
  //   },
  //   description: {
  //     en:
  //       "• รับสิทธิ์ซื้อชุดแต่ง M Sport แท้ ราคาพิเศษ\n• 1 - 30 ก.ย. 2564\n• ราคาไม่รวมภาษีมูลค่าเพิ่ม ค่าทำสี ค่าติดตั้ง และ ยาง ยกเว้นรุ่นที่ระบุไว้\n• รุ่นและเงื่อนไขเป็นไปตามที่บริษัทกำหนด",
  //     th:
  //       "• รับสิทธิ์ซื้อชุดแต่ง M Sport แท้ ราคาพิเศษ\n• 1 - 30 ก.ย. 2564\n• ราคาไม่รวมภาษีมูลค่าเพิ่ม ค่าทำสี ค่าติดตั้ง และ ยาง ยกเว้นรุ่นที่ระบุไว้\n• รุ่นและเงื่อนไขเป็นไปตามที่บริษัทกำหนด",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636523473/bmw-dsk/stage/campaign/M_Performance_iygixn.jpg",
  //   series: [188, 189, 152, 135, 154, 158],
  //   models: [],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  // },
  {
    id: "9",
    title: {
      en: "BMW Excellence Club Privilege",
      th: "สิทธิประโยชน์ BMW Excellence Club",
    },
    description: {
      en: `• BMW Excellence Club membership card\n• Exclusive privilege with 5-stars hotel, Concierge and Limousine Services. Find out more www.bmw.co.th\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• บัตรสมาชิก BMW Excellence Club \n• สิทธิประโยชน์สุดเอ็กซ์คลูซีฟ รร 5 ดาว, บริการ Conciege และบริการ Limousine รายละเอียดเพิ่มเติมที่ www.bmw.co.th\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [12, 24, 36, 48, 60],
    image:
      "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636523473/bmw-dsk/stage/campaign/excellence-club-banner_jbqfye.jpg",
    series: [154, 162, 211], // 7Series X7 i7
    models: [],
    paymentType: ["1", "2", "3", "4", "5"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "11",
  //   title: {
  //     en: "Free Wall Charge + e-Scooter",
  //     th: "ฟรี Wall Charge + e-Scooter",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี Wall Charge และ e-scooter สำหรับ MINI Cooper SE\n• เมื่อทำสัญญา Hire Purchase, Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• 1 - 31 ม.ค. 2565\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //     th:
  //       "• ฟรี Wall Charge และ e-scooter สำหรับ MINI Cooper SE\n• เมื่อทำสัญญา Hire Purchase, Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• 1 - 31 ม.ค. 2565\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636523473/bmw-dsk/stage/campaign/1.-Free-Wall-Charge_qsd4dt.jpg",
  //   series: [],
  //   models: [1095, 1096, 1103, 1104],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  // },
  {
    id: "12",
    title: {
      en: "Free 1 Year MINI Protect Insurance",
      th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
    },
    description: {
      en: `•  MINI Protect (1st Class Insurance) 1 year for MINI Cooper SE with Hire Purchase, Hire Purchase with Balloon, Finance Lease, Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `•  ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี สำหรับ MINI Cooper SE  เมื่อทำสัญญา Hire Purchase, Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-1year-protect.jpg",
    series: [],
    models: [
      1478,
      1480,
      1479, //H3 SE
    ],
    paymentType: ["1", "2", "3", "4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "13",
  //   title: {
  //     en: "Free 1 night hotel voucher at waldorf astoria bangkok",
  //     th: "ฟรี 1 night hotel voucher at waldorf astoria bangkok",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี 1 Night Hotel Voucher at Waldorf Astoria Bangkok สำหรับ MINI Cooper SE\n• เมื่อทำสัญญา Freedom Choice\n• 1 - 30 ต.ค. 2564\n• Term อย่างน้อย 36 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //     th:
  //       "• ฟรี 1 Night Hotel Voucher at Waldorf Astoria Bangkok สำหรับ MINI Cooper SE\n• เมื่อทำสัญญา Freedom Choice\n• 1 - 30 ต.ค. 2564\n• Term อย่างน้อย 36 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636523473/bmw-dsk/stage/campaign/3.-waldorf-astoria-bangkok-introduces-peacock-alley-s-festive-afternoon-tea-0_ueld0d.jpg",
  //   series: [],
  //   models: [945],
  //   paymentType: ["4"],
  //   lock: false,
  // },
  // {
  //   id: "14",
  //   title: {
  //     en: "Free 2 Years MINI Protect Insurance",
  //     th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 2 ปี",
  //   },
  //   description: {
  //     en: `• MINI Protect (1st Class Insurance) 2 year for MINI Countryman with Hire Purchase contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 15 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 2 ปี สำหรับ MINI Countryman เมื่อทำสัญญา Hire Purchase\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 15-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
  //   },
  //   requiredTerm: [48, 60],
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-2years-protect.jpg",
  //   series: [174], // all country man
  //   models: [],
  //   paymentType: ["1"],
  //   lock: false,
  //   downPaymentRange: [15, 35],
  // },
  // {
  //   id: "15",
  //   title: {
  //     en: "MINI Financial Services 20th Anniversary Lucky Draw",
  //     th: "MINI Financial Services ลุ้นโชคครบรอบ 20 ปี",
  //   },
  //   description: {
  //     en:
  //       "• ลุ้นรางวัลใหญ่ MINI Cooper SE อีกคัน และของรางวัลอื่นๆ ในทุกๆเดือน รวมกว่า 7 ล้านบาท\n• วันนี้ถึง 31 มี.ค. 2565\n• ออกรถและทำสัญญา BMW, MINI & BMW Motorrad กับบริษัทฯ รับ 1 สิทธ์ร่วมลุ้น / รายละเอียดเพิ่มเติมที่ www.mini.co.th\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด",
  //     th:
  //       "• ลุ้นรางวัลใหญ่ MINI Cooper SE อีกคัน และของรางวัลอื่นๆ ในทุกๆเดือน รวมกว่า 7 ล้านบาท\n• วันนี้ถึง 31 มี.ค. 2565\n• ออกรถและทำสัญญา BMW, MINI & BMW Motorrad กับบริษัทฯ รับ 1 สิทธ์ร่วมลุ้น / รายละเอียดเพิ่มเติมที่ www.mini.co.th\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636561483/bmw-dsk/stage/campaign/banner-20-anniversary_drdypw.jpg",
  //   series: [104, 171, 172, 173, 174, 175],
  //   models: [],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  // },
  // {
  //   id: "16",
  //   title: {
  //     en: "Free BMW Protect 1 Year Insurance",
  //     th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
  //   },
  //   description: {
  //     en: `• BMW Protect (1st Class Insurance) 1 year for Hire Purchase, Hire Purchase with Balloon, Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี สำหรับ Hire Purchase หรือ Hire Purchase with Balloon และ BMW Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
  //   },
  //   requiredTerm: 48,
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1656054038/bmw-dsk/stage/campaign/banner_1_kl0hqi.jpg",
  //   series: [205, 206, 209],
  //   models: [],
  //   paymentType: ["2", "3", "4"],
  //   lock: false,
  // },
  // {
  //   id: "17",
  //   title: {
  //     en: "Free GoPro Hero 10 Black",
  //     th: "ฟรี กล้อง GoPro Hero 10 Black",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี กล้อง GoPro Hero 10\n• 1 - 31 ธ.ค. 2564\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //     th:
  //       "• ฟรี กล้อง GoPro Hero 10\n• 1 - 31 ธ.ค. 2564\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636561476/bmw-dsk/stage/campaign/banner_3_lv5wb5.jpg",
  //   series: [188, 189, 135, 154, 158, 159, 160, 162],
  //   models: [],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  // },
  // {
  //   id: "18",
  //   title: {
  //     en: "Free 2 Year MINI Protect Insurance",
  //     th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 2 ปี",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 2 ปี สำหรับ MINI Cooper SE\n• เมื่อทำสัญญา  Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• 1 - 31 ธ.ค. 2564\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //     th:
  //       "• ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 2 ปี สำหรับ MINI Cooper SE\n• เมื่อทำสัญญา  Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• 1 - 31 ธ.ค. 2564\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1636561483/bmw-dsk/stage/campaign/banner-mini-2_year_jcrnso.jpg",
  //   series: [],
  //   models: [1095, 1096, 1103, 1104],
  //   paymentType: ["2", "3", "4"],
  //   lock: false,
  // },
  {
    id: "19",
    title: {
      en: "Free 1 Year MINI Protect Insurance",
      th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
    },
    description: {
      en: `• MINI Protect (1st Class Insurance) 1 year for MINI Countryman with Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Term 24 months / 15 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี สำหรับ MINI Countryman เมื่อทำสัญญา Freedom Choice\n• ${latestCampaignDateTh}\n• Term 24 เดือน / ดาวน์ 15-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
    },
    requiredTerm: [24],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-1year-protect.jpg",
    series: [174], // all country man
    models: [],
    paymentType: ["4"],
    lock: false,
    downPaymentRange: [15, 35],
  },
  {
    id: "20",
    title: {
      en: "Free BMW Protect 1 Year Insurance",
      th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 1 year\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment/ Applicable models only\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี \n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-1year-protect.jpg",
    series: [],
    models: [
      1570,
      1571,
      1771,
      1772, //i4 e35
      1572,
      1770,
      1773,
      1774, //i4 M50
      1573,
      1574,
      1775,
      1776, //iX 40
      1575,
      1777,
      1778,
      1779, //iX 50
      1576,
      1577,
      1578, //iX3
      1782,
      1780,
      1781,
      1579, //i7 60
      1786,
      1787,
      1785,
      1784, //i7 M70
    ],
    paymentType: ["1", "2", "3", "4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "21",
  //   title: {
  //     en: "Free BSI 10 Years + Free GoPro Hero 10 Black",
  //     th: "ฟรี BSI Upgrade 10 ปี + ฟรี กล้อง GoPro Hero 10 Black",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี กล้อง GoPro Hero 10\n• ฟรี BSI upgrade 10 ปี หรือ 100,000 กม (อย่างใดอย่างหนึ่งถึงก่อน)\n• 1 - 31 ธ.ค. 2564\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //     th:
  //       "• ฟรี กล้อง GoPro Hero 10\n• ฟรี BSI upgrade 10 ปี หรือ 100,000 กม (อย่างใดอย่างหนึ่งถึงก่อน)\n• 1 - 31 ธ.ค. 2564\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1637217991/bmw-dsk/stage/campaign/banner_5_hojqc3.jpg",
  //   series: [156],
  //   models: [],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  // },
  {
    id: "22",
    title: {
      en: "Free Wall Charge",
      th: "ฟรี Wall Charge",
    },
    description: {
      en: `• Free Wall Charge for MINI Cooper SE with Hire Purchase, Hire Purchase with Balloon, Finance Lease, Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี Wall Charge สำหรับ MINI Cooper SE เมื่อทำสัญญา Hire Purchase, Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-wall-charge.jpg",
    series: [],
    models: [
      1478,
      1480,
      1479, //H3 SE
    ],
    paymentType: ["1", "2", "3", "4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "23",
  //   title: {
  //     en: "Garmin Smart Watch Venu 2S",
  //     th: "ฟรี Garmin Smart Watch Venu 2S",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี Garmin Smart Watch Venu 2S เฉพาะรุ่นที่กำหนด\n• 1 - 30 เม.ย. 2565\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //     th:
  //       "• ฟรี Garmin Smart Watch Venu 2S เฉพาะรุ่นที่กำหนด\n• 1 - 30 เม.ย. 2565\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1647868065/bmw-dsk/stage/campaign/banner_1_ihflur.jpg",
  //   series: [
  //     156, //	X1
  //     158, //	X3
  //     159, //	X4
  //     160, //	X5
  //     161, //	X6
  //     162, //	X7
  //     205, // iX
  //     206, // iX3
  //   ],
  //   models: [],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  // },
  // {
  //   id: "24",
  //   title: {
  //     en: "Samsung The Freestyle",
  //     th: "ฟรีโปรเจคเตอร์พกพา Samsung The Freestyle",
  //   },
  //   description: {
  //     en:
  //       "• ฟรี โปรเจคเตอร์พกพา Samsung The Freestyle เฉพาะรุ่นที่กำหนด\n• 1 - 30 เม.ย. 2565\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //     th:
  //       "• ฟรี โปรเจคเตอร์พกพา Samsung The Freestyle เฉพาะรุ่นที่กำหนด\n• 1 - 30 เม.ย. 2565\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด",
  //   },
  //   image:
  //     "https://res.cloudinary.com/ddx-asia/image/upload/c_scale,w_680/v1647868080/bmw-dsk/stage/campaign/banner_2_ufoame.jpg",
  //   series: [],
  //   models: [
  //     857,
  //     860,
  //     1109,
  //     1146,
  //     1147,
  //     1173,
  //     1164,
  //     1162,
  //     1159,
  //     1161,
  //     1160,
  //     1157,
  //     1158,
  //     1120,
  //     1149,
  //     1148,
  //     1149,
  //     869,
  //     872,
  //     1123,
  //     1124,
  //     1150,
  //     1151,
  //   ],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  // },
  // skip 24 because there is one campaign with an id 24 in db
  {
    id: "25",
    title: {
      en: "3asy Ride Campaign",
      th: "ฟรี ประกัน BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี ",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 1 year\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment/ Applicable models only\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี \n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/free-motorrad-protect-1yr.jpg",
    series: [],
    models: [
      1630,
      1629,
      1628,
      1627, //R1250 GS
      1623,
      1622,
      1621,
      1620,
      1619, //C400 GT
    ],
    paymentType: ["1", "2", "3"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "26",
    title: {
      en: "3asy Ride Campaign",
      th: "ฟรี ปี๊ป (pannier) 2 ใบ",
    },
    description: {
      en: `• 2 Panniers\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment/ Applicable models only\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี ปี๊ป (pannier) 2 ใบ\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/free-2-bmw-motorrad-panniers.jpg",
    series: [],
    models: [
      1630,
      1629,
      1628,
      1627, //R1250 GS
      1634,
      1633,
      1632,
      1631, //R1250 GSA
    ],
    paymentType: ["1", "2", "3"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "27",
  //   title: {
  //     en: "Free 2 Years MINI Protect Insurance",
  //     th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 2 ปี",
  //   },
  //   description: {
  //     en: `• MINI Protect (1st Class Insurance) 2 year for MINI Cooper SE with Hire Purchase, Hire Purchase with Balloon, Finance Lease and Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 2 ปี สำหรับ MINI Cooper SE เมื่อทำสัญญา Hire Purchase, Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
  //   },
  //   requiredTerm: 48,
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-2years-protect.jpg",
  //   series: [],
  //   models: [1189, 1326], //MINI Hatch 3 SE
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  //   downPaymentRange: [0, 35],
  // },
  {
    id: "28",
    title: {
      en: "Free MSI 10 Years",
      th: "ฟรี MSI Upgrade 10 ปี",
    },
    description: {
      en: `• MSI Upgrade 10 Years for MINI COOPER S COUNTRYMAN HIGHTRIM with Hire Purchase, Hire Purchase with Balloon, Finance Lease and Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี MSI upgrade 10 ปี หรือ 100,000 กม (อย่างใดอย่างหนึ่งถึงก่อน) สำหรับ MINI COOPER S COUNTRYMAN HIGHTRIM เมื่อทำสัญญา Hire Purchase, Hire Purchase with Balloon, Finance Lease หรือ Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-10years-msi.jpg",
    series: [174],
    models: [], //CTM High
    paymentType: ["1", "2", "3", "4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "29",
  //   title: {
  //     en: "Free BMW Protect 1 Year Insurance",
  //     th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
  //   },
  //   description: {
  //     en: `• BMW Protect (1st Class Insurance) 1 year for Hire Purchase, Hire Purchase with Balloon, Finance Lease, Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี สำหรับ Hire Purchase หรือ Hire Purchase with Balloon หรือ Finance Lease หรือ Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
  //   },
  //   requiredTerm: [48, 60],
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-1year-protect.jpg",
  //   series: [205, 206, 209, 211], // all iX, iX3, i4, i7
  //   models: [],
  //   paymentType: ["1", "2", "3", "4"],
  //   lock: false,
  //   downPaymentRange: [0, 35],
  // },
  {
    id: "30",
    title: {
      en: "Free Wall Charge",
      th: "ฟรี Wall Charge",
    },
    description: {
      en: `• Free BMW Wall Charge for Hire Purchase, Hire Purchase with Balloon, Finance Lease, Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Wall Charge สำหรับ  Hire Purchase หรือ Hire Purchase with Balloon หรือ Finance Lease หรือ Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-wall-box.jpg",
    series: [205, 206, 209, 211, 242], //ix ix3 i4 i7 i5
    models: [],
    paymentType: ["1", "2", "3", "4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "31",
    title: {
      en: "3asy Ride Campaign",
      th: "ฟรี ปี๊ป (pannier) 1 ใบ",
    },
    description: {
      en: `• Top Panniers\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment/ Applicable models only\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี ปี๊ป (pannier) 1 ใบ\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/motorrrad-panniers.jpg",
    series: [224], // F 850 GS (Series F 850 GS)
    models: [1365, 1366], //F 850 GS (Model of Series F 850)
    paymentType: ["1", "2", "3"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "32",
    title: {
      en: "Free 1 Year MINI Protect Insurance",
      th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
    },
    description: {
      en: `•  MINI Protect (1st Class Insurance) 1 year for MINI COOPER S COUNTRYMAN ENTRY, MINI COOPER S COUNTRYMAN HIGHTRIM with Hire Purchase, Hire Purchase with Balloon, Finance Lease and Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `•  ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี สำหรับ MINI COOPER S COUNTRYMAN ENTRY, MINI COOPER S COUNTRYMAN HIGHTRIM เมื่อทำสัญญา Hire Purchase, Hire Purchase with Balloon,Finance Lease หรือ Freedom Choice\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-1year-protect.jpg",
    series: [174], //all country man
    models: [],
    paymentType: ["1", "2", "3", "4"],
    lock: false,
    downPaymentRange: [15, 35],
  },
  // {
  //   id: "33",
  //   title: {
  //     en: "Free BMW Protect 2 Years Insurance",
  //     th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 2 ปี",
  //   },
  //   description: {
  //     en: `• BMW Protect (1st Class Insurance) 2 years for Hire Purchase, Hire Purchase with Balloon or Finance Lease contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment 48 months/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 2 ปี สำหรับ Hire Purchase หรือ Hire Purchase with Balloon หรือ Finance Lease\n• ${latestCampaignDateTh}\n• Term 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
  //   },
  //   requiredTerm: [48, 60],
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-2years-protect.jpg",
  //   series: [],
  //   models: [970, 1118], // 220i, X7 non LCI
  //   paymentType: ["1", "2", "3"],
  //   lock: false,
  //   downPaymentRange: [0, 35],
  // },
  // {
  //   id: "34",
  //   title: {
  //     en: "Free BSI 10 Years",
  //     th: "ฟรี BSI Upgrade 10 ปี",
  //   },
  //   description: {
  //     en: `• BSI Upgrade 10 Years\n• ${latestCampaignDateEn}\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี BSI upgrade 10 ปี หรือ 100,000 กม (อย่างใดอย่างหนึ่งถึงก่อน)\n• ${latestCampaignDateTh}\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
  //   },
  //   requiredTerm: [12, 24, 36, 48, 60],
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-10years-bsi.jpg",
  //   series: [135], // 5 Series all models
  //   models: [],
  //   paymentType: ["2", "3"],
  //   lock: false,
  //   downPaymentRange: [0, 35],
  // },
  {
    id: "35",
    title: {
      en: "Free 1 Year MINI Protect Insurance",
      th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
    },
    description: {
      en: `•  MINI Protect (1st Class Insurance) 1 year for MINI Cooper SE with contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `•  ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 1 ปี สำหรับ MINI Cooper SE\n• ${latestCampaignDateTh}\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
    },
    requiredTerm: [24],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-1year-protect.jpg",
    series: [],
    models: [
      1478,
      1480,
      1479, //H3 SE
    ],
    paymentType: ["4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "36",
    title: {
      en: "Free BMW Protect 2 Years Insurance",
      th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 2 ปี",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 2 years for Hire Purchase with Balloon, Finance Lease, Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment 48 months/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 2 ปี สำหรับ BMW Hire Purchase with Balloon, Finance Lease, Freedom Choice \n• ${latestCampaignDateTh}\n• Term 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-2years-protect.jpg",
    series: [162], //all X7 LCI
    models: [
      1675,
      1676,
      1677,
      1678,
      1679, 
      1795,
      1794,
      1793,
      1792, //330Li
      1681,
      1797,
      1796, //320d
      1684,
      1799,
      1798, //330e
      1687,
      1801,
      1800, //M340
      1734,
      1732,
      1733, //X1 30e
      1728,
      1729,
      1540, //X1 20i xLine
      1730,
      1731,
      1541, //X1 20i M Sport
    ],
    paymentType: ["2", "3","4"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  {
    id: "37",
    title: {
      en: "Free BMW Protect 1 Year Insurance",
      th: "ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 1 year\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment/ Applicable models only\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี \n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-1year-protect.jpg",
      series: [162], //all X7 LCI
      models: [
        1675,
        1676,
        1677,
        1678,
        1679, 
        1795,
        1794,
        1793,
        1792, //330Li
        1681,
        1797,
        1796, //320d
        1684,
        1799,
        1798, //330e
        1687,
        1801,
        1800, //M340
        1734,
        1732,
        1733, //X1 30e
        1728,
        1729,
        1540, //X1 20i xLine
        1730,
        1731,
        1541, //X1 20i M Sport
      ],
    paymentType: ["1"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "38",
  //   title: {
  //     en: "Free 3 Years MINI Protect Insurance",
  //     th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 3 ปี",
  //   },
  //   description: {
  //     en: `• MINI Protect (1st Class Insurance) 3 year for MINI Countryman with Hire Purchase with Balloon, Finance Lease contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 15 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 3 ปี สำหรับ MINI Countryman เมื่อทำสัญญา Hire Purchase with Balloon หรือ Finance Lease\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 15-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
  //   },
  //   requiredTerm: [48, 60],
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-3years-protect.jpg",
  //   series: [174], // all country man
  //   models: [],
  //   paymentType: ["2", "3"],
  //   lock: false,
  //   downPaymentRange: [15, 35],
  // },
  // {
  //   id: "39",
  //   title: {
  //     en: "Free 3 Year MINI Protect Insurance",
  //     th: "ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 3 ปี",
  //   },
  //   description: {
  //     en: `• MINI Protect (1st Class Insurance) 3 year for MINI Countryman with Freedom Choice contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Term 48 months / 15 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี ประกัน MINI Protect (ประกันภัยชั้นหนึ่ง) 3 ปี สำหรับ MINI Countryman เมื่อทำสัญญา Freedom Choice\n• ${latestCampaignDateTh}\n• Term 48 เดือน / ดาวน์ 15-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
  //   },
  //   requiredTerm: [48],
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/mini-3years-protect.jpg",
  //   series: [174], // all country man
  //   models: [],
  //   paymentType: ["4"],
  //   lock: false,
  //   downPaymentRange: [15, 35],
  // },
  {
    id: "40",
    title: {
      en: "Free Top Pannier",
      th: "ฟรี ปี๊ป (pannier) 1 ใบ",
    },
    description: {
      en: `• Top Panniers\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment/ Applicable models only\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี ปี๊ป (pannier) 1 ใบ\n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/motorrrad-top-pannier.jpg",
    series: [],
    models: [
      1637,
      1638, // F850 GS
    ], 
    paymentType: ["1", "2", "3"],
    lock: false,
    downPaymentRange: [0, 35],
  },
  // {
  //   id: "41",
  //   title: {
  //     en: "Free Wall Charge",
  //     th: "ฟรี Wall Charge",
  //   },
  //   description: {
  //     en: `• Free BMW Wall Charge for Hire Purchase, Hire Purchase with Balloon, Finance Lease contract with BMW Financial Services Thailand\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 48 months upwards/ 0 - 35% of Down payment\n• The company reserves the right to change the terms and conditions without prior notice.`,
  //     th: `• ฟรี BMW Wall Charge สำหรับ  Hire Purchase, Hire Purchase with Balloon หรือ Finance Lease \n• ${latestCampaignDateTh}\n• Term อย่างน้อย 48 เดือน / ดาวน์ 0-35%\n• เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด`,
  //   },
  //   requiredTerm: [48, 60],
  //   image:
  //     "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/bmw-wall-box.jpg",
  //   series: [242], //ix ix3 i4 i7
  //   models: [],
  //   paymentType: ["1", "2", "3"],
  //   lock: false,
  //   downPaymentRange: [0, 35],
  // },
  {
    id: "42",
    title: {
      en: "3asy Ride Campaign",
      th: "ฟรี ประกัน BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี ",
    },
    description: {
      en: `• BMW Protect (1st Class Insurance) 1 year\n• ${latestCampaignDateEn}\n• Monthly Installment starts from 36 months upwards/ 0 - 35% of Down payment/ Applicable models only\n• The company reserves the right to change the terms and conditions without prior notice.`,
      th: `• ฟรี BMW Protect (ประกันภัยชั้นหนึ่ง) 1 ปี \n• ${latestCampaignDateTh}\n• Term อย่างน้อย 36 เดือน / ดาวน์ 0-35% / เฉพาะรุ่นที่กำหนด\n• เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า`,
    },
    requiredTerm: [36, 48, 60],
    image:
      "https://bmw-leasing-dsk-assets-dev.s3.ap-southeast-1.amazonaws.com/images/campaigns/free-motorrad-protect-1yr.jpg",
    series: [
      228, //R nineT pure
      204, //R18
      234, //R18 Classic
      235, //R18 B
      236, //R18 TC
      239, //S100RR
    ],
    models: [],
    paymentType: ["1", "2", "3"],
    lock: false,
    downPaymentRange: [0, 35],
  },
]

export default options
