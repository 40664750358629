import React from "react"
import { Formik, Form, FieldArray } from "formik"
import _ from "lodash"
import { useParams, useHistory } from "react-router-dom"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import DescriptionIcon from "@material-ui/icons/Description"
import PrintIcon from "@material-ui/icons/Print"
import { ThemeProvider } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"

import { useTranslation } from "react-i18next"

import FormField from "./components/Fields"
import Slider from "../../components/Slider/index"
import CalculationAppBar from "./components/CalculationAppBar"
import CalculationSetting from "./components/CalculationSetting"
import CalculationActions from "./components/CalculationActions"
import CalculateCarForm from "./components/UsedCarForm"
// import { calculateFinanceAmount } from "./utils/calculation"

import {
  PAYMENT_TYPE,
  VAT_INCLUDE_TYPE,
  typeOfPaymentOptions,
} from "../../constants"

import useAuthprovider from "../../hooks/useAuthprovider"
import useDataprovider from "../../hooks/useDataprovider"
import theme from "../../styles/theme"
import ReactGA from "react-ga"

import prepareSubmitData from "./utils/prepare-submit-data"
import useCalculatePageStyle from "./style"

import { capitalizeBrand } from "../utils"
import { calculateFinanceAmount as FA } from "./utils/calculation"

const UsedCar = () => {
  const classes = useCalculatePageStyle()
  const { t, i18n } = useTranslation()
  const { user } = useAuthprovider()
  const { usedCarProvider } = useDataprovider()
  const params = useParams()
  const { brand } = params
  const cappedBrand = capitalizeBrand(brand)

  const { findSFRate, submitQuotation, getQuotationImageZip } = usedCarProvider

  // Disable enter key from submit form
  const onKeyDown = (keyEvent) => {
    const target = keyEvent.target
    const tagName = target.tagName
    if (
      tagName !== "TEXTAREA" &&
      (keyEvent.charCode || keyEvent.keyCode) === 13
    ) {
      keyEvent.preventDefault()
      keyEvent.target.blur()
    }
  }

  const onSubmit = (values, actions) => {
    console.log("onSubmit", values)

    try {
      const submitValues = prepareSubmitData(values, {
        user,
        lang: i18n.language,
        findSFRate,
      })
      ReactGA.event({
        category: "PDF",
        action: "create PDF",
      })
      const output = values.output || "pdf"
      console.log({ submitValues })
      actions.setSubmitting(false)
      if (output === "pdf") {
        const windowReference = window.open()
        submitQuotation(submitValues)
          .then((response) => {
            actions.setSubmitting(false)
            // Trick to make iOS open new tab
            windowReference.location = `/quotation/pdf/usedcar-${response.id}`
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            actions.setSubmitting(false)
          })
      } else if (output === "image") {
        submitQuotation(submitValues)
          .then((response) => {
            return getQuotationImageZip(response.id)
          })
          .then((zipfileUrl) => {
            console.log({ zipfileUrl })
            window.location = zipfileUrl
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            actions.setSubmitting(false)
          })
      }
    } catch (e) {
      console.log(e.message)
      switch (e.message) {
        case "Cannot find result with parameter":
          console.log("wrong combo")
          break
      }
    }
  }

  const carInitialValues = {
    model_margin: {},
    matrix_row: {},
    margin_rate: 0.5,
    sf_rate: 0.0,
    rebate: 0,
    model_name: ``,
    is_bmw_premium_selection: "0",
    production_year: ``,
    mileage: 0,
    warranty_expire: "",
    bsi_expire: "",
    maximum_miles: 0,
    estimate_car_price: 0,
    vat_include_type: "",
    type_of_payment: PAYMENT_TYPE.HIRE_PURCHASE,
    type_of_payment_options: typeOfPaymentOptions,
    insurance_or_accessories: 0,
    discount: 0,
    down_payment: 0,
    finance_amount: 0,
    term: 48,
    balloon: 0,
    monthly_installment: 0,
    residual: 0,
    campaigns: [],
    boundary: {
      balloon: [0, 99],
    },
    locked: {
      margin_rate: false,
      monthly_installment: false,
      down_payment: true,
      balloon: true,
    },
    goalseek: "monthly_installment",
  }

  const initialFormValues = {
    customer_information: {
      customer_firstname: "",
      customer_surname: "",
      email: "",
      telephone: "",
    },
    advance_calculation: false,
    remark: "",
    cars: [carInitialValues],
  }

  const trigger = useScrollTrigger({
    threshold: 430,
    disableHysteresis: true,
  })

  const validate = (values) => {
    const errors = {}

    values.cars.forEach((car, index) => {
      const {
        sf_rate_valid,
        model_name,
        production_year,
        mileage,
        estimate_car_price,
        vat_include_type,
        type_of_payment,
        down_payment,
        discount,
        insurance_or_accessories,
        balloon,
        boundary,
        margin_rate,
        sf_rate,
      } = car

      if (!sf_rate_valid)
        _.set(
          errors,
          `cars.${index}.monthly_installment`,
          "Cannot find SF Rate that works with current Type, Down, Balloon & Term"
        )
      if (!model_name)
        _.set(
          errors,
          `cars.${index}.model_name`,
          t("field.model_name.required")
        )

      if (!production_year)
        _.set(
          errors,
          `cars.${index}.production_year`,
          t("field.production_year.required")
        )

      if (!mileage)
        _.set(errors, `cars.${index}.mileage`, t("field.mileage.required"))

      //check vat include type
      if (!vat_include_type) {
        _.set(
          errors,
          `cars.${index}.vat_include_type`,
          t("field.vat_include_type.error")
        )
      }

      // Check Insurance
      if (insurance_or_accessories > 0.1 * estimate_car_price) {
        _.set(
          errors,
          `cars.${index}.insurance_or_accessories`,
          t("field.insurance_or_accessories.warning.over")
        )
      } else if (insurance_or_accessories < 0) {
        _.set(
          errors,
          `cars.${index}.insurance_or_accessories`,
          t("field.insurance_or_accessories.warning.under")
        )
      }
      const finance_amount = FA(
        estimate_car_price,
        vat_include_type,
        down_payment,
        discount,
        insurance_or_accessories
      )

      if (down_payment < 0)
        _.set(
          errors,
          `cars.${index}.down_payment`,
          t("field.down_payment.waring_under", { percent: 0 })
        )

      if (
        [
          PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON,
          PAYMENT_TYPE.FINANCIAL_LEASE,
        ].includes(type_of_payment)
      ) {
        if (balloon < 0)
          _.set(
            errors,
            `cars.${index}.balloon`,
            t("field.balloon.waring_under", { percent: 0 })
          )

        if (boundary.balloon[1] && balloon > boundary.balloon[1])
          _.set(
            errors,
            `cars.${index}.balloon`,
            t("field.balloon.warning", { percent: boundary.balloon[1] })
          )
      }

      if (finance_amount < 300000) {
        _.set(
          errors,
          `cars.${index}.finance_amount`,
          t("field.finance_amount.warning.under_300000")
        )
      }

      if (margin_rate < -sf_rate) {
        _.set(
          errors,
          `cars.${index}.margin_rate`,
          t("field.margin.error_less_than_sfrate")
        )
      } else if (margin_rate > 3) {
        _.set(
          errors,
          `cars.${index}.margin_rate`,
          t("field.margin.error_3percent")
        )
      }

      if (
        type_of_payment !== PAYMENT_TYPE.FINANCIAL_LEASE &&
        margin_rate + sf_rate > 15
      ) {
        _.set(
          errors,
          `cars.${index}.margin_rate`,
          t("field.interest.error_percent", { percent: 15 })
        )
      }
    })

    return errors
  }

  return (
    <ThemeProvider theme={theme["bmw"]}>
      <div className={classes.root}>
        <Helmet>
          <title>
            {cappedBrand} Used Car Quote | DSK | BMW Leasing Thailand
          </title>
        </Helmet>
        <Formik
          initialValues={initialFormValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, setFieldValue, values, errors }) => (
            <Form noValidate onKeyDown={onKeyDown}>
              <FieldArray name="cars">
                {({ push, remove }) => (
                  <div>
                    <CalculationAppBar
                      values={values}
                      carInitialValues={carInitialValues}
                      push={push}
                    />
                    <div className={classes.header}>
                      <div className={classes.headerContentContainer}></div>
                    </div>
                    <div className={classes.main}>
                      <div className={classes.sidebarLeft}>
                        <div
                          className={
                            trigger
                              ? classes.positionSticky
                              : classes.positionStatic
                          }
                        >
                          <CalculationSetting values={values} />
                        </div>
                      </div>
                      <div className={classes.mainCenter}>
                        <Slider
                          items={values.cars}
                          renderItem={(car, index) => (
                            <CalculateCarForm
                              // car={car}
                              carMedia={car.media_url}
                              carsLength={values.cars.length}
                              isValid={
                                _.size(_.get(errors, `cars.${index}`)) === 0
                              }
                              // values={values}
                              index={index}
                              remove={remove}
                            />
                          )}
                        />
                      </div>
                      <div className={classes.sidebarRight}>
                        <div
                          className={
                            trigger
                              ? classes.positionSticky
                              : classes.positionStatic
                          }
                        >
                          <CalculationActions
                            values={values}
                            push={push}
                            remove={remove}
                            carInitialValues={carInitialValues}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
              <div className={classes.main}>
                <div className={classes.sidebarLeft}></div>
                <div
                  className={clsx(
                    classes.mainCenter,
                    classes.mainCenterSingleItem
                  )}
                >
                  <div className={classes.customerInformationForm}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name={`customer_information.customer_firstname`}
                          label={t("field.customer_firstname.label")}
                          placeholder="John"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name={`customer_information.customer_surname`}
                          label={t("field.customer_surname.label")}
                          placeholder="Smith"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name={`customer_information.email`}
                          label={t("field.email.label")}
                          placeholder="johnsmith@gmail.com"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name={`customer_information.telephone`}
                          label={t("field.telephone.label")}
                          type="telephone"
                          placeholder="000 000 0000"
                        />
                      </Grid>
                    </Grid>
                    <FormField
                      name={`remark`}
                      label={t("field.remark.label")}
                      helperText={t("field.remark.helperText")}
                      placeholder={t("field.remark.placeholder")}
                      multiline
                      rows={5}
                    />
                    {_.map(_.get(errors, "cars", []), (car, index) => {
                      if (car === undefined) return null
                      else
                        return (
                          <Alert
                            key={`alert-${index}`}
                            severity="error"
                            style={{ marginBottom: 8 }}
                          >
                            <AlertTitle>
                              {!!_.get(values, `cars.${index}.name`)
                                ? _.get(values, `cars.${index}.name`)
                                : `Error`}
                            </AlertTitle>
                            <ul style={{ paddingLeft: 0 }}>
                              {_.map(car, (error, i) => (
                                <li key={`error-${i}`}>{error}</li>
                              ))}
                            </ul>
                          </Alert>
                        )
                    })}
                    <div className={classes.formActions}>
                      <Typography variant="subtitle2" gutterBottom>
                        {t("field.calculation.caution")}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            type="submit"
                            disabled={isSubmitting || !isValid}
                            endIcon={<DescriptionIcon />}
                            onClick={(e) => {
                              setFieldValue("output", "pdf")
                            }}
                          >
                            {t("form_action.create_quotation")}
                            {isSubmitting && values.output === "pdf" && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            fullWidth
                            type="submit"
                            endIcon={<PrintIcon />}
                            disabled={isSubmitting || !isValid}
                            onClick={(e) => {
                              setFieldValue("output", "pdf")
                            }}
                          >
                            {t("form_action.print_request")}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                <div className={classes.sidebarRight}></div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ThemeProvider>
  )
}

export default UsedCar
