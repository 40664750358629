import React, { useState, useEffect } from "react"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import useDataprovider from "../../../hooks/useDataprovider"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton"
import RemoveIcon from "@material-ui/icons/Remove"

import useStyles from "./style"

import { thousandSeparator, getTotalWheelPrice, addVat } from "../utils"

export const SeriesField = (props) => {
  const { values, setFieldValue } = useFormikContext()
  const { series } = useDataprovider()
  const wheelSeriesOptions = series.filter(
    (car) => car.wheel_selector_image !== null
  )
  const { t } = useTranslation()

  const classes = useStyles()

  const handleSelectModel = (value) => {
    setFieldValue(props.name, value.name)
    setFieldValue("seriesId", value.id)
    setFieldValue("default_image_url", value.wheel_selector_image)
    if (values.series !== value.label) {
      setFieldValue(`wheels`, [])
      setFieldValue(`active_wheel`, {
        name: "",
        detail: "",
        id: null,
        unit_price: 0,
        discounted_price: null,
        quantity: 1,
      })
    }
  }

  return (
    <div className={classes.fieldRoot}>
      <FormControl component="fieldset" variant="outlined" fullWidth>
        <Select
          name={props.name}
          displayEmpty
          variant="outlined"
          value={values.series || ""}
          margin="dense"
          renderValue={() => {
            if (values.series) {
              return <span>{values.series}</span>
            } else {
              return <span>{t("field.model.placeholder")}</span>
            }
          }}
        >
          <div className={classes.accessoryItem}>
            {wheelSeriesOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.name}
                onClick={() => handleSelectModel(option)}
                data-item="series"
                data-name={option.name}
                data-id={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </div>
        </Select>
      </FormControl>
    </div>
  )
}

export const SelectWheelField = () => {
  const {
    values: { seriesId, active_wheel },
    setFieldValue,
  } = useFormikContext()
  const classes = useStyles()
  const { t } = useTranslation()
  const { getSeriesWheels } = useDataprovider()
  const [wheelOptions, setWheelOptions] = useState([])
  useEffect(() => {
    const getWheels = async () => {
      const seriesWheels = await getSeriesWheels(seriesId)
      setWheelOptions(seriesWheels)
    }
    getWheels()
  }, [seriesId, getSeriesWheels])

  const handleClickImage = (wheel) => {
    setFieldValue(`active_wheel`, {
      id: wheel.id,
      name: wheel.name,
      unit_price: wheel.unit_price,
      description: wheel.description,
      discounted_price: wheel.discounted_price,
      quantity: 1,
    })
    setFieldValue(`selected_image_url`, wheel.accessory_model_image)
  }
  return (
    <div className={classes.fieldRoot}>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className={classes.marginBottom10}>
          {t("field.wheel_selector.select.lebel")}
        </FormLabel>
        <Grid container spacing={2}>
          {wheelOptions.map((option) => {
            const {
              image,
              id,
              name,
              unit_price,
              discounted_price,
              description,
            } = option
            const formattedPrice = thousandSeparator(addVat(unit_price))
            const formattedDiscountPrice = discounted_price
              ? thousandSeparator(addVat(discounted_price))
              : null

            return (
              <Grid
                item
                md={4}
                xs={4}
                className={classes.wheelContainer}
                key={`wheel.${seriesId}.${id}`}
              >
                <div
                  className={`${classes.imgContainer} ${
                    active_wheel.id === id && classes.borderHighlight
                  }`}
                >
                  <ButtonBase
                    data-item="accessory"
                    data-accessoryType="wheel"
                    data-name={name}
                    data-id={id}
                    onClick={() => handleClickImage(option)}
                  >
                    <img src={image} alt={id} />
                  </ButtonBase>
                </div>
                <Typography className={classes.medTypoStyle}>{name}</Typography>
                {description && (
                  <Typography
                    variant="body1"
                    className={`${classes.smallTypoStyle} ${classes.marginBottom10}`}
                  >
                    {description}
                  </Typography>
                )}
                <Typography className={classes.medTypoStyle}>
                  {formattedDiscountPrice
                    ? formattedDiscountPrice
                    : formattedPrice}
                  {` THB`}
                </Typography>
                {formattedDiscountPrice && (
                  <Typography
                    variant="body2"
                    component="span"
                    className={`${classes.smallTypoStyle} ${classes.strikeStyle}`}
                  >
                    {formattedPrice} THB
                  </Typography>
                )}
              </Grid>
            )
          })}
        </Grid>
      </FormControl>
    </div>
  )
}

export const WheelDetailField = () => {
  const {
    values: { active_wheel, wheels },
    setFieldValue,
  } = useFormikContext()
  const { unit_price, discounted_price, id, description } = active_wheel
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAddWheel = () => {
    setFieldValue("wheels", [...wheels, active_wheel])
  }
  const isSelected = wheels.filter((wheel) => wheel.id === id).length > 0

  const formattedPrice = thousandSeparator(addVat(unit_price))
  const formattedDiscountPrice = discounted_price
    ? thousandSeparator(addVat(discounted_price))
    : null

  return (
    <div className={classes.wheelDetailContainer}>
      <Typography
        component="p"
        className={`${classes.smallTypoStyle} ${classes.marginBottom5}`}
      >
        {t("field.wheel_selector.active_wheel")}
      </Typography>
      <Typography className={classes.medTypoStyle}>
        {active_wheel.name}
      </Typography>
      {description && (
        <Typography
          variant="body1"
          className={`${classes.smallTypoStyle} ${classes.marginBottom5}`}
        >
          {description}
        </Typography>
      )}
      <Typography className={classes.medTypoStyle}>
        {formattedDiscountPrice ? formattedDiscountPrice : formattedPrice}
        {` THB`}
      </Typography>
      {formattedDiscountPrice && (
        <Typography
          variant="body2"
          component="span"
          className={`${classes.smallTypoStyle} ${classes.strikeStyle}`}
        >
          {formattedPrice} THB
        </Typography>
      )}
      {!isSelected && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.addButton}
          onClick={handleAddWheel}
        >
          {t("common.add_to_quote")}
        </Button>
      )}
    </div>
  )
}

export const WheelSummaryField = () => {
  const {
    values: { wheels },
    handleChange,
    setFieldValue,
  } = useFormikContext()
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAdd = ({ wheelIndex, quantity }) => {
    handleChange({
      target: {
        name: `wheels.${wheelIndex}.quantity`,
        value: quantity + 1,
      },
    })
  }

  const handleMinus = ({ wheelIndex, quantity, id }) => {
    if (quantity === 1) {
      const filteredWheels = wheels.filter((wheel) => wheel.id !== id)
      setFieldValue(`wheels`, filteredWheels)
    } else {
      handleChange({
        target: {
          name: `wheels.${wheelIndex}.quantity`,
          value: quantity - 1,
        },
      })
    }
  }

  return (
    <div className={classes.wheelSummaryContainer}>
      <Typography
        component="h3"
        className={`${classes.headerTypoStyle} ${classes.uppercaseTypoStyle}`}
      >
        {t("field.wheel_selector.set_price")}
      </Typography>
      {wheels.map((wheel, index) => {
        const {
          name,
          description,
          unit_price,
          quantity,
          id,
          discounted_price,
        } = wheel

        const formattedPrice = thousandSeparator(addVat(unit_price))
        const formattedDiscountPrice = discounted_price
          ? thousandSeparator(addVat(discounted_price))
          : null

        return (
          <div className={classes.detailContainer}>
            <Typography
              variant="body1"
              component="p"
              className={`${classes.medTypoStyle} ${classes.thinTypoStyle}`}
            >
              {name}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={classes.smallTypoStyle}
            >
              {description}
            </Typography>
            <div className={classes.detailFooterContainer}>
              <div className={classes.priceContainer}>
                <Typography
                  variant="body1"
                  component="span"
                  className={`${classes.medTypoStyle} ${classes.boldTypoStyle}`}
                >
                  {formattedDiscountPrice
                    ? formattedDiscountPrice
                    : formattedPrice}
                  {` THB`}
                </Typography>
                {formattedDiscountPrice && (
                  <Typography
                    variant="body2"
                    component="span"
                    className={`${classes.smallTypoStyle} ${classes.strikeStyle} ${classes.marginLeft10}`}
                  >
                    {formattedPrice} THB
                  </Typography>
                )}
              </div>
              <div className={classes.quantityContainer}>
                <IconButton
                  onClick={() =>
                    handleMinus({ wheelIndex: index, quantity, id })
                  }
                  color="primary"
                  className={classes.buttonPadding}
                >
                  <RemoveIcon />
                </IconButton>
                <Typography className={classes.itemNameStyle}>
                  {quantity}
                </Typography>
                <IconButton
                  onClick={() => handleAdd({ wheelIndex: index, quantity })}
                  color="primary"
                  className={classes.buttonPadding}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const TotalWheelsField = () => {
  const {
    values: { wheels },
  } = useFormikContext()
  const classes = useStyles()
  const { t } = useTranslation()
  const totalPrice = getTotalWheelPrice(wheels)
  return (
    <div className={classes.totalFieldContainer}>
      <Typography
        variant="body1"
        component="span"
        className={`${classes.smallTypoStyle} ${classes.boldTypoStyle}`}
      >
        {t("field.wheel_selector.total_price")}
      </Typography>
      <div>
        <Typography
          variant="body1"
          component="span"
          className={classes.priceTypoStyle}
        >
          {thousandSeparator(totalPrice)}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          className={classes.thbTypoStyle}
        >
          THB
        </Typography>
      </div>
    </div>
  )
}
