import React from "react"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import InputField from "../../../components/InputField"

import { corporateOptions } from "../../../constants"

import useStyles from "../style"

const CorporateHeader = ({ handleChange, values }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid container justify="center">
      <Grid item md={1} xs={false} />
      <Grid item md={10}>
        <div className={classes.headerContainer}>
          <Typography variant="h5" className={classes.headlineContainer}>
            Create Quotation For Corporate Sales
          </Typography>
          <Grid container>
            <Grid item md={6} xs={12}>
              <div className={classes.textFieldContainer}>
                <InputField
                  name="corporate_name"
                  value={values.corporate_name}
                  onChange={handleChange}
                  label={t("field.corporate_name.label")}
                  type="text"
                />
                <InputField
                  name="company_address"
                  value={values.company_address}
                  onChange={handleChange}
                  label={t("field.company_address.label")}
                  multiline
                  rows={5}
                  type="text"
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={classes.detailFieldContainer}>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <div className={classes.gridMarginRight}>
                      <InputField
                        name="contact_name"
                        value={values.contact_name}
                        onChange={handleChange}
                        label={t("field.contact_name.label")}
                        type="text"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={classes.gridMarginLeft}>
                      <InputField
                        name="contract_no"
                        value={values.contract_no}
                        onChange={handleChange}
                        label={t("field.contract_no.label")}
                        type="telephone"
                        placeholder="000 000 0000"
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <div className={classes.gridMarginRight}>
                      <InputField
                        name="account_no"
                        value={values.account_no}
                        onChange={handleChange}
                        label={t("field.account_no.label")}
                        type="text"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={classes.gridMarginLeft}>
                      <InputField
                        name="corporate_channel"
                        value={values.corporate_channel}
                        onChange={handleChange}
                        label={t("field.corporate_channel.label")}
                        type="select"
                        options={corporateOptions}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item md={1} xs={false} />
    </Grid>
  )
}

export default CorporateHeader
