import { makeStyles } from "@material-ui/core"

const HEADER_HEIGHT = 250

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    position: "relative",
    zIndex: -1,
    background: "#fff",
    [theme.breakpoints.up("md")]: {
      height: HEADER_HEIGHT,
      "&::before": {
        content: '""',
        position: "absolute",
        top: -200,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#f8f8f8",
        zIndex: -1,
      },
    },
  },
  headerContentContainer: {
    maxWidth: 1400,
    width: "auto",
    padding: "30px 60px 60px",
  },
  title: {
    fontSize: 24,
    lineHeight: 1.47,
  },
  main: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
      top: -HEADER_HEIGHT + 50,
    },
  },
  carImageContainer: {
    height: HEADER_HEIGHT,
  },
  formContainter: {
    margin: "0px 20px",
    position: "relative",
    minHeight: 800,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
  },
  headlineContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  checkicon: {
    borderRadius: 3,
    width: 25,
    height: 25,
    border: "1px solid black",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
  },
  fieldRoot: {
    marginBottom: theme.spacing(2.5),
  },
  fieldAlignment: {
    "& .MuiInput-underline": {
      "&:before": {
        border: "none",
      },
    },
    "& .MuiInputBase-input": {
      "&.Mui-disabled": {
        textAlign: "left",
      },
    },
  },
}))

export default useStyles
