import {
  calculateCarPrice,
  calculateFinanceAmount,
} from "../../calculate/utils/calculation"

export const getTotalDiscount = (values) => {
  const { additional_discount = 0, cars } = values
  const sum = cars.reduce((previousValue, car) => {
    return previousValue + car.discount
  }, additional_discount)
  return sum
}

export const getTotalCarPrice = (cars) => {
  const sum = cars.reduce((previousValue, car) => {
    const {
      retail_price,
      discount,
      bsi_price,
      bsi_inc,
      payment_method,
      quantity,
    } = car
    return (
      previousValue +
      calculateCarPrice(
        retail_price,
        discount,
        bsi_inc,
        bsi_price,
        quantity,
        payment_method
      )
    )
  }, 0)
  return sum
}

export const getVat = (values) => {
  const sum = getTotalCarPrice(values.cars)
  const { additional_discount = 0 } = values
  const vat = (sum - additional_discount) * 0.07
  return vat
}

export const getTotalMonthly = (cars) => {
  const sum = cars.reduce((previousValue, car) => {
    return previousValue + car.monthly_installment
  }, 0)
  return sum
}

export const getTotalFinance = (cars) => {
  const sum = cars.reduce((previousValue, car) => {
    const {
      retail_price,
      discount,
      insurance,
      down_payment,
      bsi_inc,
      bsi_price,
      quantity,
    } = car

    const totalAddOnAmount = quantity * bsi_price + insurance

    return (
      previousValue +
      calculateFinanceAmount({
        retail_price,
        down_payment,
        discount,
        addOnAmountInFinance: totalAddOnAmount,
        bsi_inc,
        bsi_price,
        quantity,
      })
    )
  }, 0)
  return sum
}
