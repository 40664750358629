import _ from "lodash"
import { Decimal } from "decimal.js"
import {
  calculateFinanceAmount,
  calculateResidual,
  calculateCarPrice,
  findRate,
  tvmCalculatePayment,
  calculateFutureValue,
} from "../../calculate/utils/calculation"

import {
  PAYMENT_METHOD,
  PAYMENT_TYPE,
  BSI_INCLUDE_TYPE,
} from "../../../constants"

const prepareSubmitQuotation = (values, { lang, user, findSFRate }) => {
  const submitValues = {
    company_id: user.company_id || 1,
    branch_id: user.branch_id || 1,
    user_id: user.legacy_id || 1,
    name: _.get(values, "name") || null,
    action: 1,
    email: user.email,
    phone: _.get(values, "telephone") || null,
    sale_name: _.get(values, "sale_consult_name", null),
    sale_contact: _.get(values, "sale_consult_no"),
    sale_phone: _.get(values, "sale_consult_no", null),
    account_no: _.get(values, "account_no"),
    status: 1,
    corporate_name: _.get(values, "corporate_name"),
    contact_name: _.get(values, "contact_name"),
    contact_no: _.get(values, "contract_no"),
    company_address: _.get(values, "company_address"),
    corporate_channel: _.get(values, "corporate_channel"),
    additional_discount: _.get(values, "additional_discount"),
    term: _.get(values, "term"),
    remark: _.get(values, "remark", ""),
    print_status: 1,
    export_file: 1,
    data: formattedData(values, findSFRate, lang),
  }

  return submitValues
}

const formattedData = (values, findSFRate, lang) => {
  const formatted = _.map(values.cars, (car) => {
    const {
      serie,
      model,
      retail_price,
      discount = 0,
      bsi_inc,
      bsi,
      bsi_price,
      insurance = 0,
      type_of_payment,
      payment_method,
      term,
      down_payment,
      balloon,
      boundary,
      margin_rate,
      campaigns,
      quantity,
    } = car

    const sf_rate = findSFRate(
      model,
      type_of_payment,
      payment_method,
      term,
      down_payment,
      balloon
    )
    const car_price = calculateCarPrice(
      retail_price,
      discount,
      bsi_inc,
      bsi_price,
      quantity
    )

    let suggested_finance = calculateFinanceAmount({
      retail_price,
      down_payment: 25,
      discount,
      insurance,
      bsi_inc,
      bsi_price,
    })

    const balloonLimit = _.get(boundary, `balloon.1`, 45)
    const hpbl_sf_rate = findSFRate(model, "2", "1", 60, 25, balloonLimit)

    const totalAddOnAmount = quantity * bsi_price + insurance

    const suggestedFinanceOption =
      payment_method === PAYMENT_METHOD.CASH
        ? {
            down: 25,
            down_amount: Decimal(0.01).times(25).times(car_price).toNumber(), // 0.01 * 25 * car_price
            balloon: balloonLimit,
            balloon_amount: Decimal(0.01)
              .times(balloonLimit)
              .times(car_price)
              .toNumber(), // 0.01 * balloonLimit * car_price
            finance: suggested_finance,
            term: 60,
            monthly: tvmCalculatePayment(
              findRate(hpbl_sf_rate, margin_rate),
              60,
              suggested_finance,
              calculateFutureValue(
                retail_price,
                car_price,
                boundary.balloon[1],
                type_of_payment
              ),
              0
            ),
          }
        : {}

    return {
      model: serie,
      tier: model,
      payment_type:
        payment_method === PAYMENT_METHOD.CASH ? `5` : type_of_payment,
      price: Decimal(retail_price)
        .plus(bsi_inc === BSI_INCLUDE_TYPE.CAR ? bsi_price : 0)
        .times(quantity)
        .toNumber(), // retail_price + BSI (If inc. in Car) then times quantity
      discount: discount,
      option: 0,
      term: term,
      down: down_payment,
      down_amount: Decimal(0.01)
        .times(down_payment)
        .times(car_price)
        .toNumber(), // 0.01 * down_payment * car_price
      balloon: balloon,
      balloon_amount: Decimal(0.01)
        .times(balloon)
        .times(
          type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE
            ? retail_price * quantity
            : car_price
        )
        .toNumber(), //0.01 * balloon * (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE ? retail_price : car_price)
      deposit: balloon,
      deposit_amount: Decimal(0.01).times(balloon).times(car_price).toNumber(), // 0.01 * balloon * car_price
      finance: calculateFinanceAmount({
        retail_price,
        down_payment,
        discount,
        addOnAmountInFinance: totalAddOnAmount,
        bsi_inc,
        bsi_price,
        quantity,
      }),
      monthly: car.monthly_installment,
      residual: calculateResidual(car_price, down_payment, balloon),
      margin: car.margin_rate,
      customer_rate: findRate(sf_rate, car.margin_rate),
      flate_rate: sf_rate,
      dealer_rebate: car.rebate,
      rebate_sharing: 0,
      insurance: insurance,
      remark: car.remark,
      raw: "raw",
      bsi: Decimal(bsi_price).times(quantity).toNumber(),
      include: bsi_inc,
      lang: lang,
      bsi_year: bsi,
      status: 1,
      campaign_list: _.map(campaigns, _.toNumber),
      quantity,
      ...suggestedFinanceOption,
    }
  })
  return formatted
}

export default prepareSubmitQuotation
