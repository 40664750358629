/* eslint-disable  react/prop-types */
import React from "react"
import _ from "lodash"
import { Decimal } from "decimal.js"
import dayjs from "dayjs"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Switch from "@material-ui/core/Switch"
import Tooltip from "@material-ui/core/Tooltip"
import Checkbox from "@material-ui/core/Checkbox"
import Box from "@material-ui/core/Box"

import CheckIcon from "@material-ui/icons/Check"
import IconButton from "@material-ui/core/IconButton"
import CheckRoundedIcon from "@material-ui/icons/CheckRounded"
import DeleteIcon from "@material-ui/icons/Delete"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"

import { makeStyles } from "@material-ui/core/styles"

import { NumericFormat, PatternFormat } from "react-number-format"

import { KeyboardDatePicker } from "@material-ui/pickers"
import { thousandSeparator } from "./utils"

// import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate"

const useStyles = makeStyles((theme) => ({
  fieldRoot: {
    marginBottom: theme.spacing(2.5),
    display: (props) => (props.hidden ? "none" : "block"),
  },
  shortBtmMargin: {
    marginBottom: theme.spacing(0.5),
  },
  lockableButtonRoot: {
    display: (props) => (props.hidden ? "none" : "block"),
    position: "relative",
    paddingLeft: 40,
    marginBottom: theme.spacing(2),
  },
  lockButtonStyle: {
    position: "absolute",
    width: 40,
    height: 40,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
  lockIconButton: {
    width: 40,
    height: 40,
  },
  switchFormControl: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  beforeInput: {
    color: "black",
  },
  adornmentPadding: {
    "& .MuiInputAdornment-root": {
      padding: `6px 0 7px`, // Fix vertical alignment
    },
    // paddingRight: "13px", // ตอนนี้ Design ยังไม่มี แต่อยากจะนำเสนอ เพื่อให้ text-align ด้านขวา ตรงกัน
  },
  switchRoot: {
    width: 60,
    height: 30,
    padding: 0,
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(30px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "primary",
        opacity: 1,
        border: "none",
      },
    },
  },
  switchThumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 20,
    backgroundColor: "#d6d6d6",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  // ButtonGroup
  buttonGroupDefault: {
    textTransform: "capitalize",
  },
  buttonGroupActive: {
    textTransform: "capitalize",
    borderColor: theme.palette.primary.main,
  },
  lockedField: {
    backgroundColor: "#f8f8f8",
  },
  priceSummaryStyle: {
    marginTop: theme.spacing(1),
    "& .MuiInputBase-root": {
      backgroundColor: "#fff",
    },
    "& .MuiInput-underline": {
      "&:before": {
        border: "none",
      },
    },
    "& .MuiInputBase-input": {
      textAlign: "end",
    },
  },
  numberBtnContainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid rgba(0,0,0,0.28)",
    borderRadius: "3px",
    "& .MuiInput-underline": {
      "&:before": {
        border: "none",
      },
    },
    "& .MuiButtonBase-root": {
      padding: theme.spacing(0.8),
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      paddingTop: theme.spacing(0.95),
    },
  },
  helperTextStyle: {
    color: "#f44336",
  },
  percentWithPriceStyle: {
    fontSize: "0.75rem",
    marginLeft: theme.spacing(1.5),
  },
  fieldPriceReadOnly: {
    '& input[type="text"]': {
      textAlign: "right",
    },
  },
  quantityField: {
    "&.MuiInputBase-root": {
      backgroundColor: "#fff",
    },
  },
  quantitySummary: {
    "&.MuiInput-underline.Mui-disabled:before": {
      border: "none",
    },
    '& input[type="quantity_total"]': {
      textAlign: "right",
    },
  },
  boldLabel: {
    fontWeight: 700,
  },
  checkicon: {
    borderRadius: 3,
    width: 25,
    height: 25,
    border: "1px solid black",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    "&.selected": {
      border: "2px solid #1C69D4",
    },
    "&.error": {
      border: "2px solid red",
      borderColor: theme.palette.error.main,
    },
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
  },
  checkboxItem: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    "& p": {
      marginTop: theme.spacing(1),
    },
  },
  boldPriceStyle: {
    fontWeight: 700,
    fontSize: "14px",
  },
  thinPriceStyle: {
    fontWeight: 300,
    fontSize: "12px",
    margin: "0px 4px",
  },
  fieldFlexRoot: {
    display: (props) => (props.hidden ? "none" : "flex"),
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: theme.spacing(2.5),
  },
  menuItemLineBreakStyle: {
    whiteSpace: "normal",
  },
}))

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        })
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  )
}

const NumberFormatPercent = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        })
      }}
      thousandSeparator
      isNumericString
      decimalScale={4}
    />
  )
}

const NumberSummaryFormat = (props) => {
  const { inputRef, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator
      decimalScale="2"
      fixedDecimalScale
    />
  )
}

const TelephoneFormatCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      format="###-###-####"
      mark="_"
      // Thailand mobile phone format
    />
  )
}

const PercentWithPrice = (props) => {
  const { name, label, lockable, locked, disabled, ...rest } = props
  const {
    value: percent,
    fullValue = 100,
    onChange,
    onBlur,
    onFocus,
    // ...remainingProps
  } = rest
  const showBlankOnZero = true
  const previewInputRef = React.useRef()
  const numValue = percent * 0.01 * fullValue
  const classes = useStyles()

  const onChangePercent = (e) => {
    const value = e.target.value
    onChange({
      target: {
        name: e.target.name,
        value: Number(value),
      },
    })
  }

  const onPriceInputBlur = () => {
    const value = previewInputRef.current.value
    const _numValue = parseFloat(value.replace(/,/g, ""))
    const percentValue = new Decimal(_numValue)
      .times(100)
      .div(fullValue)
      .toNumber()

    onChange({
      target: {
        name: name,
        value: percentValue,
      },
    })
  }
  return (
    <FormControl component="fieldset" fullWidth>
      {lockable ? null : <FormLabel component="legend">{label}</FormLabel>}
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            fullWidth
            type="number"
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              classes: { disabled: classes.lockedField },
            }}
            name={name}
            // value={percent}
            value={percent === 0 && showBlankOnZero ? `` : percent}
            onChange={onChangePercent}
            disabled={disabled || (locked && lockable)}
            error={!!props.helperText}
            onBlur={onBlur}
            onFocus={onFocus}
            // {...remainingProps}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled={disabled || (locked && lockable)}
            InputProps={{
              endAdornment: <InputAdornment position="end">THB</InputAdornment>,
              inputComponent: NumberFormatCustom,
              classes: { disabled: classes.lockedField },
            }}
            // {...remainingProps}
            value={numValue}
            inputRef={previewInputRef}
            error={!!props.helperText}
            onBlur={onPriceInputBlur}
            onFocus={onFocus}
          />
        </Grid>
        {props.helperText ? (
          <Typography
            className={`${classes.percentWithPriceStyle} ${classes.helperTextStyle}`}
            component="span"
          >
            {props.helperText}
          </Typography>
        ) : null}
      </Grid>
    </FormControl>
  )
}

const CheckBoxesGroup = (props) => {
  const { name, onChange, label, options } = props
  const classes = useStyles()
  const isPartial = _.some(options, ["value", true])
  const isAllCheck = _.every(options, ["value", true])

  const handleCheckAll = () => {
    _.map(options, (opt) => {
      onChange({
        target: {
          name: opt.name,
          value: !isAllCheck,
        },
      })
    })
  }

  return (
    <FormControl component="fieldset" fullWidth>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            icon={<span className={classes.checkicon} />}
            checkedIcon={
              <CheckIcon
                className={`${classes.checkicon} ${
                  isAllCheck ? "selected" : ""
                }`}
              />
            }
            indeterminateIcon={
              <RemoveIcon
                className={`${classes.checkicon} ${
                  isPartial ? "selected" : ""
                }`}
              />
            }
            indeterminate={isPartial && !isAllCheck}
            checked={isAllCheck}
            onChange={handleCheckAll}
          />
        }
      />
      <Box className={classes.checkboxContainer}>
        {options.map((opt) => (
          <div className={classes.checkboxItem} key={`checkbox-${opt.name}`}>
            <Checkbox
              checked={opt.value}
              icon={
                <span
                  className={`
                    ${classes.checkicon}
                    ${opt.required && !opt.value ? "error" : ""}
                    `}
                />
              }
              checkedIcon={
                <CheckIcon
                  className={`
                    ${classes.checkicon}
                    ${opt.value ? "selected" : ""}
                  `}
                />
              }
              name={opt.name}
              onChange={onChange}
            />
            <Typography variant="body1">{opt.label}</Typography>
          </div>
        ))}
      </Box>
    </FormControl>
  )
}

const QuantityWithTotal = (props) => {
  const { name, label, value: quantity, onChange, totalPrice, ...rest } = props
  const classes = useStyles()
  const handleAdd = () => {
    onChange({
      target: {
        name: name,
        value: quantity + 1,
      },
    })
  }
  const handleMinus = () => {
    onChange({
      target: {
        name: name,
        value: quantity - 1,
      },
    })
  }

  const handleInput = (e) => {
    const value = e.target.value
    onChange({
      target: {
        name: e.target.name,
        value: Number(value),
      },
    })
  }

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={classes.numberBtnContainer}>
            <IconButton
              onClick={handleMinus}
              color="secondary"
              disabled={quantity <= 1}
            >
              <RemoveIcon />
            </IconButton>
            <TextField
              InputLabelProps={{ shrink: true }}
              name={name}
              fullWidth
              size="medium"
              value={quantity}
              type="number"
              onChange={handleInput}
              disabled
              InputProps={{
                classes: { root: classes.quantityField },
              }}
            />
            <IconButton onClick={handleAdd} color="secondary">
              <AddIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            fullWidth
            className={classes.adornmentPadding}
            size="large"
            value={totalPrice}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: <InputAdornment position="end">THB</InputAdornment>,
              classes: {
                root: `${classes.quantityField} ${classes.quantitySummary}`,
              },
              inputComponent: NumberFormatCustom,
            }}
            disabled
            name={name}
            {...rest}
          />
        </Grid>
      </Grid>
    </FormControl>
  )
}

const MUIField = React.memo((props) => {
  const {
    type,
    name,
    label,
    placeholder,
    options = [],
    adornment,
    hidden,
    locked,
    beforeInput,
    lockable,
    isBold,
    isShort,
    maxLength,
    ...rest
  } = props

  // useWhyDidYouUpdate(`InputField name = ${name}`, props)

  const classes = useStyles({ hidden })
  switch (type) {
    case "switch": {
      const { value, onChange, disabled } = props
      return (
        <div className={classes.fieldRoot}>
          <FormControl
            className={classes.switchFormControl}
            component="fieldset"
            fullWidth
          >
            <Typography component="h3">{label}</Typography>
            <FormControlLabel
              name={name}
              control={
                <Switch
                  color="primary"
                  checked={value}
                  disabled={disabled}
                  classes={{
                    root: classes.switchRoot,
                    switchBase: classes.switchBase,
                    thumb: classes.switchThumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  onChange={(e) => {
                    onChange({
                      target: {
                        name: props.name,
                        value: !value,
                      },
                    })
                  }}
                />
              }
            />
          </FormControl>
        </div>
      )
    }

    case "reset": {
      const { reset } = props
      return (
        <div className={classes.fieldRoot}>
          <FormControl
            className={classes.switchFormControl}
            component="fieldset"
            fullWidth
          >
            <Typography component="h3">{label}</Typography>
            <FormControlLabel
              name={name}
              control={
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    reset()
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          </FormControl>
        </div>
      )
    }

    case "radio":
      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup name={name} {..._.omit(rest, ["error", "helperText"])}>
              {options.map((opt) => (
                <FormControlLabel
                  key={opt.value}
                  value={opt.value}
                  disabled={rest.disabled}
                  control={<Radio color="primary" />}
                  label={opt.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )

    case "button_group": {
      const { onChange } = props
      return (
        <div className={classes.fieldRoot}>
          <FormControl fullWidth component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <ButtonGroup variant="outlined" size="large" name={name} fullWidth>
              {options.map((opt) => (
                <Button
                  key={opt.value}
                  value={opt.value}
                  color={props.value === opt.value ? "primary" : "secondary"}
                  className={
                    props.value === opt.value
                      ? classes.buttonGroupActive
                      : classes.buttonGroupDefault
                  }
                  startIcon={
                    props.value === opt.value ? <CheckRoundedIcon /> : null
                  }
                  onClick={(e) => {
                    onChange({
                      target: {
                        name: props.name,
                        value: opt.value,
                      },
                    })
                  }}
                >
                  {opt.label}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        </div>
      )
    }

    case "select": {
      const { optionProps, noBottomGap = false } = rest
      return (
        <div className={noBottomGap ? null : classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <TextField
              variant="outlined"
              select
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: false,
              }}
              name={name}
              {...rest}
            >
              {options.map((opt) => (
                <MenuItem
                  key={opt.value}
                  value={opt.value}
                  disabled={opt.disabled}
                  className={classes.menuItemLineBreakStyle}
                  {...(typeof optionProps === "function"
                    ? optionProps(opt)
                    : {})}
                >
                  {opt.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
      )
    }

    case "percent_with_preview": {
      const flexibleFieldStyle = lockable
        ? classes.lockableButtonRoot
        : classes.fieldRoot
      return (
        <div className={flexibleFieldStyle}>
          <PercentWithPrice {...props} />
        </div>
      )
    }

    case "number": {
      const { value, showBlankOnZero = false, noBottomGap = false } = props
      const numberInputProps = {
        startAdornment: adornment?.start ? (
          <InputAdornment position="start">{adornment.start}</InputAdornment>
        ) : null,
        endAdornment: adornment?.end ? (
          <InputAdornment position="end">{adornment.end}</InputAdornment>
        ) : null,
        inputComponent: NumberFormatCustom,
      }
      const flexibleFieldStyle = noBottomGap
        ? null
        : lockable
        ? classes.lockableButtonRoot
        : classes.fieldRoot
      const disabledStyle = locked ? classes.lockedField : null

      return (
        <div className={flexibleFieldStyle}>
          <FormControl component="fieldset" fullWidth>
            {lockable ? null : (
              <FormLabel component="legend">{label}</FormLabel>
            )}
            {beforeInput && (
              <Typography component="div" className={classes.beforeInput}>
                {beforeInput}
              </Typography>
            )}
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...numberInputProps,
                disabled: locked || props.disabled,
                classes: { disabled: disabledStyle },
              }}
              name={name}
              disabled={locked && lockable}
              {..._.omit(rest, ["showBlankOnZero"])}
              value={value === 0 && showBlankOnZero ? `` : value}
            />
            {props.guideline ? (
              <Typography
                className={classes.percentWithPriceStyle}
                component="span"
              >
                {props.guideline}
              </Typography>
            ) : null}
          </FormControl>
        </div>
      )
    }

    case "percent": {
      const numberInputProps = {
        startAdornment: adornment?.start ? (
          <InputAdornment position="start">{adornment.start}</InputAdornment>
        ) : null,
        endAdornment: adornment?.end ? (
          <InputAdornment position="end">{adornment.end}</InputAdornment>
        ) : null,
        inputComponent: NumberFormatPercent,
      }
      const flexibleFieldStyle = lockable
        ? classes.lockableButtonRoot
        : classes.fieldRoot
      const disabledStyle = locked ? classes.lockedField : null
      return (
        <div className={flexibleFieldStyle}>
          <FormControl component="fieldset" fullWidth>
            {lockable ? null : (
              <FormLabel component="legend">{label}</FormLabel>
            )}
            {beforeInput && (
              <Typography component="div" className={classes.beforeInput}>
                {beforeInput}
              </Typography>
            )}
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...numberInputProps,
                disabled: locked || props.disabled,
                classes: { disabled: disabledStyle },
              }}
              name={name}
              disabled={locked && lockable}
              {..._.omit(rest, ["decimalScale"])}
            />
          </FormControl>
        </div>
      )
    }

    case "price_readonly": {
      const priceInputProps = {
        startAdornment: (
          <InputAdornment position="start">{label}</InputAdornment>
        ),
        endAdornment: adornment?.end ? (
          <InputAdornment position="end">{adornment.end}</InputAdornment>
        ) : null,
        inputComponent: NumberFormatCustom,
      }
      return (
        <div className={`${classes.fieldRoot} ${classes.fieldPriceReadOnly}`}>
          <TextField
            variant="standard"
            fullWidth
            className={classes.adornmentPadding}
            size="small"
            InputLabelProps={{ shrink: false }}
            InputProps={priceInputProps}
            disabled
            name={name}
            {...rest}
          />
          {props.guideline ? (
            <Typography
              className={classes.percentWithPriceStyle}
              component="span"
            >
              {props.guideline}
            </Typography>
          ) : null}
        </div>
      )
    }

    case "price_summary": {
      const priceInputProps = {
        startAdornment: (
          <InputAdornment position="start">{label}</InputAdornment>
        ),
        endAdornment: adornment?.end ? (
          <InputAdornment position="end">{adornment.end}</InputAdornment>
        ) : null,
        inputComponent: NumberSummaryFormat,
      }
      return (
        <div>
          <TextField
            variant="standard"
            fullWidth
            className={`${classes.adornmentPadding} ${classes.priceSummaryStyle}`}
            size="small"
            InputLabelProps={{ shrink: false }}
            InputProps={priceInputProps}
            disabled
            name={name}
            {...rest}
          />
        </div>
      )
    }

    case "quantity": {
      const { value, onChange, name /*...rest*/ } = props
      const handleAdd = () => {
        onChange({
          target: {
            name: name,
            value: value + 1,
          },
        })
      }
      const handleMinus = () => {
        onChange({
          target: {
            name: name,
            value: value - 1,
          },
        })
      }

      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <div className={classes.numberBtnContainer}>
              <IconButton
                onClick={handleMinus}
                color="primary"
                disabled={!value}
              >
                <RemoveIcon />
              </IconButton>
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                name={name}
                fullWidth
                size="medium"
                value={value}
                type="number"
              />
              <IconButton onClick={handleAdd} color="primary">
                <AddIcon />
              </IconButton>
            </div>
          </FormControl>
        </div>
      )
    }

    case "quantity_total": {
      return (
        <div className={classes.fieldRoot}>
          <QuantityWithTotal {...props} />
        </div>
      )
    }

    case "checkboxes": {
      return (
        <div className={classes.fieldRoot}>
          <CheckBoxesGroup {...props} />
        </div>
      )
    }

    case "telephone": {
      const telephoneInputProps = { inputComponent: TelephoneFormatCustom }
      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel
              component="legend"
              className={isBold && classes.boldLabel}
            >
              {label}
            </FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder={placeholder}
              InputProps={telephoneInputProps}
              name={name}
              {...rest}
            />
            {props.guideline ? (
              <Typography
                className={`${classes.percentWithPriceStyle} ${classes.helperTextStyle}`}
                component="span"
              >
                {props.guideline}
              </Typography>
            ) : null}
          </FormControl>
        </div>
      )
    }

    case "total_price": {
      const { value } = props
      const formattedPrice = value ? thousandSeparator(value) : 0
      return (
        <div className={classes.fieldFlexRoot}>
          {label && (
            <Typography component="span" className={classes.thinPriceStyle}>
              {label}
            </Typography>
          )}
          <Typography
            component="span"
            variant="body1"
            className={classes.boldPriceStyle}
          >
            {formattedPrice} THB
          </Typography>
        </div>
      )
    }

    case "date":
      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              inputVariant="outlined"
              size="small"
              minDate={dayjs().format("YYYY-MM-DD")}
              InputProps={{
                style: { paddingRight: 0 },
              }}
              {...rest}
              onChange={(e) => {
                rest.onChange({
                  type: "change",
                  target: {
                    name,
                    value: e.format("YYYY-MM-DD"),
                  },
                })
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </FormControl>
        </div>
      )

    case "text":
    default:
      return (
        <div
          className={`${classes.fieldRoot} ${
            isShort && classes.shortBtmMargin
          }`}
        >
          <FormControl component="fieldset" fullWidth>
            <FormLabel
              component="legend"
              className={isBold && classes.boldLabel}
            >
              {label}
            </FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              type={type}
              placeholder={placeholder}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: adornment?.start ? (
                  <InputAdornment position="start">
                    {adornment.start}
                  </InputAdornment>
                ) : null,
                endAdornment: adornment?.end ? (
                  <InputAdornment position="end">
                    {adornment.end}
                  </InputAdornment>
                ) : null,
              }}
              inputProps={{ maxLength: maxLength }}
              name={name}
              {...rest}
            />
            {props.guideline ? (
              <Typography
                className={classes.percentWithPriceStyle}
                component="span"
              >
                {props.guideline}
              </Typography>
            ) : null}
          </FormControl>
        </div>
      )
  } // end Switch
})

export const LockableButton = ({
  lockable,
  locked,
  onToggleLock,
  disabled,
  hidden,
  ...rest
}) => {
  const classes = useStyles()
  return lockable && !hidden ? (
    <div className={classes.lockButtonStyle}>
      <Tooltip
        title={
          disabled ? `Can't unlock more than 2 fields` : `Click to toggle lock`
        }
        placement="top"
      >
        <IconButton
          onClick={onToggleLock}
          className={classes.lockIconButton}
          size="small"
          {...rest}
        >
          {locked ? <LockIcon /> : <LockOpenIcon />}
        </IconButton>
      </Tooltip>
    </div>
  ) : null
}

export const FieldLabel = ({ lockable, label, ...rest }) => {
  return lockable ? (
    <FormLabel component="legend" {...rest}>
      {label}
    </FormLabel>
  ) : null
}

export default MUIField
