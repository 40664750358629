import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  fieldRoot: {
    marginBottom: theme.spacing(2.5),
    padding: "4px 0px",
  },
  marginBottom10: {
    marginBottom: theme.spacing(1.5),
  },
  marginBottom5: {
    marginBottom: theme.spacing(0.5),
  },
  imgContainer: {
    marginBottom: theme.spacing(1),
    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "12px",
    },
  },
  smallTypoStyle: {
    fontSize: "12px",
    lineHeight: "15.5px",
    fontWeight: 300,
  },
  medTypoStyle: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  wheelDetailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  addButton: {
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
  wheelSummaryContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  headerTypoStyle: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    marginBottom: theme.spacing(1),
  },
  thinTypoStyle: {
    fontWeight: 300,
  },
  boldTypoStyle: {
    fontWeight: 700,
  },
  detailContainer: {
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    width: "100%",
  },
  detailFooterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  quantityContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  totalFieldContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderTop: "2px solid rgba(0, 0, 0, 0.1)",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
  priceTypoStyle: {
    fontSize: "32px",
    lineHeight: "36px",
  },
  thbTypoStyle: {
    fontSize: "18px",
    lineHeight: "36px",
    marginLeft: theme.spacing(1),
  },
  strikeStyle: {
    textDecorationLine: "line-through",
  },
  marginLeft10: {
    marginLeft: theme.spacing(1),
  },
  borderHighlight: {
    boxSizing: "border-box",
    border: "5px solid #1c69d4",
    borderRadius: "12px",
  },
  uppercaseTypoStyle: {
    textTransform: "uppercase",
  },
}))

export default useStyles
