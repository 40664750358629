import React from "react"
import { Formik, Form } from "formik"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import { useParams, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useDataprovider from "../../hooks/useDataprovider"
import useLastVisitBrand from "../../hooks/useLastVisitBrand"
import { Helmet } from "react-helmet"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../../styles/theme"

import {
  SeriesField,
  SelectWheelField,
  WheelDetailField,
  WheelSummaryField,
  TotalWheelsField,
} from "./components/Fields"
import { prepareExportData } from "./utils"

import { capitalizeBrand } from "../utils"
import useStyles from "./style"

const WheelSelector = () => {
  const classes = useStyles()
  const { setBrand } = useDataprovider()
  const [lastVisitBrand, setLastVisitBrand] = useLastVisitBrand()
  const params = useParams()
  const history = useHistory()
  const { brand } = params
  const cappedBrand = capitalizeBrand(brand)
  const { t } = useTranslation()

  const onSubmit = (values) => {
    console.log("submitting", values)
    const { output, seriesId, wheels } = values
    const exportParams = prepareExportData({ seriesId, wheels })
    const destinationPath = output === "new_car" ? "calculate" : "accessory"
    window.open(`/${destinationPath}/${brand}?${exportParams}`, "_self")
  }

  React.useEffect(() => {
    if (brand) {
      setBrand(brand)
      setLastVisitBrand(brand) // save to localStorage
    } else {
      history.push(`/wheel/${lastVisitBrand}`)
    }
  }, [brand, setBrand])

  // Disable enter key from submit form
  const onKeyDown = (keyEvent) => {
    const target = keyEvent.target
    const tagName = target.tagName
    if (
      tagName !== "TEXTAREA" &&
      (keyEvent.charCode || keyEvent.keyCode) === 13
    ) {
      keyEvent.preventDefault()
      keyEvent.target.blur()
    }
  }

  const wheelInitialValues = {
    name: "",
    description: "",
    id: null,
    price: 0,
    discounted_price: null,
    quantity: 1,
  }

  const initialValues = {
    series: "",
    seriesId: null,
    default_image_url: "",
    selected_image_url: "",
    active_wheel: wheelInitialValues,
    wheels: [],
  }

  return (
    <ThemeProvider theme={theme["bmw"]}>
      <div className={classes.root}>
        <Helmet>
          <title>
            {cappedBrand} Wheel Selector | DSK | BMW Leasing Thailand
          </title>
        </Helmet>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => {
            const {
              series,
              active_wheel,
              wheels,
              default_image_url,
              selected_image_url,
            } = values
            return (
              <Form noValidate onKeyDown={onKeyDown}>
                <Grid
                  container
                  spacing={2}
                  alignItems={series ? "flex-start" : "center"}
                >
                  <Grid item md={8} xs={12}>
                    <Typography
                      variant="subtitle2"
                      component="h2"
                      className={`${classes.title} ${classes.uppercaseTypoStyle}`}
                    >
                      {t("nav.wheel")}
                    </Typography>
                    <div className={classes.seriesContainer}>
                      <Typography component="span" className={classes.subtitle}>
                        {series ? "BMW" : t("field.wheel_selector.placeholder")}
                      </Typography>
                      {series && (
                        <Typography
                          component="span"
                          className={`${classes.subtitle} ${classes.bold}`}
                        >
                          {series}
                        </Typography>
                      )}
                    </div>
                    <div className={classes.imgSliderContainer}>
                      <div className={classes.imgRatio}>
                        {series && active_wheel.id ? (
                          <div className={classes.imgContainer}>
                            <ReactCompareSlider
                              //stye the threshold (line and button)
                              handle={
                                <div
                                  style={{
                                    width: "1px",
                                    height: "100%",
                                    display: "grid",
                                    backgroundColor: "white",
                                    placeContent: "center",
                                    boxShadow: "rgb(0 0 0 / 35%) 0px 0px 3px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                      boxShadow: "rgb(0 0 0 / 35%) 0px 0px 7px",
                                    }}
                                  />
                                </div>
                              }
                              itemOne={
                                <ReactCompareSliderImage
                                  src={default_image_url}
                                  alt="default"
                                />
                              }
                              itemTwo={
                                <ReactCompareSliderImage
                                  src={selected_image_url}
                                  alt="Wheeled"
                                />
                              }
                              style={{
                                position: "absolute",
                                width: "100%",
                                top: 0,
                                left: 0,
                                overflow: "hidden",
                                height: "calc(60vw * 9/16)", // to force React img slider to 16.9 ratio
                              }}
                            />
                          </div>
                        ) : series ? (
                          <img
                            src={default_image_url}
                            alt="default_wheels"
                            style={{
                              height: "calc(60vw * 9/16)", // to force React img slider to 16.9 ratio
                            }}
                          />
                        ) : (
                          <img
                            src={`/images/placeholder-bmw.png`}
                            alt="Placeholder"
                          />
                        )}
                      </div>
                    </div>
                    {series && active_wheel.id && <WheelDetailField />}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <SeriesField name="series" />
                    {series && (
                      <React.Fragment>
                        <SelectWheelField />
                        {series && wheels.length > 0 && (
                          <React.Fragment>
                            <WheelSummaryField />
                            <TotalWheelsField />
                            <Button
                              color="primary"
                              variant="contained"
                              size="large"
                              type="submit"
                              fullWidth
                              className={classes.marginVertical10}
                              id="createAccessoryQuote"
                              onClick={() =>
                                setFieldValue("output", "accessory")
                              }
                            >
                              {t("field.wheel_selector.create_accessory")}
                            </Button>
                            <Button
                              color="primary"
                              variant="contained"
                              size="large"
                              type="submit"
                              fullWidth
                              id="createCarQuote"
                              onClick={() => setFieldValue("output", "new_car")}
                            >
                              {t("field.wheel_selector.create_new_car")}
                            </Button>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </div>
    </ThemeProvider>
  )
}

export default WheelSelector
