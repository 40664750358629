import {
  useRef,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react"
import * as Sentry from "@sentry/react"

import { useLocalStorage } from "react-use"
import jwt_decode from "jwt-decode"
import _ from "lodash"
import config from "../config"

export const isTokenValid = (token) => {
  try {
    const decoded = jwt_decode(token)
    // console.log(`token expire in ${Math.round(decoded.exp - Date.now() / 1000) / 60}m`)
    if (decoded.exp > Date.now() / 1000) return true
    return false
  } catch (err) {
    return false
  }
}

const getTokenByRefreshToken = async (refresh_token) => {
  try {
    if (!refresh_token) return null

    const url = config.authEndpoint
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${config.authAuthorization}`,
      },
    }
    const data = {
      grant_type: "refresh_token",
      client_id: config.authClientId,
      refresh_token,
    }
    const body = _.map(data, (v, k) => `${k}=${v}`).join("&")

    const res = await fetch(`${url}/oauth2/token`, {
      method: "POST",
      ...options,
      body,
    })
    const json = await res.json()
    if (json.error) throw new Error(json.error)
    const { access_token, id_token } = json
    return { access_token, id_token }
  } catch (err) {
    console.log(err)
    switch (err.message) {
      case "invalid_grant": {
        // Code has already been used.
        return null
      }
      default:
        console.log(err.message)
        return null
    }
  }
}

const getTokenByCode = async (code) => {
  try {
    if (!code) return null

    const url = config.authEndpoint
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${config.authAuthorization}`,
      },
    }
    const data = {
      code,
      grant_type: "authorization_code",
      client_id: config.authClientId,
      redirect_uri: window.location.origin.replace(/\/$/, ""), // config.authRedirectURI,
      scope: "dsk/api.read dsk/api.write profile aws.cognito.signin.user.admin",
    }
    const body = _.map(data, (v, k) => `${k}=${v}`).join("&")

    const res = await fetch(`${url}/oauth2/token`, {
      method: "POST",
      ...options,
      body,
    })
    const json = await res.json()
    if (json.error) throw new Error(json.error)
    const { access_token, id_token, refresh_token } = json
    return { access_token, id_token, refresh_token }
  } catch (err) {
    console.log(err)
    switch (err.message) {
      case "invalid_grant": {
        // Code has already been used.
        return null
      }
      default:
        console.log(err.message)
        return null
    }
  }
}

const initialState = {
  code: null,
  user: null,
  token: null,
  idToken: null,
  refreshToken: null,
  logout: null,
}

const AuthProviderContext = createContext(initialState)

export const AuthProvider = (props) => {
  const usePopup = false
  const loginRedirect = props.loginRedirect || `/`
  const logoutRedirect = props.logoutRedirect || `/login`

  const loginWindowRef = useRef()
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get("code")

  const [token, setToken, removeToken] = useLocalStorage("bmw-dsk:token")
  const [idToken, setIdToken, removeIdToken] = useLocalStorage(
    "bmw-dsk:idtoken"
  )
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage(
    "bmw-dsk:refreshtoken"
  )

  let timer = useRef()

  const afterSignIn = () => {
    if (usePopup) {
      window.close()
    } else {
      window.location = "/"
    }
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (code) {
      if (!token) {
        getTokenByCode(code).then(
          ({ access_token, id_token, refresh_token, ...rest }) => {
            console.log({ access_token, id_token, refresh_token, rest })
            if (access_token) {
              setToken(access_token)
              setIdToken(id_token)
              setRefreshToken(refresh_token)
              // afterSignIn()
            }
          }
        )
      } else {
        afterSignIn()
      }
    }
  }, [code, token, setToken])
  /* eslint-enable react-hooks/exhaustive-deps */

  const signInWithPopup = () => {
    console.log("signInWithPopup")
    const data = {
      client_id: config.authClientId,
      response_type: "code",
      redirect_uri: `${window.location.origin.replace(/\/$/, "")}`,
    }

    const qs = _.map(data, (v, k) => `${k}=${v}`).join("&")

    const url = `${config.authEndpoint}/login?${qs}&scope=dsk/api.write+dsk/api.read+email+openid+profile+aws.cognito.signin.user.admin`

    if (usePopup) {
      if (window && config.authEndpoint) {
        const width = 400
        const height = 660
        const y = window.outerHeight / 2 + window.screenY - height / 2
        const x = window.outerWidth / 2 + window.screenX - width / 2
        loginWindowRef.current = window.open(
          url,
          "popup",
          `width=${width},height=${height},top=${y},left=${x}`
        )
        timer.current = setInterval(checkWindowClose, 500)
      }
    } else {
      window.location = url
    }
  }

  // Listen to when child window close, then redirect
  const checkWindowClose = () => {
    if (loginWindowRef.current && loginWindowRef.current.closed) {
      window.location = loginRedirect
      clearInterval(timer.current)
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const logout = useCallback(() => {
    console.log(`user logout`)
    removeToken()
    removeIdToken()
    removeRefreshToken()
    window.location = logoutRedirect
  }, [logoutRedirect, removeToken])
  /* eslint-enable react-hooks/exhaustive-deps */

  // If token is not valid, then run logout flow
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isTokenValid(token) && refreshToken) {
      // Use refresh token to get new token
      try {
        console.log(`access_token expire, try get new token from refresh token`)
        getTokenByRefreshToken(refreshToken).then((res) => {
          if (res) {
            const { id_token, access_token } = res
            // console.log(`GotToken by refreshToken`, { id_token, access_token })
            setToken(access_token)
            setIdToken(id_token)
          } else {
            logout()
          }
        })
      } catch (err) {
        Sentry.captureMessage("Fail to renew access_token")
        logout()
      }
    }
  }, [isTokenValid(token), refreshToken, logout])
  /* eslint-enable react-hooks/exhaustive-deps */

  const getUserInfo = _.memoize((token) => {
    try {
      const decoded = jwt_decode(token)
      return {
        id: decoded.sub,
        name: decoded.name,
        given_name: decoded.given_name,
        family_name: decoded.family_name,
        email: decoded.email,
        email_verified: decoded.email_verified || true,
        phone_number: decoded.phone_number,
        username: decoded.preferred_username || decoded["cognito:username"],
        company_id: Number(decoded["custom:company_id"]),
        branch_id: Number(decoded["custom:branch_id"]),
        type: Number(decoded["custom:type"]),
        legacy_id: Number(decoded["custom:legacy_id"]),
      }
    } catch (err) {
      return {
        id: "anonymous",
        email: "",
        username: "Anonymous",
      }
    }
  })
  const user = getUserInfo(idToken)

  return (
    <AuthProviderContext.Provider
      value={{
        code,
        user,
        token,
        idToken,
        refreshToken,
        isLogin: isTokenValid(token),
        signInWithPopup,
        logout,
      }}
    >
      {props.children}
    </AuthProviderContext.Provider>
  )
}

const useAuthProvider = () => {
  const state = useContext(AuthProviderContext)
  return state
}

export default useAuthProvider
