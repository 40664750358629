import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import _ from "lodash"
import { Formik, Form, FieldArray } from "formik"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"

import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"

import useDataprovider from "../../hooks/useDataprovider"
import useAuthprovider from "../../hooks/useAuthprovider"
import useLastVisitBrand from "../../hooks/useLastVisitBrand"

import Slider from "../../components/Slider"
import CorporateHeader from "./components/corporateHeader"
import CorporateFooter from "./components/corporateFooter"
import CorporateCalculationSetting from "./components/corporateCalculationSetting"
import CorporateCarForm from "./components/corporateCarForm"
import CalculationAppBar from "../calculate/components/CalculationAppBar"
import { calculateFinanceAmount } from "../calculate/utils/calculation"
import { PAYMENT_METHOD, PAYMENT_TYPE, BSI_INCLUDE_TYPE } from "../../constants"

import prepareSubmitData from "./utils/prepare-submit-data"

import campaignOptions from "../../data/campaignOptions"

import { capitalizeBrand } from "../utils"
import useStyles from "./style"

const CorporateSales = () => {
  const { findSFRate, switchDomain } = useDataprovider()
  const params = useParams()
  const { brand } = params
  const cappedBrand = capitalizeBrand(brand)
  const history = useHistory()
  const [lastVisitBrand, setLastVisitBrand] = useLastVisitBrand()
  const { setBrand } = useDataprovider()
  const { submitCorporateSale } = useDataprovider()
  const { user } = useAuthprovider()
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    // Let dataProvider know which matrix to pick from
    switchDomain("corporateSale")
  }, [])

  useEffect(() => {
    if (brand) {
      setBrand(brand)
      setLastVisitBrand(brand) // save to localStorage
    } else {
      history.push(`/corporate-sales/${lastVisitBrand}`)
    }
  }, [brand, setBrand])

  const carInitialValues = {
    name: ``,
    media_url: undefined,
    model_margin: {},
    model_has_freedomchoice: false,
    margin_rate: 0,
    rebate: 0,
    serie: "",
    model: "",
    retail_price: 0,
    quantity: 1,
    type_of_payment: PAYMENT_TYPE.HIRE_PURCHASE,
    payment_method: PAYMENT_METHOD.FINANCE,
    term: 60,
    bsi: 3,
    bsi_price: 0,
    bsi_inc: BSI_INCLUDE_TYPE.FINANCE,
    insurance: 0,
    discount: 0,
    down_payment: 35,
    monthly_installment: 0,
    balloon: 0,
    boundary: {
      balloon: [0, 45],
      down_payment: [0, 35],
    },
    locked: {
      margin_rate: false,
      monthly_installment: false,
      down_payment: true,
      balloon: true,
    },
    goalseek: "monthly_installment",
    campaigns: campaignOptions
      .filter((option) => option.lock === true)
      .map((option) => option.id),
  }

  const initialValues = {
    corporate_name: "",
    company_address: "",
    contact_name: "",
    contract_no: "",
    account_no: "",
    corporate_channel: "SME",
    cars: [carInitialValues],
    advance_calculation: false,
    sale_consult_name: "",
    sale_consult_no: "",
    additional_discount: 0,
    remark: "",
    term:
      "- ใบเสนอราคามีอายุ 7 วัน\n- ราคาอุปกรณ์ตกแต่งและค่าแรงสามารถเปลี่ยนแปลงได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า \n- อุปกรณ์ตกแต่งและอะไหล่แท้จาก บริษัท บีเอ็มดับเบิลยู (ประเทศไทย) จำกัด รับประกันตามเงื่อนไขของทางบริษัทฯ เป็นระยะเวลา 2 ปี ไม่จำกัดระยะทาง",
  }

  const trigger = useScrollTrigger({
    threshold: 430,
    disableHysteresis: true,
  })

  const onSubmit = (values) => {
    const submitValues = prepareSubmitData(values, {
      user,
      lang: i18n.language,
      findSFRate,
    })
    const windowReference = window.open()
    submitCorporateSale(submitValues)
      .then((response) => {
        windowReference.location = `/quotation/pdf/corporatesales-${response.id}`
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const validate = (values) => {
    const errors = {}

    values.cars.forEach((car, index) => {
      const {
        retail_price,
        discount,
        bsi,
        bsi_inc,
        bsi_price,
        insurance,
        down_payment,
        type_of_payment,
        balloon,
        monthly_installment,
        margin_rate,
        model,
        payment_method,
        term,
        boundary,
        quantity,
        campaigns,
      } = car

      const sf_rate = findSFRate(
        model,
        type_of_payment,
        payment_method,
        term,
        down_payment,
        balloon
      )
      const totalRetailPrice = retail_price * quantity
      // Series
      if (!car.serie)
        _.set(errors, `cars.${index}.serie`, t("field.serie.placeholder"))

      // Model
      if (!car.model)
        _.set(errors, `cars.${index}.model`, t("field.model.placeholder"))

      // Check Insurance
      if (insurance > 0.1 * totalRetailPrice) {
        _.set(
          errors,
          `cars.${index}.insurance`,
          t("field.insurance.warning.over")
        )
      } else if (insurance < 0) {
        _.set(
          errors,
          `cars.${index}.insurance`,
          t("field.insurance.warning.under")
        )
      }

      // Check Discount
      if (discount >= totalRetailPrice) {
        _.set(
          errors,
          `cars.${index}.discount`,
          t("field.discount.warning.over")
        )
      } else if (discount < 0) {
        _.set(
          errors,
          `cars.${index}.discount`,
          t("field.discount.warning.under")
        )
      }

      const totalAddOnAmount = quantity * bsi_price + insurance

      //check finance amount
      const financeAmount = calculateFinanceAmount({
        retail_price,
        down_payment,
        discount,
        addOnAmountInFinance: totalAddOnAmount,
        bsi_inc,
        bsi_price,
        quantity,
      })
      if (financeAmount <= 0)
        _.set(
          errors,
          `cars.${index}.finance_amount`,
          t("field.finance_amount.warning")
        )

      // check down payment
      const minDownpaymentAllow = _.get(boundary, "down_payment.0", 0)
      const maxDownpaymentAllow = _.get(boundary, "down_payment.1", 35)
      if (down_payment > maxDownpaymentAllow) {
        _.set(
          errors,
          `cars.${index}.down_payment`,
          t("field.down_payment.waring_over", { percent: maxDownpaymentAllow })
        )
      } else if (
        down_payment < minDownpaymentAllow &&
        type_of_payment !== PAYMENT_TYPE.FREEDOM_CHOICE &&
        payment_method !== PAYMENT_METHOD.CASH
      ) {
        _.set(
          errors,
          `cars.${index}.down_payment`,
          t("field.down_payment.waring_under", { percent: minDownpaymentAllow })
        )
      }
      //check balloon
      if (balloon > boundary.balloon[1]) {
        _.set(
          errors,
          `cars.${index}.balloon`,
          t("field.balloon.warning", { percent: boundary.balloon[1] })
        )
      }

      if (monthly_installment < 0) {
        _.set(
          errors,
          `cars.${index}.monthly_installment`,
          t("field.monthly_installment.warning")
        )
      }

      if (margin_rate < -sf_rate) {
        _.set(
          errors,
          `cars.${index}.margin_rate`,
          t("field.margin.error_less_than_sfrate")
        )
      }

      //check bsi only in freedom choice
      if (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE && bsi < term / 12) {
        _.set(
          errors,
          `cars.${index}.bsi`,
          t("field.bsi_option.warning", {
            brand: brand === "mini" ? "MSI" : "BSI",
          })
        )
      }

      //Check special campaign
      if (campaigns.length > 3) {
        _.set(
          errors,
          `cars.${index}.campaigns`,
          t("field.special_campaign.warning.over")
        )
      }
    })
    return errors
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {cappedBrand} Corporate Sales Quote | DSK | BMW Leasing Thailand
        </title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ errors, isValid, values, handleChange }) => (
          <Form>
            <FieldArray name="cars">
              {({ push, remove }) => (
                <div>
                  <CalculationAppBar
                    values={values}
                    carInitialValues={carInitialValues}
                    push={push}
                    LeftPanel={CorporateCalculationSetting}
                  />
                  <div className={classes.headerBackground}>
                    <CorporateHeader
                      handleChange={handleChange}
                      values={values}
                    />
                  </div>
                  <div className={classes.main}>
                    <div className={classes.sidebarLeft}>
                      <div
                        className={
                          trigger
                            ? classes.positionSticky
                            : classes.positionStatic
                        }
                      >
                        <CorporateCalculationSetting
                          values={values}
                          push={push}
                          remove={remove}
                          carInitialValues={carInitialValues}
                        />
                      </div>
                    </div>
                    <div className={classes.mainCenter}>
                      <Slider
                        items={values.cars}
                        renderItem={(car, index) => {
                          return (
                            <CorporateCarForm
                              carMedia={car.media_url}
                              carsLength={values.cars.length}
                              isValid={
                                _.size(_.get(errors, `cars.${index}`)) === 0
                              }
                              index={index}
                              remove={remove}
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </FieldArray>
            <div className={classes.errorContainer}>
              {_.map(_.get(errors, "cars", []), (car, index) => {
                if (car === undefined) return null
                else
                  return (
                    <Alert
                      key={`alert-${index}`}
                      severity="error"
                      style={{ marginBottom: 8 }}
                    >
                      <AlertTitle>
                        {!!_.get(values, `cars.${index}.name`)
                          ? _.get(values, `cars.${index}.name`)
                          : `Error`}
                      </AlertTitle>
                      <ul style={{ paddingLeft: 0 }}>
                        {_.map(car, (error, i) => (
                          <li key={`error-${i}`}>{error}</li>
                        ))}
                      </ul>
                    </Alert>
                  )
              })}
            </div>
            <div className={classes.footer}>
              <CorporateFooter
                handleChange={handleChange}
                values={values}
                isValid={isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CorporateSales
