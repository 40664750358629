import React from "react"
import InputField from "../../../components/InputField/InputField"
import { useField, useFormikContext } from "formik"
import {
  getNetValue,
  getVat,
  getTotal,
  getDiscountValue,
} from "../utils/calculation"

export const DiscountBahtField = (props) => {
  const { name, type, ...rest } = props
  const [field] = useField(props.name)
  const { values } = useFormikContext()
  const { value } = field
  return (
    <InputField
      name={name}
      type={type}
      value={value}
      hidden={values.discount_type === "1"}
      {...rest}
    />
  )
}

export const DiscountPercentField = (props) => {
  const { name, type, ...rest } = props
  const [field] = useField(props.name)
  const { values } = useFormikContext()
  const { value } = field
  return (
    <InputField
      name={name}
      type={type}
      value={value}
      hidden={values.discount_type === "0"}
      {...rest}
    />
  )
}

export const NetField = (props) => {
  const { name, type, label, ...rest } = props
  const { values } = useFormikContext()
  return (
    <InputField
      name={name}
      type={type}
      label={label}
      value={getNetValue(values.purchase_accessories)}
      {...rest}
    />
  )
}

export const SummaryDiscountValueField = (props) => {
  const { type, label, ...rest } = props
  const { values } = useFormikContext()

  return (
    <InputField
      type={type}
      label={label}
      value={getDiscountValue(values)}
      {...rest}
    />
  )
}

export const SummaryInstallField = (props) => {
  const { type, label, ...rest } = props
  const { values } = useFormikContext()
  return (
    <InputField
      type={type}
      label={label}
      value={values.install_fee || 0}
      {...rest}
    />
  )
}

export const VatField = (props) => {
  const { name, type, label, ...rest } = props
  const { values } = useFormikContext()
  return (
    <InputField
      name={name}
      type={type}
      label={label}
      value={getVat(values)}
      {...rest}
    />
  )
}

export const TotalField = (props) => {
  const { name, type, label, ...rest } = props
  const { values } = useFormikContext()
  return (
    <InputField
      name={name}
      type={type}
      label={label}
      value={getTotal(values)}
      {...rest}
    />
  )
}
