import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    height: "100vh",
  },
  captionStyle: {
    fontWeight: 300,
    marginBottom: theme.spacing(2),
    whiteSpace: "break-spaces",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuList: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 0,
  },
  menuButtonLanguage: {
    textTransform: "uppercase",
    width: "max-content",
    color: theme.palette.primary,
    "&.selected": {
      fontWeight: 700,
      color: "#1D68D4",
    },
  },
  buttonProgress: {
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: "50%",
    marginLeft: "-12px",
    marginTop: "-12px",
  },
  sideGridStyle: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  langContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    flexDirection: "column",
    height: "100%",
  },
}))

export default useStyles
