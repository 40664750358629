import { Decimal } from "decimal.js"
import Finance from "tvm-financejs"
import _ from "lodash"

import {
  PAYMENT_TYPE,
  BSI_INCLUDE_TYPE,
  VAT_INCLUDE_TYPE,
} from "../../../constants"

export const findRate = (sf_rate, margin_rate = 0) => {
  return new Decimal(sf_rate + margin_rate).toNumber()
}

export const calculateCarPrice = (estimate_car_price = 0) => {
  return estimate_car_price
}

/**
 *
 * @param {*} car_price
 * @param {*} vat_include_type
 * @param {*} down_payment
 * @param {*} discount
 * @param {*} insurance_or_accessories
 * @returns Finanace Amount = (car price + down (carprice) + ins) x 1.07
 */
export const calculateFinanceAmount = (
  estimate_car_price = 0,
  vat_include_type = VAT_INCLUDE_TYPE.FINANCE,
  down_payment = 0,
  discount = 0,
  insurance_or_accessories = 0
) => {
  if (!estimate_car_price) return 0
  const totalPrice = new Decimal(estimate_car_price)

  if (vat_include_type === VAT_INCLUDE_TYPE.FINANCE) {
    return totalPrice
      .times(1 - down_payment * 0.01)
      .add(insurance_or_accessories)
      .times(1.07)
      .toDP(2)
      .toNumber()
  } else {
    return totalPrice
      .times(1 - down_payment * 0.01)
      .add(insurance_or_accessories)
      .toDP(2)
      .toNumber()
  }
}

export const calculateFutureValue = (car_price = 0, balloon = 0) => {
  return new Decimal(car_price)
    .times(0.01)
    .times(_.toNumber(balloon))
    .toDP(2)
    .toNumber()
}

export const calculateBalloon = (future_value, retail_price, discount = 0) => {
  return new Decimal(future_value)
    .div(retail_price - discount)
    .times(100)
    .toDP(2)
    .toNumber()
}

export const calculateResidual = (
  car_price = 0,
  down_payment = 0,
  balloon = 0
) => {
  return new Decimal(_.toNumber(down_payment) + _.toNumber(balloon))
    .times(0.01)
    .times(car_price)
    .toDP(2)
    .toNumber()
}

export const calculateDownPaymentPercent = (
  financeAmount,
  carPrice,
  bsi_inc = BSI_INCLUDE_TYPE.FINANCE,
  bsi_price = 0
) => {
  const bsiAmount = bsi_inc === BSI_INCLUDE_TYPE.FINANCE ? bsi_price : 0
  return new Decimal((carPrice - financeAmount + bsiAmount) / carPrice)
    .times(100)
    .toNumber()
}
/*
 * ==========================================================================================
 * Time-Value-Money Calculation
 * ==========================================================================================
 
  * get Monthly Installment function
  * -----------------
  * 1. generate a Monthly Installment by using a variant of type_of_payment
  * 2. if type_of_payment === Hire Purchase will using
  *    Customer Flat Rate (sf_rate + margin_rate)  *  finance_amount * term / 12 then add finance_amount after that will divide by term
  * 3. if type_of_payment !== Hire Purchase will using PMT (payment finance function base on excel)
  *    which is fill with ( Customer Eff Rate (sf_rate + margin_rate)/12, Term, Finance_amount, Balloon )
  */

export const tvmCalculatePayment = (
  rate,
  term,
  presentValue,
  finalValue,
  type_of_payment
) => {
  // console.log(`tvmCalculatePayment`, {
  //   rate,
  //   term,
  //   presentValue,
  //   finalValue,
  //   type_of_payment
  // })
  const finance = new Finance()
  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    // Flat rate
    return new Decimal(0.01)
      .times(rate)
      .times(presentValue)
      .times(term)
      .div(12)
      .add(presentValue)
      .div(term)
      .toDP(2)
      .toNumber()
    // return ((0.01 * rate * presentValue * term) / 12 + presentValue) / term
  } else {
    // non-flat, PMT rate
    const pmt = finance.PMT((0.01 * rate) / 12, term, -presentValue, finalValue)
    return pmt
  }
}

export const tvmCalculatePresentValue = (
  rate,
  term,
  payment,
  futureValue = 0,
  type_of_payment = PAYMENT_TYPE.HIRE_PURCHASE
) => {
  const type = 0 // when payments are due (0 for end of period/arrears, and 1 for beginning of period/advance)
  const finance = new Finance()

  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    return new Decimal(payment)
      .times(term)
      .div((0.01 * rate * term) / 12 + 1)
      .toDP(2)
      .toNumber()
  } else {
    const pv = -finance.PV((0.01 * rate) / 12, term, payment, futureValue, type)
    // console.log(`tvmCalculatePresentValue`, {
    //   pv,
    //   rate: (0.01 * rate) / 12,
    //   term,
    //   payment,
    //   futureValue,
    //   type,
    // })
    return pv
  }
}

export const tvmCalculateRate = (
  term,
  payment,
  presentValue,
  futureValue = 0,
  type_of_payment = PAYMENT_TYPE.HIRE_PURCHASE
) => {
  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    const rate =
      ((12 * (payment * term - presentValue)) / (presentValue * term)) * 100
    return rate
  } else {
    const type = 0 // when payments are due (0 for end of period/arrears, and 1 for beginning of period/advance)
    const finance = new Finance()
    const rate =
      finance.RATE(term, payment, -presentValue, futureValue, type) * 12
    return new Decimal(rate).toDP(4).times(100).toNumber()
  }
}

export const tvmCalculateFutureValue = (rate, term, payment, presentValue) => {
  const finance = new Finance()
  return finance.FV((0.01 * rate) / 12, term, payment, presentValue, 0)
}

/*
 * ==========================================================================================
 * End of Time-Value-Money Calculation
 * ==========================================================================================
 */

export const calculateDiscountRate = (margin_rate = 0, sf_rate = 0) => {
  if (margin_rate > 0) return 0.35 * margin_rate + sf_rate
  return sf_rate
}

export const calculateRebate = (
  marginRate,
  sfRate,
  term,
  presentValue,
  futureValue = 0,
  type_of_payment
) => {
  const finance = new Finance()
  const discountRate = calculateDiscountRate(marginRate, sfRate)
  const customerRate = findRate(marginRate, sfRate)
  const monthlyInstallment = tvmCalculatePayment(
    customerRate,
    term,
    presentValue,
    futureValue,
    type_of_payment
  )
  const discountInstallment = tvmCalculatePayment(
    discountRate,
    term,
    presentValue,
    type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE ? 0 : futureValue,
    type_of_payment
  )

  let discountEffectiveRate
  if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
    discountEffectiveRate =
      finance.RATE(term, discountInstallment, -presentValue, 0, 0) * 12 * 100
  } else discountEffectiveRate = discountRate
  const pv = -finance.PV(
    (0.01 * discountEffectiveRate) / 12,
    term,
    monthlyInstallment - discountInstallment,
    0
  )
  return new Decimal(pv).toDP(2).toNumber()
}
