import React from "react"
import { useParams } from "react-router-dom"
import * as Sentry from "@sentry/react"

import useDataprovider from "../hooks/useDataprovider"

const PdfViewer = (props) => {
  const { type } = props
  const [errorMessage, setErrorMessage] = React.useState(null)
  const {
    getQuotationPdf,
    getAccessoryQuotationPdf,
    getBookingPdf,
    getCorporateSaleQuotationPdf,
    usedCarProvider,
  } = useDataprovider()

  const { getQuotationPdf: getUsedcarQuotationPdf } = usedCarProvider

  const params = useParams()
  React.useEffect(() => {
    let getPdf
    let id
    switch (type) {
      case "accessory":
        getPdf = getAccessoryQuotationPdf
        id = params.quotationId
        break
      case "usedcar-quotation":
        getPdf = getUsedcarQuotationPdf
        id = params.quotationId
        break
      case "booking":
        getPdf = getBookingPdf
        id = params.bookingId
        break
      case "corporate-sale-quotation":
        getPdf = getCorporateSaleQuotationPdf
        id = params.quotationId
        break
      default:
        getPdf = getQuotationPdf
        id = params.quotationId
        break
    }

    getPdf(id)
      .then((pdfUrl) => {
        console.log(pdfUrl)
        window.location = pdfUrl
      })
      .catch((err) => {
        console.log(err)
        Sentry.captureException(err)
        setErrorMessage(
          `An error occurred. Cannot view PDF for Quotation Type = ${type}, id = ${id}. Please contact admin team to investigate.`
        )
      })
  }, [params, type])

  if (errorMessage)
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {errorMessage}
      </div>
    )
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Generating PDF
    </div>
  )
}

PdfViewer.defaultProps = {
  type: "car",
}

export default PdfViewer
