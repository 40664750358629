import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import { Hidden, makeStyles } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ImageIcon from "@material-ui/icons/Image"

import axios from "axios"
import InputField from "../../../components/InputField"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  accessoryListContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    backgroundColor: "#fff",
    borderRadius: "10px",
    filter: "drop-shadow(0 0 0.75rem rgba(0,0,0,0.15))",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: "2px solid rgba(0,0,0,0.18)",
      filter: "none",
      borderRadius: 0,
      margin: 0,
    },
  },
  gridCustomSpacing: {
    paddingRight: theme.spacing(2.5),
  },
  imageContainer: {
    borderRadius: "10px",
    width: "80px",
    height: "80px",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  },
  iconContainer: {
    marginBottom: theme.spacing(1.7),
  },
  emptyImgContainer: {
    border: "3px dotted gray",
    borderRadius: "10px",
    width: "80px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& input": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  accessoryListTextAlign: {
    "& .MuiInputBase-input": {
      textAlign: "left",
    },
  },
}))

const AccessoryList = (props) => {
  const { values, remove, onChange, index /*...rest*/ } = props
  const { name, quantity, price, img_url } = values
  const classes = useStyles()
  const matches = useMediaQuery("(max-width:960px)")
  const { t } = useTranslation()
  const inputFileRef = React.useRef()

  const handleImageUpload = () => {
    const { files } = inputFileRef.current
    const formData = new FormData()
    formData.append("file", files[0])
    formData.append(
      "upload_preset",
      `${process.env.REACT_APP_CLOUDINARY_PRESETS}`
    )
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/`,
        formData
      )
      .then((res) => {
        onChange({
          target: {
            name: `purchase_accessories.${index}.img_url`,
            value: _.replace(
              res.data.secure_url,
              "upload/",
              "upload/w_80,h_80,c_fill/"
            ),
          },
        })
        onChange({
          target: {
            name: `purchase_accessories.${index}.quantity`,
            value: 1,
          },
        })
      })
      .catch((err) => console.log(err))
  }

  const imageController = img_url ? (
    <div className={classes.imageContainer}>
      <img className={classes.imageStyle} src={img_url} alt={`img-${index}`} />
    </div>
  ) : (
    <div className={classes.emptyImgContainer}>
      <input
        accept="image/*"
        name={`purchase_accessories.${index}.img_url`}
        type="file"
        id={`icon-button-file${index}`}
        ref={inputFileRef}
        onChange={handleImageUpload}
      />
      <label htmlFor={`icon-button-file${index}`}>
        <IconButton aria-label="upload picture" component="span">
          <ImageIcon />
        </IconButton>
      </label>
    </div>
  )

  let net_price = quantity * price
  return (
    <div className={classes.root}>
      <div className={classes.accessoryListContainer}>
        <Grid container alignItems="center">
          <Grid item md={1} xs={10}>
            {imageController}
          </Grid>
          <Grid item md={1} xs={2}>
            <Hidden smUp>
              <IconButton onClick={() => remove(index)}>
                <DeleteIcon />
              </IconButton>
            </Hidden>
          </Grid>
          <Grid item md={3} xs={12}>
            <div className={classes.gridCustomSpacing}>
              <InputField
                name={`purchase_accessories.${index}.name`}
                label={matches ? t("field.acc_item.label") : null}
                className={classes.accessoryListTextAlign}
                value={t(name)}
                type="text"
                placeholder="Accessory name"
                onChange={onChange}
              />
            </div>
          </Grid>
          <Grid item md={2} xs={6}>
            <div className={classes.gridCustomSpacing}>
              <InputField
                name={`purchase_accessories.${index}.quantity`}
                label={matches ? t("field.acc_qty.label") : null}
                value={quantity}
                type="quantity"
                onChange={onChange}
              />
            </div>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={6} />
          </Hidden>
          <Grid item md={2} xs={6}>
            <div className={classes.gridCustomSpacing}>
              <InputField
                name={`purchase_accessories.${index}.price`}
                label={matches ? t("field.unit_price.label") : null}
                value={price}
                type="number"
                onChange={onChange}
              />
            </div>
          </Grid>
          <Grid item md={2} xs={6}>
            <div className={classes.gridCustomSpacing}>
              <InputField
                name="net_price"
                label={matches ? t("field.net_price.label") : null}
                value={net_price}
                type="number"
                className={classes.accessoryListTextAlign}
                disabled
              />
            </div>
          </Grid>
          <Hidden smDown>
            <Grid item md={1}>
              <div className={classes.iconContainer}>
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </div>
  )
}

export default AccessoryList
