import React from "react"
import { makeStyles } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"

import CalculationSetting from "./CalculationSetting"

const drawerWidth = `240`

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {},
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  closeButton: {
    position: "absolute",
    top: 8,
    right: 0,
  },
  calculationToolbar: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
  },
  menuButton: {
    color: "white",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    color: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: "80%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const CalculationAppBar = React.memo(
  ({ values = [], push, carInitialValues }) => {
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen)
    }

    const trigger = useScrollTrigger({ treshold: 64, disableHysteresis: true })

    const handleAddCar = () => {
      push(carInitialValues)
    }

    return (
      <div className={classes.root}>
        <Hidden mdUp>
          <AppBar
            position={trigger ? "fixed" : "static"}
            color="primary"
            elevation={1}
            className={classes.appBar}
          >
            <Toolbar className={classes.calculationToolbar}>
              <Button
                variant="outlined"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                Setting
              </Button>
              <Typography className={classes.title} noWrap>
                <Hidden smDown>Create Quotation</Hidden>
              </Typography>
              <Button
                variant="outlined"
                onClick={handleAddCar}
                className={classes.menuButton}
                startIcon={<AddIcon />}
              >
                Car
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              className={classes.closeButton}
              onClick={handleDrawerToggle}
            >
              <CloseIcon />
            </IconButton>
            <CalculationSetting values={values} />
          </Drawer>
        </Hidden>
      </div>
    )
  }
)

export default CalculationAppBar
