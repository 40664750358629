import { withRouter } from "react-router-dom"
import ReactGA from "react-ga"

const RouteChangeTracker = ({ history }) => {
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(window.location.pathname + window.location.search, [
      "tracker1",
    ])
    ReactGA.pageview(window.location.pathname + window.location.search, [
      "tracker2",
    ])
  })
  return null
}

export default withRouter(RouteChangeTracker)
