import Decimal from "decimal.js"
import qs from "qs"

export const thousandSeparator = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const addVat = (num) => {
  return new Decimal(num).times(1.07).toDP(0).toNumber()
}

export const getTotalWheelPrice = (wheels) => {
  return wheels.reduce(
    (acc, curr) =>
      acc +
      addVat(
        (curr.discounted_price ? curr.discounted_price : curr.unit_price) *
          curr.quantity
      ),
    0
  )
}

export const prepareExportData = ({ seriesId, wheels }) => {
  const parsedWheels = wheels.map((wheel) => {
    return { id: wheel.id, quantity: wheel.quantity }
  })
  const obj = {
    seriesId,
    wheels: parsedWheels,
  }

  return qs.stringify(obj)
}
